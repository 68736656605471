import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createCompanies = (payload) => {
    return doPrivatePostRequest(payload, '/companies/create')
}

export const updateCompanies = (_doc_id, payload) => {
    return doPrivatePostRequest(payload, `/companies/update/${_doc_id}`)
}

export const getCompanies = (_doc_id) => {
    return doPrivateGetRequest(`/companies/get/${_doc_id}`)
}

export const getAllCompanies = () => {
    const payload = {
        'st': 'ALL'
    }
    return doPrivatePostRequest(payload, '/companies/get')
}

export const deleteCompanies = (_doc_id) => {
    return doPrivateDelRequest(`/companies/delete/${_doc_id}`)
}
