import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisH, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Table, Dropdown, ButtonGroup, Form } from '@themesberg/react-bootstrap';

export const ProductsTables = ({ brands, products, deleteProducts, handleEditProduct }) => {
  const [filters, setFilters] = useState({
    name: "",
    slug: "",
    price: "",
    category: ""
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const filteredProducts = products.filter(product => {
    return (
      (filters.name === "" || (product.name ?? "").toLowerCase().includes(filters.name.toLowerCase())) &&
      (filters.slug === "" || (product.slug ?? "").toLowerCase().includes(filters.slug.toLowerCase())) &&
      (filters.price === "" || (product.price ?? "").toLowerCase().includes(filters.price.toLowerCase())) &&
      (filters.category === "" || (product.category ?? "").toLowerCase().includes(filters.category.toLowerCase()))
    );
  });

  const getBrandNameById = (id) => {
    const brand = brands.find(brand => brand._id === id);
    return brand ? brand.brand_name : 'Unknown';
  };

  const TableRow = (props) => {
    const { _id, name, slug, price, brand, description, meta_data, category } = props;

    return (
      <tr>
        <td>
          <div>
            {meta_data && meta_data.product_image ? (
              <img src={meta_data.product_image} width="75px" height="75px" alt="Product Image" />
            ) : (
              <img src="https://via.placeholder.com/150" width="75px" height="75px" alt="Default Placeholder" />
            )}
          </div>
        </td>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center" onClick={() => handleEditProduct({ _id, name, slug, price, brand, description, meta_data, category })}>
            <div>
              <span className="h6">{name}</span><br />
              <small>{getBrandNameById(brand)}</small>
            </div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">
          {slug ? slug : "-"}
        </td>
        <td className="border-0">
          {price}
        </td>
        <td className="fw-bold border-0">
          {category}
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="tableNav">
              <Dropdown.Item onClick={() => handleEditProduct({ _id, name, slug, price, brand, description, meta_data, category })}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={() => deleteProducts(_id)}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Thumbnail</th>
              <th className="border-0">Name</th>
              <th className="border-0">Slug</th>
              <th className="border-0">Price</th>
              <th className="border-0">Category</th>
              <th className="border-bottom">Action</th>
            </tr>
            <tr>
              <th></th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Name"
                  name="name"
                  value={filters.name}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Slug"
                  name="slug"
                  value={filters.slug}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Price"
                  name="price"
                  value={filters.price}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Category"
                  name="category"
                  value={filters.category}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product) => <TableRow key={`listing-${product._id}`} {...product} handleEditProduct={handleEditProduct} deleteProducts={deleteProducts} />)
            ) : (
              <tr>
                <td colSpan="8" className="text-center">
                  No records could be found at this time. Create your first product by clicking Add Product
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
