import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Card, Container, Button, Row, Col } from '@themesberg/react-bootstrap';

const SignupSuccess = ({ personalDetails, companyDetails, selectedPackage, paymentDetails }) => {

    const handleDownloadReceipt = () => {
        // Logic to generate and download PDF receipt
    };

    const handleSignIn = () => {
        // Logic to navigate to sign-in page
    };

    return (
        <Container fluid className="min-vh-100 d-flex flex-column bg-primary text-white py-6 justify-content-center align-items-center">
            <Row className="text-center mb-4">
                <Col>
                    <FontAwesomeIcon icon={faCheckCircle} size="4x" className="text-success" />
                    <h3 className="d-inline-block ms-3">Signup Success</h3>
                </Col>
            </Row>
            <Card className="p-4 text-black" style={{ width: '80%', maxWidth: '900px' }}>
                <Card.Body>
                    <Card.Title className="text-center mb-4">Invoice</Card.Title>
                    <Row className="mb-4">
                        <Col>
                            <h5>Personal Details</h5>
                            <ul>
                                <li>Name: {personalDetails?.firstName || 'N/A'} {personalDetails?.lastName || 'N/A'}</li>
                                <li>Email: {personalDetails?.email || 'N/A'}</li>
                                <li>Account Type: {personalDetails?.accountType || 'N/A'}</li>
                            </ul>
                        </Col>
                        <Col>
                            <h5>Company Details</h5>
                            <ul>
                                <li>Legal Name: {companyDetails?.legal_name || 'N/A'}</li>
                                <li>Trading Name: {companyDetails?.trading_name || 'N/A'}</li>
                                <li>Registration Number: {companyDetails?.registration_number || 'N/A'}</li>
                                <li>VAT Number: {companyDetails?.vat_number || 'N/A'}</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="mb-4 text-black">
                        <Col>
                            <h5>Selected Package</h5>
                            <p>
                                <strong>Package:</strong> {selectedPackage?.title || 'N/A'}<br />
                                <strong>Price:</strong> R{selectedPackage?.isAnnual ? selectedPackage.annual_price : selectedPackage?.monthly_price || 'N/A'} |
                                USD ${selectedPackage?.isAnnual ? selectedPackage.annual_usd_price : selectedPackage?.monthly_usd_price || 'N/A'} |
                                EUR €{selectedPackage?.isAnnual ? selectedPackage.annual_eu_price : selectedPackage?.monthly_eu_price || 'N/A'}
                            </p>
                            <h6>Features:</h6>
                            <ul>
                                {selectedPackage?.features && selectedPackage.features.map((feature, index) => (
                                    <li key={index}>{feature.text}</li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                    <Row className="mb-4 text-black">
                        <Col>
                            <h5>Payment Summary</h5>
                            <p>
                                <strong>Transaction ID:</strong> {paymentDetails?.transactionId || 'N/A'}<br />
                                <strong>Amount Paid:</strong> R{paymentDetails?.amount || 'N/A'}<br />
                                <strong>Payment Date:</strong> {paymentDetails?.date || 'N/A'}
                            </p>
                        </Col>
                    </Row>
                    <Row className="text-center text-black">
                        <Col>
                            <Button variant="primary" onClick={handleSignIn} className="me-2">
                                Sign in to account
                            </Button>
                            <Button variant="secondary" onClick={handleDownloadReceipt}>
                                Download Receipt
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default SignupSuccess;
