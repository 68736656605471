import React, { useState, useEffect, useRef } from 'react';
import Joyride from 'react-joyride';
import { Button } from '@themesberg/react-bootstrap';

const TourGuide = ({ steps, runInitially = false, onComplete }) => {
  const [run, setRun] = useState(runInitially);
  const [stepIndex, setStepIndex] = useState(0);
  const isMountedRef = useRef(true);

  useEffect(() => {
    isMountedRef.current = true;

    if (runInitially) {
      setRun(true);
    }

    return () => {
      isMountedRef.current = false;
    };
  }, [runInitially]);

  const handleJoyrideCallback = (data) => {
    const { status, index } = data;
    const finishedStatuses = ['finished', 'skipped'];
  
    if (finishedStatuses.includes(status)) {
      if (isMountedRef.current) {
        setRun(false);
        if (onComplete) onComplete();
      }
    } else {
      if (isMountedRef.current) {
        setStepIndex(index + 1);
      }
    }
  };  

  const startTour = () => {
    if (isMountedRef.current) {
      setRun(true);
      setStepIndex(0);
    }
  };

  return (
    <>
      {run && (
        <Joyride
          steps={steps}
          run={run}
          stepIndex={stepIndex}
          callback={handleJoyrideCallback}
          continuous
          showSkipButton
          showProgress
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      )}
      <Button variant="brand-orange" className="mb-3" onClick={startTour}>Start Tour</Button>
    </>
  );
}

export default TourGuide;
