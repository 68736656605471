import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createPosts = (payload) => {

    return doPrivatePostRequest(payload, '/website/posts/create')
}

export const updatePosts = (_doc_id, payload) => {
    return doPrivatePostRequest(payload, `/website/posts/update/${_doc_id}`)
}

export const getPosts = (_doc_id) => {
    return doPrivatePostRequest(`/website/posts/get/${_doc_id}`)
}

export const getAllPosts = () => {
    const payload = {
        'st': 'ALL'
    }
    return doPrivatePostRequest(payload, '/website/posts/get')
}

export const deletePosts = (_doc_id) => {
    return doPrivateDelRequest(`/website/posts/delete/${_doc_id}`)
}
