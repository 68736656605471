import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch, faCog, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';
import { getAllUsers, registerUser, deleteUsers, updateUser } from "../../../api/private/users";
import { UsersTables } from "../../../components/tables/UsersTables";
import AddUserModal from "../../../components/modals/AddUserModal";
import AlertComponent from "../../../functions/alerts";
import { useUserRole } from "../../../contexts/UserRoleContext";
import { ToastContainer, toast } from "react-toastify";

const UserAccounts = () => {
  const { userRole } = useUserRole();
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [initialUserData, setInitialUserData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [alert, setAlert] = useState({ type: '', message: '' });

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = () => {
    getAllUsers().then(response => {
      if (response.data.Result === 'OK') {
        setUsers(response.data.Records);
        setAlert({type: 'success', message:`Loaded users successfully`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      } else {
        console.warn(response.data);
        setAlert({type: 'success', message:`Loaded users successfully`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      }
    }).catch(error => {
      console.error(error);
      setAlert({type: 'error', message:`Loaded users unsuccessfully`})
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    });
  };

  function createUserAcc(_obj){
    registerUser(_obj['first_name'], _obj['last_name'], _obj['email_address'], _obj['mobile_number']).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        setShowModal(false)
        setAlert({type: 'success', message:`Created user successfully`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadUsers()
      } else {
        console.warn(_resp.data)
        setAlert({type: 'success', message:`Created user successfully`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadUsers()
      }
    }).catch((_resp) => {
      console.log(_resp)
      setAlert({type: 'error', message:`Created user unsuccessfully`})
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    })
  }

  function deleteUser(user){
    deleteUsers(user).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        setAlert({type: 'success', message:`Deleted user successfully`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadUsers();
      } else {
        console.warn(_resp.data)
        setAlert({type: 'success', message:`Deleted user successfully`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadUsers()
      }
    }).catch((_resp) => {
      console.log(_resp)
      setAlert({type: 'error', message:`Deleted user unsuccessfully`})
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    })
  }

  function updateUserAcc(user, userData){
    console.log("Update function ran with object:", user);
    
    // Check if _doc_id is undefined or not
    if(!user['id']) {
      console.error("Document ID is undefined.");
      setAlert({type: 'error', message:`User doc-id is undefined`})
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      return;
    }

    const payload = {
       'first_name': user['first_name'],
       'last_name': user['last_name'],
       'email_address': user['email_address'],
       'mobile_number': user['mobile_number']
    }
    console.log(user);
  
    updateUser(user['id'], payload).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        setAlert({type: 'success', message:`Updated user successfully`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        setShowModal(false)
        console.log(_resp.data)
        loadUsers()
      } else {
        console.warn(_resp.data)
        setAlert({type: 'success', message:`Updated user successfully`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadUsers()
      }
    }).catch((_resp) => {
      console.log(_resp)
      setAlert({type: 'error', message:`Updated user unsuccessfully`})
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    })
  }

  const handleEditUser = (user) => {
    console.log(user);
    setInitialUserData(user);
    setIsEditMode(true);
    setShowModal(true);
  };

  const handleAddUser = () => {
    setInitialUserData(null);
    setIsEditMode(false);
    setShowModal(true);
  };

  return (
    <>
    <ToastContainer hideProgressBar={true} theme="light" pauseOnHover autoClose={5000}/>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Subscriptions</Breadcrumb.Item>
            <Breadcrumb.Item active>User Accounts</Breadcrumb.Item>
          </Breadcrumb>
          <h4>User Accounts</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
            <Button className="btn btn-primary" onClick={handleAddUser}>Add User</Button>
          </ButtonGroup>
        </div>
      </div>

      <AlertComponent type={alert.type} message={alert.message} />      

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            {/* <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup> */}
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <UsersTables users={users} deleteUser={deleteUser} handleEditUser={handleEditUser} />
      <AddUserModal
        showModal={showModal}
        handleCloseModal={() => setShowModal(false)}
        createUserAcc={createUserAcc}
        updateUserAcc={updateUserAcc}
        initialUserData={initialUserData}
        isEditMode={isEditMode}
      />
    </>
  );
};

export default UserAccounts;
