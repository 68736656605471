import { doPrivatePostRequest } from "./requests";

export const authUser = (email, password) => {

    const payload = {
            'email': email,
            'password': password
        }

    return doPrivatePostRequest(payload, '/auth/auth-user')
}

export const registerUser = (payload) => {
    return doPrivatePostRequest(payload, '/auth/register')
}