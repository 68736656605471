import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Modal, Button, Tabs, Tab } from '@themesberg/react-bootstrap';
import axios from 'axios';

const AddPackageModal = ({ showModal, handleCloseModal, createPackage, updatePackage, initialPackageData, isEditMode }) => {
    const [title, setTitle] = useState('');
    const [features, setFeatures] = useState('');
    const [monthlyPrice, setMonthlyPrice] = useState('');
    const [monthlyUsdPrice, setMonthlyUsdPrice] = useState('');
    const [monthlyEuPrice, setMonthlyEuPrice] = useState('');
    const [annualPrice, setAnnualPrice] = useState('');
    const [annualUsdPrice, setAnnualUsdPrice] = useState('');
    const [annualEuPrice, setAnnualEuPrice] = useState('');
    const [buttonLink, setButtonLink] = useState('');
    const [maxCompanyProfile, setMaxCompanyProfile] = useState('');
    const [maxBrands, setMaxBrands] = useState('');
    const [maxProducts, setMaxProducts] = useState('');
    const [maxUsers, setMaxUsers] = useState('');
    const [maxClientChats, setMaxClientChats] = useState('');
    const [dashboardAccess, setDashboardAccess] = useState(false);
    const [analyticsAccess, setAnalyticsAccess] = useState(false);
    const [reportsAccess, setReportsAccess] = useState(false);

    // Add-On Pricing states
    const [addonBrandsPrice, setAddonBrandsPrice] = useState('');
    const [addonBrandsPriceUsd, setAddonBrandsPriceUsd] = useState('');
    const [addonBrandsPriceEur, setAddonBrandsPriceEur] = useState('');
    const [addonBrandsNumber, setAddonBrandsNumber] = useState('');

    const [addonUsersPrice, setAddonUsersPrice] = useState('');
    const [addonUsersPriceUsd, setAddonUsersPriceUsd] = useState('');
    const [addonUsersPriceEur, setAddonUsersPriceEur] = useState('');
    const [addonUsersNumber, setAddonUsersNumber] = useState('');

    const [addonCompaniesPrice, setAddonCompaniesPrice] = useState('');
    const [addonCompaniesPriceUsd, setAddonCompaniesPriceUsd] = useState('');
    const [addonCompaniesPriceEur, setAddonCompaniesPriceEur] = useState('');
    const [addonCompaniesNumber, setAddonCompaniesNumber] = useState('');

    const [addonChatsPrice, setAddonChatsPrice] = useState('');
    const [addonChatsPriceUsd, setAddonChatsPriceUsd] = useState('');
    const [addonChatsPriceEur, setAddonChatsPriceEur] = useState('');
    const [addonChatsNumber, setAddonChatsNumber] = useState('');

    const [addonProductsPrice, setAddonProductsPrice] = useState('');
    const [addonProductsPriceUsd, setAddonProductsPriceUsd] = useState('');
    const [addonProductsPriceEur, setAddonProductsPriceEur] = useState('');
    const [addonProductsNumber, setAddonProductsNumber] = useState('');

    useEffect(() => {
        if (isEditMode && initialPackageData) {
            setTitle(initialPackageData.title || '');
            setFeatures(initialPackageData.features ? initialPackageData.features.map(f => f.text).join(", ") : '');
            setMonthlyPrice(initialPackageData.monthlyPrice || '');
            setMonthlyUsdPrice(initialPackageData.monthlyUsdPrice || '');
            setMonthlyEuPrice(initialPackageData.monthlyEuPrice || '');
            setAnnualPrice(initialPackageData.annualPrice || '');
            setAnnualUsdPrice(initialPackageData.annualUsdPrice || '');
            setAnnualEuPrice(initialPackageData.annualEuPrice || '');
            setButtonLink(initialPackageData.buttonLink || '');
            setMaxCompanyProfile(initialPackageData.maxCompanyProfile || '');
            setMaxBrands(initialPackageData.maxBrands || '');
            setMaxProducts(initialPackageData.maxProducts || '');
            setMaxUsers(initialPackageData.maxUsers || '');
            setMaxClientChats(initialPackageData.maxClientChats || '');
            setDashboardAccess(initialPackageData.dashboardAccess || false);
            setAnalyticsAccess(initialPackageData.analyticsAccess || false);
            setReportsAccess(initialPackageData.reportsAccess || false);

            // Initialize add-on prices and numbers
            setAddonBrandsPrice(initialPackageData.addonBrandsPrice || '');
            setAddonBrandsPriceUsd(initialPackageData.addonBrandsPriceUsd || '');
            setAddonBrandsPriceEur(initialPackageData.addonBrandsPriceEur || '');
            setAddonBrandsNumber(initialPackageData.addonBrandsNumber || '');

            setAddonUsersPrice(initialPackageData.addonUsersPrice || '');
            setAddonUsersPriceUsd(initialPackageData.addonUsersPriceUsd || '');
            setAddonUsersPriceEur(initialPackageData.addonUsersPriceEur || '');
            setAddonUsersNumber(initialPackageData.addonUsersNumber || '');

            setAddonCompaniesPrice(initialPackageData.addonCompaniesPrice || '');
            setAddonCompaniesPriceUsd(initialPackageData.addonCompaniesPriceUsd || '');
            setAddonCompaniesPriceEur(initialPackageData.addonCompaniesPriceEur || '');
            setAddonCompaniesNumber(initialPackageData.addonCompaniesNumber || '');

            setAddonChatsPrice(initialPackageData.addonChatsPrice || '');
            setAddonChatsPriceUsd(initialPackageData.addonChatsPriceUsd || '');
            setAddonChatsPriceEur(initialPackageData.addonChatsPriceEur || '');
            setAddonChatsNumber(initialPackageData.addonChatsNumber || '');

            setAddonProductsPrice(initialPackageData.addonProductsPrice || '');
            setAddonProductsPriceUsd(initialPackageData.addonProductsPriceUsd || '');
            setAddonProductsPriceEur(initialPackageData.addonProductsPriceEur || '');
            setAddonProductsNumber(initialPackageData.addonProductsNumber || '');
        } else {
            resetForm();
        }
    }, [initialPackageData, isEditMode]);

    const resetForm = () => {
        setTitle('');
        setFeatures('');
        setMonthlyPrice('');
        setMonthlyUsdPrice('');
        setMonthlyEuPrice('');
        setAnnualPrice('');
        setAnnualUsdPrice('');
        setAnnualEuPrice('');
        setButtonLink('');
        setMaxCompanyProfile('');
        setMaxBrands('');
        setMaxProducts('');
        setMaxUsers('');
        setMaxClientChats('');
        setDashboardAccess(false);
        setAnalyticsAccess(false);
        setReportsAccess(false);

        // Reset add-on prices and numbers
        setAddonBrandsPrice('');
        setAddonBrandsPriceUsd('');
        setAddonBrandsPriceEur('');
        setAddonBrandsNumber('');

        setAddonUsersPrice('');
        setAddonUsersPriceUsd('');
        setAddonUsersPriceEur('');
        setAddonUsersNumber('');

        setAddonCompaniesPrice('');
        setAddonCompaniesPriceUsd('');
        setAddonCompaniesPriceEur('');
        setAddonCompaniesNumber('');

        setAddonChatsPrice('');
        setAddonChatsPriceUsd('');
        setAddonChatsPriceEur('');
        setAddonChatsNumber('');

        setAddonProductsPrice('');
        setAddonProductsPriceUsd('');
        setAddonProductsPriceEur('');
        setAddonProductsNumber('');
    };

    const fetchExchangeRates = async () => {
        try {
            const response = await axios.get(`https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_dPHfQwAfQLRubJ6hHtwgyFTEvlXY5B6zQ2KENmt0&currencies=USD,EUR&base_currency=ZAR`);
            const rates = response.data.data;

            // Convert for monthly
            const monthlyRandToUsd = rates.USD ? monthlyPrice * rates.USD : 0;
            const monthlyRandToEur = rates.EUR ? monthlyPrice * rates.EUR : 0;
            setMonthlyUsdPrice(monthlyRandToUsd.toFixed(2));
            setMonthlyEuPrice(monthlyRandToEur.toFixed(2));

            // Convert for annual
            const annualRandToUsd = rates.USD ? annualPrice * rates.USD : 0;
            const annualRandToEur = rates.EUR ? annualPrice * rates.EUR : 0;
            setAnnualUsdPrice(annualRandToUsd.toFixed(2));
            setAnnualEuPrice(annualRandToEur.toFixed(2));

            // Convert for add-ons
            setAddonBrandsPriceUsd((addonBrandsPrice * rates.USD).toFixed(2));
            setAddonBrandsPriceEur((addonBrandsPrice * rates.EUR).toFixed(2));

            setAddonUsersPriceUsd((addonUsersPrice * rates.USD).toFixed(2));
            setAddonUsersPriceEur((addonUsersPrice * rates.EUR).toFixed(2));

            setAddonCompaniesPriceUsd((addonCompaniesPrice * rates.USD).toFixed(2));
            setAddonCompaniesPriceEur((addonCompaniesPrice * rates.EUR).toFixed(2));

            setAddonChatsPriceUsd((addonChatsPrice * rates.USD).toFixed(2));
            setAddonChatsPriceEur((addonChatsPrice * rates.EUR).toFixed(2));

            setAddonProductsPriceUsd((addonProductsPrice * rates.USD).toFixed(2));
            setAddonProductsPriceEur((addonProductsPrice * rates.EUR).toFixed(2));
        } catch (error) {
            console.error("Failed to fetch exchange rates", error);
        }
    };

    const handleSave = () => {
        const packageData = {
          title,
          features: features.split(",").map(text => ({ text: text.trim() })), // assuming features are comma-separated
          monthlyPrice,
          monthlyUsdPrice,
          monthlyEuPrice,
          annualPrice,
          annualUsdPrice,
          annualEuPrice,
          buttonLink,
          maxCompanyProfile,
          maxBrands,
          maxProducts,
          maxUsers,
          maxClientChats,
          dashboardAccess,
          analyticsAccess,
          reportsAccess,
          addonBrandsPrice,
          addonBrandsPriceUsd,
          addonBrandsPriceEur,
          addonBrandsNumber,
          addonUsersPrice,
          addonUsersPriceUsd,
          addonUsersPriceEur,
          addonUsersNumber,
          addonCompaniesPrice,
          addonCompaniesPriceUsd,
          addonCompaniesPriceEur,
          addonCompaniesNumber,
          addonChatsPrice,
          addonChatsPriceUsd,
          addonChatsPriceEur,
          addonChatsNumber,
          addonProductsPrice,
          addonProductsPriceUsd,
          addonProductsPriceEur,
          addonProductsNumber
        };
      
        if (isEditMode && initialPackageData && initialPackageData._id) {
          updatePackage(initialPackageData._id, packageData); // Pass the correct _id and package data for update
        } else {
          createPackage(packageData); // Create a new package if it's not edit mode
        }
      };      

    return (
        <Modal size="xl" show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{isEditMode ? 'Edit Package' : 'Add Package'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey="general">
                    <Tab eventKey="general" title="General">
                        <Form>
                            <Row className="mt-3">
                                <Col md={12} className="mb-3">
                                    <Form.Group controlId="title">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter title"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12} className="mb-3">
                                    <Form.Group controlId="features">
                                        <Form.Label>Features</Form.Label>
                                        <Form.Control
                                            required
                                            as="textarea"
                                            placeholder="Enter features"
                                            value={features}
                                            onChange={(e) => setFeatures(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12} className="mb-3">
                                    <Form.Group controlId="buttonLink">
                                        <Form.Label>Button Link</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter button link"
                                            value={buttonLink}
                                            onChange={(e) => setButtonLink(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Tab>
                    <Tab eventKey="details" title="Package Details">
                        <Form>
                            <Row className="mt-3">
                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="maxCompanyProfile">
                                        <Form.Label>Max Company Profile</Form.Label>
                                        <Form.Control
                                            required
                                            type="number"
                                            placeholder="Enter max company profile"
                                            value={maxCompanyProfile}
                                            onChange={(e) => setMaxCompanyProfile(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="maxBrands">
                                        <Form.Label>Max Brands</Form.Label>
                                        <Form.Control
                                            required
                                            type="number"
                                            placeholder="Enter max brands"
                                            value={maxBrands}
                                            onChange={(e) => setMaxBrands(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="maxProducts">
                                        <Form.Label>Max Products</Form.Label>
                                        <Form.Control
                                            required
                                            type="number"
                                            placeholder="Enter max products"
                                            value={maxProducts}
                                            onChange={(e) => setMaxProducts(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="maxUsers">
                                        <Form.Label>Max Users</Form.Label>
                                        <Form.Control
                                            required
                                            type="number"
                                            placeholder="Enter max users"
                                            value={maxUsers}
                                            onChange={(e) => setMaxUsers(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="maxClientChats">
                                        <Form.Label>Max Client Chats</Form.Label>
                                        <Form.Control
                                            required
                                            type="number"
                                            placeholder="Enter max client chats"
                                            value={maxClientChats}
                                            onChange={(e) => setMaxClientChats(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12} className="mb-3">
                                    <Form.Group controlId="dashboardAccess">
                                        <Form.Label>Dashboard Access</Form.Label>
                                        <Form.Check
                                            type="switch"
                                            id="dashboardAccess"
                                            label="Enable Dashboard Access"
                                            checked={dashboardAccess}
                                            onChange={(e) => setDashboardAccess(e.target.checked)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12} className="mb-3">
                                    <Form.Group controlId="analyticsAccess">
                                        <Form.Label>Analytics Access</Form.Label>
                                        <Form.Check
                                            type="switch"
                                            id="analyticsAccess"
                                            label="Enable Analytics Access"
                                            checked={analyticsAccess}
                                            onChange={(e) => setAnalyticsAccess(e.target.checked)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12} className="mb-3">
                                    <Form.Group controlId="reportsAccess">
                                        <Form.Label>Reports Access</Form.Label>
                                        <Form.Check
                                            type="switch"
                                            id="reportsAccess"
                                            label="Enable Reports Access"
                                            checked={reportsAccess}
                                            onChange={(e) => setReportsAccess(e.target.checked)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Tab>
                    <Tab eventKey="pricing" title="Pricing">
                        <Form className="mt-3">
                            <h4>Monthly Pricing</h4>
                            <hr />
                            <Row className="mt-3">
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="monthlyPrice">
                                        <Form.Label>Rand Price</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter price in ZAR"
                                            value={monthlyPrice}
                                            onChange={(e) => setMonthlyPrice(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="monthlyUsdPrice">
                                        <Form.Label>USD Price</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter USD price"
                                            value={monthlyUsdPrice}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="monthlyEuPrice">
                                        <Form.Label>EU Price</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter EU price"
                                            value={monthlyEuPrice}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <h4>Annual Pricing</h4>
                            <hr />
                            <Row className="mt-3">
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="annualPrice">
                                        <Form.Label>Rand Price</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter price in ZAR"
                                            value={annualPrice}
                                            onChange={(e) => setAnnualPrice(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="annualUsdPrice">
                                        <Form.Label>USD Price</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter USD price"
                                            value={annualUsdPrice}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="annualEuPrice">
                                        <Form.Label>EU Price</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter EU price"
                                            value={annualEuPrice}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <h4>Add-On Pricing</h4>
                            <hr />
                            <Row className="mt-3">
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonBrandsPrice">
                                        <Form.Label>Price per Addon Brand</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter price per addon brand in ZAR"
                                            value={addonBrandsPrice}
                                            onChange={(e) => setAddonBrandsPrice(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonBrandsNumber">
                                        <Form.Label>Max No. of Addon Brands</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter number of addon brands"
                                            value={addonBrandsNumber}
                                            onChange={(e) => setAddonBrandsNumber(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonBrandsPriceUsd">
                                        <Form.Label>USD Price</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="USD price"
                                            value={addonBrandsPriceUsd}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonBrandsPriceEur">
                                        <Form.Label>EU Price</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="EU price"
                                            value={addonBrandsPriceEur}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>

                                {/* Repeat the same structure for other add-ons */}
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonUsersPrice">
                                        <Form.Label>Price per Addon User</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter price per addon user in ZAR"
                                            value={addonUsersPrice}
                                            onChange={(e) => setAddonUsersPrice(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonUsersNumber">
                                        <Form.Label>Max No. of Addon Users</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter number of addon users"
                                            value={addonUsersNumber}
                                            onChange={(e) => setAddonUsersNumber(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonUsersPriceUsd">
                                        <Form.Label>USD Price</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="USD price"
                                            value={addonUsersPriceUsd}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonUsersPriceEur">
                                        <Form.Label>EU Price</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="EU price"
                                            value={addonUsersPriceEur}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>

                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonCompaniesPrice">
                                        <Form.Label>Price per Addon Company</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter price per addon company in ZAR"
                                            value={addonCompaniesPrice}
                                            onChange={(e) => setAddonCompaniesPrice(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonCompaniesNumber">
                                        <Form.Label>Max No. of Addon Companies</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter number of addon companies"
                                            value={addonCompaniesNumber}
                                            onChange={(e) => setAddonCompaniesNumber(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonCompaniesPriceUsd">
                                        <Form.Label>USD Price</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="USD price"
                                            value={addonCompaniesPriceUsd}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonCompaniesPriceEur">
                                        <Form.Label>EU Price</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="EU price"
                                            value={addonCompaniesPriceEur}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>

                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonChatsPrice">
                                        <Form.Label>Price per Addon Chat</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter price per addon chat in ZAR"
                                            value={addonChatsPrice}
                                            onChange={(e) => setAddonChatsPrice(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonChatsNumber">
                                        <Form.Label>Max No. of Addon Chats</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter number of addon chats"
                                            value={addonChatsNumber}
                                            onChange={(e) => setAddonChatsNumber(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonChatsPriceUsd">
                                        <Form.Label>USD Price</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="USD price"
                                            value={addonChatsPriceUsd}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonChatsPriceEur">
                                        <Form.Label>EU Price</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="EU price"
                                            value={addonChatsPriceEur}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>

                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonProductsPrice">
                                        <Form.Label>Price per Addon Product</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter price per addon product in ZAR"
                                            value={addonProductsPrice}
                                            onChange={(e) => setAddonProductsPrice(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonProductsNumber">
                                        <Form.Label>Max No. of Addon Products</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter number of addon products"
                                            value={addonProductsNumber}
                                            onChange={(e) => setAddonProductsNumber(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonProductsPriceUsd">
                                        <Form.Label>USD Price</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="USD price"
                                            value={addonProductsPriceUsd}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-3">
                                    <Form.Group controlId="addonProductsPriceEur">
                                        <Form.Label>EU Price</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="EU price"
                                            value={addonProductsPriceEur}
                                            readOnly
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Button variant="secondary" onClick={fetchExchangeRates}>
                                Convert to USD and EUR
                            </Button>
                        </Form>
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSave}
                >
                    {isEditMode ? 'Update Package' : 'Save Package'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddPackageModal;
