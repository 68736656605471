import axios from "axios";
import { api_token_key } from "../../utils/constants";
import localSessionApi from "../../utils/constants"

const PROXY_ENDPOINT = process.env.REACT_APP_PROXY_ENDPOINT


export function doPublicPostRequest(_payload, _path){
    const PROXY_TOKEN = localSessionApi.getWithExpiry(api_token_key)
    console.log("proxy token is", PROXY_TOKEN)
    const config = {
        method: 'post',
        url: `${PROXY_ENDPOINT}${_path}`,
        timeout: 40000,
        headers: {'Content-Type': 'application/json'},
        data : _payload,
        headers: {
            'FLC-JWT': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2VtYWlsIjoiZW5yaWNvQGlnbml0ZW1lZGlhLmNvLnphIiwidXNlcl9pZCI6IjE2NzBiMzRjMDhiYzRkZThhYjRjMmU2NDExYjBhMDgyIiwidXNlcl9yb2xlIjoiYWRtaW4iLCJleHAiOjE3Mjc1NzY5ODR9.uteUNj5GEbuQyIJrj8itkGPkHZRUOx8bI7t8xlibM9g'
        }
    };
    return axios(config)
}

export function doPublicGetRequest(_path) {
    const PROXY_TOKEN = localSessionApi.getWithExpiry(api_token_key)
    console.log("proxy token is", PROXY_TOKEN)
    const config = {
        method: 'get',
        url: `${PROXY_ENDPOINT}${_path}`,
        timeout: 40000,
        headers: {
            'Content-Type': 'application/json',
            'FLC-JWT': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2VtYWlsIjoiZW5yaWNvQGlnbml0ZW1lZGlhLmNvLnphIiwidXNlcl9pZCI6IjE2NzBiMzRjMDhiYzRkZThhYjRjMmU2NDExYjBhMDgyIiwidXNlcl9yb2xlIjoiYWRtaW4iLCJleHAiOjE3Mjc1NzY5ODR9.uteUNj5GEbuQyIJrj8itkGPkHZRUOx8bI7t8xlibM9g'
        }
    }

    return axios(config)
}