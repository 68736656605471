import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Card, Button, Table, Dropdown, Pagination, ButtonGroup, Form } from '@themesberg/react-bootstrap';

export const CompaniesTables = ({ companies, deleteCompany, handleEditCompany }) => {

  const ITEMS_PER_PAGE = 10;

  const [filters, setFilters] = useState({
    legalName: "",
    tradingName: "",
    regAddress: "",
    billingAddress: "",
    industryType: "",
    location: "",
    webAddress: "",
    bbbee: "",
    brands: "",
    dealerNetwork: ""
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const filteredCompanies = companies.filter(company => {
    const location = company.location ? `${company.location.address} ${company.location.area_code}` : "";
    const bbbee = company.bbbee ? `${company.bbbee.level} ${company.bbbee.expiry_date}` : "";
    const brands = company.brands ? company.brands.map(brand => brand.name).join(', ') : "";

    return (
      (filters.legalName === "" || (company.legal_name ?? "").toLowerCase().includes(filters.legalName.toLowerCase())) &&
      (filters.tradingName === "" || (company.trading_name ?? "").toLowerCase().includes(filters.tradingName.toLowerCase())) &&
      (filters.regAddress === "" || (company.registered_address ?? "").toLowerCase().includes(filters.regAddress.toLowerCase())) &&
      (filters.billingAddress === "" || (company.billing_address ?? "").toLowerCase().includes(filters.billingAddress.toLowerCase())) &&
      (filters.industryType === "" || (company.industry_type ?? []).join(', ').toLowerCase().includes(filters.industryType.toLowerCase())) &&
      (filters.location === "" || location.toLowerCase().includes(filters.location.toLowerCase())) &&
      (filters.webAddress === "" || (company.website_url ?? "").toLowerCase().includes(filters.webAddress.toLowerCase())) &&
      (filters.bbbee === "" || bbbee.toLowerCase().includes(filters.bbbee.toLowerCase())) &&
      (filters.brands === "" || brands.toLowerCase().includes(filters.brands.toLowerCase())) &&
      (filters.dealerNetwork === "" || (company.dealer_network ? "yes" : "no").toLowerCase().includes(filters.dealerNetwork.toLowerCase()))
    );
  });

  const TableRow = ({
    _id,
    company_type,
    company_logo,
    company_bio,
    status,
    approved,
    legal_name,
    trading_name,
    registration_number,
    vat_number,
    contact_number,
    primary_email,
    social_links = { linkedin: '', facebook: '', instagram: '', twitter: '' },
    dealer_network,
    registered_address,
    billing_address,
    industry_type = [],
    location = { address: '', area_code: '' },
    website_url,
    bbbee = { level: '', expiry_date: '', certificate: null },
    brands = []
  }) => {
    return (
      <tr>
        <td><img src={company_logo} height="50px" width="50px" alt="company logo"/></td>
        <td>
          <Card.Link href="#" className="d-flex align-items-center" onClick={() => handleEditCompany({
            _id,
            company_type,
            company_logo,
            company_bio,
            status,
            approved,
            legal_name,
            trading_name,
            registration_number,
            vat_number,
            contact_number,
            primary_email,
            social_links,
            dealer_network,
            registered_address,
            billing_address,
            industry_type,
            location,
            website_url,
            bbbee,
            brands
          })}>
            <div className="title-col">
              <span className="h6">{legal_name}</span><br/>
              {registration_number ? registration_number : "-"}<br/>
              {vat_number ? vat_number : "-"}
            </div>
          </Card.Link>
        </td>
        <td>{trading_name}</td>
        <td>{approved}</td>
        <td>{registered_address}</td>
        <td>{billing_address}</td>
        <td>{Array.isArray(industry_type) ? industry_type.join(', ') : industry_type}</td>
        <td>
          {location.address && location.area_code ? `${location.address}, ${location.area_code}` : 'No location data'}
        </td>
        <td>{website_url}</td>
        <td>
          {bbbee.expiry_date || bbbee.level ?
            [
              bbbee.level ? `${bbbee.level}` : '',
              bbbee.expiry_date ? `Expiry Date: ${bbbee.expiry_date}` : '',
            ].filter(Boolean).join(', ') :
            'No BBBEE data'
          }
        </td>
        <td>{Array.isArray(brands) ? brands.map(item => item.name).join(', ') : 'No brands data'}</td>
        <td>
          <Form.Check 
            type="checkbox" 
            checked={dealer_network} 
            disabled 
          />
        </td>
        <td>
          {social_links.linkedin && <a href={social_links.linkedin} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>}{" "}
          {social_links.facebook && <a href={social_links.facebook} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>}{" "}
          {social_links.instagram && <a href={social_links.instagram} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>}{" "}
          {social_links.twitter && <a href={social_links.twitter} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>}
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="tableNav">
              <Dropdown.Item onClick={() => handleEditCompany({
                _id,
                company_type,
                company_logo,
                company_bio,
                status,
                approved,
                legal_name,
                trading_name,
                registration_number,
                vat_number,
                contact_number,
                primary_email,
                social_links,
                dealer_network,
                registered_address,
                billing_address,
                industry_type,
                location,
                website_url,
                bbbee,
                brands
              })}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={() => deleteCompany(_id)}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredCompanies.slice(startIndex, endIndex);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageCount = Math.ceil(filteredCompanies.length / ITEMS_PER_PAGE);
  const items = [];
  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th>Thumbnail</th>
              <th>Legal Name</th>
              <th>Trade Name</th>
              <th>Status</th>
              <th>Reg Address</th>
              <th>Billing Address</th>
              <th>Industry Type</th>
              <th>Location</th>
              <th>Web Address</th>
              <th>BBBEE</th>
              <th>Brands</th>
              <th>Dealer Network</th>
              <th>Social Links</th>
              <th>Action</th>
            </tr>
            <tr>
              <th></th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Legal Name"
                  name="legalName"
                  value={filters.legalName}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Trade Name"
                  name="tradingName"
                  value={filters.tradingName}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Reg Address"
                  name="regAddress"
                  value={filters.regAddress}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Billing Address"
                  name="billingAddress"
                  value={filters.billingAddress}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Industry Type"
                  name="industryType"
                  value={filters.industryType}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Location"
                  name="location"
                  value={filters.location}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Web Address"
                  name="webAddress"
                  value={filters.webAddress}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by BBBEE"
                  name="bbbee"
                  value={filters.bbbee}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Brands"
                  name="brands"
                  value={filters.brands}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Dealer Network"
                  name="dealerNetwork"
                  value={filters.dealerNetwork}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredCompanies.length > 0 ? (
              currentTableData().map((company) => <TableRow key={`company-${company._id}`} {...company} deleteCompany={deleteCompany} handleEditCompany={handleEditCompany} />)
            ) : (
              <tr>
                <td colSpan="13" className="text-center">
                  No companies found. Click "Add Companies" to add new entries.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination className="justify-content-center">{items}</Pagination>
      </Card.Body>
    </Card>
  );
};
