import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';
import AddCategoryModal from "../../../components/modals/AddCategoryModal";
import { getAllCategories, createCategories, updateCategories, deleteCategories } from "../../../api/private/categories";
import { makeToast } from "../../../functions/toasts";
import { CmsCategoriesTables } from "../../../components/tables/CmsCategoriesTables";
import AlertComponent from "../../../functions/alerts"
import { useUserRole } from "../../../contexts/UserRoleContext";
import { ToastContainer, toast } from "react-toastify"
export default () => {
  const { userRole } = useUserRole();
  const [categories, setCategories] = useState([])
  const [initialCategoryData, setInitialCategoryData] = useState(null);
  const [categoryMounted, setCategoryMounted] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [alert, setAlert] = useState({ type: '', message: '' });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    loadCategories();
  }, []); 

  function loadCategories() {
    getAllCategories().then(response => {
      const { data } = response;
      if (data.Result === 'OK' || data.Result === 'SUCCESS') {
        setCategories(data.Records || []); 
        console.log("Fetched categories:", data.Records);
      } else {
        console.error('Failed to fetch categories:', data);
      }
    }).catch(error => {
      console.error('Error fetching categories:', error);
      makeToast('error', 'Failed to load categories');
    });
  }

  function createCategory(categoryData) {
    const payload = {
      'seo_title': categoryData['seo_title'],
      'seo_meta_descr': categoryData['seo_meta_descr'],
      'slug': categoryData['slug'],
      'canonical_url': categoryData['canonical_url'],
      'index_url': categoryData['index_url'],
      'schema': [],
      'created_on': categoryData['created_on'],
      'featured_image_url': categoryData['featured_image_url'],
      'addon_meta': []
    }

    createCategories(payload).then((_resp) => {
      if (_resp.data.Result === 'OK' || _resp.data.Result === 'SUCCESS') {
        setAlert({type: 'success', message:`${_resp.data.Message}`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        handleCloseModal();
        loadCategories()
      } else {
        console.warn(_resp.data);
        console.log(categoryData)
        setAlert({type: 'success', message:`${_resp.data.Message}`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadCategories()
      }
    }).catch((_resp) => {
      console.error(_resp);
      setAlert({type: 'error', message:`${_resp.data.Message}`})
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    });
  }

  function updateCategory(category, categoryData, description) {
    console.log("Update function ran with object:", categoryData);

    if(!category['_id']) {
      console.error("Document ID is undefined:", category['_id']);
      makeToast('error', 'Document ID is missing');
      return;
    }

    const payload = { 
      'seo_title': categoryData['seo_title'],
      'seo_meta_descr': categoryData['seo_meta_descr'],
      'slug': categoryData['slug'],
      'canonical_url': categoryData['canoniical_url'],
      'index_url': categoryData['index_url'],
      'schema': [],
      'created_on': categoryData['created_on'],
      'featured_image_url': categoryData['featured_image_url'],
      'addon_meta': []
    };

    updateCategories(category["_id"], payload).then((_resp) => {
      console.log("This is the category", category)
      if (_resp.data.Result === 'OK' || _resp.data.Result === 'SUCCESS') {
        makeToast('success', 'Category updated successfully');
        handleCloseModal();
        setAlert({type: 'success', message:`${_resp.data.Message}`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadCategories()
      } else {
        console.warn(_resp.data);
        setAlert({type: 'success', message:`${_resp.data.Message}`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadCategories()
      }
    }).catch((_resp) => {
      console.error(_resp);
      makeToast('error', 'Failed to update category');
      setAlert({type: 'error', message:`${_resp.data.Message}`})
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    });
  }

  function deleteCategory(category) {
    deleteCategories(category).then((_resp) => {
      if (_resp.data.Result === 'OK' || 'SUCCESS') {
        makeToast('success', 'Deleted category')
        setAlert({type: 'success', message:`${_resp.data.Message}`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadCategories();
      } else {
        console.warn(_resp.data)
        setAlert({type: 'success', message:`${_resp.data.Message}`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadCategories()
      }
    }).catch((_resp) => {
      console.log(_resp)
      makeToast('error', 'Failed to delete category')
      setAlert({type: 'error', message:`${_resp.data.Message}`})
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    })
  }

  const handleEditCategory = (category) => {
    console.log(category);
    setInitialCategoryData(category);
    setIsEditMode(true);
    setShowModal(true);
  };

  const handleAddCategory = () => {
    setInitialCategoryData(null);
    setIsEditMode(false);
    setShowModal(true);
  };
  return (
    <>
    <ToastContainer hideProgressBar={true} theme="light" pauseOnHover autoClose={5000}/>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>CMS</Breadcrumb.Item>
            <Breadcrumb.Item active>Categories</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Categories</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
            <Button className="btn btn-primary" onClick={handleAddCategory}>Add Category</Button>
          </ButtonGroup>
        </div>
      </div>

      <AlertComponent type={alert.type} message={alert.message} />      

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            {/* <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup> */}
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <CmsCategoriesTables categories={categories} deleteCategory={deleteCategories} handleEditCategory={handleEditCategory} />
      <AddCategoryModal showModal={showModal} handleCloseModal={() => setShowModal(false)} createCategory={createCategories} updateCategory={updateCategories} initialCategoryData={initialCategoryData} isEditMode={isEditMode} />
    </>
  );
};
