import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisH, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Table, Dropdown, ButtonGroup, Form } from '@themesberg/react-bootstrap';

export const PackagesTables = ({ packages, handleEditPackage, handleDeletePackage }) => {
  const [filters, setFilters] = useState({
    title: "",
    features: "",
    price: "",
    btn_link: "",
    dateCreated: ""
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const filteredPackages = packages.filter(packaged => {
    return (
      (filters.title === "" || (packaged.title ?? "").toLowerCase().includes(filters.title.toLowerCase())) &&
      (filters.features === "" || (packaged.features?.text ?? "").toLowerCase().includes(filters.features.toLowerCase())) &&
      (filters.price === "" || (packaged.price ?? "").toLowerCase().includes(filters.price.toLowerCase())) &&
      (filters.btn_link === "" || (packaged.btn_link ?? "").toLowerCase().includes(filters.btn_link.toLowerCase())) &&
      (filters.dateCreated === "" || (packaged.created_on ?? "").toLowerCase().includes(filters.dateCreated.toLowerCase()))
    );
  });

  const TableRow = (props) => {
    const { id, title, features, price, btn_link, created_on } = props;

    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center">
            <div><span className="h6">{title}</span></div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">
          {features ? features.text : "-"}
        </td>
        <td className="fw-bold border-0">
          {price ? price : "-"}
        </td>
        <td className="fw-bold border-0">
          {btn_link ? btn_link : "-"}
        </td>
        <td className="border-0">
          {created_on}
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="tableNav">
              <Dropdown.Item onClick={() => handleEditPackage(props)}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={() => handleDeletePackage(id)}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Title</th>
              <th className="border-0">Features</th>
              <th className="border-0">Prices</th>
              <th className="border-0">Button Link</th>
              <th className="border-0">Date Created</th>
              <th className="border-bottom">Action</th>
            </tr>
            <tr>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Title"
                  name="title"
                  value={filters.title}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Features"
                  name="features"
                  value={filters.features}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Price"
                  name="price"
                  value={filters.price}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Button Link"
                  name="btn_link"
                  value={filters.btn_link}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Date Created"
                  name="dateCreated"
                  value={filters.dateCreated}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredPackages.length > 0 ? (
              filteredPackages.map(packaged => <TableRow key={`packaged-${packaged.id}`} {...packaged} />)
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No records could be found at this time. Create your first supplier by clicking Add Pages
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
