import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createSuppliers = (payload) => {
    return doPrivatePostRequest(payload, '/suppliers/create')
}

export const updateSuppliers = (payload, doc_id) => {
    return doPrivatePostRequest(payload, `/suppliers/update/${doc_id}`)
}

export const getSuppliers = (_doc_id) => {
    return doPrivateGetRequest(`/suppliers/get/${_doc_id}`)
}

export const getAllSuppliers = () => {
    const payload = {
        'st': 'ALL'
    }
    return doPrivatePostRequest(payload, '/suppliers/get')
}

export const deleteSuppliers = (_doc_id) => {
    return doPrivateDelRequest(`/suppliers/delete/${_doc_id}`)
}
