import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Card, Button } from '@themesberg/react-bootstrap';
import { getAllPosts } from '../api/private/posts';
import { Link } from 'react-router-dom';
import { makeToast } from '../functions/toasts';
import { Routes } from "../routes";


export const BlogCards = () => {
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadPosts();
  }, []);

  const loadPosts = () => {
    getAllPosts().then(response => {
      if (response.data.Result === 'OK') {
        const posts = response.data.Records
        setPosts(posts);
        setLoading(false);
      } else {
        console.warn(response.data);
        makeToast('error', 'Failed to load posts');
      }
    }).catch(error => {
      console.error(error);
      makeToast('error', 'Failed to load posts');
    });
  };

  return (
    <>
      {loading ? (
        <Row className="justify-content-center">
          <Col>Loading...</Col>
        </Row>
      ) : (
        <section className="bg-white justify-content-start align-items-start">
          <Container className="align-items-start text-dark justify-content-start pt-4 pb-6">
            <Row className="mt-2" style={{ marginBottom: "20px" }}>
              {posts.map((post, index) => (
                <Col key={index} xs={12} sm={6} lg={4} style={{ marginBottom: "24px" }}>
                  <Card className="blog-card">
                    <Card.Header className="blog-card-header m-0 p-0">
                    {post.image? 
                          <img src={`${post.image}`} alt={post.title} height="225px" width="auto"/>
                        : 
                          <img src="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=" alt="logo"/>
                    }
                    </Card.Header>
                    <Card.Body>
                      <h3>{post.title}</h3>
                      <small>{post.author}</small>
                      {post.excerpt}
                    </Card.Body>
                    <Card.Footer>
                      <Button as={Link} to={`${Routes.BlogRead.path.replace(':slug', post.slug)}`} className="btn btn-primary">Read Article</Button>                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};