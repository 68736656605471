import React from 'react';

const AlertComponent = ({ type, message }) => {
  if (!message) return null;

  if(type === 'error'){
  return (
    <div className={`alert alert-danger alert-${type}`} role="alert">
      {message}
    </div>
  );
  }else if(type === 'success'){
    return (
        <div className={`alert alert-success alert-${type}`} role="alert">
          {message}
        </div>
      );
  }else if(type === 'warning'){
    return (
        <div className={`alert alert-warning alert-${type}`} role="alert">
          {message}
        </div>
    );
  }else{
    return (
        <div className={`alert alert-info alert-${type}`} role="alert">
          {message}
        </div>
      );
  }
};

export default AlertComponent;