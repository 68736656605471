import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Accordion, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Modal, Tabs, Tab } from '@themesberg/react-bootstrap';
import { getAllCategories } from '../../api/private/categories';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import { getAllUsers } from '../../api/private/users';

import 'react-quill/dist/quill.snow.css';

const AddPostModal = ({ showModal, handleCloseModal, createPost, updatePost, isEditMode, initialPostData }) => {
    const [description, setDescription] = useState('');
    const [authors, setAuthors] = useState([]);
    const [categories, setCategories] = useState([])
    const [componentContent, setComponentContent] = useState([]);
    const [postData, setPostData] = useState({
        _id: "",
        post_type: "",
        title: "",
        seo_title: "",
        seo_description: "",
        slug: "",
        author: "",
        canonical_link: "",
        excerpt: "",
        status: "",
        page_content: "",
        image_doc_id: "",
        date_published: "",
        created_on: "",
        component_content: []
    });

    useEffect(() => {
        loadUsers()
        loadCategories()
        if (showModal && isEditMode && initialPostData) {
            const {
                _id = '',
                title = '',
                seo_title = '',
                seo_description = '',
                slug = '',
                author = '',
                canonical_link = '',
                excerpt = '',
                status = '',
                page_content = '',
                image_doc_id = '',
                date_published = '',
                created_on = '',
                component_content = []
            } = initialPostData;

            setPostData({
                _id,
                title,
                seo_title,
                seo_description,
                slug,
                author,
                canonical_link,
                excerpt,
                status,
                page_content,
                image_doc_id,
                date_published,
                created_on,
                component_content
            });

            setDescription(description);
        } else {
            resetForm();
        }
    }, [showModal, isEditMode, initialPostData]);

    const resetForm = () => {
        setPostData({
            _id: "",
            post_type: "",
            title: "",
            seo_title: "",
            seo_description: "",
            slug: "",
            author: "",
            canonical_link: "",
            excerpt: "",
            status: "",
            page_content: "",
            image_doc_id: "",
            date_published: "",
            created_on: "",
            component_content: []
        });
        setDescription('');
    };

    const onKeyValueChange = (key, value) => {
        setPostData(prevPostData => ({
            ...prevPostData,
            [key]: value,
        }));
    };

    const handleDescriptionChange = (content) => {
        setDescription(content);
    };

    const loadUsers = () => {
        getAllUsers().then(response => {
            if (response.data.Result === 'OK') {
                setAuthors(response.data.Records);
            } else {
                console.warn(response.data);
            }
        }).catch(error => {
            console.error(error);
        });
    };

    function loadCategories() {
        getAllCategories().then(response => {
            const { data } = response;
            if (data.Result === 'OK') {
                setCategories(data.Records || []);
                console.log("Fetched categories:", data.Records);
            } else {
                console.error('Failed to fetch categories:', data);
            }
        }).catch(error => {
            console.error('Error fetching categories:', error);
        });
    }

    const handleSubmit = () => {
        console.log("Submit mode:", isEditMode ? "Edit" : "Add", "Post ID:", postData?._id);
        const payload = {
            ...postData,
            page_content: description
        };

        if (isEditMode && postData && postData._id) {
            console.log("Updating post with ID:", postData._id);
            updatePost(payload);
        } else {
            console.log("Creating new post");
            createPost(payload);
        }

        handleCloseModal();
    };

    const handleAddPostElement = () => {
        const newElement = {
            id: 'New Element',
            component_content_type: '',
            heading: '',
            headingStyle: '',
            subtext: '',
            text: ''
        };
        setComponentContent(prevElements => [...prevElements, newElement]);
    };

    const handleRemovePostElement = (index) => {
        setComponentContent(prevElements => prevElements.filter((_, i) => i !== index));
    };

    const handlePostElementChange = (index, field, value) => {
        setComponentContent(prevElements => {
            const updatedElements = [...prevElements];
            updatedElements[index] = { ...updatedElements[index], [field]: value };
            return updatedElements;
        });
    };

    return (
        <>
            <Modal size="xl" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditMode ? 'Update Post' : 'Add Post'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey="general">
                        <Tab eventKey="general" title="General">
                            <Form>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="title">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control required type="text"
                                                placeholder="Enter title"
                                                value={postData.title}
                                                onChange={(e) => onKeyValueChange('title', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="title">
                                            <Form.Label>Slug</Form.Label>
                                            <Form.Control required type="text"
                                                placeholder="Enter slug"
                                                value={postData.slug}
                                                onChange={(e) => onKeyValueChange('slug', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="type">
                                            <Form.Label>Post Type</Form.Label>
                                            <Form.Control required type="text"
                                                placeholder="Enter post type"
                                                value={postData.post_type}
                                                onChange={(e) => onKeyValueChange('post_type', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="status">
                                            <Form.Label>Status</Form.Label>
                                            <Form.Select required>
                                                <option value="">Select status</option>
                                                <option value="draft">Draft</option>
                                                <option value="under review">Under Review</option>
                                                <option value="published">Published</option>
                                                <option value="private">Private</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="published">
                                            <Form.Label>Date Published</Form.Label>
                                            <Form.Control required
                                                type="date"
                                                value={postData.date_published}
                                                onChange={(e) => onKeyValueChange('date_published', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="author">
                                            <Form.Label>Author</Form.Label>
                                            <Form.Select
                                                value={postData.author}
                                                onChange={(e) => onKeyValueChange('author', e.target.value)}
                                                required
                                            >
                                                <option value="">Select Author</option>
                                                {authors && authors
                                                    .filter(author => author.user_role === 'admin') // Filter authors to only include those with 'admin' role
                                                    .map(author => (
                                                        <option key={author.id} value={author.first_name}>{author.first_name}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="category">
                                            <Form.Label>Category</Form.Label>
                                            <Form.Select
                                                value={postData.category}
                                                onChange={(e) => onKeyValueChange('category', e.target.value)}
                                                required
                                            >
                                                <option value="">Select Category</option>
                                                {categories && categories.length > 0 && categories.map(category => (
                                                    <option key={category.seo_title} value={category.seo_title}>{category.seo_title}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Placement</Form.Label>
                                            <div>
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Supplier"
                                                    value="Supplier"
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    label="About Us"
                                                    value="About Us"
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Contact"
                                                    value="Contact"
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="category">
                                            <Form.Label>Supplier</Form.Label>
                                            <Form.Select
                                                value={postData.category}
                                                onChange={(e) => onKeyValueChange('category', e.target.value)}
                                                required
                                            >
                                                <option value="">Select Category</option>
                                                {categories && categories.length > 0 && categories.map(category => (
                                                    <option key={category.seo_title} value={category.seo_title}>{category.seo_title}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="pageContent" style={{ height: '400px' }}>
                                            <Form.Label>Post Content</Form.Label>
                                            <ReactQuill style={{ height: '300px' }}
                                                value={description}
                                                onChange={handleDescriptionChange}
                                                placeholder="Enter post content"
                                                modules={{
                                                    toolbar: [
                                                        [{ 'header': [1, 2, false] }],
                                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                        ['link', 'image'],
                                                        ['clean']
                                                    ]
                                                }}
                                                formats={[
                                                    'header', 'font', 'size',
                                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                                    'list', 'bullet', 'indent',
                                                    'link', 'image'
                                                ]}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="featuredImage">
                                            <Form.Label>Featured Image</Form.Label>
                                            <Form.Control required type="file"
                                                placeholder="Enter featured image"
                                                value={postData.featured_image}
                                                onChange={(e) => onKeyValueChange('featured_image', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Tab>
                        <Tab eventKey="componentContent" title="Component Content">
                            <Form>
                                <div className="text-end">
                                    <Button className="mt-3" variant="primary" onClick={handleAddPostElement}>Add Element</Button>
                                </div>
                                {componentContent.map((component_content, index) => (
                                    <Accordion key={index} className="mt-3">
                                        <Accordion.Item eventKey={index.toString()}>
                                            <Accordion.Header>{component_content.id}</Accordion.Header>
                                            <Accordion.Body>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Identifier</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={component_content.id}
                                                                onChange={(e) => handlePostElementChange(index, 'id', e.target.value)}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6} className="mb-3">
                                                        <Form.Group controlId="component_content_type">
                                                            <Form.Label>Element</Form.Label>
                                                            <Form.Select
                                                                required
                                                                value={component_content.component_content_type}
                                                                onChange={(e) => handlePostElementChange(index, 'component_content_type', e.target.value)}
                                                            >
                                                                <option value="">Select Element Type</option>
                                                                <option value="list">List</option>
                                                                <option value="textbox">Textbox</option>
                                                                <option value="cardbox">Cardbox</option>
                                                                <option value="heading">Heading</option>
                                                                <option value="button">Button</option>
                                                                <option value="paragraph">Paragraph</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6} className="mb-3">
                                                        <Form.Group controlId="font_class">
                                                            <Form.Label>Heading Type</Form.Label>
                                                            <Form.Select
                                                                required
                                                                value={component_content.font_class}
                                                                onChange={(e) => handlePostElementChange(index, 'font_class', e.target.value)}
                                                            >
                                                                <option value="">Select Heading Type</option>
                                                                <option value="h1">H1</option>
                                                                <option value="h2">H2</option>
                                                                <option value="h3">H3</option>
                                                                <option value="h4">H4</option>
                                                                <option value="h5">H5</option>
                                                                <option value="h6">H6</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12} className="mb-3">
                                                        <Form.Group controlId="text">
                                                            <Form.Label>Text</Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                value={component_content.text}
                                                                onChange={(e) => handlePostElementChange(index, 'text', e.target.value)}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12} className="mb-3">
                                                        <Form.Group controlId="subtext">
                                                            <Form.Label>SubText</Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                value={component_content.subtext}
                                                                onChange={(e) => handlePostElementChange(index, 'subtext', e.target.value)}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Button variant="danger" onClick={() => handleRemovePostElement(index)}>Remove</Button>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                ))}
                            </Form>
                        </Tab>
                        <Tab eventKey="seo" title="SEO">
                            <Form>
                                <Row className="mt-3">
                                    <Col md={6}>
                                        <Form.Group controlId="title">
                                            <Form.Label>SEO Title</Form.Label>
                                            <Form.Control required type="text"
                                                placeholder="Enter title"
                                                value={postData.seo_title}
                                                onChange={(e) => onKeyValueChange('seo_title', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="canonical">
                                            <Form.Label>Canonical URL</Form.Label>
                                            <Form.Control required type="text"
                                                placeholder="Enter canonical"
                                                value={postData.canonical_link}
                                                onChange={(e) => onKeyValueChange('canonical_link', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={12}>
                                        <Form.Group controlId="seoDescription">
                                            <Form.Label>SEO Description</Form.Label>
                                            <Form.Control required as="textarea"
                                                placeholder="Enter SEO description"
                                                value={postData.seo_description}
                                                onChange={(e) => onKeyValueChange('seo_description', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        {isEditMode ? 'Update Post' : 'Add Post'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddPostModal;
