import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Image, Breadcrumb, Button, Container } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { BlogCards } from '../../../components/BlogCards';
import { NavigationBar } from "../../../components/frontendNav/Navigation";
import { Footer } from '../../../components/frontendNav/Footer'
import { Routes } from "../../../routes";
import { faCheck, faHome } from "@fortawesome/free-solid-svg-icons";
import analytics from '../../../functions/analytics'

export default () => {

    useEffect(() => {
        analytics.page()
    })
    
    return (
        <>
            {/*-- Navigation Bar --*/}
            <NavigationBar />

            <section className="partner-brands align-items-start justify-content-start overflow-hidden bg-white text-dark">
                <Container>
                    <Row className="align-items-center pt-3">
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <div className="d-block mb-4 mb-md-0">
                                <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                                    <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
                                    <Breadcrumb.Item>Subscriptions</Breadcrumb.Item>
                                    <Breadcrumb.Item active>User Accounts</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center pb-3" xs={12} sm={12} md={6} lg={6}>
                            <div className="d-block mb-4 mb-md-0">
                                <div className="d-none mx-2 d-md-inline-block">
                                    <a as={Link} to={""}>Add To Shortlist</a>
                                </div>
                            </div>
                            <div className="d-block mx-2 mb-4 mb-md-0">
                                <div className="d-none d-md-inline-block fw-bolder">
                                    <a as={Link} to={""}>Average Rating: 8.9/10</a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section style={{ borderTop: "1px solid #d7d7d7", borderBottom: "1px solid #d7d7d7", marginLeft: 0, marginRight: 0 }} className="partner-brands align-items-start justify-content-start overflow-hidden bg-white text-dark">
                <Container>
                    <Row className="align-items-center pt-3">
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <div className="d-block mb-4 mb-md-0">
                                <p className="lead">Baoli South Africa</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            {/* Additional content for this column can go here */}
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="partner-brands align-items-start justify-content-start overflow-hidden pt-5 pt-lg-5 pb-5 pb-lg-5 bg-white text-dark">
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <img src="https://forklifting.co.za/wp-content/uploads/2023/11/3.png" />
                        </Col>
                        <Col className="d-flex justify-content-start align-items-center" xs={12} sm={12} md={4} lg={4}>
                            <p>Kion Baoli is a brand of forklift trucks that was founded in China in 2003 and acquired in 2009 by the KION Group, the European market leader and the world’s second largest manufacturer of warehouse technology.</p>
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <div style={{ flex: '0 0 25%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: '10px 0px 10px 10px' }}>
                                    <Button as={Link} to={Routes.Wizard.path} className="btn btn-primary p-3 cardBtn" style={{ marginBottom: '10px', borderRadius: '0px 0px 0px 0px' }}>Get A Quote</Button>
                                    <Button as={Link} to={Routes.AllListings.path} className="btn btn-primary p-3 cardBtn" style={{ marginBottom: '10px', borderRadius: '0px 0px 0px 0px' }}>View Distributor</Button>
                                    <Button className="btn btn-primary p-3 cardBtn" style={{ borderRadius: '0px 0px 0px 0px' }}>Contact</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="partner-brands align-items-start justify-content-start overflow-hidden bg-grey text-dark">
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4}>
                        <img height="400px" width="auto" src="https://forklifting.co.za/wp-content/uploads/2023/11/forklifts-for-sale.3.png" />
                    </Col>
                    <Col className="py-4 px-6" xs={12} sm={12} md={4} lg={4}>
                        <h5>About</h5>
                    </Col>
                    <Col className="py-4 px-6" xs={12} sm={12} md={4} lg={4}>
                        <h5>Offerings</h5>
                        <ul className="pt-3 offering-list list-style-none">
                            <li><FontAwesomeIcon style={{ color: "orange", marginRight: "15px" }} icon={faCheck} /> Something</li>
                        </ul>
                    </Col>
                </Row>
            </section>

            <section className="align-items-center justify-content-center overflow-hidden pt-5 pt-lg-6 px-7 pb-6 bg-white text-dark">
                <Row>
                    <Col xs={10}>
                        <h3 className="fw-bolder">View & compare Baoli South Africa's full range of products & services</h3>
                        <p>Unlock a world of insights! Login now for exclusive access to detailed product specs, performance data, service details, and side-by-side comparisons. Elevate your decision-making with comprehensive information at your fingertips.</p>
                    </Col>
                    <Col xs={2}>
                        <Button className="mt-4 btn btn-primary">
                            Login
                        </Button>
                    </Col>
                </Row>
            </section>

            <section className="align-items-center justify-content-center overflow-hidden pt-5 pt-lg-6 px-7 pb-6 bg-white text-dark">
                <Row>
                    <Col className="text-center" xs={12}>
                        <h1 className="text-center fw-bolder">Baoli South Africa Articles & News</h1>
                        <p className="text-center">Read Baoli South Africa's latest articles and news here.</p>
                        <BlogCards />
                    </Col>
                </Row>
            </section>




            {/*-- Footer --*/}
            <Footer />
        </>
    );
};