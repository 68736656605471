import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Routes } from "../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Button } from '@themesberg/react-bootstrap';
import { makeToast } from '../functions/toasts';
import { getAllBrands } from '../api/public/brands';
import badge1 from '../assets/img/bbbee/1.png';
import badge2 from '../assets/img/bbbee/2.png';
import badge3 from '../assets/img/bbbee/3.png';
import badge4 from '../assets/img/bbbee/4.png';
import badge5 from '../assets/img/bbbee/5.png';
import { getAllCompanies } from '../api/private/companies';
import useIsMobile from '../utils/UseIsMobile'; 

export const ListingCard = ({ filteredBrands }) => {
  const [brands, setBrands] = useState([]);
  const [companies, setCompanies] = useState([]);
  const isMobile = useIsMobile(); // Use the hook to detect mobile

  const bbbee_badges = [
    { level: "1", badge_asset: badge1 },
    { level: "2", badge_asset: badge2 },
    { level: "3", badge_asset: badge3 },
    { level: "4", badge_asset: badge4 },
    { level: "exempt", badge_asset: badge5 },
  ];

  useEffect(() => {
    loadBrands();
    loadCompanies();
  }, []);

  function loadBrands() {
    getAllBrands().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        let brands = _resp.data.Records;
        brands = brands.map(brand => ({
          ...brand,
          brand_name_slug: brand.brand_name.toLowerCase().replace(/ /g, '-').replace(/[^a-z0-9-]/g, '')
        }));
        setBrands(brands);
      } else {
        console.warn(_resp.data);
      }
    }).catch((_resp) => {
      console.log(_resp);
      makeToast('error', 'Failed to load listings');
    });
  }

  function loadCompanies() {
    getAllCompanies().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        let companies = _resp.data.Records.map(company => ({
          ...company,
          trading_name_slug: company.trading_name.toLowerCase().replace(/ /g, '-').replace(/[^a-z0-9-]/g, '')
        }));
        setCompanies(companies);
      } else {
        console.warn(_resp.data);
      }
    }).catch((_resp) => {
      console.log(_resp);
      makeToast('error', 'Failed to load listings');
    });
  }

  const getBbbeeBadge = (level) => {
    if (level === undefined) {
      const exemptBadge = bbbee_badges.find(badge => badge.level === "exempt");
      return exemptBadge ? exemptBadge.badge_asset : '';
    }
    const badge = bbbee_badges.find(badge => badge.level === level.toString());
    return badge ? badge.badge_asset : ''; 
  }

  function truncateText(text, maxWords) {
    const wordsArray = text.split(' ');
    if (wordsArray.length > maxWords) {
      return `${wordsArray.slice(0, maxWords).join(' ')}...`;
    }
    return text;
  }

  return (
    <section className="brand-list mb-4 pb-4 pt-4">
      {filteredBrands.map((filteredBrand) => {
        const matchedCompany = companies.find(company => company._id === filteredBrand.company_id);
        
        return isMobile ? (
          // Mobile Card Layout
          <Row key={filteredBrand.brand_name} className="brand-list-item justify-content-center align-items-center px-3 py-4">
            <Col xs={12}>
              <Card className="brandCard" style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div className="card-image px-4">
                    {filteredBrand.brand_image ? 
                      <img src={`${filteredBrand.brand_image}`} alt={filteredBrand.brand_name} style={{ maxWidth: '75px', maxHeight: '75px' }} />
                    : 
                      <img src="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=" alt="logo" style={{ maxWidth: '75px', maxHeight: '75px' }} />
                    }
                  </div>
                  <div className="card-titles">
                    <Card.Title>{filteredBrand.brand_name}</Card.Title>
                    <small className="small text-dark">{filteredBrand.brand_location}</small>
                    <p className="mt-0 pt-0">Founded in {filteredBrand.founded}</p>
                  </div>
                  <div className="bbbee_badge_wrapper">
                    <img src={getBbbeeBadge(filteredBrand.bbbee_level)} alt={`BBBEE Level ${filteredBrand.bbbee_level} Badge`} style={{ maxHeight: '50px', width: 'auto' }} />
                  </div>
                </div>
                <Card.Body>
                  <Card.Text className="p-4">
                    {truncateText(filteredBrand.brand_description, 30)}
                    <hr className="mt-4" />
                    <div className="offering-section">
                      {filteredBrand.meta.map((action, idx) => (
                        <Button key={idx} variant="brand-orange" as={Link} to={Routes.Wizard.path} className="btn btn-primary p-2 my-2 cardBtn" style={{ width: '100%' }}>
                          Get A Quote
                        </Button>
                      ))}
                      {filteredBrand.cta.map((action, idx) => (
                        <Button key={idx} variant="primary" as={Link} to={`${Routes.BrandsView.path.replace(':brand_name', filteredBrand.brand_name)}`} className="btn btn-primary p-2 my-2 cardBtn" style={{ width: '100%' }}>
                          {action.button_text}
                        </Button>
                      ))}
                      <Button variant="primary" as={Link} to={`${Routes.CompanyView.path.replace(':trading_name', matchedCompany ? matchedCompany.trading_name_slug : 'default-company')}`}  className="btn btn-primary p-2 my-2 cardBtn" style={{ width: '100%' }}>
                        View Company Profile
                      </Button>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          // Desktop Card Layout
          <Row key={filteredBrand.brand_name} className="brand-list-item justify-content-center align-items-center px-6 py-4">
            <Col xs={12} md={12} lg={12} xl={12}>
              <Card className="brandCard" style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
                <div style={{ flex: '0 0 75%' }}>
                  <Card.Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div className="card-image px-4">
                        {filteredBrand.brand_image ? 
                          <img src={`${filteredBrand.brand_image}`} alt={filteredBrand.brand_name} width="75px" height="75px" />
                        : 
                          <img src="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=" alt="logo" width="75px" height="75px" />
                        }
                      </div>
                      <div className="card-titles">
                        <Card.Title>{filteredBrand.brand_name}</Card.Title>
                        <small className="small text-dark">{filteredBrand.brand_location}</small>
                        <p className="mt-0 pt-0">Founded in {filteredBrand.founded}</p>
                      </div>
                    </div>
                    <div className="bbbee_badge_wrapper">
                      <img src={getBbbeeBadge(filteredBrand.bbbee_level)} alt={`BBBEE Level ${filteredBrand.bbbee_level} Badge`} height="50" width="auto"/>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Card.Text className="p-4">
                      {truncateText(filteredBrand.brand_description, 50)}
                      <hr className="mt-4" />
                      <div style={{ fontSize: '14px' }} className="offering-section">
                        <Row>
                          <Col md={4}>
                            <FontAwesomeIcon style={{ color: 'green' }} icon={faCheckCircle} /> Offering Placeholder<br/>
                            <FontAwesomeIcon style={{ color: 'green' }} icon={faCheckCircle} /> Offering Placeholder<br/>
                            <FontAwesomeIcon style={{ color: 'green' }} icon={faCheckCircle} /> Offering Placeholder
                          </Col>
                          <Col md={4}>
                            <FontAwesomeIcon style={{ color: 'green' }} icon={faCheckCircle} /> Offering Placeholder<br/>
                            <FontAwesomeIcon style={{ color: 'green' }} icon={faCheckCircle} /> Offering Placeholder<br/>
                            <FontAwesomeIcon style={{ color: 'green' }} icon={faCheckCircle} /> Offering Placeholder
                          </Col>
                          <Col md={4}>
                            <FontAwesomeIcon style={{ color: 'green' }} icon={faCheckCircle} /> Offering Placeholder<br/>
                            <FontAwesomeIcon style={{ color: 'green' }} icon={faCheckCircle} /> Offering Placeholder<br/>
                            <FontAwesomeIcon style={{ color: 'green' }} icon={faCheckCircle} /> Offering Placeholder
                          </Col>
                        </Row>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </div>
                <div style={{ flex: '0 0 25%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', borderLeft: '1px solid #d7d7d7' }}>
                  <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '10px 0px 10px 10px' }}>
                    {filteredBrand.meta.map((action, idx) => (
                      <Button key={idx} variant="brand-orange" as={Link} to={Routes.Wizard.path} className="btn btn-primary p-4 my-2 cardBtn" style={{ marginBottom: '5px', borderRadius: '0px 0px 0px 0px' }}>
                        Get A Quote
                      </Button>
                    ))}
                    {filteredBrand.cta.map((action, idx) => (
                      <Button key={idx} variant="primary" as={Link} to={`${Routes.BrandsView.path.replace(':brand_name', filteredBrand.brand_name)}`} className="btn btn-primary p-4 my-2 cardBtn" style={{ marginBottom: '5px', borderRadius: '0px 0px 0px 0px' }}>
                        {action.button_text}
                      </Button>
                    ))}
                    <Button variant="primary" as={Link} to={`${Routes.CompanyView.path.replace(':trading_name', matchedCompany ? matchedCompany.trading_name_slug : 'default-company')}`}  className="btn btn-primary p-4 my-2 cardBtn" style={{ marginBottom: '5px', borderRadius: '0px 0px 0px 0px' }}>
                      View Company Profile
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        );
      })}
    </section>
  );
};
