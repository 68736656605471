import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';
import AddBuyerModal from "../../../components/modals/AddBuyerModal";
import { getAllBuyers, createBuyers, deleteBuyers, updateBuyers } from "../../../api/private/buyers";
import AlertComponent from "../../../functions/alerts";
import { getAllCompanies } from "../../../api/private/companies";
import { getAllUsers } from "../../../api/private/users";
import { BuyersTables } from "../../../components/tables/BuyersTables";
import { useUserRole } from "../../../contexts/UserRoleContext";
import { ToastContainer, toast} from "react-toastify";

export default () => {
  const { userRole } = useUserRole();
  const [buyers, setBuyers] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [pageMounted, setPageMounted] = useState(false)
  const [companies, setCompanies] = useState([])
  const [users, setUsers] = useState([])
  const [isEditMode, setIsEditMode] = useState(false);
  const [initialBuyerData, setInitialBuyerData] = useState(null);
  const [alert, setAlert] = useState({ type: '', message: '' });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    loadBuyers();
    loadCompanies();
    loadUsers();
  }, [pageMounted])

  function loadBuyers() {
    getAllBuyers().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        const records = _resp.data.Records
        setAlert({ type: 'success', message: `Buyers loaded successfully.` })
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        setBuyers(records)
      } else {
        console.warn(_resp.data)
        setAlert({ type: 'error', message: `Buyers not loaded. Please contact support.` })
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      }
    }).catch((_resp) => {
      console.log(_resp)
      setAlert({ type: 'error', message: `Buyers not loaded.Please contact support.` })
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    })
  }

  const loadUsers = () => {
    getAllUsers().then(response => {
      if (response.data.Result === 'OK') {
        setUsers(response.data.Records);
      } else {
        console.warn(response.data);
      }
    }).catch(error => {
      console.error(error);
    });
  };

  function loadCompanies() {
    getAllCompanies().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        const records = _resp.data.Records
        setCompanies(records)
        setAlert({ type: 'success', message: `${_resp.data.Message}` })
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      } else {
        console.warn(_resp.data)
        setAlert({ type: 'error', message: `${_resp.data.Message}` })
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      }
    }).catch((_resp) => {
      console.log(_resp)
      setAlert({ type: 'error', message: `${_resp.data.Message}` })
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    })
  }

  function deleteBuyer(buyer) {
    deleteBuyers(buyer['_id']).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        loadUsers();
        setAlert({ type: 'success', message: `${_resp.data.Message}` })
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      } else {
        console.warn(_resp.data)
        setAlert({ type: 'error', message: `${_resp.data.Message}` })
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      }
    }).catch((_resp) => {
      console.log(_resp)
      setAlert({ type: 'error', message: `${_resp.data.Message}` })
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    })
  }

  function createBuyer(addedBuyerData) {
    // Replace 'createBuyers' with the correct function to make the HTTP request
    createBuyers(addedBuyerData).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        setShowModal(false);
        setAlert({ type: 'success', message: `${_resp.data.Message}` })
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        handleCloseModal()
      } else {
        console.warn(_resp.data);
        setAlert({ type: 'error', message: `${_resp.data.Message}` })
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      }
    }).catch((_resp) => {
      console.log(_resp);
      setAlert({ type: 'error', message: `${_resp.data.Message}` })
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    });
  }

  function updateBuyer(buyer, buyerData) {
    console.log("Update function ran with object:", buyer);

    // Check if _doc_id is undefined or not
    if (!buyer['id']) {
      console.error("Document ID is undefined.");
      return;
    }

    const payload = {
      'linked_companies': [
        {
          'doc_id': buyerData['linked_companies']['doc_id'],
          'legal_name': buyerData['linked_companies']['legal_name']
        }
      ],
      'linked_user': {
        'doc_id': buyerData['linked_user']['doc_id'],
        'email': buyerData['linked_user']['email']
      }
    }
    console.log(buyer);

    updateBuyers(buyer['id'], payload).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        setShowModal(false)
        setAlert({ type: 'success', message: `${_resp.data.Message}` })
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        handleCloseModal()
        console.log(_resp.data)
      } else {
        console.warn(_resp.data)
        setAlert({ type: 'error', message: `${_resp.data.Message}` })
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        handleCloseModal()
      }
    }).catch((_resp) => {
      console.log(_resp)
      setAlert({ type: 'error', message: `${_resp.data.Message}` })
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    })
  }

  const handleEditBuyer = (buyer) => {
    console.log(buyer);
    setInitialBuyerData(buyer);
    setIsEditMode(true);
    setShowModal(true);
  };

  const handleAddBuyer = () => {
    setInitialBuyerData(null);
    setIsEditMode(false);
    setShowModal(true);
  };

  return (
    <>
    <ToastContainer hideProgressBar={true} theme="light" pauseOnHover autoClose={5000}/>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>companies</Breadcrumb.Item>
            <Breadcrumb.Item active>Buyers</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Buyers</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
            <Button className="btn btn-primary" onClick={handleShowModal}>Add Buyers</Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            {/* <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup> */}
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <AlertComponent type={alert.type} message={alert.message} />

      <BuyersTables buyers={buyers} handleEditBuyer={handleEditBuyer} deleteBuyer={deleteBuyer} users={users} companies={companies}/>
      <AddBuyerModal showModal={showModal}
        handleCloseModal={handleCloseModal}
        createBuyer={createBuyer}
        updateBuyer={updateBuyer}
        initialBuyerData={initialBuyerData}
        isEditMode={isEditMode}
        companies={companies} users={users} />
    </>
  );
};
