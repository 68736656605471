import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faEdit, faEllipsisH, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Table, Dropdown, Pagination, ButtonGroup, Form } from '@themesberg/react-bootstrap';

const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return (
    value ? <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}{suffix}
      </span>
    </span> : "--"
  );
};

const ITEMS_PER_PAGE = 10;

export const CmsPagesTables = ({ pages, deletePage, handleEditPage }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    title: "",
    createdOn: "",
    type: "",
    category: "",
    parent: "",
    author: ""
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const filteredPages = pages.filter(page => {
    return (
      (filters.title === "" || (page.title ?? "").toLowerCase().includes(filters.title.toLowerCase())) &&
      (filters.createdOn === "" || (page.created_on ?? "").toLowerCase().includes(filters.createdOn.toLowerCase())) &&
      (filters.type === "" || (page.type ?? "").toLowerCase().includes(filters.type.toLowerCase())) &&
      (filters.category === "" || (page.category ?? "").toLowerCase().includes(filters.category.toLowerCase())) &&
      (filters.parent === "" || (page.parent ?? "").toLowerCase().includes(filters.parent.toLowerCase())) &&
      (filters.author === "" || (page.author ?? "").toLowerCase().includes(filters.author.toLowerCase()))
    );
  });

  const currentTableData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    const sortedPages = filteredPages.slice().sort((a, b) => new Date(b.created_on) - new Date(a.created_on));

    return sortedPages.slice(startIndex, endIndex);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageCount = Math.ceil(filteredPages.length / ITEMS_PER_PAGE);
  const items = [];
  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
        {number}
      </Pagination.Item>
    );
  }

  const statusColorMap = {
    "draft": "#cccccc",  // grey
    "under review": "#007bff",  // blue
    "published": "#28a745", // green
    "private": "#FFEA00" // yellow
  };

  const getStatusColor = (status) => {
    return statusColorMap[status] || "#cccccc";
  };

  const TableRow = (props) => {
    const { _id, type, parent, title, seo_title, slug, created_on, canonical_link, author, excerpt, status, date_published, page_content, category, component_content } = props;
    const statusColor = getStatusColor(status);
    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center" onClick={() => handleEditPage({ _id, type, title, seo_title, slug, created_on, canonical_link, author, excerpt, status, date_published, page_content, category, component_content })}>
            <span style={{ height: '15px', width: '15px', backgroundColor: statusColor, borderRadius: '50%', display: 'inline-block', marginRight: '10px' }}></span>
            <div className="title-col">
              <span className="h6">{title}</span><br />
              {slug ? slug : "-"}<br />
              {status ? status : "-"}
            </div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">
          {seo_title ? seo_title : "-"}
        </td>
        <td className="border-0">
          {created_on}
        </td>
        <td className="fw-bold border-0">
          {canonical_link ? canonical_link : "-"}
        </td>
        <td className="fw-bold border-0">
          <span onClick={() => handleEditPage({ _id, type, title, parent, seo_title, slug, created_on, canonical_link, author, excerpt, status, date_published, page_content, category, component_content })}>{parent ? parent : "Unassigned"}</span>
        </td>
        <td className="fw-bold border-0">
          {type ? type : "-"}
        </td>
        <td className="fw-bold border-0">
          {category ? category : "-"}
        </td>
        <td className="fw-bold border-0">
          {author ? author : "-"}
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="tableNav">
              <Dropdown.Item onClick={() => handleEditPage({ _id, type, title, parent, seo_title, slug, created_on, canonical_link, author, excerpt, status, date_published, page_content, category, component_content })}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={() => deletePage(_id)}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Title</th>
              <th className="border-0">SEO Title</th>
              <th className="border-0">Date Created</th>
              <th className="border-0">Type</th>
              <th className="border-0">Category</th>
              <th className="border-0">Canonical URL</th>
              <th className="border-0">Parent</th>
              <th className="border-0">Author</th>
              <th className="border-bottom">Action</th>
            </tr>
            <tr>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Title"
                  name="title"
                  value={filters.title}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th></th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Date Created"
                  name="createdOn"
                  value={filters.createdOn}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Type"
                  name="type"
                  value={filters.type}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Category"
                  name="category"
                  value={filters.category}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th></th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Parent"
                  name="parent"
                  value={filters.parent}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Author"
                  name="author"
                  value={filters.author}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredPages.length > 0 ? (
              currentTableData().map((page, index) => <TableRow key={`page-${page._id || index}`} {...page} handleEditPage={handleEditPage} deletePage={deletePage} />)
            ) : (
              <tr>
                <td colSpan="9" className="text-center">
                  No records could be found at this time. Create your first page by clicking Add Pages
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination className="justify-content-center">{items}</Pagination>
      </Card.Body>
    </Card>
  );
};