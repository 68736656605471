import React, { useState, useEffect } from "react";
import { Col, Row, Image, Button, Container } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { NavigationBar } from "../../components/frontendNav/Navigation";
import { Footer } from '../../components/frontendNav/Footer'
import { Routes } from "../../routes";
import { getAllPages } from "../../api/private/pages";
import { getAllPosts } from "../../api/private/posts";
import { makeToast } from "../../functions/toasts";
import analytics from '../../functions/analytics';

export default () => {

  const [page, setPage] = useState({});
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadPages();
    loadPosts();
    analytics.page();
  }, []);

  const loadPosts = () => {
    getAllPosts().then(response => {
      if (response.data.Result === 'OK') {
        const faqPosts = response.data.Records.filter(post => post.post_type === 'about');
        setPosts(faqPosts);
      } else {
        console.warn(response.data);
        makeToast('error', 'Failed to load posts');
      }
    }).catch(error => {
      console.error(error);
      makeToast('error', 'Failed to load posts');
    });
  };

  function loadPages() {
    getAllPages().then(response => {
      if (response.data && response.data.Result === 'OK') {
        const records = response.data.Records;
        const foundPage = records.find(p => p.post_type === "about");
        if (foundPage) {
          setPage(foundPage);
          setLoading(false);
          console.log(`Page found:`, foundPage);
        } else {
          console.warn(`Page with slug "careers" not found.`);
        }
      } else {
        console.warn('Unexpected response structure:', response);
      }
    }).catch(error => {
      console.error('Error loading pages:', error);
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <>
      {/*-- Navigation Bar --*/}
      <NavigationBar />

      <section className="partner-brands align-items-start justify-content-start overflow-hidden pt-5 pt-lg-6 px-3 px-lg-7 bg-white text-dark">
        <Container>
          <h4 className="fw-normal lead text-dark pb-3">WELCOME TO FORKLIFTING.CO</h4>
          <h1 className="fw-bolder text-dark pb-4 mb-4">We exist to help forklift buyers make best-fit-for-business decisions quicker.</h1>
          <span className="lead">With years of experience in the material handling industry, we’ve combined our insights with technology and customer service to bring you a comprehensive and innovative solution that serves you, the forklift buyer or supplier, better.</span>
        </Container>
      </section>

      <section className="partner-brands align-items-start justify-content-start overflow-hidden pt-5 pt-lg-6 px-3 px-lg-7 pb-6 bg-white text-dark">
        <Container fluid>
          <Row>
            <Col xs={12} md={4}>
              <h3 className="fw-bolder">We connect businesses <br />around the world!</h3>
              <span className="lead">We’re actively sourcing businesses around the world so that you don’t have to. Our aim is to provide you with one platform for all your forklifting needs.</span>
            </Col>
            <Col className="d-flex align-items-center justify-content-center mt-4 mt-md-0" xs={12} md={8}>
              <img src="https://forklifting.co.za/wp-content/uploads/2023/11/globe-1024x400.png" className="w-100 img-fluid" />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="partner-brands text-center align-items-center justify-content-center overflow-hidden pt-5 pt-lg-6 px-3 px-lg-7 pb-6 bg-grey text-dark">
        <Container>
          <Row>
            <Col xs={12}>
              <h2 className="fw-bolder">Why use our platform?</h2>
              <Row className="justify-content-center align-items-center mt-6">
                {posts.map((post, index) => (
                  <Col key={index} className="align-items-start text-left bg-white p-4 mx-2 mx-md-4" xs={12} md={4}>
                    <h4>
                      {post.featured_image ? (
                        <img style={{ border: "1px solid #efefef", borderRadius: "100%" }} src={`${post.featured_image}`} width="30px" className="mx-2 blog-thumb-round" />
                      ) : (
                        <img style={{ border: "1px solid #efefef", borderRadius: "100%" }} src="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=" width="30px" className="m-2 blog-thumb-round" />
                      )}
                      {post.title}
                    </h4>
                    <p>{post.excerpt}</p>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="align-items-center justify-content-center overflow-hidden pt-5 pt-lg-6 px-3 px-sm-2 px-lg-7 pb-6 bg-white text-dark">
        <Container>
          <Row className="flex flex-column flex-md-row align-items-center text-center text-md-left">
            <Col xs={12} md={9} className="mb-3 mb-md-0">
              <h3 className="fw-bolder">For Buyers</h3>
              <p className="lead">Solve your forklift needs and challenges by searching our vetted database of forklift brands and suppliers.</p>
            </Col>
            <Col xs={12} md={3}>
              <Button as={Link} to={Routes.Brands.path} style={{ borderRadius: "0px", boxShadow: "25px 19px 40px 0px rgba(0, 0, 0, 0.24)" }} variant="brand-orange" className="px-5 px-4 py-3 mt-3 mt-md-0 btn btn-primary">
                Start Search
              </Button>
            </Col>
          </Row>
          <Row className="flex flex-column flex-md-row align-items-center text-center text-md-left mt-4">
            <Col xs={12} md={9} className="mb-3 mb-md-0">
              <h3 className="fw-bolder">For Sellers</h3>
              <p className="lead">Solve your forklift needs and challenges by searching our vetted database of forklift brands and suppliers.</p>
            </Col>
            <Col xs={12} md={3}>
              <Button as={Link} to={Routes.ForSuppliers.path} style={{ borderRadius: "0px", boxShadow: "25px 19px 40px 0px rgba(0, 0, 0, 0.24)" }} variant="brand-orange" className="px-5 px-4 py-3 mt-3 mt-md-0 btn btn-primary">
                Get Listed
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      {/*-- Footer --*/}
      <Footer />
    </>
  );
};
