import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';
import { getAllOfferings, createOfferings, updateOfferings, deleteOfferings } from "../../../api/private/offerings";
import { OfferingsTables } from "../../../components/tables/OfferingsTables";
import { makeToast } from "../../../functions/toasts";
import AddOfferingModal from "../../../components/modals/AddOfferingModal"
import { ToastContainer, toast } from "react-toastify";
export default () => {

  const [offerings, setOfferings] = useState([])
  const [initialOfferingData, setInitialOfferingData] = useState(null);
  const [pageMounted, setPageMounted] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
      loadOfferings();
    }, [])

  function loadOfferings() {
    getAllOfferings().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        const records = _resp.data.Records
        setOfferings(records)
      } else {
        console.warn(_resp.data)
      }
    }).catch((_resp) => {
      console.log(_resp)
      makeToast('error', 'Failed to load offerings')
    })
  }

  function createOffering(offeringData){
    
    const payload = {
      'offering': offeringData['offering'],
      'offering_content': offeringData['offering_content'],
      'rental_service': offeringData['rental_service'],
      'maintenance_and_repairs': offeringData['maintenance_and_repairs'],
      'training_programmings': offeringData['training_progreammings'],
      'spare_parts_and_accessories': offeringData['spare_parts_and_accessories'],
      'emergency_services_and_response': offeringData['emergency_services_and_response'],
      'technology_solutions': offeringData['technology_solutions'],
      'refurbished_solutions': offeringData['refurbished_solutions'],
      'customized_solutions': offeringData['customized_solution'],
      'compliance_and_certification_services': offeringData['compliance_and_certification_services'],
      'warranty_offered': offeringData['warranty_offered'],
      'notes': offeringData['notes']
    }
    console.log(payload)

    createOfferings(payload).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        makeToast('success', 'Created new offering')
        handleCloseModal()
        setShowModal(false)
      } else {
        console.warn(_resp.data)
      }
    }).catch((_resp) => {
      console.log(_resp)
      makeToast('error', 'Failed to create offering')
    })
  }

  function updateOffering(offering, offeringData){
    console.log("Update function ran with object:", offering);
    
    if(!offering['_id']) {
      console.error("Document ID is undefined.");
      makeToast('error', 'Document ID is missing');
      return;
    }

    const payload = {
      'offering': offeringData['offering'],
      'offering_content': offeringData['offering_content'],
      'rental_service': offeringData['rental_service'],
      'maintenance_and_repairs': offeringData['maintenance_and_repairs'],
      'training_programmings': offeringData['training_progreammings'],
      'spare_parts_and_accessories': offeringData['spare_parts_and_accessories'],
      'emergency_services_and_response': offeringData['emergency_services_and_response'],
      'technology_solutions': offeringData['technology_solutions'],
      'refurbished_solutions': offeringData['refurbished_solutions'],
      'customized_solutions': offeringData['customized_solution'],
      'compliance_and_certification_services': offeringData['compliance_and_certification_services'],
      'warranty_offered': offeringData['warranty_offered'],
      'notes': offeringData['notes']
    }
    console.log("This is the offering", offering);
  
    updateOfferings(offering['_id'], payload).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        makeToast('success', 'User updated successfully')
        setShowModal(false)
        console.log(_resp.data)
      } else {
        console.warn(_resp.data)
      }
    }).catch((_resp) => {
      console.log(_resp)
      makeToast('error', 'Failed to update offering')
    })
  }

  function deleteOffering(offerings){
    deleteOfferings(offerings).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        makeToast('success', 'Deleted offering')
        loadOfferings();
      } else {
        console.warn(_resp.data)
      }
    }).catch((_resp) => {
      console.log(_resp)
      makeToast('error', 'Failed to delete offering')
    })
  }

  const handleEditOffering = (offering) => {
    console.log(offering);
    setInitialOfferingData(offering);
    setIsEditMode(true);
    setShowModal(true);
  };

  const handleAddOffering = () => {
    setInitialOfferingData(null);
    setIsEditMode(false);
    setShowModal(true);
  };
  return (
    <>
    <ToastContainer hideProgressBar={true} theme="light" pauseOnHover autoClose={5000}/>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Brands</Breadcrumb.Item>
            <Breadcrumb.Item active>Services</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Services</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
            <Button className="btn btn-primary" onClick={handleAddOffering}>Add Service Link</Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            {/* <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup> */}
          </Col>
          <Col xs={4} md={4} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <OfferingsTables offerings={offerings} deleteOffering={deleteOffering} handleEditOffering={handleEditOffering}/>
      <AddOfferingModal 
      showModal={showModal} 
      handleCloseModal={handleCloseModal} 
      createOffering={createOffering}
      updateOffering={updateOffering}
      initialOfferingData={initialOfferingData}
      isEditMode={isEditMode}
      />
    </>
  );
};
