import React, { useState, useEffect } from 'react';
import { Container, Breadcrumb, Button, Accordion, Col, Row, Card } from '@themesberg/react-bootstrap';
import { NavigationBar } from "../../../../components/frontendNav/Navigation";
import { Footer } from '../../../../components/frontendNav/Footer'
import { getAllOfferings } from '../../../../api/private/offerings';
export default () => {

    const [offerings, setOfferings] = useState([])

    useEffect(()=> {
        loadOfferings()
    })

    function loadOfferings() {
        getAllOfferings().then((_resp) => {
          if (_resp.data.Result === 'OK') {
            const records = _resp.data.Records
            setOfferings(records)
          } else {
            console.warn(_resp.data)
          }
        }).catch((_resp) => {
          console.log(_resp)
        })
      }

    return (
        <>
            {/* Navigation Bar */}
            <NavigationBar />
            <section className="partner-brands text-center align-items-center justify-content-center overflow-hidden pt-5 pt-lg-6 pb-6 pb-lg-6 bg-grey text-dark">
                <div style={{ width: '90%', textAlign: 'left', paddingLeft: '10%' }}>
                    <h1 className="fw-bold text-dark">Products</h1>
                    <div className="d-block mb-4 mb-md-0">
                        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                            <Breadcrumb.Item>You are here:</Breadcrumb.Item>
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Products</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </section>
            <section className="bg-white partner-brands align-items-center justify-content-center overflow-hidden pt-5 pt-lg-6 pb-6 pb-lg-6 text-dark">
            <Container className="bg-white pt-5 pt-lg-6 pb-6 pb-lg-6">
                <Row>
                    {offerings.map((offering, index) => (
                        <Col key={index} sm={12} md={12}>
                            <Accordion>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                        {offering['Product Name']}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <strong>Brand:</strong> {offering.Brand}<br />
                                            <strong>Series Name:</strong> {offering['Series Name']}<br />
                                            <strong>Model Numbers:</strong> {offering['Model Numbers']}<br />
                                            <strong>Load Capacity:</strong> {offering['Load Capacity']}<br />
                                            <strong>Load Centre:</strong> {offering['Load Centre']}<br />
                                            <strong>Lift Height:</strong> {offering['Lift Height']}<br />
                                            <strong>Extended Mast Height:</strong> {offering['Extended Mast Height']}<br />
                                            <strong>Special Features:</strong> {offering['Special Features']}<br />
                                            <strong>Description:</strong> {offering.Description}<br />
                                            <strong>Tags:</strong> {offering.Tags.join(', ')}<br />
                                            <div>
                                                <strong>Product Images:</strong><br />
                                                {offering['Product Images'].map((image, idx) => (
                                                    <img key={idx} src={image} alt={`Product ${idx}`} style={{ width: '100px', height: '100px' }} />
                                                ))}
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section >
            {/* Footer */ }
            < Footer />
        </>
    );
};
