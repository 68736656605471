import React, { useState, useEffect } from 'react';
import { Modal, Tab, Tabs, Form, Row, Col, Button, Accordion } from '@themesberg/react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddFaqModal = ({ showModal, handleCloseModal, createFaqs, updateFaq, initialFaqData, isEditMode }) => {
    const [faqData, setFaqData] = useState({
        _id: '',
        title: '',
        type: '',
        component_content: [],
    });
    const [faqElements, setFaqElements] = useState([]);

    useEffect(() => {
        if (showModal && isEditMode && initialFaqData) {
            setFaqData({
                _id: initialFaqData._id || initialFaqData._id, // Make sure to handle both potential keys
                title: initialFaqData.title || '',
                type: initialFaqData.type || '',
                component_content: initialFaqData.component_content || []
            });
            setFaqElements(initialFaqData.component_content || []);
        } else {
            setFaqData({ _id: '', title: '', type: '', component_content: [] });
            setFaqElements([]);
        }
    }, [initialFaqData, isEditMode, showModal]);

    const handleFaqElementChange = (index, field, value) => {
        const updatedElements = [...faqElements];
        updatedElements[index] = { ...updatedElements[index], [field]: value };
        setFaqElements(updatedElements);
    };

    const handleSubmit = () => {
        const updatedFaqData = {
            _id: faqData._id,
            title: faqData.title,
            type: faqData.type,
            component_content: faqElements
        };
    
        if (isEditMode) {
            console.log("Updating FAQ with ID:", updatedFaqData._id);
            updateFaq(updatedFaqData); // Pass the entire FAQ data object
        } else {
            createFaqs(updatedFaqData);
        }
        handleCloseModal();
    };
    

    const onKeyValueChange = (key, value) => {
        setFaqData(prevFaqData => ({
            ...prevFaqData,
            [key]: value,
        }));
    };

    const handleAddFaqElement = () => {
        setFaqElements([...faqElements, {
            id: '',
            component_content_type: '',
            font_class: '',
            text: '',
            link_type: '',
            link_url: ''
        }]);
    };

    const handleRemoveFaqElement = (index) => {
        const updatedElements = [...faqElements];
        updatedElements.splice(index, 1);
        setFaqElements(updatedElements);
    };

    return (
        <Modal size="xl" show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{isEditMode ? 'Edit Faq' : 'Add Faq'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey="general">
                    <Tab eventKey="general" title="General">
                        <Form>
                            <Row className="mt-3">
                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="title">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control type="text" value={faqData.title}
                                            placeholder="Enter title" onChange={(e) => onKeyValueChange('title', e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="type">
                                        <Form.Label>Type</Form.Label>
                                        <Form.Select value={faqData.type}
                                            onChange={(e) => onKeyValueChange('type', e.target.value)}>
                                            <option value="">Select category</option>
                                            <option value="buyer">Buyers</option>
                                            <option value="supplier">Suppliers</option>
                                            <option value="support">Support</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Tab>
                    <Tab eventKey="faqElements" title="Faq Elements">
                        <Form>
                            <div className="text-end">
                                <Button className="mt-3" variant="primary" onClick={handleAddFaqElement}>Add Element</Button>
                            </div>
                            {faqElements.map((element, index) => (
                                <Accordion key={index} className="mt-3">
                                    <Accordion.Item eventKey={index.toString()}>
                                        <Accordion.Header>{element.id || 'New Element'}</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Identifier</Form.Label>
                                                        <Form.Control type="text" value={element.id}
                                                            onChange={(e) => handleFaqElementChange(index, 'id', e.target.value)} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12} className="mb-3">
                                                    <Form.Group controlId="text">
                                                        <Form.Label>Text</Form.Label>
                                                        <Form.Control as="textarea" value={element.text}
                                                            onChange={(e) => handleFaqElementChange(index, 'text', e.target.value)} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Button variant="danger" onClick={() => handleRemoveFaqElement(index)}>Remove</Button>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            ))}
                        </Form>
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                <Button variant="primary" onClick={handleSubmit}>
                    {isEditMode ? 'Update Faq' : 'Add Faq'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddFaqModal;
