import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisH, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, Table, Dropdown, Pagination, ButtonGroup, Button, Form, InputGroup, Row, Col } from '@themesberg/react-bootstrap';

export const QuotesTables = ({ quotes, handleEditQuote, handleDeleteQuote }) => {

  const ITEMS_PER_PAGE = 10;

  const [filters, setFilters] = useState({
    id: "",
    company: "",
    contactPerson: "",
    status: ""
  });

  const [currentPage, setCurrentPage] = useState(1);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const filteredQuotes = quotes.filter(quote => {
    const contactPerson = quote.user_profile ? `${quote.user_profile.name} ${quote.user_profile.surname}` : "-";
    const quoteStatus = quote.quote_status ?? "undefined";

    return (
      (filters.id === "" || quote._id.toLowerCase().includes(filters.id.toLowerCase())) &&
      (filters.company === "" || (quote.user_profile?.company ?? "Unlinked").toLowerCase().includes(filters.company.toLowerCase())) &&
      (filters.contactPerson === "" || contactPerson.toLowerCase().includes(filters.contactPerson.toLowerCase())) &&
      (filters.status === "" || quoteStatus.toLowerCase().includes(filters.status.toLowerCase()))
    );
  });

  const TableRow = ({ _id, user_profile, created_on, quote_details, forklift_service_and_maintenance_enquiry, forklift_sales_new, forklift_sales_used, forklift_hire, request_for_training, general_contact_form, quote_value, quote_status }) => {
    const statusColorMap = {
      "undefined": "#c7c7c7", // grey
      "in progress": "yellow", // yellow
      "quoting": "orange", // orange
      "approved": "green", // green
      "rejected": "red" // red
    };

    const getStatusColor = (status) => {
      return statusColorMap[status] || "#c7c7c7"; 
    };

    const contactPerson = user_profile ? `${user_profile.name} ${user_profile.surname}` : "-";
    const quoteTotal = typeof quote_details?.quote_total === 'number' ? quote_details.quote_total.toFixed(2) : (typeof quote_details?.quote_total === 'string' ? parseFloat(quote_details.quote_total).toFixed(2) : '-');    
    const quoteStatus = quote_details?.quote_status ?? "undefined";
    const statusColor = getStatusColor(quoteStatus);

    return (
      <tr>
        <td onClick={() => handleEditQuote(_id, user_profile, created_on, quote_details, quote_value, quote_status, forklift_service_and_maintenance_enquiry, forklift_sales_new, forklift_sales_used, forklift_hire, request_for_training, general_contact_form)}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ height: '15px', width: '15px', backgroundColor: statusColor, borderRadius: '50%', display: 'inline-block', marginRight: '5px' }}></span>
            <span style={{ fontWeight: "bold", color: statusColor }}>{_id.slice(-6)}</span>
          </div>
        </td>
        <td>{user_profile?.company ?? "Unlinked"}</td>
        <td>{contactPerson}</td>
        <td>{created_on ? new Date(created_on).toLocaleDateString() : '-'}</td>
        <td>{quote_status ?? "undefined"}</td>
        <td>{quote_value ?? '-'}</td>
        <td>{quoteTotal}</td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleEditQuote(_id, user_profile, created_on, quote_details, forklift_service_and_maintenance_enquiry, forklift_sales_new, forklift_sales_used, forklift_hire, request_for_training, general_contact_form)}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={() => handleDeleteQuote(_id)}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  const currentTableData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    const sortedQuotes = filteredQuotes.slice().sort((a, b) => new Date(b.created_on) - new Date(a.created_on));

    return sortedQuotes.slice(startIndex, endIndex);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageCount = Math.ceil(filteredQuotes.length / ITEMS_PER_PAGE);
  const items = [];
  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th>Id</th>
              <th>Company</th>
              <th>Contact Person</th>
              <th>Date</th>
              <th>Status</th>
              <th>Value</th>
              <th>Total</th>
              <th>Actions</th>
            </tr>
            <tr>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Id"
                  name="id"
                  value={filters.id}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Company"
                  name="company"
                  value={filters.company}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Contact Person"
                  name="contactPerson"
                  value={filters.contactPerson}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th></th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Status"
                  name="status"
                  value={filters.status}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredQuotes.length > 0 ? (
              currentTableData().map((quote) => <TableRow key={`quote-${quote._id}`} {...quote} handleEditQuote={handleEditQuote} handleDeleteQuote={handleDeleteQuote} />)
            ) : (
              <tr>
                <td colSpan="8" className="text-center">
                  No quotes found.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination className="justify-content-center">{items}</Pagination>
      </Card.Body>
    </Card>
  );
};