import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createMessage = (payload) => {
    return doPrivatePostRequest(payload, '/messages/create')
}

export const updateMessage = (_doc_id, payload) => {
    return doPrivatePostRequest(payload, `/messages/update/${_doc_id}`)
}

export const getMessage = (_doc_id) => {
    return doPrivatePostRequest(`/messages/get/${_doc_id}`)
}

export const getAllMessages = () => {
    const payload = {
        'st': 'ALL'
    }
    return doPrivatePostRequest(payload, '/messages/get')
}

export const deleteMessage = (_doc_id) => {
    return doPrivateDelRequest(`/messages/delete/${_doc_id}`)
}
