import { doPublicPostRequest } from "./requests";

export const createBrand = (type, title, category, description, specs, offer_id, offer_name) => {

    const payload = {
            'type': type,
            'title': title,
            'category': category,
            'description': description,
            'specs': specs,
            'linked_offering': {
                'doc_id': offer_id,
                'name': offer_name
            }
    }

    return doPublicPostRequest(payload, '/brands/create')
}

export const updateBrand = (_doc_id, payload) => {
    return doPublicPostRequest(payload, `/brands/update/${_doc_id}`)
}

export const getBrand = (_doc_id) => {
    return doPublicPostRequest(`/brands/get/${_doc_id}`)
}

export const getAllBrands = () => {
    const payload = {
        'st': 'ALL'
    }
    return doPublicPostRequest(payload, '/public/brands/get')
}

// export const deleteBrand = (_doc_id) => {
//     return doPublicDelRequest(`/brands/delete/${_doc_id}`)
// }
