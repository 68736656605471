import { doPublicPostRequest, doPublicGetRequest } from "./requests";

export const getPages = (_doc_id) => {
    return doPublicPostRequest(`/website/pages/get/${_doc_id}`)
}

export const getAllPages = () => {
    const payload = {
        'st': 'ALL'
    }
    return doPublicPostRequest(payload, '/website/pages/get')
}