import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Accordion, Button, Modal, Tab, Tabs } from '@themesberg/react-bootstrap';
import ReactQuill from 'react-quill';
import { getAllPages } from '../../api/private/pages';
import { getAllCategories } from '../../api/private/categories';
import 'react-quill/dist/quill.snow.css';
import { getAllUsers } from '../../api/private/users';

const AddPageModal = ({ showModal, handleCloseModal, createPage, updatePage, initialPageData, isEditMode }) => {
    const [description, setDescription] = useState('');
    const [componentContent, setComponentContent] = useState([]);
    const [authors, setAuthors] = useState([])
    const [pages, setPages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [pageData, setPageData] = useState({
        id: '',
        type: "",
        priority: "",
        parent: "",
        title: "",
        seo_title: "",
        seo_description: "",
        slug: "",
        author: "",
        canonical_link: "",
        excerpt: "",
        status: "",
        page_content: "",
        category: "",
        image_doc_id: "",
        date_published: "",
        created_on: "",
        component_content: []
    });

    function loadPages() {
        getAllPages().then((_resp) => {
            if (_resp.data.Result === 'OK') {
                const records = _resp.data.Records
                setPages(records)
            } else {
                console.warn(_resp.data)
            }
        }).catch((_resp) => {
            console.log(_resp)
        })
    }

    const loadUsers = () => {
        getAllUsers().then(response => {
            if (response.data.Result === 'OK') {
                setAuthors(response.data.Records);
            } else {
                console.warn(response.data);
            }
        }).catch(error => {
            console.error(error);
        });
    };

    function loadCategories() {
        getAllCategories().then(response => {
            const { data } = response;
            if (data.Result === 'OK') {
                setCategories(data.Records || []);
                console.log("Fetched categories:", data.Records);
            } else {
                console.error('Failed to fetch categories:', data);
            }
        }).catch(error => {
            console.error('Error fetching categories:', error);
        });
    }

    useEffect(() => {
        if (showModal && isEditMode && initialPageData) {
            setPageData({
                ...initialPageData,
                date_published: initialPageData.date_published ? initialPageData.date_published.split('T')[0] : ""
            });
            setDescription(initialPageData.page_content || '');
            setComponentContent(initialPageData.component_content || []);
        } else {
            resetForm();
        }
    }, [showModal, isEditMode, initialPageData]);

    useEffect(() => {
        loadPages()
        loadCategories()
        loadUsers()
        console.log('Component Content Updated:', componentContent);
    }, [componentContent]);

    // console.log(initialPageData);
    // console.log('Component Content:', componentContent);

    // Reset function
    const resetForm = () => {
        setPageData({
            type: "",
            priority: "",
            parent: "",
            title: "",
            seo_title: "",
            seo_description: "",
            slug: "",
            author: "",
            canonical_link: "",
            excerpt: "",
            status: "",
            page_content: "",
            category: "",
            image_doc_id: "",
            date_published: "",
            created_on: "",
            component_content: []
        });
        setDescription('');
        setComponentContent([]);
    };

    const onKeyValueChange = (key, value) => {
        setPageData(prev => ({
            ...prev,
            [key]: value
        }));
    };

    const handleSubmit = () => {
        console.log("Submitting with page data:", pageData);
        console.log("Submitting with component content:", componentContent);

        if (isEditMode) {
            updatePage(pageData._id, { ...pageData, page_content: description, component_content: componentContent });
        } else {
            createPage({ ...pageData, page_content: description, component_content: componentContent });
        }

        handleCloseModal();
    };

    const handleDescriptionChange = (content) => {
        setDescription(content);
    };

    const handleAddPageElement = () => {
        const newElement = {
            id: `New Element ${componentContent.length + 1}`,
            component_content_type: '',
            heading: '',
            headingStyle: '',
            subtext: '',
            text: ''
        };
        setComponentContent(prevElements => [...prevElements, newElement]);
    };

    const handleRemovePageElement = (index) => {
        setComponentContent(prevElements => prevElements.filter((_, i) => i !== index));
    };

    const handlePageElementChange = (index, field, value) => {
        setComponentContent(prevElements => prevElements.map((element, idx) => {
            if (idx === index) {
                return { ...element, [field]: value };
            }
            return element;
        }));
    };

    return (
        <>
            <Modal size="xl" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditMode ? 'Update Page' : 'Add Page'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey="general">
                        <Tab eventKey="general" title="General">
                            <Form>
                                <Row className="mt-3">
                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="title">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control required type="text" placeholder="Enter title"
                                                value={pageData.title}
                                                onChange={(e) => onKeyValueChange('title', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="slug">
                                            <Form.Label>Slug</Form.Label>
                                            <Form.Control required type="text"
                                                placeholder="Enter title"
                                                value={pageData.slug}
                                                onChange={(e) => onKeyValueChange('slug', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="type">
                                            <Form.Label>Type</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                value={pageData.type}
                                                onChange={(e) => onKeyValueChange('type', e.target.value)}
                                                placeholder="Enter type"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="category">
                                            <Form.Label>Category</Form.Label>
                                            <Form.Select
                                                value={pageData.category}
                                                onChange={(e) => onKeyValueChange('category', e.target.value)}
                                                required
                                            >
                                                <option value="">Select Category</option>
                                                {categories && categories.length > 0 && categories.map(category => (
                                                    <option key={category.seo_title} value={category.seo_title}>{category.seo_title}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="author">
                                            <Form.Label>Author</Form.Label>
                                            <Form.Select
                                                value={pageData.author}
                                                onChange={(e) => onKeyValueChange('author', e.target.value)}
                                                required
                                            >
                                                <option value="">Select Author</option>
                                                {authors && authors
                                                    .filter(author => author.user_role === 'admin') // Filter authors to only include those with 'admin' role
                                                    .map(author => (
                                                        <option key={author.id} value={author.first_name}>{author.first_name}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="status">
                                            <Form.Label>Status</Form.Label>
                                            <Form.Select required
                                                value={pageData.status}
                                                onChange={(e) => onKeyValueChange('status', e.target.value)}
                                            >
                                                <option value="">Select status</option>
                                                <option value="draft">Draft</option>
                                                <option value="under review">Under Review</option>
                                                <option value="published">Published</option>
                                                <option value="private">Private</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="date_published">
                                            <Form.Label>Date Published</Form.Label>
                                            <Form.Control required type="date"
                                                value={pageData.date_published}
                                                onChange={(e) => onKeyValueChange('date_published', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="status">
                                            <Form.Label>Priority</Form.Label>
                                            <Form.Select required
                                                value={pageData.priority}
                                                onChange={(e) => onKeyValueChange('priority', e.target.value)}
                                            >
                                                <option value="">Select priority</option>
                                                <option value="parent">Parent</option>
                                                <option value="child">Child</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    {pageData.priority === "child" && (
                                        <Col md={6} className="mb-3">
                                            <Form.Group controlId="parent">
                                                <Form.Label>Parent Page</Form.Label>
                                                <Form.Select
                                                    value={pageData.parent}
                                                    onChange={(e) => onKeyValueChange('parent', e.target.value)}
                                                    required
                                                >
                                                    <option value="">Select Parent Page</option>
                                                    {pages && pages.length > 0 && pages.map(page => (
                                                        <option key={page.id} value={page.id}>{page.title}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    )}
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="description" style={{ height: '400px' }}>
                                            <Form.Label>Page Content</Form.Label>
                                            <ReactQuill style={{ height: '300px' }}
                                                value={description}
                                                onChange={handleDescriptionChange}
                                                placeholder="Enter post content"
                                                modules={{
                                                    toolbar: [
                                                        [{ 'header': [1, 2, false] }],
                                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                        ['link', 'image'],
                                                        ['clean']
                                                    ]
                                                }}
                                                formats={[
                                                    'header', 'font', 'size',
                                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                                    'list', 'bullet', 'indent',
                                                    'link', 'image'
                                                ]}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="image_doc_ic">
                                            <Form.Label>Featured Image</Form.Label>
                                            <Form.Control required type="file" placeholder="Enter featured image" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Tab>
                        <Tab eventKey="componentContent" title="Component Content">
                            <Form>
                                <div className="text-end">
                                    <Button className="mt-3" variant="primary" onClick={handleAddPageElement}>Add Element</Button>
                                </div>
                                {componentContent.map((component_content, index) => (
                                    <Accordion key={index} className="mt-3">
                                        <Accordion.Item eventKey={index.toString()}>
                                            <Accordion.Header>{component_content.id}</Accordion.Header>
                                            <Accordion.Body>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label>Identifier</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={component_content.id}
                                                                onChange={(e) => handlePageElementChange(index, 'id', e.target.value)}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6} className="mb-3">
                                                        <Form.Group controlId="component_content_type">
                                                            <Form.Label>Element</Form.Label>
                                                            <Form.Select
                                                                required
                                                                value={component_content.component_content_type}
                                                                onChange={(e) => handlePageElementChange(index, 'component_content_type', e.target.value)}
                                                            >
                                                                <option value="">Select Element Type</option>
                                                                <option value="list">List</option>
                                                                <option value="textbox">Textbox</option>
                                                                <option value="cardbox">Cardbox</option>
                                                                <option value="heading">Heading</option>
                                                                <option value="button">Button</option>
                                                                <option value="paragraph">Paragraph</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6} className="mb-3">
                                                        <Form.Group controlId="font_class">
                                                            <Form.Label>Heading Type</Form.Label>
                                                            <Form.Select
                                                                required
                                                                value={component_content.font_class}
                                                                onChange={(e) => handlePageElementChange(index, 'font_class', e.target.value)}
                                                            >
                                                                <option value="">Select Heading Type</option>
                                                                <option value="h1">H1</option>
                                                                <option value="h2">H2</option>
                                                                <option value="h3">H3</option>
                                                                <option value="h4">H4</option>
                                                                <option value="h5">H5</option>
                                                                <option value="h6">H6</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12} className="mb-3">
                                                        <Form.Group controlId="text">
                                                            <Form.Label>Text</Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                value={component_content.text}
                                                                onChange={(e) => handlePageElementChange(index, 'text', e.target.value)}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12} className="mb-3">
                                                        <Form.Group controlId="subtext">
                                                            <Form.Label>SubText</Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                value={component_content.subtext}
                                                                onChange={(e) => handlePageElementChange(index, 'subtext', e.target.value)}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Button variant="danger" onClick={() => handleRemovePageElement(index)}>Remove</Button>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                ))}
                            </Form>
                        </Tab>
                        <Tab eventKey="seo" title="SEO">
                            <Form>
                                <Row className="mt-3">
                                    <Col md={6}>
                                        <Form.Group controlId="seo_title">
                                            <Form.Label>SEO Title</Form.Label>
                                            <Form.Control required type="text"
                                                placeholder="Enter title"
                                                value={pageData.seo_title}
                                                onChange={(e) => onKeyValueChange('seo_title', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="canonical_link">
                                            <Form.Label>Canonical URL</Form.Label>
                                            <Form.Control required type="text"
                                                placeholder="Enter canonical"
                                                value={pageData.canonical_link}
                                                onChange={(e) => onKeyValueChange('canonical_link', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md={12}>
                                        <Form.Group controlId="seo_description">
                                            <Form.Label>SEO Description</Form.Label>
                                            <Form.Control required as="textarea"
                                                placeholder="Enter SEO description"
                                                value={pageData.seo_description}
                                                onChange={(e) => onKeyValueChange('seo_description', e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        {isEditMode ? 'Update Page' : 'Add Page'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddPageModal;
