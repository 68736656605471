import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';
import { getRobots, updateRobots } from '../../../api/private/robots'
import { makeToast } from '../../../functions/toasts'
import { useUserRole } from "../../../contexts/UserRoleContext";

export default () => {
  const { userRole } = useUserRole();
  const [robotsContent, setRobotsContent] = useState('');

  useEffect(() => {
    fetchCurrentRobotsContent();
  }, []);

  const fetchCurrentRobotsContent = () => {
    {
      getRobots().then((_resp) => {
        const data = _resp
        if (data && data.content) {
          setRobotsContent(data.content);
        } else {
          console.error('No content in response:', data);
        }
      }).catch((_resp) => {
        console.log(_resp)
        makeToast('error', 'Failed to load brands')
      });
    }
  };

  const handleRobotsContentChange = (event) => {
    setRobotsContent(event.target.value);
  };

  const handleUpdateRobotsContent = async () => {
    try {
      const response = await updateRobots(robotsContent);
      if (response.status === 'success') {
        alert('Robots.txt updated successfully');
      } else {
        console.error('Failed to update robots.txt:', response.message);
      }
    } catch (error) {
      console.error('Failed to update robots.txt:', error);
    }
  };

  return (
    <>

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>SEO</Breadcrumb.Item>
            <Breadcrumb.Item active>Robots</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Robots</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
            <Button onClick={handleUpdateRobotsContent}>Update Robots</Button>
          </ButtonGroup>
        </div>
      </div>

      <Row className="mt-3">
        <Col>
          <Form.Group controlId="robotsTxtContent">
            <Form.Label>Robots.txt Content</Form.Label>
            <Form.Control
              as="textarea"
              rows={10}
              placeholder={`User agent:* \nDisallow: \nSitemap: https://localhost:3000/sitemap.xml`}
              value={robotsContent}
              onChange={handleRobotsContentChange}
              style={{ height: 'auto', minHeight: '80vh' }}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};