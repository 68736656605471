import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Col, Row, Form, Button, Modal } from '@themesberg/react-bootstrap';
import makeAnimated from 'react-select/animated';
import { getAllCompanies } from "../../api/private/companies"; // Ensure correct path
import { getAllUsers } from "../../api/private/users"; // Ensure correct path

const AddBuyerModal = ({ showModal, handleCloseModal, isEditMode, createBuyer, updateBuyer, initialBuyerData, buyer }) => {
    const [buyerData, setBuyerData] = useState({
        linked_companies: [{
            doc_id: '',
            email: ''
        }],
        linked_user: {
                doc_id: '',
                email: ''
        }
    });

    const [companies, setCompanies] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (isEditMode && initialBuyerData) {
            setBuyerData({
                linked_companies: [{
                    doc_id: initialBuyerData.linked_company._id,
                    legal_name: initialBuyerData.linkedCompany.legal_name
                }],
                linked_user:  {
                    doc_id: initialBuyerData.linked_user._id,
                    email: initialBuyerData.linked_user.email
                }
            });
        }
    }, [isEditMode, initialBuyerData]);

    useEffect(() => {
        // Load Companies
        getAllCompanies().then(response => {
            if (response.data.Result === 'OK') {
                const companyOptions = response.data.Records.map(company => ({
                    value: company._id,
                    label: company.legal_name
                }));
                setCompanies(companyOptions);
            } else {
                console.warn('Failed to load companies:', response.data.Message);
            }
        }).catch(error => {
            console.error('Error fetching companies:', error);
        });

        // Load Users
        getAllUsers().then(response => {
            if (response.data.Result === 'OK') {
                const userOptions = response.data.Records.map(user => ({
                    value: user._id,
                    label: user.email_address
                }));
                setUsers(userOptions)
            } else {
                console.warn('Failed to load users:', response.data.Message);
            }
        }).catch(error => {
            console.error('Error fetching users:', error);
        });
    }, []);

    const animatedComponents = makeAnimated();

    const handleCompanyChange = selectedOption => {
        setBuyerData({ ...buyerData, linked_companies: selectedOption ? selectedOption.value : '' });
    };

    const handleUserChange = selectedOption => {
        setBuyerData({ ...buyerData, linked_user: selectedOption ? selectedOption.value : '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); 
        console.log('handleSubmit called', { isEditMode, initialBuyerData, buyerData });
    
        try {
            if (isEditMode) {
                console.log('Updating buyer with ID:', initialBuyerData.id);
                await updateBuyer(initialBuyerData.id, buyerData);
            } else {

                const addedBuyerData = buyerData

                console.log('Creating new buyer');
                await createBuyer(addedBuyerData);
            }
            handleCloseModal();
        } catch (error) {
            console.error('Error in handleSubmit:', error);
        }
    };
    

    return (
        <Modal size="lg" show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Add Buyer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Form.Group controlId="linked_company">
                                <Form.Label>Linked Company</Form.Label>
                                <Select
                                    components={animatedComponents}
                                    options={companies}
                                    onChange={handleCompanyChange}
                                    value={companies.find(c => c.value === buyerData.linked_companies)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group controlId="linked_user">
                                <Form.Label>Linked User</Form.Label>
                                <Select
                                    components={animatedComponents}
                                    options={users}
                                    onChange={handleUserChange}
                                    value={users.find(u => u.value === buyerData.linked_user)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                <Button variant="primary"  onClick={handleSubmit}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddBuyerModal;
