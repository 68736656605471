import React from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const LogoCarousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear",
        arrows: false,
        responsive: [
            {
                breakpoint: 768, // or the max-width you consider as "mobile"
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };

    return (
        <div className="logo-carousel px-4 mx-4">
            <Slider {...settings}>
                <div><img src="https://forklifting.co.za/wp-content/uploads/2023/11/1.png" alt="Logo 1" /></div>
                <div><img src="https://forklifting.co.za/wp-content/uploads/2023/11/forklift-brands.png" alt="Logo 2" /></div>
                <div><img src="https://forklifting.co.za/wp-content/uploads/2023/11/4.png" alt="Logo 3" /></div>
                <div><img src="https://forklifting.co.za/wp-content/uploads/2023/11/Linde-Forklift.png" alt="Logo 4" /></div>
                <div><img src="https://forklifting.co.za/wp-content/uploads/2023/11/clark-forklift.png" alt="Logo 5" /></div>
                <div><img src="https://forklifting.co.za/wp-content/uploads/2023/11/5.png" alt="Logo 6" /></div>
                <div><img src="https://forklifting.co.za/wp-content/uploads/2023/11/3.png" alt="Logo 7" /></div>
            </Slider>
        </div>
    );
};

export default LogoCarousel;
