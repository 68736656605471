import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Button, Modal, Tabs, Tab } from '@themesberg/react-bootstrap';
import { getAllBrands } from '../../api/private/brands';

const AddProductModal = ({ showModal, handleCloseModal, createProducts, updateProducts, initialProductData, isEditMode }) => {
    const [productData, setProductData] = useState({
        name: '',
        slug: '',
        price: '',
        brand: '',
        description: '',
        meta_data: [],
        image: '',
        category: ''
    });

    const categories = [
        { value: 'Rough Terrain Forklift', label: 'Rough Terrain Forklift' },
        { value: 'Counterbalance Forklift', label: 'Counterbalance Forklift' },
        { value: 'Forklift Loader', label: 'Forklift Loader' },
        { value: 'Pallet Jack', label: 'Pallet Jack' },
        { value: 'Turret Forklift', label: 'Turret Forklift' },
        { value: 'Order Picker', label: 'Order Picker' },
        { value: 'Sideloader', label: 'Sideloader' },
        { value: 'Articulated Forklift', label: 'Articulated Forklift' },
    ];

    const attributeOptions = [
        { value: 'Lift Capacity', label: 'Lift Capacity (Tonnes)' },
        { value: 'Lift Reach', label: 'Lift Reach' },
        { value: 'Power Source', label: 'Power Source' },
        { value: 'Type', label: 'Type' },
    ]

    const [brands, setBrands] = useState([]);
    const [attributes, setAttributes] = useState([
        { key: '', value: '' }
    ]);

    useEffect(() => {
        if (showModal && isEditMode && initialProductData) {
            setProductData({
                ...initialProductData,
                brochure: null,
                photos: null
            });
            if (initialProductData.meta_data) {
                setAttributes(initialProductData.meta_data.map(attr => ({ key: attr.key, value: attr.value })));
            }
        } else {
            setProductData({ name: '', slug: '', price: '', brand: '', description: '', meta_data: [], image: '', category: '' });
            setAttributes([{ key: '', value: '' }]);
        }
    }, [initialProductData, isEditMode, showModal]);

    useEffect(() => {
        if (showModal) {
            getAllBrands().then(response => {
                if (response.data.Result === 'OK') {
                    setBrands(response.data.Records);
                }
            }).catch(error => {
                console.error('Error loading brands:', error);
            });
        }
    }, [showModal]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setProductData(prevProductData => {
            const updatedData = { ...prevProductData, [name]: value };
            console.log('Updated Product Data:', updatedData);
            return updatedData;
        });
    };

    const handleCategoryChange = (event) => {
        const { value } = event.target;
        setProductData(prevProductData => {
            const updatedData = { ...prevProductData, category: value };
            console.log('Updated Category Data:', updatedData);
            return updatedData;
        });
    };

    const handleFileChange = (key, files) => {
        if (files.length > 0) {
            setProductData(prevProductData => {
                const updatedData = { ...prevProductData, [key]: files[0] };
                console.log('Updated File Data:', updatedData);
                return updatedData;
            });
        }
    };

    const handleAttributeChange = (index, field, value) => {
        const newAttributes = [...attributes];
        newAttributes[index][field] = value;
        setAttributes(newAttributes);
    };

    const addAttribute = () => {
        setAttributes([...attributes, { key: '', value: '' }]);
    };

    const removeAttribute = (index) => {
        const newAttributes = [...attributes];
        newAttributes.splice(index, 1);
        setAttributes(newAttributes);
    };

    const handleSave = () => {
        const updatedProductData = {
            ...productData,
            meta_data: attributes
        };
        isEditMode ? updateProducts(updatedProductData) : createProducts(updatedProductData);
    };

    // Function to get brand name by brand id
    const getBrandNameById = (id) => {
        const brand = brands.find(brand => brand._id === id);
        return brand ? brand.brand_name : 'Unknown';
    };

    return (
        <Modal size="lg" show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{isEditMode ? 'Update Product' : 'Add Product'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey="basicInfo" id="product-tabs" className="mb-3">
                    <Tab eventKey="basicInfo" title="Basic Info">
                        <Form>
                            <Row>
                                <Col md={12} className="mb-3">
                                    <Form.Group controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            required type="text"
                                            placeholder="Enter name"
                                            name="name"
                                            value={productData.name}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="mb-3">
                                    <Form.Group controlId="slug">
                                        <Form.Label>Slug</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter slug"
                                            name="slug"
                                            value={productData.slug}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="price">
                                        <Form.Label>Price</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter price"
                                            name="price"
                                            value={productData.price}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6} className="mb-3">
                                    <Form.Group controlId="brand">
                                        <Form.Label>Brand</Form.Label>
                                        <Form.Select
                                            required
                                            name="brand"
                                            value={productData.brand}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select brand</option>
                                            {brands.map(brand => (
                                                <option key={brand._id} value={brand._id}>{brand.brand_name}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="mb-3">
                                    <Form.Group controlId="description">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            required
                                            placeholder="Enter description"
                                            name="description"
                                            value={productData.description}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Tab>
                    <Tab eventKey="productMeta" title="Product Meta">
                        <Form>
                            <Row>
                                <Col md={12} className="mb-3">
                                    <h4>Product Meta</h4>
                                    <hr className="separator" />
                                    <Form.Group controlId="image">
                                        <Form.Label>Thumbnail</Form.Label>
                                        <Form.Control
                                            type="file"
                                            required
                                            name="image"
                                            onChange={(e) => handleFileChange('image', e.target.files)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12} className="mb-3">
                                    <Form.Group controlId="category">
                                        <Form.Label>Product Category</Form.Label>
                                        <Form.Select
                                            required
                                            name="category"
                                            value={productData.category}
                                            onChange={handleCategoryChange}
                                        >
                                            <option value="">Select category</option>
                                            {categories.map(category => (
                                                <option key={category.value} value={category.value}>{category.label}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <h4>Attributes</h4>
                                    {attributes.map((attribute, index) => (
                                        <Row key={index}>
                                            <Col md={5}>
                                                <Form.Group>
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Select
                                                        value={attribute.key}
                                                        onChange={(e) => handleAttributeChange(index, 'key', e.target.value)}
                                                        required
                                                    >
                                                        <option value="">Select attribute</option>
                                                        {attributeOptions.map(attr => (
                                                            <option key={attr.value} value={attr.value}>{attr.label}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col md={5}>
                                                <Form.Group>
                                                    <Form.Label>Value</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={attribute.value}
                                                        onChange={(e) => handleAttributeChange(index, 'value', e.target.value)}
                                                        required
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Button variant="danger" onClick={() => removeAttribute(index)} style={{ marginTop: '32px' }}>
                                                    Remove
                                                </Button>
                                            </Col>
                                        </Row>
                                    ))}
                                    <Button className="mt-3" variant="primary" onClick={addAttribute}>
                                        Add Attribute
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                <Button variant="brand-orange" onClick={handleSave}>
                    {isEditMode ? 'Update Product' : 'Add Product'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddProductModal;
