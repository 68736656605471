import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, ButtonGroup, Breadcrumb, Dropdown } from '@themesberg/react-bootstrap';
import AddQuoteModal from "../../../components/modals/AddQuoteModal";
import { getAllQuotes, createQuotes, updateQuotes, deleteQuotes } from "../../../api/private/quotes";
import { QuotesTables } from "../../../components/tables/QuotesTables";
import { makeToast } from "../../../functions/toasts";
import AlertComponent from "../../../functions/alerts";
import useResizeObserver from "../../../service/ResizeObserver";
import { ToastContainer, toast } from 'react-toastify';
import { useUserRole } from "../../../contexts/UserRoleContext";

const Quotes = forwardRef((props, ref) => {
  const { userRole } = useUserRole();
  const [quotes, setQuotes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [pageMounted, setPageMounted] = useState(false);
  const [initialQuoteData, setInitialQuoteData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [alert, setAlert] = useState({ type: '', message: '' });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const resizeCallback = (rect, element) => {
    console.log(`Element ${element.className} resized to ${rect.width} x ${rect.height}`);
  };

  const resizeObserverRef = useResizeObserver(resizeCallback);

  useEffect(() => {
    if (!pageMounted) {
      setPageMounted(true);
      loadQuotes();
    }
  }, [pageMounted]);

  function loadQuotes() {
    getAllQuotes().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        const records = _resp.data.Records;
        setQuotes(records);
      } else {
        console.warn(_resp.data);
      }
    }).catch((_resp) => {
      console.log(_resp);
      makeToast('error', 'Failed to load quotes');
    });
  }

  function createQuote(quoteData) {
    const payload = {
      '_id': '',
      '_rev': '',
      'created_on': new Date().toISOString(),
      'created_by': {
        'email': quoteData.created_by.email,
        'doc_id': quoteData.created_by.doc_id
      },
      'user_profile': {
        'name': quoteData.user_profile.name,
        'surname': quoteData.user_profile.surname,
        'company': quoteData.user_profile.company,
        'designation': quoteData.user_profile.designation,
        'contact_number': quoteData.user_profile.contact_number,
        'email_address': quoteData.user_profile.email_address,
        'physical_address': quoteData.user_profile.physical_address
      },
      'assistance_required': quoteData.assistance_required,
      'quote_type': quoteData.quote_type,
      'products': quoteData.products,
      'brands': quoteData.brands,
      'quote_status': quoteData.quote_status,
      'quote_value': quoteData.quote_value,
      'suppliers': quoteData.supplier,
      'supplier_notes': quoteData.supplier_notes,
      'quote_docs': quoteData.quote_docs,
      'forkliftType': quoteData.forkliftType
    };

    createQuotes(payload).then((_resp) => {
      if (_resp.data.Result === 'OK' || _resp.data.Result === 'SUCCESS') {
        handleCloseModal()
        toast.success(`${_resp.data.Message}`)
        loadQuotes();
      } else {
        console.warn(_resp.data);
        handleCloseModal()
        toast.error(`${_resp.data.Message}`)
        loadQuotes()
      }
    }).catch((_resp) => {
      handleCloseModal()
      toast.error(`${_resp.data.Message}`)
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      console.error(_resp);
      makeToast('error', 'Failed to create quote');
    });
  }

  function updateQuote(quote, quoteData) {
    const payload = {
      '_id': quote._id,
      '_rev': quote._rev,
      'created_on': quote.created_on,
      'created_by': {
        'email': quote.created_by.email,
        'doc_id': quote.created_by.doc_id
      },
      'user_profile': {
        'name': quoteData.user_profile.name,
        'surname': quoteData.user_profile.surname,
        'company': quoteData.user_profile.company,
        'designation': quoteData.user_profile.designation,
        'contact_number': quoteData.user_profile.contact_number,
        'email_address': quoteData.user_profile.email_address,
        'physical_address': quoteData.user_profile.physical_address
      },
      'assistance_required': quoteData.assistance_required,
      'quote_type': quoteData.quote_type,
      'products': quoteData.products,
      'brands': quoteData.brands,
      'quote_status': quoteData.quote_status,
      'quote_value': quoteData.quote_value,
      'suppliers': quoteData.supplier,
      'supplier_notes': quoteData.supplier_notes,
      'quote_docs': quoteData.quote_docs,
      'forkliftType': quoteData.forkliftType
    };

    updateQuotes(quote["_id"], payload).then((_resp) => {
      if (_resp.data && _resp.data.Result === 'OK' || _resp.data.Result === 'SUCCESS') {
        toast.success(`${_resp.data.Message}` || 'Updated successfully.')
      } else {
        console.warn(_resp.data);
        toast.error(`${_resp.data.Message}` || 'Failed to update quote.')
      }
      handleCloseModal();
      loadQuotes();
    }).catch((_resp) => {
      console.error('Error updating quote:', _resp);
      toast.error('An unexpected error occurred.')
      handleCloseModal();
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      makeToast('error', 'Failed to update quote');
    });
  }

  function deleteQuote(quote) {
    deleteQuotes(quote).then((_resp) => {
      if (_resp.data.Result === 'OK' || _resp.data.Result === 'SUCCESS') {
        toast.success('Quote deleted successfully.')
        loadQuotes();
      } else {
        console.warn(_resp.data);
        toast.error('Failed to delete quote')
        loadQuotes()
      }
    }).catch((_resp) => {
      console.log(_resp);
      toast.error(`${_resp.data.Message}`)
    });
  }

  const handleEditQuote = (quoteId) => {
    const quoteToEdit = quotes.find(quote => quote._id === quoteId);
    if (quoteToEdit) {
      console.log("Full quote data for editing:", quoteToEdit);
      setInitialQuoteData(quoteToEdit);
      setIsEditMode(true);
      setShowModal(true);
    } else {
      console.error("Quote data not found for editing:", quoteId);
      toast.error('Quote data not found for editing', quoteId)
    }
  };

  const handleAddQuote = () => {
    setInitialQuoteData(null);
    setIsEditMode(false);
    setShowModal(true);     
  };

  const handleDeleteQuote = (quote) => {
    deleteQuote(quote);
  };

  useImperativeHandle(ref, () => ({
    openQuoteModal: (quoteId) => {
      handleEditQuote(quoteId);
    }
  }));

  return (
    <>
      <ToastContainer hideProgressBar={true} theme="light" pauseOnHover autoClose={5000}/>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Quotes</Breadcrumb.Item>
            <Breadcrumb.Item active>Quote Requests</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Quote Requests</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
            {['supplier', 'admin'].includes(userRole) ?
              <Button className="btn btn-primary" onClick={handleAddQuote}>Add Quotes</Button>
              : ''}
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}></Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <AlertComponent type={alert.type} message={alert.message} />

      <QuotesTables quotes={quotes} handleShowModal={handleShowModal} handleEditQuote={handleEditQuote} handleDeleteQuote={handleDeleteQuote} />
      <AddQuoteModal
        userRole={userRole}
        showModal={showModal}
        suppliers={suppliers}
        handleCloseModal={handleCloseModal}
        createQuote={createQuote}
        updateQuote={updateQuote}
        initialQuoteData={initialQuoteData}
        isEditMode={isEditMode}
      />
    </>
  );
});

export default Quotes;