import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Container, Form, InputGroup } from '@themesberg/react-bootstrap';
import '../../../scss/styles.css';
import { getAllPages } from "../../../api/private/pages";
import { NavigationBar } from "../../../components/frontendNav/Navigation";
import { Footer } from '../../../components/frontendNav/Footer'
import { Routes } from "../../../routes";
import { Link } from "react-router-dom";
import analytics from '../../../functions/analytics'

export default () => {

  const [page, setPage] = useState({})
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadPages();
    analytics.page()
  }, [])

  function loadPages() {
    getAllPages().then(response => {
      if (response.data && response.data.Result === 'OK') {
        const records = response.data.Records;
        const foundPage = records.find(p => p.slug === "products-services");
        if (foundPage) {
          setPage(foundPage);
          setLoading(false);
          console.log(`Page found:`, foundPage);
        } else {
          console.warn(`Page with slug "products-services" not found.`);
        }
      } else {
        console.warn('Unexpected response structure:', response);
      }
    }).catch(error => {
      console.error('Error loading pages:', error);
    }).finally(() => {
      setLoading(false);
    });
  }

  function chunkArray(array, size) {
    return array.reduce((acc, val, i) => {
      let idx = Math.floor(i / size);
      let page = acc[idx] || (acc[idx] = []);
      page.push(val);

      return acc;
    }, []);
  }

  return (
    <>
      <NavigationBar />
      {loading ? (
        <Row className="justify-content-center">
          <Col>Loading...</Col>
        </Row>
      ) : (
        <>
          <section className="overflow-hidden">
            <div className="bg-primary table-settings pb-4 pt-4">
              <Row className="justify-content-center align-items-center">
                <div className="background-overlay"></div>
                <Col xs={12} md={12} lg={12} xl={12}>
                  <section className="text-center align-items-center justify-content-center overflow-hidden pt-4 pt-lg-4 pb-4 pb-lg-4 bg-primary text-white">
                    <span className="fw-normal text-white">DISCOVER A RANGE OF FORKLIFTS</span>
                    {page && (
                      <h1 className="fw-bold text-white">{page['title']}</h1>
                    )}
                  </section>
                </Col>
              </Row>
              <Row className="justify-content-center align-items-center pb-4">
                <Col xs={9} md={9} lg={9} xl={9}>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control type="text" placeholder="Search" />
                  </InputGroup>
                </Col>
              </Row>
            </div>
          </section>

          <section className="products py-6 mb-9 mb-lg-9 bg-white text-dark">
            <Container>
              {page.component_content && page.component_content.length > 0 && chunkArray(page.component_content.filter(content => content.id === 'productBox'), 3).map((chunk, chunkIndex) => (
                <Row className="py-6" key={chunkIndex}>
                  {chunk.map((content, index) => (
                    <Col className="px-4 with-border" md={4} key={index}>
                      <span className={`fw-bolder text-dark ${content.font_class}`}>{content.text}</span>
                      <p className="fw-normal lead py-4">{content.subtext}</p>
                        <Button style={{ borderRadius: "5px" }} variant="brand-orange" className="btn btn-secondary px-4" as={Link} to={`${content.link_url}`}>View</Button>
                    </Col>
                  ))}
                </Row>
              ))}
            </Container>
          </section>

          <section style={{ background: "#DA6220" }} className="orange-section mt-6 mt-lg-6 pb-6">
            <Container>
              <Row className="justify-content-center text-center align-items-center orange-section-height">
                <div className="center-block mt-4">
                  <h2 style={{ fontSize: "48px" }} className="fw-bolder text-dark">Can't Find What You Need</h2>
                  <p className="lead mx-auto">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                  <Button style={{ background: "#DA6220", borderRadius: "0px", boxShadow: "25px 19px 40px 0px rgba(0, 0, 0, 0.24)" }} variant="brand-orange" className="mt-4 btn">Log A Ticket</Button>
                </div>
              </Row>
            </Container>
          </section>

          {/*-- Footer --*/}
          <Footer />
        </>
      )}
    </>
  );
}