import React, { useState } from 'react';
import { Button, Modal } from '@themesberg/react-bootstrap';
import { createImage as createImageApi, uploadImage as uploadImageApi } from '../../api/private/images';

const UploadMediaModal = ({ _show, _onResp }) => {
const baseBtnUploadText = 'Upload'
const [btnUploadText, setBtnUploadText] = useState(baseBtnUploadText)
const [file, setFile] = useState(null);
const [filename, setFilename] = useState('')
const [fileCaption, setFileCaption] = useState('')
const [fileDescription, setFileDescription] = useState('')
const [fileAlt, setFileAlt] = useState('')

const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
        const uploadedFile = event.target.files[0];
        setFile(uploadedFile);
        setFilename(uploadedFile.name);
    }
};


function onUploadClick(){
    if(file === null){return;}

    setBtnUploadText('Processing...')
    const payload = {
        "image_name": filename,
        "caption": fileCaption,
        "description": fileDescription,
        "image_alt": fileAlt
    }
    createImageApi(payload).then((_resp) => {
        if(_resp.data.Result === 'SUCCESS'){
            const record = _resp.data.Record
            uploadImage(record['_id'])
        }else{
            console.warn(_resp)
        }
    }).catch((_resp) => {
        console.error(_resp)
        setBtnUploadText(baseBtnUploadText)
    }).finally((_resp) => {

    })
}

function uploadImage(_doc_id){
    setBtnUploadText('Uploading...')
    uploadImageApi(_doc_id, file).then((_resp) => {
        if(_resp.data.Result === 'SUCCESS'){
            // TODO shout YAY
            _onResp('close', null)
        }else{
            console.warn(_resp.data)
        }
    }).catch((_resp) => {
        console.error(_resp)
    }).finally((_resp) => {
        setBtnUploadText(baseBtnUploadText)
    })
}

return (
    <Modal size="lg" show={_show} onHide={() => _onResp('close', null)}>
        <Modal.Header closeButton>
            <Modal.Title>Upload Media</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="card border-0">
                <div className="row">
                    <div className="col-6">
                        <input onChange={handleFileChange} type="file" className='form-control' accept="image/*"/>
                        {file && (
                            <div className='p-2'>
                                {/* <p>File name: {file.name}</p> */}
                                <img src={URL.createObjectURL(file)} alt="Selected" className='rounded' />
                            </div>
                        )}
                    </div>
                    <div className="col-6">
                        <span>Filename</span>
                        <input onChange={(e) => {setFilename(e.target.value)}} value={filename} type="text" className='form-control' placeholder='filename.*'/>
                        <span>Caption</span>
                        <input onChange={(e) => {setFileCaption(e.target.value)}} value={fileCaption} type="text" className='form-control' placeholder='caption'/>
                        <span>Image Alt</span>
                        <input onChange={(e) => {setFileAlt(e.target.value)}} value={fileAlt} type="text" className='form-control' placeholder='Image Alt'/>
                        <span>Description</span>
                        <input onChange={(e) => {setFileDescription(e.target.value)}} value={fileDescription} type="text" className='form-control' placeholder='Description'/>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => _onResp('close', null)} variant="secondary">Close</Button>
            <Button onClick={() => onUploadClick()} variant="primary" disabled={btnUploadText !== baseBtnUploadText}>
                {btnUploadText}
            </Button>
        </Modal.Footer>
    </Modal>
)};

export default UploadMediaModal;

