import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card } from '@themesberg/react-bootstrap';
import { faExchangeAlt, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../routes";
import { Link } from 'react-router-dom';

function ProductCard({ prod, openModal, toggleProductSelection, isSelected }) {
    return (
        <Card className="product-card text-center">
            {prod.image ? (
                <img src={prod.image} alt={prod.name} className="product-image" />
            ) : (
                <img src="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=" alt="Default" className="product-image" />
            )}
            <div className="product-details">
                <h5 className="product-name pt-2">{prod.name}</h5>
                <p className="product-brand py-0 mt-0 mb-2">Brand: {prod.brand}</p>
                <p className="product-price">Price: {prod.price}</p>
                <div className="product-btn-group">
                    <Button variant={isSelected ? "success" : "brand-orange"} className="btn mb-3 mx-1" onClick={() => toggleProductSelection(prod)}>
                        <FontAwesomeIcon icon={isSelected ? faCheck : faExchangeAlt}/>
                    </Button>
                    <Button as={Link} className="btn btn-primary mb-3 mx-1" to={Routes.Quote.path}>Request A Quote</Button>
                </div>
            </div>
        </Card>
    );
}

export default ProductCard;
