import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, ButtonGroup, Breadcrumb, Dropdown } from '@themesberg/react-bootstrap';
import { getAllPackages, getPackagesById, createPackages, updatePackages, deletePackages } from "../../../api/private/packages"; // Added getPackagesById
import { makeToast } from "../../../functions/toasts";
import { PackagesTables } from "../../../components/tables/PackagesTables";
import AddPackageModal from '../../../components/modals/AddPackageModal';
import { useUserRole } from "../../../contexts/UserRoleContext";
import { ToastContainer } from "react-toastify";

const Packages = () => {
  const { userRole } = useUserRole();
  const [packages, setPackages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [initialPackageData, setInitialPackageData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    loadPackages();
  }, []);

  const loadPackages = () => {
    getAllPackages().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        setPackages(_resp.data.Records);
      } else {
        console.warn(_resp.data);
        makeToast('error', 'Failed to load packages');
      }
    }).catch((_resp) => {
      console.error(_resp);
      makeToast('error', 'Failed to load packages');
    });
  };

  const handleCreatePackage = (packageData) => {
    createPackages(packageData).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        setShowModal(false);
        makeToast('success', 'Package created successfully');
        loadPackages();
      } else {
        console.warn(_resp.data);
        makeToast('error', 'Failed to create package');
      }
    }).catch((_resp) => {
      console.error(_resp);
      makeToast('error', 'Failed to create package');
    });
  };

  const handleUpdatePackage = (packageId, packageData) => {
    // Fetch the package by ID to get the latest _rev
    getPackagesById(packageId).then((response) => {
      if (response.data.Result === 'OK') {
        const currentDoc = response.data.Record;

        // Merge the updated data with the existing document and include _rev
        const updatedPackageData = {
          ...currentDoc, // Use the existing document data
          ...packageData, // Merge with the new updated data
          _rev: currentDoc._rev // Ensure the correct revision is used
        };

        // Now send the updated document to CouchDB
        updatePackages(packageId, updatedPackageData).then((_resp) => {
          if (_resp.data.Result === 'SUCCESS' || _resp.data.Result === 'OK') {
            setShowModal(false);
            makeToast('success', 'Package updated successfully');
            loadPackages();
          } else {
            console.warn(_resp.data);
            makeToast('error', 'Failed to update package');
          }
        }).catch((error) => {
          console.error('Error updating package:', error);
          makeToast('error', 'Failed to update package');
        });
      } else {
        makeToast('error', 'Failed to fetch package details.');
      }
    }).catch((error) => {
      console.error('Error fetching package:', error);
      makeToast('error', 'Failed to fetch package details.');
    });
  };

  const handleDeletePackage = (packageId) => {
    deletePackages(packageId).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        makeToast('success', 'Package deleted successfully');
        loadPackages();
      } else {
        console.warn(_resp.data);
        makeToast('error', 'Failed to delete package');
      }
    }).catch((_resp) => {
      console.error(_resp);
      makeToast('error', 'Failed to delete package');
    });
  };

  const handleAddPackage = () => {
    setInitialPackageData(null);
    setIsEditMode(false);
    setShowModal(true);
  };

  const handleEditPackage = (packageData) => {
    setInitialPackageData(packageData);
    setIsEditMode(true);
    setShowModal(true);
  };

  return (
    <>
      <ToastContainer hideProgressBar={true} theme="light" pauseOnHover autoClose={5000} />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Subscriptions</Breadcrumb.Item>
            <Breadcrumb.Item active>Packages</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Packages</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
            <Button className="btn btn-primary" onClick={handleAddPackage}>Add Package</Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}></Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <PackagesTables packages={packages} handleEditPackage={handleEditPackage} handleDeletePackage={handleDeletePackage} />
      
      <AddPackageModal
        showModal={showModal}
        handleCloseModal={() => setShowModal(false)}
        createPackage={handleCreatePackage}
        updatePackage={handleUpdatePackage}
        initialPackageData={initialPackageData}
        isEditMode={isEditMode}
      />
    </>
  );
};

export default Packages;
