import { useRef, useEffect, useCallback } from 'react';

const useResizeObserver = (callback, deps = []) => {
  const ref = useRef(null);  // Create a ref for the element to be observed

  const handleResize = useCallback((entries) => {
    if (callback) {
      entries.forEach(entry => callback(entry.contentRect, entry.target));
    }
  }, [callback]);

  useEffect(() => {
    const observer = new ResizeObserver(handleResize);
    if (ref.current) {
      observer.observe(ref.current);  // Start observing the element
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);  // Stop observing the element
      }
      observer.disconnect();  // Clean up the observer
    };
  }, [ref, handleResize, ...deps]);  // Include dependencies if any

  return ref;  // Return the ref to be attached to the element
};

export default useResizeObserver;
