import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { Container, Form, Button, Row, Col, Alert, Card, InputGroup } from '@themesberg/react-bootstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUnlockAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { makeToast } from '../../functions/toasts';
import { authUser } from '../../api/private/auth';
import { createQuotes } from '../../api/private/quotes';
import { getAllBrands } from '../../api/private/brands';
import { getAllProducts } from '../../api/private/products';
import localSessionApi from '../../utils/constants';
import AlertComponent from "../../functions/alerts";
import { registerUser } from '../../api/private/users';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { Routes } from '../../routes';
import { createNotification } from '../../api/private/notifications';

const provinces = [
  { value: 'Eastern Cape', label: 'Eastern Cape' },
  { value: 'Free State', label: 'Free State' },
  { value: 'Gauteng', label: 'Gauteng' },
  { value: 'KwaZulu-Natal', label: 'KwaZulu-Natal' },
  { value: 'Limpopo', label: 'Limpopo' },
  { value: 'Mpumalanga', label: 'Mpumalanga' },
  { value: 'North West', label: 'North West' },
  { value: 'Northern Cape', label: 'Northern Cape' },
  { value: 'Western Cape', label: 'Western Cape' },
];

const Wizard = () => {
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [alert, setAlert] = useState({ type: '', message: '' });
  const [step, setStep] = useState(1);
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [quoteType, setQuoteType] = useState('');
  const [productOptions, setProductOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    company: '',
    designation: '',
    contact_number: '',
    physical_address: '',
    email_address: '',
    password: '',
    quote_status: 'undefined',
    assistance_required: '',
    products: [],
    brands: [],
    serviceRequirements: [],
    parts: [],
    forkliftType: '',
    meta_data: []
  });
  const [errors, setErrors] = useState([]);
  const [touched, setTouched] = useState({});
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [filteredProductOptions, setFilteredProductOptions] = useState([]);
  const [selectedForkliftType, setSelectedForkliftType] = useState('');
  const [error, setError] = useState('');

  const [showDropdowns, setShowDropdowns] = useState(false);

  const autocompleteRef = useRef(null);

  useEffect(() => {
    loadProducts();
    loadBrands();
  }, []);

  useEffect(() => {
    if (selectedForkliftType || selectedBrands.length > 0) {
      filterProducts(selectedForkliftType, selectedBrands);
      setShowDropdowns(true);
    } else {
      setFilteredProductOptions(productOptions);
      setShowDropdowns(false);
    }
  }, [selectedForkliftType, selectedBrands, productOptions]);

  const loadBrands = () => {
    getAllBrands().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        const records = _resp.data.Records;
        const brandOptions = records.map(record => ({
          value: record._id,
          label: record.brand_name,
          thumbnail: record.brand_image,
        }));
        setBrands(records);
        setBrandOptions(brandOptions);
      } else {
        console.warn(_resp.data);
      }
    }).catch((_resp) => {
      console.log(_resp);
    });
  };

  const loadProducts = () => {
    getAllProducts().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        const records = _resp.data.Records.map(record => ({
          _id: record._id,
          name: record.name || "", 
          slug: record.slug || "",
          price: record.price || "",
          brand: record.brand || "",
          description: record.description || "",
          meta_data: record.meta_data || [],
          category: record.category || "",
          thumbnail: record.thumbnail || "",
        }));
        setProducts(records);
        setProductOptions(records);
      } else {
        console.warn(_resp.data);
      }
    }).catch((_resp) => {
      console.log(_resp);
    });
  };

  const handleSelectChange = (selectedOptions, actionMeta) => {
    setFormData({
      ...formData,
      [actionMeta.name]: selectedOptions,
    });
  };

  const handleProductChange = (selectedOptions) => {
    setSelectedProducts(selectedOptions);
  };

  const handleBrandChange = (selectedOptions) => {
    setSelectedBrands(selectedOptions);
    filterProducts(selectedForkliftType, selectedOptions);
  };

  const handleForkliftTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedForkliftType(selectedType);
    setFormData({
      ...formData,
      forkliftType: selectedType,
    });
    filterProducts(selectedType, selectedBrands);
  };

  const filterProducts = (forkliftType, selectedBrands) => {
    if (!forkliftType && selectedBrands.length === 0) {
      setFilteredProductOptions(productOptions);
      return;
    }

    const selectedBrandValues = selectedBrands.map(option => option.value);
    const filteredProducts = productOptions.filter(product => {
      const matchesBrand = selectedBrandValues.length === 0 || selectedBrandValues.includes(product.brand);
      const matchesForkliftType = product.meta_data.some(meta => meta.key === 'Type' && meta.value === forkliftType);
      return matchesBrand && matchesForkliftType;
    });

    // Ensure that selected products are included in the filtered options
    const combinedProducts = [...new Set([...filteredProducts, ...selectedProducts])];
    setFilteredProductOptions(combinedProducts);
  };


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'serviceRequirements') {
      const newServiceRequirements = checked
        ? [...formData.serviceRequirements, value]
        : formData.serviceRequirements.filter((item) => item !== value);

      setFormData({
        ...formData,
        [name]: newServiceRequirements,
      });
    } else {
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      });
    }
  };

  const handlePartChange = (index, e) => {
    const { name, value } = e.target;
    const newParts = formData.parts.map((part, i) => (
      i === index ? { ...part, [name]: value } : part
    ));
    setFormData({
      ...formData,
      parts: newParts,
    });
  };

  const addPartRow = () => {
    setFormData({
      ...formData,
      parts: [...formData.parts, { partName: '', partNumber: '', quantity: '' }],
    });
  };

  const removePartRow = (index) => {
    const newParts = formData.parts.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      parts: newParts,
    });
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched({ ...touched, [name]: true });
  };

  const handleQuoteTypeChange = (e) => {
    setQuoteType(e.target.value);
  };

  const validateStep1Fields = () => {
    const newErrors = [];
    if (!formData.name) newErrors.push('Name is required');
    if (!formData.surname) newErrors.push('Surname is required');
    if (!formData.company) newErrors.push('Company is required');
    if (!formData.designation) newErrors.push('Designation is required');
    if (!formData.contact_number) newErrors.push('Contact Number is required');
    if (!formData.email_address) newErrors.push('Email Address is required');
    if (!formData.physical_address) newErrors.push('Address is required');
    if (!quoteType) newErrors.push('Quote Type is required');
    setErrors(newErrors);
    return newErrors.length === 0;
  };

  const validateStep2Fields = () => {
    const newErrors = [];
    if (quoteType === 'service' && !formData.forkliftType) newErrors.push('Forklift Type is required');
    if (quoteType === 'service' && !formData.numberOfForklifts) newErrors.push('Number of Forklifts is required');
    if (quoteType === 'service' && formData.locationOfForklifts.length === 0) newErrors.push('Location of Forklifts is required');
    if (quoteType === 'salesNew' && !formData.forkliftType) newErrors.push('Forklift Type is required');
    if (quoteType === 'salesNew' && !formData.liftCapacity) newErrors.push('Lift Capacity is required');
    if (quoteType === 'salesUsed' && !formData.forkliftType) newErrors.push('Forklift Type is required');
    if (quoteType === 'salesUsed' && !formData.liftCapacity) newErrors.push('Lift Capacity is required');
    if (quoteType === 'parts' && !formData.forkliftType) newErrors.push('Forklift Type is required');
    if (quoteType === 'hire' && !formData.forkliftType) newErrors.push('Forklift Type is required');
    if (quoteType === 'hire' && !formData.liftCapacity) newErrors.push('Lift Capacity is required');
    if (quoteType === 'hire' && (!formData.rentalStart || !formData.rentalEnd)) newErrors.push('Rental Start and End Dates are required');
    if (quoteType === 'training' && !formData.trainingDetails) newErrors.push('Training Details are required');
    if (quoteType === 'contact' && !formData.additionalInfo) newErrors.push('Additional Info is required');
    setErrors(newErrors);
    return newErrors.length === 0;
  };

  const formatQuoteData = (data) => {
    const { name, surname, company, designation, contact_number, physical_address, email_address, ...otherFields } = data;
    return data;
  };

  const handleNext = () => {
    if (step === 1) {
      if (validateStep1Fields()) {
        setStep(step + 1);
      }
    } else if (step === 2) {
      if (validateStep2Fields()) {
        setStep(step + 1);
      }
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const [quoteId, setQuoteId] = useState(null);
  const randomString = generateRandomString(12);

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('Form data before submission:', formData);

    const assistanceRequired = {
      forkliftType: formData.forkliftType,
      numberOfForklifts: formData.numberOfForklifts,
      locationOfForklifts: formData.locationOfForklifts,
      serviceRequirements: formData.serviceRequirements,
      additionalInfo: formData.additionalInfo,
      liftCapacity: formData.liftCapacity,
      newForklift: formData.newForklift,
      saleTerms: formData.saleTerms,
      rentalStart: formData.rentalStart,
      rentalEnd: formData.rentalEnd,
      trainingDetails: formData.trainingDetails,
      trainingLocation: formData.trainingLocation,
      forkliftTrainType: formData.trainingType,
      forkliftTrainLevel: formData.trainingLevel,
      parts: formData.parts,
      trainingNumber: formData.trainingNumber,
      startDate: formData.startDate,
      endDate: formData.endDate,
    };

    const userProfile = {
      name: formData.name,
      surname: formData.surname,
      company: formData.company,
      designation: formData.designation,
      contact_number: formData.contact_number,
      physical_address: formData.physical_address,
      email_address: formData.email_address
    };

    const selectedBrandValues = selectedBrands.map(brand => brand.value);
    const selectedProductValues = selectedProducts.map(product => product._id);

    const payload = {
      user_profile: userProfile,
      assistance_required: assistanceRequired,
      quote_status: 'undefined',
      quote_type: quoteType,
      quote_docs: [],
      suppliers: [
        {
          brand_name: selectedBrandValues,
          tied_products: selectedProductValues
        }
      ],
      supplier_notes: '',
      products: selectedProductValues,
      brands: selectedBrandValues,
      forklift_type: formData.forkliftType || '',  
    };

    console.log('Payload being sent:', payload);

    if (validateStep2Fields()) {
      try {
        const response = await createQuotes(payload);
        console.log('API Response:', response);
        if (response.data && response.data.Result === 'OK' || response.data.Result === 'SUCCESS' && response.data.Record) {
          const id = response.data.Record._id.slice(-6);
          console.log('New quote _id:', id);
          setQuoteId(id);

          const registerResponse = await registerUser(formData.name, formData.surname, formData.email_address, formData.contact_number, randomString);
          console.log('User Registration Response:', registerResponse);

          if (registerResponse.data.Result !== 'SUCCESS') {
            setAlert({ type: 'danger', message: 'Failed to register user. Please try again later.' });
            return;
          }

          setStep((prevStep) => prevStep + 1);
        } else {
          console.error('Failed to create quote: Invalid response structure', response);
          setAlert({ type: 'danger', message: 'Failed to create quote. Please try again later.' });
        }
      } catch (error) {
        console.error('Failed to create quote or register user', error);
        setAlert({ type: 'danger', message: 'Failed to create quote or register user. Please try again later.' });
      }
    }
  };

  const getValidationClass = (fieldName) => {
    return touched[fieldName] && errors.includes(`${fieldName} is required`) ? 'is-invalid' : '';
  };

  const handleLoginChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    setError('');

    const { email_address, password } = formData;

    if (!email_address.trim() || !password.trim()) {
      setError('Please enter both email address and password');
      return;
    }

    try {
      const response = await authUser("email", email_address, "password", password);
      if (response.data.Result === 'SUCCESS') {
        localStorage.setItem('user_email', email_address);
        const access_token = response.data.Token;
        const user_data = response.data.Record;
        makeToast('success', 'Logged in successfully');
        localSessionApi.setWithExpiry('FLC-JWT', access_token);

        if (localSessionApi.getWithExpiry('FLC-JWT')) {
          localSessionApi.setWithExpiry('FRK-AUTH-USER', user_data);
        }

        setFormData({
          ...formData,
          name: user_data.name,
          surname: user_data.surname,
          company: user_data.company_position,
          contact_number: user_data.mobile_number,
          email_address: user_data.email,
        });

        setIsLoginVisible(false);
      } else {
        setError('Authentication failed');
        setAlert({ type: 'error', message: `${response.Message}` });
      }
    } catch (error) {
      console.error(error);
      setError('Failed to log in');
      makeToast('error', 'Failed to log in');
      setAlert({ type: 'error', message: `${error}` });
    }
  };

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      const address = place.formatted_address;
      setFormData({
        ...formData,
        physical_address: address,
      });
    }
  };

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const getBrandNameById = (brandId) => {
    const brand = brands.find((brand) => brand._id === brandId);
    return brand ? brand.brand_name : 'Unknown Brand';
  };

  return (
    <Container fluid className="mx-0 px-0">
      <Row className="mb-2">
        <Col
          id="formCol"
          md={showDropdowns ? 8 : 12}
          className={`mx-0 px-8 py-6 min-vh-100 bg-primary text-white ${showDropdowns ? 'col-md-8' : 'col-md-12'}`}
        >
          <Form onSubmit={handleSubmit}>
            {step === 1 && (
              <>
                <div hidden={!isLoginVisible}>
                  <h5 className="text-white">Login to autocomplete your details</h5>
                </div>
                {isLoginVisible && (
                  <>
                    <Form.Group controlId="formEmail" className="mb-2">
                      <Form.Label>Email Address</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          type="email"
                          name="email_address"
                          value={formData.email_address}
                          placeholder="Enter your email"
                          onChange={handleLoginChange}
                          onBlur={handleBlur}
                          className={getValidationClass('email_address')}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="formPassword" className="mb-2">
                      <Form.Label>Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          type="password"
                          name="password"
                          value={formData.password}
                          placeholder="Enter your password"
                          onChange={handleLoginChange}
                          onBlur={handleBlur}
                          className={getValidationClass('Password')}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Button variant="brand-orange" onClick={handleLoginSubmit}>Login</Button>

                    <AlertComponent type={alert.type} message={alert.message} />
                  </>
                )}
                <div className="text-center">
                  <Button className="mb-3 text-center text-white" variant="link" onClick={() => setIsLoginVisible(!isLoginVisible)}>
                    {isLoginVisible ? 'Cancel Login' : 'Login to your account'}
                  </Button>
                </div>
                <Row className="mt-4 mb-2">
                  <Col>
                    <Form.Group controlId="formName" className="mb-2">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={formData.name}
                        placeholder="Enter your first name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={getValidationClass('Name')}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formSurname" className="mb-2">
                      <Form.Label>Surname</Form.Label>
                      <Form.Control
                        type="text"
                        name="surname"
                        value={formData.surname}
                        placeholder="Enter your last name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={getValidationClass('Surname')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="formCompany" className="mb-2">
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    type="text"
                    name="company"
                    value={formData.company}
                    placeholder="Enter your company name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={getValidationClass('Company')}
                  />
                </Form.Group>
                <Form.Group controlId="formDesignation" className="mb-2">
                  <Form.Label>Designation</Form.Label>
                  <Form.Select
                    name="designation"
                    value={formData.designation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={getValidationClass('Designation')}
                  >
                    <option value="">Select Designation</option>
                    <option value="Project Manager">Project Manager</option>
                    <option value="Site Manager">Site Manager</option>
                    <option value="Operations Manager">Operations Manager</option>
                    <option value="Warehouse Manager">Warehouse Manager</option>
                    <option value="Logistics Manager">Logistics Manager</option>
                    <option value="Supply Chain Manager">Supply Chain Manager</option>
                    <option value="Transport Manager">Transport Manager</option>
                    <option value="Cold Storage Manager">Cold Storage Manager</option>
                    <option value="FMCG Manager">FMCG Manager</option>
                    <option value="Director of Operations">Director of Operations</option>
                    <option value="General Manager">General Manager</option>
                    <option value="CEO">CEO</option>
                    {/* Add more options as needed */}
                  </Form.Select>
                </Form.Group>
                <Row className="mb-2">
                  <Col>
                    <Form.Group controlId="formContactNumber" className="mb-2">
                      <Form.Label>Contact Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="contact_number"
                        value={formData.contact_number}
                        placeholder="Enter your contact number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={getValidationClass('Contact Number')}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formEmail" className="mb-2">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        name="email_address"
                        value={formData.email_address}
                        placeholder="Enter your email address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={getValidationClass('Email Address')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="physicalAddress" className="mb-2">
                  <Form.Label>Address</Form.Label>
                  <LoadScript
                    googleMapsApiKey={googleMapsApiKey}
                    libraries={['places']}>
                    <Autocomplete
                      onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                      onPlaceChanged={handlePlaceChanged}
                    >
                      <Form.Control
                        type="text"
                        name="physical_address"
                        value={formData.physical_address}
                        placeholder="Enter your address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={getValidationClass('Address')}
                      />
                    </Autocomplete>
                  </LoadScript>
                </Form.Group>
                <Form.Group controlId="formQuoteType" className="mb-2">
                  <Form.Label>Quote Type</Form.Label>
                  <Form.Control
                    required
                    as="select"
                    name="quoteType"
                    value={quoteType}
                    onChange={handleQuoteTypeChange}
                    onBlur={handleBlur}
                    className={getValidationClass('Quote Type')}
                  >
                    <option value="">Select Quote Type</option>
                    <option value="service">Forklift Service & Maintenance</option>
                    <option value="salesNew">New Forklift Sales</option>
                    <option value="salesUsed">Used Forklift Sales</option>
                    <option value="hire">Forklift Hire</option>
                    <option value="parts">Parts Request</option>
                    <option value="training">Request for Training</option>
                    <option value="contact">General Contact</option>
                  </Form.Control>
                </Form.Group>
                <Button className="mt-3 mx-2" variant="brand-orange" onClick={handleNext}>
                  Next
                </Button>
              </>
            )}
            {step === 2 && quoteType === 'service' && (
              <>
                <h2>Forklift Service & Maintenance Enquiry</h2>
                <Form.Group controlId="formForkliftType" className="mb-2">
                  <Form.Label>Forklift Type</Form.Label>
                  <Form.Select
                    name="forkliftType"
                    value={formData.forkliftType}
                    onChange={handleForkliftTypeChange}
                    onBlur={handleBlur}
                    className={getValidationClass('Forklift Type')}
                  >
                    <option value="">Select Forklift Type</option>
                    <option value="Electric">Electric</option>
                    <option value="Diesel">Diesel</option>
                    <option value="Hybrid">Hybrid</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="formNumberOfForklifts" className="mb-2">
                  <Form.Label>Number of forklifts</Form.Label>
                  <Form.Control
                    type="number"
                    name="numberOfForklifts"
                    value={formData.numberOfForklifts}
                    placeholder="Enter number of forklifts"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={getValidationClass('Number of Forklifts')}
                  />
                </Form.Group>
                <Form.Group controlId="formLocationOfForklifts" className="mb-3">
                  <Form.Label>Address / Location of forklifts</Form.Label>
                  <Select
                    isMulti
                    name="locationOfForklifts"
                    options={provinces}
                    placeholder="Select location(s)"
                    className="basic-multi-select text-dark"
                    classNamePrefix="select"
                    onChange={handleSelectChange}
                    value={formData.locationOfForklifts}
                  />
                </Form.Group>
                <Form.Group controlId="formServiceRequirements" className="mb-2">
                  <Form.Label>Select service / maintenance requirements</Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Preventative Maintenance"
                    name="serviceRequirements"
                    value="Preventative Maintenance"
                    checked={formData.serviceRequirements.includes('Preventative Maintenance')}
                    onChange={handleChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Emergency Repairs"
                    name="serviceRequirements"
                    value="Emergency Repairs"
                    checked={formData.serviceRequirements.includes('Emergency Repairs')}
                    onChange={handleChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Parts & Components"
                    name="serviceRequirements"
                    value="Parts & Components"
                    checked={formData.serviceRequirements.includes('Parts & Components')}
                    onChange={handleChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="All of the above"
                    name="serviceRequirements"
                    value="All of the above"
                    checked={formData.serviceRequirements.includes('All of the above')}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formAdditionalInfo" className="mb-2">
                  <Form.Label>Provide more information that can be helpful for the quoting process</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="additionalInfo"
                    value={formData.additionalInfo}
                    placeholder="Enter additional information"
                    onChange={handleChange}
                  />
                </Form.Group>
                <Button className="mt-3 mx-2" variant="secondary" onClick={handleBack}>
                  Back
                </Button>
                <Button className="mt-3 mx-2" variant="brand-orange" type="submit">
                  Submit
                </Button>
              </>
            )}
            {step === 2 && (quoteType === 'salesNew' || quoteType === 'salesUsed') && (
              <>
                <h2>Forklift Sales ({quoteType === 'salesNew' ? 'New Forklifts' : 'Used Forklifts'})</h2>
                <Form.Group controlId="formForkliftType" className="mb-2">
                  <Form.Label>Forklift Type</Form.Label>
                  <Form.Select
                    name="forkliftType"
                    value={formData.forkliftType}
                    onChange={handleForkliftTypeChange}
                    onBlur={handleBlur}
                    className={getValidationClass('Forklift Type')}
                  >
                    <option value="">Select Forklift Type</option>
                    <option value="Electric">Electric</option>
                    <option value="Diesel">Diesel</option>
                    <option value="Hybrid">Hybrid</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="formLiftCapacity" className="mb-2">
                  <Form.Label>Lift Capacity</Form.Label>
                  <Form.Control
                    type="text"
                    name="liftCapacity"
                    value={formData.liftCapacity}
                    placeholder="Enter lift capacity"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={getValidationClass('Lift Capacity')}
                  />
                </Form.Group>
                <Button className="mt-3 mx-2" variant="secondary" onClick={handleBack}>
                  Back
                </Button>
                <Button className="mt-3 mx-2" variant="brand-orange" type="submit">
                  Submit
                </Button>
              </>
            )}
            {step === 2 && quoteType === 'hire' && (
              <>
                <h2>Forklift Hire</h2>
                <Form.Group controlId="formForkliftType" className="mb-2">
                  <Form.Label>Forklift Type</Form.Label>
                  <Form.Select
                    name="forkliftType"
                    value={formData.forkliftType}
                    onChange={handleForkliftTypeChange}
                    onBlur={handleBlur}
                    className={getValidationClass('Forklift Type')}
                  >
                    <option value="">Select Forklift Type</option>
                    <option value="Electric">Electric</option>
                    <option value="Diesel">Diesel</option>
                    <option value="3-wheel">3-wheel</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="formLiftCapacity" className="mb-2">
                  <Form.Label>Lift Capacity</Form.Label>
                  <Form.Control
                    type="text"
                    name="liftCapacity"
                    value={formData.liftCapacity}
                    placeholder="Enter lift capacity"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={getValidationClass('Lift Capacity')}
                  />
                </Form.Group>
                <Form.Group controlId="formRentalStart" className="mb-2">
                  <Form.Label>Rental Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="rentalStart"
                    value={formData.rentalStart}
                    placeholder="Enter rental start date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={getValidationClass('Rental Start')}
                  />
                </Form.Group>
                <Form.Group controlId="formRentalEnd" className="mb-2">
                  <Form.Label>Rental End Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="rentalEnd"
                    value={formData.rentalEnd}
                    placeholder="Enter rental end date"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={getValidationClass('Rental End')}
                  />
                </Form.Group>
                <Form.Group controlId="formRentalTerms" className="mb-2">
                  <Form.Check
                    type="checkbox"
                    label="Provide me with the rental terms & conditions"
                    name="rentalTerms"
                    checked={formData.rentalTerms}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Button className="mt-3 mx-2" variant="secondary" onClick={handleBack}>
                  Back
                </Button>
                <Button className="mt-3 mx-2" variant="brand-orange" type="submit">
                  Submit
                </Button>
              </>
            )}
            {step === 2 && quoteType === 'parts' && (
              <>
                <h2>Parts Quotation</h2>
                <Form.Group controlId="formForkliftType" className="mb-2">
                  <Form.Label>Forklift Type</Form.Label>
                  <Form.Select
                    name="forkliftType"
                    value={formData.forkliftType}
                    onChange={handleForkliftTypeChange}
                    onBlur={handleBlur}
                    className={getValidationClass('Forklift Type')}
                  >
                    <option value="">Select Forklift Type</option>
                    <option value="Electric">Electric</option>
                    <option value="Diesel">Diesel</option>
                    <option value="3-wheel">3-wheel</option>
                  </Form.Select>
                </Form.Group>
                {formData.forkliftType && (
                  <Form.Group controlId="formForkliftBrand" className="mb-2">
                    <Form.Label>Select Forklift Brand</Form.Label>
                    <Select
                      isMulti
                      name="forkliftBrand"
                      options={brandOptions}
                      placeholder="Select brands"
                      className="basic-multi-select text-dark"
                      classNamePrefix="select"
                      onChange={handleBrandChange}
                      value={selectedBrands}
                    />
                  </Form.Group>
                )}
                <Form.Group controlId="formForkliftModel" className="mb-2">
                  <Form.Label>Forklift Model</Form.Label>
                  <Form.Control
                    name="forkliftModel"
                    value={formData.forkliftModel}
                    placeholder="Enter forklift model"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={getValidationClass('Forklift Model')}
                  />
                </Form.Group>
                <Button className="mt-2" variant="secondary" onClick={addPartRow}>
                  <FontAwesomeIcon icon={faPlus} /> Add Part
                </Button>
                <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                  {Array.isArray(formData.parts) && formData.parts.map((part, index) => (
                    <Row key={index} className="mt-2 mb-3">
                      <Col md={3}>
                        <Form.Group controlId={`formPartName${index}`} className="mb-2">
                          <Form.Label>Part Name</Form.Label>
                          <Form.Control
                            name="partName"
                            value={part.partName}
                            placeholder="Enter a part name"
                            onChange={(e) => handlePartChange(index, e)}
                            onBlur={handleBlur}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group controlId={`formPartNumber${index}`} className="mb-2">
                          <Form.Label>Part / Serial Number</Form.Label>
                          <Form.Control
                            name="partNumber"
                            value={part.partNumber}
                            placeholder="Enter a part number"
                            onChange={(e) => handlePartChange(index, e)}
                            onBlur={handleBlur}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group controlId={`formQuantity${index}`} className="mb-2">
                          <Form.Label>Quantity</Form.Label>
                          <Form.Control
                            name="quantity"
                            value={part.quantity}
                            placeholder="Enter quantity"
                            onChange={(e) => handlePartChange(index, e)}
                            onBlur={handleBlur}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2} className="d-flex align-items-end mt-0 pt-0">
                        <Button className="mt-0 mx-2" variant="danger" onClick={() => removePartRow(index)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                        <Button className="mt-0 mx-2" variant="secondary" onClick={addPartRow}>
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </div>
                <Button className="mt-3 mx-2" variant="secondary" onClick={handleBack}>
                  Back
                </Button>
                <Button className="mt-3 mx-2" variant="brand-orange" type="submit">
                  Submit
                </Button>
              </>
            )}
            {step === 2 && quoteType === 'training' && (
              <>
                <h2>Training Request</h2>
                <Form.Group controlId="formTrainingDetails" className="mb-2">
                  <Form.Label>Provide a quote for the following training</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="trainingDetails"
                    value={formData.trainingDetails}
                    placeholder="Enter training details"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={getValidationClass('Training Details')}
                  />
                </Form.Group>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formTrainingNumber" className="mb-2">
                      <Form.Label>Number of Trainees</Form.Label>
                      <Form.Control
                        type="number"
                        name="trainingNumber"
                        value={formData.trainingNumber}
                        placeholder="Enter training number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={getValidationClass('Training Number')}
                      />
                    </Form.Group>
                  </Col>
                  <Form.Group controlId="formForkliftTrainType" className="mb-2">
                    <Form.Label>Training Type </Form.Label>
                    <Form.Select
                      name="forkliftTrainType"
                      value={formData.trainingType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={getValidationClass('Forklift Training Type')}
                    >
                      <option value="New">New</option>
                      <option value="Refresher">Refresher</option>
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formForkliftTrainLevel" className="mb-2">
                      <Form.Label>Training Level</Form.Label>
                      <Form.Select
                        name="forkliftTrainLevel"
                        value={formData.trainingLevel}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={getValidationClass('Forklift Training Level')}
                      >
                        <option value="Novice">Novice</option>
                        <option value="Operator">Operator</option>
                        <option value="Supervisor">Supervisor</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formForkliftTrainLocation" className="mb-2">
                      <Form.Label>Training Location</Form.Label>
                      <Form.Select
                        name="trainingLocation"
                        value={formData.trainingLocation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={getValidationClass('Forklift Training Location')}
                      >
                        <option value="Onsite">On-site</option>
                        <option value="Training Center">Closest Training Center</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formStartDate" className="mb-2">
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="startDate"
                        value={formData.startDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={getValidationClass('Start Date')}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formEndDate" className="mb-2">
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="endDate"
                        value={formData.endDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={getValidationClass('End Date')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button className="mt-3 mx-2" variant="secondary" onClick={handleBack}>
                  Back
                </Button>
                <Button className="mt-3 mx-2" variant="brand-orange" type="submit">
                  Submit
                </Button>
              </>
            )}
            {step === 2 && quoteType === 'contact' && (
              <>
                <h2>General Contact Form</h2>
                <Form.Group controlId="formAdditionalInfo" className="mb-2">
                  <Form.Label>Please assist me as follows</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="additionalInfo"
                    value={formData.additionalInfo}
                    placeholder="Enter your message"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={getValidationClass('Additional Info')}
                  />
                </Form.Group>
                <Button className="mt-3 mx-2" variant="secondary" onClick={handleBack}>
                  Back
                </Button>
                <Button className="mt-3 mx-2" variant="brand-orange" type="submit">
                  Submit
                </Button>
              </>
            )}
            {errors.length > 0 && (
              <Alert variant="danger" className="mt-3">
                {errors.map((error, idx) => (
                  <div key={idx}>{error}</div>
                ))}
              </Alert>
            )}
            {step === 3 && (
              <>
                <h2>Congratulations!</h2>
                <p>Your request has been submitted to the respective suppliers who will provide you with comparative quotes shortly.
                  <br />You should receive an email to your submitted email address with your link to log in.<br /><br /> Your preferred suppliers will get back to you soon.</p>
                <hr />
                <div className="mt-3 mb-4">
                  <h4>Your Quote Reference:</h4>
                  {quoteId && (
                    <p>{quoteId}</p>
                  )}
                  <sub>Use this reference should you have any queries with Forklifting.co</sub>
                </div>
                <hr />
                <Button as={Link} to={Routes.Presentation.path} variant="secondary" className="mx-1 mt-2">Go Back Home</Button>
                <Button as={Link} to={Routes.DashboardOverview.path} variant="secondary" className="mx-1 mt-2">Go To Dashboard</Button>
              </>
            )}
          </Form>
        </Col>
        {showDropdowns && (
          <Col
            id="productCol"
            className="bg-light px-5 py-5 col-md-4"
          >
            {formData.forkliftType && (
              <>
                <Form.Group controlId="formBrand" className="mb-2">
                  <Form.Label>Select Forklift Suppliers</Form.Label>
                  <Select
                    isMulti
                    required
                    name="brands"
                    options={brandOptions}
                    placeholder="Select up to 3 forklift Suppliers"
                    className="basic-multi-select text-dark"
                    classNamePrefix="select"
                    onChange={handleBrandChange}
                    getOptionLabel={(option) => (
                      <div className="d-flex align-items-center">
                        <img
                          src={option.thumbnail}
                          alt={option.label}
                          style={{ width: '40px', height: '40px', marginRight: '10px' }}
                        />
                        {option.label}
                      </div>
                    )}
                  />
                  <sub className="description">Need help finding a supplier? Select <b>Forklifting Co</b> and we'll assist you to find suitable quotes.</sub>
                </Form.Group>
                <Form.Group controlId="formProduct" className="mb-2">
                  <Form.Label>Products</Form.Label>
                  <Select
                    isMulti
                    required
                    name="products"
                    options={filteredProductOptions}
                    placeholder="Select products"
                    className="basic-multi-select text-dark"
                    classNamePrefix="select"
                    onChange={handleProductChange}
                    value={selectedProducts}
                    getOptionLabel={(option) => (
                      <div key={option._id} className="d-flex align-items-center">
                        <img
                          src={option.thumbnail}
                          alt={option.name}
                          style={{ width: '40px', height: '40px', marginRight: '10px' }}
                        />
                        {option.name}
                      </div>
                    )}
                  />
                </Form.Group>
              </>
            )}
            {selectedProducts.map((product, _id) => (
              <Card key={_id} className="mb-2">
                <Card.Img variant="top" src={product.thumbnail} alt={product.name} />
                <Card.Body>
                  <Card.Title>{product.name}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">{getBrandNameById(product.brand)}</Card.Subtitle>
                  <Card.Text>{product.description}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Wizard;
