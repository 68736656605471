
export const Routes = {

    // pages
    Presentation: { path: "/" },
    StartYourSearch: { path: "/start-your-search" },
    ProductServices: { path: "/product-services" },
    ProductList: { path: "/products" },
    ProductsView: { path: "/products/:product"},
    Brands: { path: "/brands" },
    BrandsView: { path: "/brands/:brand_name"},
    Support: { path: "/support" },
    AllListings: { path: "/all-listings"},
    Careers: { path: "/careers"},
    CompanySearch: { path: "/companies"},
    CompanyView: { path: "/companies/:trading_name"},
    About: { path: "/about" },
    Insights: { path: "/insights" },
    ForSuppliers: { path: "/for-suppliers" },
    FrequentlyAsked: { path: "/faq"},
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    Blogs: { path: "/blogs"},
    BlogRead: { path: "/blogs/read/:slug"},
    Contact: { path: "/contact"},

    //wizard
    Wizard: { path: "/wizard/wizard"},
    Quote: { path: "/wizard/quote"},

    //admin
    DashboardOverview: { path: "/dashboard/overview" },
    AnalyticsDashboard: { path: "/analytics" },
    Transactions: { path: "/transactions" },
    Settings: { path: "/settings" },
    Listings: { path: "/admin/brands/listings"},
    Offerings: { path: "/admin/brands/offerings"},
    Reports: { path: "/admin/reports/reports"},
    Requests: { path: "/admin/quotes/requests"},
    Suppliers: { path: "/admin/companies/suppliers"},
    Companies: { path: "/admin/companies/companies"},
    SupplyBrands: { path: "/admin/brands/brands"},
    Buyers: { path: "/admin/companies/buyers"},
    UserAccounts: { path: "/admin/subscriptions/users"},
    Quotes: { path: "/admin/quotes/quotes"},
    Billing: { path: "/examples/billing" },
    Invoice: { path: "/examples/invoice" },
    Signin: { path: "/admin/auth/sign-in" },
    Packages: {path: "/admin/subscriptions/packages"},
    Signup: { path: "/sign-up" },
    SignupCancel: { path: "/signup-cancel"},
    SignupSuccess: { path: "/signup-success"},
    Signout: { path: "/admin/auth/signout"},
    ForgotPassword: { path: "/admin/auth/forgot-password" },
    ResetPassword: { path: "/admin/auth/reset-password" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
    Pages: { path: "/admin/cms/pages"},
    Faqs: { path: "/admin/cms/faqs"},
    Inbox: { path: "/admin/chat/inbox"},
    LandingPages: { path: "/admin/cms/landing"},
    Categories: { path: "/admin/cms/categories"},
    Posts: { path: "/admin/cms/posts"},
    Media: { path: "/admin/cms/media"},
    Robots: { path: "/admin/seo/robots"},
    Sitemap: { path: "/admin/seo/sitemap"},
    Packages: { path: "/admin/subscriptions/packages"},

    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" }
};