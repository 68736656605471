import React, { useEffect, useState } from "react";
import analytics from '../../../functions/analytics'
import { useParams } from "react-router-dom";
import { Container, Row, Col, Breadcrumb } from '@themesberg/react-bootstrap';
import { NavigationBar } from "../../../components/frontendNav/Navigation";
import { Footer } from '../../../components/frontendNav/Footer';
import { getAllPosts } from '../../../api/private/posts';
import { makeToast } from "../../../functions/toasts";

export default () => {
    const { slug } = useParams();
    const [selectedPost, setSelectedPost] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadPosts();
        analytics.page()
    }, [slug]);

    const loadPosts = () => {
        getAllPosts().then(response => {
            if (response.data.Result === 'OK') {
                const posts = response.data.Records;
                const post = posts.find(post => post.slug === slug);
                setSelectedPost(post);
                setLoading(false);
            } else {
                console.warn(response.data);
                makeToast('error', 'Failed to load posts');
            }
        }).catch(error => {
            console.error(error);
            makeToast('error', 'Failed to load posts');
        });
    };

    const renderComponentContent = (item) => {
        switch (item.component_content_type) {
          case 'text':
            return (
              <div key={item.id}>
                <p className={item.font_class}>{item.text}</p>
                {item.link_type === 'button' && <a href={item.link_url}>Read More</a>}
              </div>
            );
          case 'banner':
            return (
              <div key={item.id} style={{ backgroundImage: `url(${item.background_img})` }}>
                <h1 className={item.font_class}>{item.text}</h1>
                {item.link_type === 'button' && <a href={item.link_url}>Find Out More</a>}
              </div>
            );
          case 'image':
            return (
              <figure key={item.id}>
                <img src={item.image_doc_id} alt={item.alt_text} />
                <figcaption>{item.caption}</figcaption>
                {item.description && <p>{item.description}</p>}
              </figure>
            );
          default:
            return null;
        }
      };

    return (
        <>
            <NavigationBar />
            <section className="partner-brands text-center align-items-center justify-content-center overflow-hidden pt-5 pt-lg-6 pb-6 pb-lg-6 bg-grey text-dark">
                <div style={{ width: '90%', textAlign: 'left', paddingLeft: '10%' }}>
                    {selectedPost && (
                        <h1 className="fw-bold text-dark">{selectedPost.title}</h1>
                    )}
                    <div className="d-block mb-4 mb-md-0">
                        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                            <Breadcrumb.Item>You are here:</Breadcrumb.Item>
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                            <Breadcrumb.Item>Insights</Breadcrumb.Item>
                            {selectedPost ? (
                                <Breadcrumb.Item active>
                                    {selectedPost.title}
                                </Breadcrumb.Item>
                            ) : (
                                <Breadcrumb.Item active>
                                    Read
                                </Breadcrumb.Item>
                            )}
                        </Breadcrumb>
                    </div>
                </div>
            </section>

            <section className="bg-white justify-content-center align-items-start">
                <Container className="py-6">
                    {loading ? (
                        <Row className="justify-content-center">
                            <Col>Loading...</Col>
                        </Row>
                    ) : selectedPost ? (
                        <Row>
                            <Col md={8}>
                                <p>{selectedPost.excerpt}</p>
                                <div dangerouslySetInnerHTML={{ __html: selectedPost.page_content }} />
                            </Col>
                            <Col md={4}>
                                {selectedPost.featured_image && (
                                    <img src={selectedPost.featured_image} alt={selectedPost.title} className="img-fluid" />
                                )}
                                {selectedPost.component_content.map(renderComponentContent)}
                            </Col>
                        </Row>
                    ) : (
                        <Row className="justify-content-center">
                            <Col>No post found.</Col>
                        </Row>
                    )}
                </Container>
            </section>

            <Footer />
        </>
    );
};
