import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, Table, Dropdown, ButtonGroup, Button } from '@themesberg/react-bootstrap';

export const CmsCategoriesTables = ({ categories, deleteCategory, handleEditCategory }) => {
  const TableRow = ({ _id, seo_title, slug, created_on, canonical_url, featured_image_url, addon_meta }) => {
    return (
      <tr>
        <td className="border-o">
        <td className="fw-bold border-0">
          {featured_image_url || "-"}
        </td>
        </td>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center" onClick={() => handleEditCategory({ seo_title, created_on, _id, canonical_url, featured_image_url })}>
            <div>
              <span className="h6">
                  <td className="fw-bold border-0">
                  {seo_title || "-"}
                </td>
              </span>
            </div>
          </Card.Link>
        </td>
        <td className="border-0">
          {created_on}
        </td>
        <td className="fw-bold border-0">
          {canonical_url || "-"}
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleEditCategory({ _id, seo_title, created_on, canonical_url, featured_image_url })}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={() => deleteCategory(_id)}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Featured Image</th>
              <th className="border-0">Category Name</th>
              <th className="border-0">Date Created</th>
              <th className="border-0">Canonical URL</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            {categories.length > 0 ? (
              categories.map((category, index) => <TableRow key={`category-${category._id || index}`} {...category} />)
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No records could be found at this time. Create your first supplier by clicking Add Categories
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
