import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Button, Modal } from '@themesberg/react-bootstrap';

const AddUserModal = ({ showModal, handleCloseModal, createUserAcc, updateUserAcc, initialUserData, isEditMode }) => {
    const [userData, setUserData] = useState({
        first_name: '',
        last_name: '',
        email_address: '',
        mobile_number: ''
    });

    useEffect(() => {
        console.log("Modal State:", { showModal, isEditMode, initialUserData });
        if (showModal && isEditMode && initialUserData) {
            setUserData({
                id: initialUserData._id || '',
                first_name: initialUserData.first_name || '',
                last_name: initialUserData.last_name || '',
                email_address: initialUserData.email_address || '',
                mobile_number: initialUserData.mobile_number || ''
            });
        } else {
            setUserData({ first_name: '', last_name: '', email_address: '', mobile_number: '' });
        }
    }, [initialUserData, isEditMode, showModal]);

    function onKeyValueChange(key, value) {
        setUserData((prevUserData) => ({
            ...prevUserData,
            [key]: value,
        }));
    }

    return (
        <>
            <Modal size="lg" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditMode ? 'Edit User' : 'Add User'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={6} className="mb-3">
                                <Form.Group controlId="first_name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter name"
                                        value={userData.first_name}
                                        onChange={(e) => onKeyValueChange('first_name', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group controlId="last_name">
                                    <Form.Label>Surname</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter surname"
                                        value={userData.last_name}
                                        onChange={(e) => onKeyValueChange('last_name', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className="mb-3">
                                <Form.Group controlId="email_address">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        required
                                        type="email"
                                        placeholder="Enter email address"
                                        value={userData.email_address}
                                        onChange={(e) => onKeyValueChange('email_address', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group controlId="mobile_number">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter mobile number"
                                        value={userData.mobile_number}
                                        onChange={(e) => onKeyValueChange('mobile_number', e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="brand-orange" onClick={() => isEditMode ? updateUserAcc(userData) : createUserAcc(userData)}>
                        {isEditMode ? 'Update User' : 'Add User'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddUserModal;
