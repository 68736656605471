import React, { useEffect, useState } from "react";
import analytics from '../../../functions/analytics'
import { useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faFlag, faMapPin, faPhone, faCertificate, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Breadcrumb, Button } from '@themesberg/react-bootstrap';
import { NavigationBar } from "../../../components/frontendNav/Navigation";
import { Footer } from '../../../components/frontendNav/Footer';
import { getAllBrands } from '../../../api/private/brands';
import { getAllCompanies } from '../../../api/private/companies';
import { makeToast } from "../../../functions/toasts";
import { Routes } from '../../../routes';
import { BlogCards } from '../../../components/BlogCards'
import ProductCarousel from "../../../components/ProductCarousel";
import CompareModal from "../../../components/modals/CompareModal";

//TODO: Company details pulled through as managed by

export default () => {
    const { brand_name } = useParams();
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState('')
    const [products, setProducts] = useState([])

    useEffect(() => {
        loadBrands();
        analytics.page()
    }, [brand_name]);

    const loadBrands = () => {
        getAllBrands().then(response => {
            if (response.data.Result === 'OK') {
                const brands = response.data.Records;
                const decodedBrandName = decodeURIComponent(brand_name.replace(/-/g, ' '));
                const brand = brands.find(brand => brand.brand_name.toLowerCase() === decodedBrandName.toLowerCase());
                setSelectedBrand(brand);
                setLoading(false);
            } else {
                console.warn(response.data);
                makeToast('error', 'Failed to load posts');
            }
        }).catch(error => {
            console.error(error);
            makeToast('error', 'Failed to load posts');
        });
    };

    const offerings = [
        { id: 1, name: "Demo Product 1", brand: "Demo Brand" },
        { id: 2, name: "Demo Product 2", brand: "Demo Brand" },
        { id: 3, name: "Demo Product 3", brand: "Demo Brand" },
        { id: 4, name: "Demo Product 4", brand: "Demo Brand" },
        { id: 5, name: "Demo Product 5", brand: "Demo Brand" },
        { id: 6, name: "Demo Product 6", brand: "Demo Brand" },
        { id: 7, name: "Demo Product 7", brand: "Demo Brand" },
        { id: 8, name: "Demo Product 8", brand: "Demo Brand" },
        { id: 9, name: "Demo Product 9", brand: "Demo Brand" }
    ]

    return (
        <>
            <NavigationBar />
            <section className="partner-brands text-center align-items-center justify-content-center overflow-hidden pt-4 pb-2 bg-white text-dark">
                <div className="d-flex pt-3" style={{ borderBottom: "0.75px solid #000", borderTop: "0.75px solid #000", width: '100%', textAlign: 'left', paddingLeft: '10%' }}>
                    {selectedBrand && (
                        <p className="fw-bold text-dark">{selectedBrand.brand_name}</p>
                    )}
                    <div className="d-block mx-4 mb-4 mb-md-0">
                        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                            <Breadcrumb.Item>You are here:</Breadcrumb.Item>
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                            <Breadcrumb.Item>Brands</Breadcrumb.Item>
                            {selectedBrand ? (
                                <Breadcrumb.Item active>
                                    {selectedBrand.brand_name}
                                </Breadcrumb.Item>
                            ) : (
                                <Breadcrumb.Item active>
                                    Read
                                </Breadcrumb.Item>
                            )}
                        </Breadcrumb>
                    </div>
                </div>
            </section>

            {loading ? (
                <Row className="justify-content-center">
                    <Col>Loading...</Col>
                </Row>
            ) : selectedBrand ? (
                <section className="bg-white justify-content-center align-items-start">
                    <Container className="py-6">
                        <Row>
                            <Col md={3}>
                                {selectedBrand.brand_image ?
                                    <img src={selectedBrand.brand_image} alt={selectedBrand.brand_name} width="200px" height="200px" />
                                    :
                                    <img src="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=" width="200px" height="200px" alt={selectedBrand.brand_name} />
                                }
                            </Col>
                            <Col md={6}>
                                <p>{selectedBrand.brand_description}</p>
                            </Col>
                            <Col md={3}>
                                <div style={{ flex: '0 0 25%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: '10px 0px 10px 10px' }}>
                                        {selectedBrand.meta.map((action, idx) => (
                                            <Button key={idx} variant="brand-orange" as={Link} to={Routes.Wizard.path} className="btn btn-primary p-3 cardBtn" style={{ marginBottom: '10px', borderRadius: '0px 0px 0px 0px' }}>
                                                Get A Quote
                                            </Button>
                                        ))}
                                        {/* TODO: Add company dynamic link*/}
                                        {/* {brands.meta.map((action, idx) => ( */}
                                        <Button variant="primary" as={Link} to={`${Routes.CompanyView.path.replace(':legal_name', company.legal_name)}`} className="btn btn-primary p-3 cardBtn" style={{ marginBottom: '10px', borderRadius: '0px 0px 0px 0px' }}>
                                            View Company Profile
                                        </Button>
                                        {/* ))} */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className="mx-0 px-0">
                        <Row className="bg-light mx-0 px-0">
                            <Col className="mx-0 px-0" md={4}>
                                {selectedBrand.meta.brand_logo ?
                                    <img src={selectedBrand.meta.brand_logo} alt={selectedBrand.brand_name} width="400px" /> :
                                    <img src="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=" width="400px" alt={selectedBrand.brand_name} />
                                }
                            </Col>
                            <Col className="py-5" md={4}>
                                <h4>Managed By</h4>
                                <ul className="mt-2 list-style-none managed-details">
                                    {selectedBrand.brand_name && (
                                        <li>
                                            <FontAwesomeIcon className="icon" icon={faHome} />
                                            {/* Link to company */}
                                            &nbsp;<Link to="">{selectedBrand.brand_name}</Link>
                                        </li>
                                    )}
                                    {selectedBrand.founded && (
                                        <li>
                                            <FontAwesomeIcon className="icon" icon={faFlag} />
                                            &nbsp;Founded in {selectedBrand.founded}
                                        </li>
                                    )}
                                    {selectedBrand.meta.address && (
                                        <li>
                                            <FontAwesomeIcon className="icon" icon={faMapPin} />
                                            &nbsp;{selectedBrand.meta.address}
                                        </li>
                                    )}
                                    {selectedBrand.meta.contact_number && (
                                        <li>
                                            <FontAwesomeIcon className="icon" icon={faPhone} />
                                            &nbsp;{selectedBrand.meta.contact_number}
                                        </li>
                                    )}
                                    {selectedBrand.meta.bbbee_level && (
                                        <li>
                                            <FontAwesomeIcon className="icon" icon={faCertificate} />
                                            &nbsp;{selectedBrand.meta.bbbee_level}
                                            <br />
                                            Valid up until {selectedBrand.meta.bbbee_expiry}
                                        </li>
                                    )}
                                </ul>
                            </Col>
                            <Col className="py-5">
                                <h4>Offerings</h4>
                                {/* <ul className="mt-2 list-style-none offer-details">
                                    <li><FontAwesomeIcon className="icon" icon={faCheck} />&nbsp;{selectedBrand.brand_name}</li>
                                    <li><FontAwesomeIcon className="icon" icon={faCheck} />&nbsp;Founded in {selectedBrand.founded ? selectedBrand.founded : ''}</li>
                                    <li><fontAwesomeIcon className="icon" icon={faCheck} />&nbsp;{selectedBrand.meta.address ? selectedBrand.meta.address : ''}</li>
                                    <li><FontAwesomeIcon className="icon" icon={faCheck} />&nbsp;{selectedBrand.meta.contact_number ? selectedBrand.meta.contact_number : ''}</li>
                                    <li><FontAwesomeIcon className="icon" icon={faCheck} />&nbsp;{selectedBrand.meta.bbbee_level ? selectedBrand.meta.bbbee_level : ''}</li>
                                </ul> */}
                            </Col>
                        </Row>
                    </Container>
                    <Container className="mt-4 pt-4">
                        <Row className="mt-2 pb-6">
                            <Col className="text-center" md={12}>
                                <h3>{`${selectedBrand.brand_name}`} Products</h3>
                            </Col>
                            <Col md={12}>
                                <ProductCarousel products={offerings} />
                            </Col>
                        </Row>
                    </Container>
                    <Container className="mt-4 pt-4">
                        <Row>
                            <Col className="text-center" md={12}>
                                <h3>Latest {`${selectedBrand.brand_name}`} Insights</h3>
                                <BlogCards />
                            </Col>
                        </Row>
                        <Row className="mt-2 pb-6">
                            <Col md={10}>
                                <h4 className="fw-bold">View & compare {`${selectedBrand.brand_name}`} <span style={{ color: "#DA6220" }}>full range of products & services</span></h4>
                                <p>Unlock a world of insights! Login now for exclusive access to detailed product specs, performance data, service details, and side-by-side comparisons. Elevate your decision-making with comprehensive information at your fingertips.</p>
                            </Col>
                            <Col className="flex-end" md={2}>
                                <Button as={Link} to={Routes.Signin.path} style={{ borderRadius: "0px", boxShadow: "25px 19px 40px 0px rgba(0, 0, 0, 0.24)" }} variant="brand-orange" className="px-5 px-4 py-3 mt-4 btn btn-primary">
                                    Login
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </section>
            ) : (
                <Row className="justify-content-center">
                    <Col>No post found.</Col>
                </Row>
            )}

            <Footer />
        </>
    );
};
