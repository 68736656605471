import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Button, Modal } from '@themesberg/react-bootstrap';

const AddSupplierModal = ({ showModal, handleCloseModal, createSupplierAcc, updateSupplier, companies, users, brands, isEditMode, initialSupplierData }) => {
    const [supplierData, setSupplierData] = useState({
        linked_company: { doc_id: '', name: '' },
        linked_user: [{ doc_id: '', name: '' }],
        brands: []
    });

    useEffect(() => {
        if (showModal) {
            if (isEditMode && initialSupplierData) {
                const supplierBrands = brands ? brands.filter(brand => brand.supplier_id === initialSupplierData.linked_company.doc_id) : [];
                console.log("Setting supplier data for edit mode:", initialSupplierData);
                setSupplierData({
                    linked_company: initialSupplierData.linked_company ? {
                        doc_id: initialSupplierData.linked_company.doc_id,
                        name: initialSupplierData.linked_company.name
                    } : { doc_id: '', name: '' },
                    linked_user: initialSupplierData.linked_user && initialSupplierData.linked_user.length ? initialSupplierData.linked_user : [{ doc_id: '', name: '' }],
                    brands: supplierBrands.map(brand => brand._id)
                });
            } else {
                console.log("Setting supplier data for add mode");
                setSupplierData({
                    linked_company: { doc_id: '', name: '' },
                    linked_user: [{ doc_id: '', name: '' }],
                    brands: []
                });
            }
        }
    }, [initialSupplierData, isEditMode, showModal, brands]);

    const handleCompanyChange = (event) => {
        const selectedCompanyId = event.target.value;
        const selectedCompany = companies.find(company => company.id === selectedCompanyId);
        const supplierBrands = brands ? brands.filter(brand => brand.supplier_id === selectedCompanyId) : [];
        if (selectedCompany) {
            setSupplierData(prev => ({
                ...prev,
                linked_company: {
                    doc_id: selectedCompany.id,
                    name: selectedCompany.legal_name || selectedCompany.trading_name,
                },
                brands: supplierBrands.map(brand => brand._id)
            }));
        }
    };

    const handleUserChange = (event) => {
        const selectedUserId = event.target.value;
        const selectedUser = users.find(user => user._id === selectedUserId);
        if (selectedUser) {
            setSupplierData(prev => ({
                ...prev,
                linked_user: [{
                    doc_id: selectedUser._id,
                    name: `${selectedUser.first_name} ${selectedUser.last_name}`,
                }]
            }));
        }
    };

    const handleBrandsChange = (event) => {
        const selectedBrands = Array.from(event.target.selectedOptions, option => option.value);
        setSupplierData(prev => ({
            ...prev,
            brands: selectedBrands
        }));
    };

    const handleSubmit = () => {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        const createdBy = userDetails ? {
            doc_id: userDetails._id,
            email: userDetails.email
        } : {
            doc_id: '',
            email: ''
        };

        const payload = {
            linked_company: supplierData.linked_company,
            linked_user: supplierData.linked_user.filter(user => user.doc_id !== ''),
            created_by: createdBy,
            brands: supplierData.brands
        };

        console.log("Payload:", payload); // Log payload to debug
        if (isEditMode) {
            updateSupplier(payload);
        } else {
            createSupplierAcc(payload);
        }
        handleCloseModal();
    };

    return (
        <>
            <Modal size="lg" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditMode ? 'Edit' : 'Add'} Company User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md={6} className="mb-3">
                                <Form.Group controlId="linked_company">
                                    <Form.Label>Linked Company</Form.Label>
                                    <Form.Select required value={supplierData.linked_company.doc_id} onChange={handleCompanyChange}>
                                        <option value="">Select company</option>
                                        {companies.map((company) => (
                                            <option key={company.id} value={company.id}>{company.legal_name || company.trading_name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group controlId="linked_user">
                                    <Form.Label>Linked User</Form.Label>
                                    <Form.Select required value={supplierData.linked_user[0]?.doc_id} onChange={handleUserChange}>
                                        <option value="">Select user</option>
                                        {users.map((user) => (
                                            <option key={user._id} value={user._id}>
                                                {user.first_name} {user.last_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={12} className="mb-3">
                                <Form.Group controlId="linked_brands">
                                    <Form.Label>Brands</Form.Label>
                                    <Form.Select value={supplierData.brands} onChange={handleBrandsChange}>
                                        {brands && brands.map((brand) => (
                                            <option key={brand._id} value={brand._id}>{brand.name}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="brand-orange" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddSupplierModal;
