import React, { useState, useEffect } from 'react';
import { Link, Route, Router } from 'react-router-dom';
import { Col, InputGroup, Row, Form, Button, Modal, Tabs, Tab } from '@themesberg/react-bootstrap';
import { Routes } from '../../routes';
import AddBrandModal from './AddBrandModal';
import Papa from 'papaparse';
import Select from 'react-select';

const AddCompaniesModal = ({ brands, showModal, handleCloseModal, initialCompanyData, createOffering, updateOffering, initialOfferingData, createCompAcc, updateCompAcc, isEditMode }) => {

    const [userRole, setUserRole] = useState('');
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [uploadCsvFile, setUploadCsvFile] = useState(null);
    const [showAddNewBrandModal, setShowAddNewBrandModal] = useState(false);
    const [offeringData, setOfferingData] = useState({
        offering: "",
        offering_content: "",
        rental_service: false,
        maintenance_and_repairs: false,
        training_programmings: false,
        spare_parts_and_accessories: false,
        emergency_services_and_response: false,
        technology_solutions: false,
        refurbished_solutions: false,
        customized_solutions: false,
        compliance_and_certification_services: false,
        warranty_offered: false,
        notes: "",
        product: false,
        service: false
    });
    const [companyData, setCompanyData] = useState({
        id: '',
        legal_name: '',
        trading_name: '',
        registration_number: '',
        vat_number: '',
        contact_number: '',
        primary_email: '',
        social_links: {
            linkedin: '',
            facebook: '',
            instagram: '',
            twitter: ''
        },
        dealer_network: '',
        registered_address: '',
        billing_address: '',
        industry_type: [],
        location: {
            address: '',
            area_code: ''
        },
        website_url: '',
        bbbee: {
            level: '',
            expiry_date: '',
            certificate: null
        },
        brands: [],
        company_type: '',
        company_logo: null,
        company_bio: ''
    });

    useEffect(() => {
        if (showModal && isEditMode && initialCompanyData) {
            setCompanyData({
                ...companyData,
                ...initialCompanyData,
                social_links: initialCompanyData.social_links || { linkedin: '', facebook: '', instagram: '', twitter: '' },
                bbbee: initialCompanyData.bbbee || { level: '', expiry_date: '', certificate: null },
                brands: initialCompanyData.brands || []
            });
            setSelectedBrands(initialCompanyData.brands.map(brand => ({
                value: brand._id,
                label: brand.name
            })));
        }
    }, [initialCompanyData, isEditMode, showModal]);

    const locationsArray = [
        { address: 'Cape Town', area_code: '8000' },
        { address: 'Johannesburg', area_code: '2000' },
        { address: 'Durban', area_code: '4000' },
        { address: 'Pietermaritzburg', area_code: '3200' },
        { address: 'Bloemfontein', area_code: '9300' },
        { address: 'Qbergha', area_code: '2092' },
        { address: 'Bhisho', area_code: '5605' },
        { address: 'Mahikeng', area_code: '2735' },
        { address: 'Mmbombela', area_code: '1200' },
        { address: 'Polokwane', area_code: '0699' },
        { address: 'Kimberley', area_code: '8300' },
    ];

    const handleCsvFileChange = (event) => {
        setUploadCsvFile(event.target.files[0]);
    };

    const handleShowAddNewBrandModal = () => setShowAddNewBrandModal(true);
    const handleCloseAddNewBrandModal = () => setShowAddNewBrandModal(false);

    const handleCsvUpload = () => {
        if (uploadCsvFile) {
            Papa.parse(uploadCsvFile, {
                complete: function (results) {
                    console.log("Parsed results:", results);
                    createOffering({ offeringMeta: results.data });
                },
                header: true,
                skipEmptyLines: true,
            });
        } else {
            alert('Please select a CSV file first!');
        }
    };

    const formatRegistrationNumber = (value) => {
        const digits = value.replace(/\D/g, '');

        const parts = [
            digits.slice(0, 4),
            digits.slice(4, 10),
            digits.slice(10, 13)
        ].filter(Boolean);

        return parts.join('/');
    };

    const handleDownloadTemplate = () => {
        const headers = [
            "Brand", "Product Name", "Tags", "Series Name", "Model Numbers",
            "Load Capacity", "Load Centre", "Lift Height", "Extended Mast Height",
            "Special Features", "Description", "Product Images"
        ];

        const csvHeader = headers.join(',');
        const csvRows = [csvHeader];
        const csvString = csvRows.join("\n");
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'product_template.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleRegistrationNumberChange = (e) => {
        const { value } = e.target;
        setCompanyData({ ...companyData, registration_number: value });
    };

    const handleLocationChange = (selectedOptions) => {
        const selectedLocations = Array.from(selectedOptions).map((option) => {
            const selectedIndex = parseInt(option.value, 10);
            return locationsArray[selectedIndex];
        });

        setCompanyData({
            ...companyData,
            location: selectedLocations
        });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setOfferingData(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

   
    const handleSaveChanges = () => {
        const updatedCompanyData = {
            ...companyData,
            brands: selectedBrands.map(brand => ({ _id: brand.value, name: brand.label }))
        };

        if (isEditMode) {
            updateCompAcc(updatedCompanyData);
            updateOffering(offeringData);
        } else {
            createCompAcc(updatedCompanyData);
            createOffering(offeringData);
        }
        handleCloseModal();
    };

    function isValidRsaId(_said_string, string) {
        if (_said_string.length !== 13) {
            return false;
        }
        var isValid = true;
        var regex = new RegExp(/(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/);
        isValid = regex.test(_said_string);
        if (!isValid) {
            return false;
        }
        var tempTotal = 0;
        var checkSum = 0;
        var multiplier = 1;
        for (var i = 0; i < 13; ++i) {
            tempTotal = parseInt(_said_string.charAt(i)) * multiplier;
            if (tempTotal > 9) {
                tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
            }
            checkSum = checkSum + tempTotal;
            multiplier = (multiplier % 2 === 0) ? 1 : 2;
        }
        if ((checkSum % 10) !== 0) {
            isValid = false;
        }

        return isValid;
    }

    function onKeyValueChange(key, event) {
        const isCheckbox = event.target.type === 'checkbox';
        const value = isCheckbox ? event.target.checked : event.target.value;

        setOfferingData(prevOfferingData => ({
            ...prevOfferingData,
            [key]: value,
        }));
    }

    function handleFileChange(key, files) {
        if (files.length > 0) {
            setOfferingData(prevOfferingData => ({
                ...prevOfferingData,
                [key]: files[0],
            }));
        }
    }

    return (
        <>
            <Modal size="lg" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditMode ? 'Edit Company' : 'Add Company'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs className="mb-3" defaultActiveKey="generalInformation" id="companyDetailsTabs">
                        <Tab eventKey="generalInformation" title="General Information">
                            <Form>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="company_type">
                                            <Form.Label>Company Type</Form.Label>
                                            <Form.Select
                                                required
                                                value={companyData.company_type}
                                                onChange={(e) => setCompanyData({ ...companyData, company_type: e.target.value })}
                                            >
                                                <option value="">Select company type</option>
                                                <option value="Sole Proprietorship">Sole Proprietorship</option>
                                                <option value="Close Corporation">Close Corporation</option>
                                                <option value="Private Company">Private Company</option>
                                                <option value="Public Company">Public Company</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="legal_name">
                                            <Form.Label>Legal Name</Form.Label>
                                            <Form.Control required type="text"
                                                placeholder="Enter legal name"
                                                value={companyData.legal_name}
                                                onChange={(e) => setCompanyData({ ...companyData, legal_name: e.target.value })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="trading_name">
                                            <Form.Label>Trade Name</Form.Label>
                                            <Form.Control required type="text"
                                                placeholder="Enter trade name"
                                                value={companyData.trading_name}
                                                onChange={(e) => setCompanyData({ ...companyData, trading_name: e.target.value })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="registration_number">
                                            <Form.Label>Reg Number</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Enter reg number"
                                                value={companyData.registration_number}
                                                onChange={handleRegistrationNumberChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="vat_number">
                                            <Form.Label>Vat Number</Form.Label>
                                            <Form.Control required type="text"
                                                placeholder="Enter VAT number"
                                                value={companyData.vat_number}
                                                onChange={(e) => setCompanyData({ ...companyData, vat_number: e.target.value })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="registered_address">
                                            <Form.Label>Reg Address</Form.Label>
                                            <Form.Control required type="text"
                                                placeholder="Enter reg address"
                                                value={companyData.registered_address}
                                                onChange={(e) => setCompanyData({ ...companyData, registered_address: e.target.value })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="billing_address">
                                            <Form.Label>Billing Address</Form.Label>
                                            <Form.Control required type="text"
                                                placeholder="Enter billing address"
                                                value={companyData.billing_address}
                                                onChange={(e) => setCompanyData({ ...companyData, billing_address: e.target.value })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {['buyer', 'admin'].includes(userRole) ?
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <Form.Group controlId="industry_type">
                                                <Form.Label>Industry Type</Form.Label>
                                                <Form.Select
                                                    required
                                                    value={companyData.industry_type}
                                                    onChange={(e) => setCompanyData({ ...companyData, industry_type: e.target.value })}
                                                >
                                                    <option value="">Select industry</option>
                                                    <option value="Manufacturing">Manufacturing</option>
                                                    <option value="Warehousing">Warehousing</option>
                                                    <option value="Cold Storage">Cold Storage</option>
                                                    <option value="Construction">Construction</option>
                                                    <option value="Transportation">Transportation</option>
                                                    <option value="FMCG">FMCG</option>
                                                    <option value="Distribution">Distribution</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    : ''}
                                {['supplier', 'admin'].includes(userRole) ?
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <Form.Group controlId="locationMultiselect">
                                                <Form.Label>Nearest Branch Locations</Form.Label>
                                                <Form.Select
                                                    required
                                                    as="select"
                                                    multiple
                                                    onChange={(e) => handleLocationChange(e.target.selectedOptions)}
                                                >
                                                    {locationsArray.map((location, index) => (
                                                        <option key={index} value={index}>
                                                            {`${location.address}, ${location.area_code}`}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    : ''}

                                <Row>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="bbbeeLevel">
                                            <Form.Label>BBBEE Level</Form.Label>
                                            <Form.Select
                                                required
                                                value={companyData.bbbee?.level || ''}
                                                onChange={(e) => setCompanyData({
                                                    ...companyData,
                                                    bbbee: { ...companyData.bbbee, level: e.target.value }
                                                })}
                                            >
                                                <option value="">Select BBBEE Level</option>
                                                <option value="Level 1">Level 1</option>
                                                <option value="Level 2">Level 2</option>
                                                <option value="Level 3">Level 3</option>
                                                <option value="Level 4">Level 4</option>
                                                <option value="Exempt">Exempt</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="bbbeeExpiryDate">
                                            <Form.Label>BBBEE Expiry Date</Form.Label>
                                            <Form.Control required type="date"
                                                placeholder="Enter BBBEE expiry date"
                                                value={companyData.bbbee.expiry_date}
                                                onChange={(e) => setCompanyData({
                                                    ...companyData,
                                                    bbbee: { ...companyData.bbbee, expiry_date: e.target.value }
                                                })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {['supplier', 'admin'].includes(userRole) ?
                                    <Row>
                                        <Col md={12} className="mb-3">
                                            <Form.Group controlId="bbbeeCertificate">
                                                <Form.Label>BBBEE Certificate</Form.Label>
                                                <Form.Control required type="file"
                                                    accept="application/pdf"
                                                    onChange={(e) => {
                                                        const file = e.target.files[0];
                                                        if (file && file.type === "application/pdf") {
                                                            // Handle the PDF file upload here
                                                            //handleCertificateUpload(file);
                                                        } else {
                                                            // Optionally alert the user if a non-PDF file is selected
                                                            alert("Please select a PDF file.");
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row> : ''}
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="website_url">
                                            <Form.Label>Web Address</Form.Label>
                                            <Form.Control required type="text"
                                                placeholder="Enter web address"
                                                value={companyData.website_url}
                                                onChange={(e) => setCompanyData({ ...companyData, website_url: e.target.value })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Tab>
                        <Tab eventKey="additionalInformation" title="Additional Information">
                            <Form>
                                <Row className="mb-3">
                                    <Col md={6}>
                                        <Form.Group controlId="contact_number">
                                            <Form.Label>Contact Number</Form.Label>
                                            <Form.Control type="text" placeholder="Enter contact number"
                                                value={companyData.contact_number}
                                                onChange={(e) => setCompanyData({ ...companyData, contact_number: e.target.value })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group controlId="primary_email">
                                            <Form.Label>Primary Email</Form.Label>
                                            <Form.Control type="email" placeholder="Enter primary email"
                                                value={companyData.primary_email}
                                                onChange={(e) => setCompanyData({ ...companyData, primary_email: e.target.value })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="social_linkedin">
                                            <Form.Label>LinkedIn</Form.Label>
                                            <Form.Control type="text" placeholder="LinkedIn URL"
                                                value={companyData.social_links.linkedin}
                                                onChange={(e) => setCompanyData({ ...companyData, social_links: { ...companyData.social_links, linkedin: e.target.value } })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="social_facebook">
                                            <Form.Label>Facebook</Form.Label>
                                            <Form.Control type="text" placeholder="Facebook URL"
                                                value={companyData.social_links.facebook}
                                                onChange={(e) => setCompanyData({ ...companyData, social_links: { ...companyData.social_links, facebook: e.target.value } })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="social_instagram">
                                            <Form.Label>Instagram</Form.Label>
                                            <Form.Control type="text" placeholder="Instagram URL"
                                                value={companyData.social_links.instagram}
                                                onChange={(e) => setCompanyData({ ...companyData, social_links: { ...companyData.social_links, instagram: e.target.value } })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <Form.Group controlId="social_twitter">
                                            <Form.Label>Twitter</Form.Label>
                                            <Form.Control type="text" placeholder="Twitter URL"
                                                value={companyData.social_links.twitter}
                                                onChange={(e) => setCompanyData({ ...companyData, social_links: { ...companyData.social_links, twitter: e.target.value } })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="dealer_network">
                                            <Form.Label>Dealer Network</Form.Label>
                                            <Form.Check type="checkbox" label="Part of dealer network"
                                                checked={companyData.dealer_network}
                                                onChange={(e) => setCompanyData({ ...companyData, dealer_network: e.target.checked })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {isEditMode ?
                                    <Row className="mb-3">
                                        <Col md={12}>
                                            <Form.Group controlId="brands">
                                                <Form.Label>Brands</Form.Label>
                                                <Select
                                                    isMulti
                                                    value={(companyData.brands || []).map(brand => ({
                                                        value: brand.doc_id,
                                                        label: brand.name
                                                    }))}
                                                    onChange={(selectedOptions) => {
                                                        const brands = selectedOptions ? selectedOptions.map(option => ({
                                                            id: option.value,
                                                            name: option.label
                                                        })) : [];
                                                        setCompanyData({ ...companyData, brands });
                                                    }}
                                                    options={(initialCompanyData.brands || []).map(brand => ({
                                                        value: brand.doc_id,
                                                        label: brand.name
                                                    }))}
                                                    noOptionsMessage={() => "No brands available. Add new brand."}
                                                    placeholder="Select brands..."
                                                />
                                                <a className="primary-link-btn" onClick={handleShowAddNewBrandModal}>
                                                    Add New Brand
                                                </a>
                                                <AddBrandModal show={showAddNewBrandModal} onHide={handleCloseAddNewBrandModal} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    : ''}
                                <Row className="mb-3">
                                    <Col md={12}>
                                        <Form.Group controlId="company_logo">
                                            <Form.Label>Company Logo</Form.Label>
                                            <Form.Control
                                                type="file"
                                                onChange={(e) => {
                                                    const file = e.target.files[0];
                                                    setCompanyData({ ...companyData, company_logo: file });
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col md={12}>
                                        <Form.Group controlId="company_bio">
                                            <Form.Label>Company Bio</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                placeholder="Enter company biography"
                                                value={companyData.company_bio}
                                                onChange={(e) => setCompanyData({ ...companyData, company_bio: e.target.value })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Tab>
                        <Tab eventKey="offeringInformation" title="Offering Information" >
                            <Form>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Offering Type</Form.Label>
                                            <div>
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Products"
                                                    name="product"
                                                    checked={offeringData.product}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Services"
                                                    name="service"
                                                    checked={offeringData.service}
                                                    onChange={handleCheckboxChange}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="offering_content">
                                            <Form.Label>Offering Content</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                required
                                                name="offering_content"
                                                placeholder="Add offering content"
                                                value={offeringData.offering_content}
                                                onChange={(e) => onKeyValueChange('offering_content', e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Services Offered</Form.Label>
                                            <div>
                                                <Row>
                                                    <Col xs={12} sm={12} md={4}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Rental Service"
                                                            name="rental_service"
                                                            value={offeringData.rental_service}
                                                            onChange={(e) => onKeyValueChange('rental_service', e)}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Maintenance & Repairs"
                                                            name="maintenance_and_repairs"
                                                            value={offeringData.maintenance_and_repairs}
                                                            onChange={(e) => onKeyValueChange('maintenance_and_repairs', e)}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Training Programmings"
                                                            name="training_programmings"
                                                            value={offeringData.training_programmings}
                                                            onChange={(e) => onKeyValueChange('training_programmings', e)}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={12} md={4}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Spare Parts & Accessories"
                                                            name="spare_parts_and_accessories"
                                                            value={offeringData.spare_parts_and_accessories}
                                                            onChange={(e) => onKeyValueChange('spare_parts_and_acessories', e)}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Emergency Services & Response"
                                                            name="emergency_services_and_response"
                                                            value={offeringData.emergency_services_and_response}
                                                            onChange={(e) => onKeyValueChange('emergency_services_and_response', e)}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Technology Solutions"
                                                            name="technology_solutions"
                                                            value={offeringData.technology_solutions}
                                                            onChange={(e) => onKeyValueChange('technology_solutions', e)}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Refurbished Solutions"
                                                            name="refurbished_solutions"
                                                            value={offeringData.refurbished_solutions}
                                                            onChange={(e) => onKeyValueChange('refurbished_solutions', e)}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={12} md={4}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Customized Solutions"
                                                            name="customized_solutions"
                                                            value={offeringData.customized_solutions}
                                                            onChange={(e) => onKeyValueChange('customized_solutions', e)}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Compliance & Certificate Services"
                                                            name="compliance_and_certification_services"
                                                            value={offeringData.compliance_and_certification_services}
                                                            onChange={(e) => onKeyValueChange('compliance_and_certification_services', e)}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Warranty Offered"
                                                            name="warranty_offered"
                                                            value={offeringData.warranty_offered}
                                                            onChange={(e) => onKeyValueChange('warranty_offered', e)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="notes">
                                            <Form.Label>Notes</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                required
                                                name="notes"
                                                placeholder="Enter notes"
                                                value={offeringData.notes}
                                                onChange={(e) => onKeyValueChange('notes', e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="brand-orange" onClick={handleSaveChanges}>
                        {isEditMode ? 'Update Company' : 'Add Company'}
                    </Button>
                </Modal.Footer>
            </Modal>

            <AddBrandModal
                showModal={showAddNewBrandModal}
                handleCloseModal={handleCloseAddNewBrandModal}
                createBrands={(newBrand) => {
                    setSelectedBrands([...selectedBrands, { value: newBrand._id, label: newBrand.name }]);
                    setShowAddNewBrandModal(false);
                }}
            />
        </>
    );
};

export default AddCompaniesModal;
