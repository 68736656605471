import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Col, Row, Form, Button, Table, Card } from '@themesberg/react-bootstrap';
import Select from 'react-select';
import { getAllBrands } from '../../../api/private/brands';
import { getAllProducts } from '../../../api/private/products';
import { getAllUsers } from '../../../api/private/users';
import { getAllSuppliers } from '../../../api/private/suppliers';
import { getAllQuotes } from '../../../api/private/quotes';
import { CSVLink } from 'react-csv';

const Reports = () => {
    const [reportType, setReportType] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [fields, setFields] = useState([]);
    const [selectedFields, setSelectedFields] = useState([]);
    const [step, setStep] = useState('selectFields'); // 'selectFields' or 'generateReport'

    const reportOptions = [
        { value: 'users', label: 'Users' },
        { value: 'brands', label: 'Brands' },
        { value: 'suppliers', label: 'Suppliers' },
        { value: 'quotes', label: 'Quotes' },
        { value: 'products', label: 'Products' }
    ];

    useEffect(() => {
        setFields([]); // Clear fields when report type changes
        setData([]);   // Clear data when report type changes
        setSelectedFields([]); // Clear selected fields when report type changes
        setStep('selectFields'); // Reset to field selection step
    }, [reportType]);

    const fetchData = () => {
        const fetchFunctions = {
            users: loadUsers,
            brands: loadBrands,
            suppliers: loadSuppliers,
            quotes: loadQuotes,
            products: loadProducts
        };

        if (reportType && fetchFunctions[reportType.value]) {
            fetchFunctions[reportType.value](startDate, endDate);
        }
    };

    const loadUsers = (startDate, endDate) => {
        getAllUsers({ startDate, endDate }).then(response => {
            if (response.data.Result === 'OK') {
                setData(response.data.Records);
                setFields(Object.keys(response.data.Records[0] || {}).map(key => ({ value: key, label: key })));
            } else {
                console.warn(response.data);
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const loadBrands = (startDate, endDate) => {
        getAllBrands({ startDate, endDate }).then(response => {
            if (response.data.Result === 'OK') {
                setData(response.data.Records);
                setFields(Object.keys(response.data.Records[0] || {}).map(key => ({ value: key, label: key })));
            } else {
                console.warn(response.data);
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const loadSuppliers = (startDate, endDate) => {
        getAllSuppliers({ startDate, endDate }).then(response => {
            if (response.data.Result === 'OK') {
                setData(response.data.Records);
                setFields(Object.keys(response.data.Records[0] || {}).map(key => ({ value: key, label: key })));
            } else {
                console.warn(response.data);
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const loadQuotes = (startDate, endDate) => {
        getAllQuotes({ startDate, endDate }).then(response => {
            if (response.data.Result === 'OK') {
                setData(response.data.Records);
                setFields(Object.keys(response.data.Records[0] || {}).map(key => ({ value: key, label: key })));
            } else {
                console.warn(response.data);
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const loadProducts = (startDate, endDate) => {
        getAllProducts({ startDate, endDate }).then(response => {
            if (response.data.Result === 'OK') {
                setData(response.data.Records);
                setFields(Object.keys(response.data.Records[0] || {}).map(key => ({ value: key, label: key })));
            } else {
                console.warn(response.data);
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleNextClick = () => {
        if (step === 'selectFields') {
            setStep('generateReport');
            fetchData();
        }
    };

    const generateCSVData = () => {
        if (data.length === 0 || selectedFields.length === 0) {
            return [];
        }

        const headers = selectedFields.map(field => field.label);
        const rows = data.map(item => selectedFields.map(field => JSON.stringify(item[field.value]) || ''));

        return [headers, ...rows];
    };

    return (
        <div className="dashboard-container">
            <Row>
                <Col md={6}>
                    <div className="d-flex flex-column justify-content-center mr-auto">
                        <Form.Group>
                            <Form.Label>Type of Report</Form.Label>
                            <Select
                                options={reportOptions}
                                value={reportType}
                                onChange={setReportType}
                                placeholder="Select Report Type"
                            />
                        </Form.Group>
                    </div>
                </Col>
                <Col md={6}>
                    <div className="d-flex flex-column align-items-end">
                        <Form.Group>
                            <Form.Label>Report Start Date</Form.Label>
                            <DatePicker
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                className="my-1 mx-1 form-control"
                                dateFormat="yyyy-MM-dd"
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Report End Date</Form.Label>
                            <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                className="my-1 mx-1 form-control"
                                dateFormat="yyyy-MM-dd"
                            />
                        </Form.Group>
                    </div>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col className="my-2" md={9}>
                    {fields.length > 0 && (
                        <Form.Group>
                            <Form.Label>Select Fields</Form.Label>
                            <Select
                                options={fields}
                                isMulti
                                value={selectedFields}
                                onChange={setSelectedFields}
                                placeholder="Select Fields"
                            />
                        </Form.Group>
                    )}
                </Col>
                <Col md={3}>
                    <Button variant="primary" onClick={handleNextClick}>
                        {step === 'selectFields' ? 'Select Report Fields' : 'Generate Report'}
                    </Button>
                </Col>
                <Col md={12}>
                    {data.length > 0 && step === 'generateReport' && (
                        <>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        {selectedFields.map(field => (
                                            <th key={field.value}>{field.label}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={index}>
                                            {selectedFields.map(field => (
                                                <td key={field.value}>{typeof item[field.value] === 'object' ? JSON.stringify(item[field.value]) : item[field.value]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <Button variant="secondary" className="text-white mt-2">
                                <CSVLink data={generateCSVData()} filename={`report_${reportType?.value || 'report'}.csv`}>
                                    Download CSV
                                </CSVLink>
                            </Button>

                        </>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default Reports;
