import React from 'react'
import { Col, Row, Button, Form } from '@themesberg/react-bootstrap';

export const SupplierForm = () => {
    return (
        <Form>
            <Row>
                <Col>
                    <Form.Group className="mt-4" controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter your name" />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mt-4" controlId="formSurname">
                        <Form.Label>Surname</Form.Label>
                        <Form.Control type="text" placeholder="Enter your surname" />
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group className="mt-4" controlId="formCompany">
                <Form.Label>Company</Form.Label>
                <Form.Control type="text" placeholder="Enter your company name" />
            </Form.Group>
            <Form.Group className="mt-4" controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter your email address" />
            </Form.Group>
            <Button style={{ borderRadius:"5px" }} variant="primary" className="px-5 mt-5" type="submit">
                Submit
            </Button>
        </Form>

    );
}