import React from "react";
import moment from "moment-timezone";
import { Container, Row, Col, Card } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faEnvelope, faBuilding, faBook } from "@fortawesome/free-solid-svg-icons";
import { Routes } from "../../routes";
import './footer.css'; 
import { Link } from "react-router-dom";

export default (props) => {
  const currentYear = moment().get("year");

  return (
    <div>
      {/* Desktop Footer */}
      <footer className="footer section py-5 d-none d-md-block">
        <Row>
          <Col xs={12} lg={6} className="mb-4 mb-lg-0">
            <p className="mb-0 text-center text-xl-left">
              Copyright © 2023-{`${currentYear} `}
              <Card.Link href="https://themesberg.com" target="_blank" className="text-blue text-decoration-none fw-normal">
                Forklifting Co
              </Card.Link>
            </p>
          </Col>
          <Col xs={12} lg={6}>
            <ul className="list-inline list-group-flush list-group-borderless text-center text-xl-right mb-0">
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link as={Link} to={Routes.About.path}>
                  About
                </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link as={Link} to={Routes.FrequentlyAsked.path}>
                  FAQ
                </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link as={Link} to={Routes.Insights.path}>
                  Insights
                </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link as={Link} to={Routes.Support.path}>
                  Support
                </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link as={Link} to={Routes.Contact.path}>
                  Contact
                </Card.Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} className="text-center mt-6">
            <span className="text-dark">Designed & Developed by
             &nbsp;<a href="https://ignitemedia.co.za" target="_blank" rel="noopener noreferrer" className="text-decoration-none text-dark"><b>Ignite Media</b></a>
            </span>
          </Col>
        </Row>
      </footer>

      {/* Mobile Footer */}
      <footer className="mobile-footer bg-primary d-md-none">
        <Container className="d-flex justify-content-around align-items-center py-3">
          <Link to="/admin/quotes/quotes" className="text-white text-center">
            <FontAwesomeIcon icon={faFilePdf} size="lg" />
            <p className="mb-0 small">Quotes</p>
          </Link>
          <Link to="/admin/chat/inbox" className="text-white text-center">
            <FontAwesomeIcon icon={faEnvelope} size="lg" />
            <p className="mb-0 small">Inbox</p>
          </Link>
          <Link to="/admin/companies/companies" className="text-white text-center">
            <FontAwesomeIcon icon={faBuilding} size="lg" />
            <p className="mb-0 small">Companies</p>
          </Link>
          <Link to="/admin/brands/brands" className="text-white text-center">
            <FontAwesomeIcon icon={faBook} size="lg" />
            <p className="mb-0 small">Brands</p>
          </Link>
        </Container>
      </footer>
    </div>
  );
};
