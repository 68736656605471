import React, { useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup, Tabs, Tab } from '@themesberg/react-bootstrap';

export const GeneralInfoForm = () => {
  const [birthday, setBirthday] = useState("");

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form>
          <Tabs defaultActiveKey="personalInfo" id="info-tabs" className="mb-3">
            <Tab eventKey="personalInfo" title="Personal Information">
              <h5 className="mb-4">General information</h5>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group id="firstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control required type="text" placeholder="Enter your first name" />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="lastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control required type="text" placeholder="Also your last name" />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={6} className="mb-3">
                  <Form.Group id="birthday">
                    <Form.Label>Birthday</Form.Label>
                    <Datetime
                      timeFormat={false}
                      onChange={setBirthday}
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                          <Form.Control
                            required
                            type="text"
                            value={birthday ? moment(birthday).format("MM/DD/YYYY") : ""}
                            placeholder="mm/dd/yyyy"
                            onFocus={openCalendar}
                            onChange={() => { }} />
                        </InputGroup>
                      )} />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="gender">
                    <Form.Label>Gender</Form.Label>
                    <Form.Select defaultValue="0">
                      <option value="0">Gender</option>
                      <option value="1">Female</option>
                      <option value="2">Male</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group id="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control required type="email" placeholder="name@company.com" />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="phone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control required type="number" placeholder="+12-345 678 910" />
                  </Form.Group>
                </Col>
              </Row>
              <h5 className="my-4">Address</h5>
              <Row>
                <Col sm={9} className="mb-3">
                  <Form.Group id="address">
                    <Form.Label>Address</Form.Label>
                    <Form.Control required type="text" placeholder="Enter your home address" />
                  </Form.Group>
                </Col>
                <Col sm={3} className="mb-3">
                  <Form.Group id="addressNumber">
                    <Form.Label>Number</Form.Label>
                    <Form.Control required type="number" placeholder="No." />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={8} className="mb-3">
                  <Form.Group id="city">
                    <Form.Label>City</Form.Label>
                    <Form.Control required type="text" placeholder="City" />
                  </Form.Group>
                </Col>
                <Col sm={4} className="mb-3">
                  <Form.Group id="zip">
                    <Form.Label>ZIP</Form.Label>
                    <Form.Control required type="tel" placeholder="ZIP" />
                  </Form.Group>
                </Col>
              </Row>
            </Tab>

            <Tab eventKey="companyInfo" title="Company Information">
              <h5 className="mb-4">Company Details</h5>
              <Row>
                <Col sm={6} className="mb-3">
                  <Form.Group id="company-name">
                    <Form.Label>Registered Name</Form.Label>
                    <Form.Control required type="text" placeholder="Enter your company name" />
                  </Form.Group>
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Group id="vatNumber">
                    <Form.Label>VAT Number</Form.Label>
                    <Form.Control required type="number" placeholder="Vat No." />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="mb-3">
                  <Form.Group id="company-reg">
                    <Form.Label>Company Registration</Form.Label>
                    <Form.Control required type="text" placeholder="Your Company Reg" />
                  </Form.Group>
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Company Type</Form.Label>
                    <Form.Select id="company-type" defaultValue="0">
                      <option value="CC">Close Corporation</option>
                      <option value="PTY">Proprietary Limited</option>
                      <option value="SOLE-PROP">Sole Prop</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group id="company-tel">
                    <Form.Label>Company Contact Number</Form.Label>
                    <Form.Control required type="tel" placeholder="Your company number" />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group id="company-email">
                    <Form.Label>Company Email</Form.Label>
                    <Form.Control required type="email" placeholder="Your company email" />
                  </Form.Group>
                </Col>
              </Row>
            </Tab>
          </Tabs>
          <div className="mt-3">
            <Button variant="primary" type="submit">Save All</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};
