import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';
import AddPageModal from "../../../components/modals/AddPageModal";
import { getAllPages, createPages, updatePages, deletePages } from "../../../api/private/pages";
import { makeToast } from "../../../functions/toasts";
import { CmsPagesTables } from "../../../components/tables/CmsPagesTables";
import { useUserRole } from "../../../contexts/UserRoleContext";
import { ToastContainer, toast } from "react-toastify";

export default () => {
  const { userRole } = useUserRole();

  const [pages, setPages] = useState([])
  const [page, setPage] = useState({})
  const [initialPageData, setInitialPageData] = useState(null);
  const [pageMounted, setPageMounted] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    loadPages();
  }, [])

  function loadPages() {
    getAllPages().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        const records = _resp.data.Records;
        const landingPages = records.filter(p => p.type === "Landing Page" || p.category === 'Product Landing Page'); // Use filter instead of find
        setPages(landingPages); // Set the filtered landingPages array in the state
        console.log(`Pages found:`, landingPages);
        setLoading(false);
      } else {
        console.warn(_resp.data);
      }
    }).catch((_resp) => {
      console.log(_resp);
      makeToast('error', 'Failed to load pages');
    });
  }

  function createPage(pageData) {
    createPages(pageData).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        makeToast('success', 'Created new page');
        handleCloseModal();
      } else {
        console.warn(_resp.data);
        console.log(pageData)
      }
    }).catch((_resp) => {
      console.error(_resp);
      makeToast('error', 'Failed to create page');
    });
  }

  function updatePage(page, pageData, description) {
    console.log("Update function ran with object:", pageData);

    if(!page['_id']) {
      console.error("Document ID is undefined:", page['_id']);
      makeToast('error', 'Document ID is missing');
      return;
    }

    const payload = { 
      'title': pageData['title'],
      'seo_title': pageData['seo_title'],
      'seo_description': pageData['seo_description'],
      'slug': pageData['slug'],
      'author': pageData['author'],
      'canonical_link': pageData['canonical_link'],
      'excerpt': pageData['excerpt'],
      'status': pageData['status'],
      'date_published': pageData['date_published'],
      'created_on': pageData['created_on'],
      'page_content': pageData['page_content'],
      'image_doc_id': pageData['image_doc_id'],
      'component_content': [
          {
              'component_content_type': pageData['component_content']['component_content_type'],
              'id': pageData['component_content']['id'],
              'font_class': pageData['component_content']['font_class'],
              'text': pageData['component_content']['text'],
              'subtext': pageData['component_content']['subtext'],
              'link_type': pageData['component_content']['link_type'],
              'link_url': pageData['component_content']['link_url']
          }
      ],
    };

    updatePages(page["_id"], payload).then((_resp) => {
      console.log("This is the page", page)
      if (_resp.data.Result === 'OK') {
        makeToast('success', 'Page updated successfully');
        handleCloseModal();
      } else {
        console.warn(_resp.data);
      }
    }).catch((_resp) => {
      console.error(_resp);
      makeToast('error', 'Failed to update page');
    });
  }

  function deletePage(page) {
    deletePages(page).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        makeToast('success', 'Deleted page')
        loadPages();
      } else {
        console.warn(_resp.data)
      }
    }).catch((_resp) => {
      console.log(_resp)
      makeToast('error', 'Failed to delete page')
    })
  }

  const handleEditPage = (page) => {
    console.log(page);
    setInitialPageData(page);
    setIsEditMode(true);
    setShowModal(true);
  };

  const handleAddPage = () => {
    setInitialPageData(null);
    setIsEditMode(false);
    setShowModal(true);
  };
  return (
    <>
     <ToastContainer hideProgressBar={true} theme="light" pauseOnHover autoClose={5000}/>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>CMS</Breadcrumb.Item>
            <Breadcrumb.Item active>Landing Pages</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Landing Pages</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
            <Button className="btn btn-primary" onClick={handleAddPage}>Add Page</Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            {/* <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup> */}
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <CmsPagesTables pages={pages} deletePage={deletePage} handleEditPage={handleEditPage} />
      <AddPageModal showModal={showModal}
        handleCloseModal={handleCloseModal}
        createPage={createPage}
        updatePage={updatePage}
        initialPageData={initialPageData}
        isEditMode={isEditMode}
      />
    </>
  );
};
