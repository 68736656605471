import React, { useState, useEffect } from "react";
import { Col, Row } from '@themesberg/react-bootstrap';
import { NavigationBar } from "../../components/frontendNav/Navigation";
import { Footer } from '../../components/frontendNav/Footer'
import { Routes } from "../../routes";
import analytics from '../../functions/analytics'

export default () => {

  useEffect(() => {
    analytics.page()
  })
  return (
    <>
      {/*-- Navigation Bar --*/}
      <NavigationBar />

      <section className="partner-brands align-items-start justify-content-start overflow-hidden pt-5 pt-lg-6 pb-4 px-7 bg-white text-dark">
        <h1 className="fw-normal lead text-dark">Filter your search by the categories below</h1>
      </section>

      <div className="bg-primary table-settings pt-6 pb-6 px-7">
        <Row className="justify-content-center align-items-center">
          <div className="background-overlay-med"></div>
          <Col xs={12} md={12} lg={12} xl={12}>
            <section className="text-center align-items-center justify-content-center overflow-hidden pt-4 pt-lg-4 pb-4 pb-lg-4 bg-primary text-white">
              <div className="d-flex justify-content-center">
                <select className="form-select me-2">
                  <option value="">Filter by product</option>
                  {/* Add product options here */}
                </select>
                <select className="form-select me-2">
                  <option value="">Filter by service</option>
                  {/* Add service options here */}
                </select>
                <select className="form-select me-2">
                  <option value="">Filter by location</option>
                  {/* Add location options here */}
                </select>
                <select className="form-select me-2">
                  <option value="">Filter by rating</option>
                  {/* Add rating options here */}
                </select>
                <button className="btn btn-secondary">GO</button>
              </div>
            </section>
          </Col>
        </Row>
      </div>

      <section className="partner-brands align-items-start justify-content-start overflow-hidden pt-2 pt-lg-4 pb-4 px-7 bg-white text-dark">
        <h2 className="fw-bolder text-dark">Best forklift suppliers</h2>
      </section>

       {/*-- Footer --*/}
       <Footer />
    </>
  );
};