import React from 'react';
import { Button, Card } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { Routes } from "../routes";
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function ProductCarousel({ products, openModal }) {
  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };

  return (
    <Slider {...settings}>
      {products.map((product, index) => (
        <div key={index} className="px-2">
          <Card className="product-card text-center">
            {product.image ? (
              <img src={product.image} alt={product.name} className="product-image" />
            ) : (
              <img src="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=" alt="" className="product-image" />
            )}
            <div className="product-details">
              <h4 className="product-name pt-2">{product.name}</h4>
              <p className="product-brand py-0 mt-0 mb-2">Brand: {product.brand}</p>
              <div className="product-btn-group">
                {/* <Button variant="brand-orange" className="btn btn-seconadary mb-3 mx-1" onClick={openModal}><FontAwesomeIcon icon={faExchangeAlt} /></Button> */}
                <Button as={Link} className="btn btn-primary mb-3 mx-1" to={Routes.Quote.path}>Request A Quote</Button>
              </div>
            </div>
          </Card>
        </div>
      ))}
    </Slider>
  );
}

export default ProductCarousel;
