import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Container, Row, Col } from '@themesberg/react-bootstrap';
import { useHistory } from 'react-router-dom';

const SignupCancel = ({ selectedPackage }) => {
  const history = useHistory();

  const handleBackToPayment = () => {
    history.push('/signup-wizard/step-3'); // Adjust the path according to your routing structure
  };

  // Check if selectedPackage is defined
  if (!selectedPackage) {
    return (
      <Container fluid className="min-vh-100 d-flex bg-primary text-white flex-column justify-content-center align-items-center">
        <Row className="text-center mb-4">
          <Col>
            <FontAwesomeIcon icon={faTimesCircle} size="4x" className="text-danger" />
            <h3 className="d-inline-block ms-3">Signup payment failed</h3>
          </Col>
        </Row>
        <Row className="text-center mb-4 mx-6">
          <Col>
            <p>
              If you’re seeing this screen, your payment was either intentionally cancelled, timed out, or failed at our payment partner.
              Please try your purchase again, or get in touch with our support team.
            </p>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <Button variant="secondary" onClick={handleBackToPayment}>
              Back to Payment
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid className="min-vh-100 d-flex flex-column bg-primary text-white justify-content-center align-items-center">
      <Row className="text-center mb-4">
        <Col>
          <FontAwesomeIcon icon={faTimesCircle} size="4x" className="text-danger" />
          <h3 className="d-inline-block ms-3">Signup payment failed</h3>
        </Col>
      </Row>
      <Row className="text-center mb-4 mx-6">
        <Col>
          <p>
            If you’re seeing this screen, your payment was either intentionally cancelled, timed out, or failed at our payment partner.
            Please try your purchase again, or get in touch with our support team.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center mb-4">
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>{selectedPackage.title}</Card.Title>
              <Card.Text>
                <strong>Features:</strong>
                <ul>
                  {selectedPackage.features && selectedPackage.features.map((feature, index) => (
                    <li key={index}>{feature.text}</li>
                  ))}
                </ul>
                <strong>Price: </strong>
                R{selectedPackage.isAnnual ? selectedPackage.annual_price : selectedPackage.monthly_price} | 
                USD ${selectedPackage.isAnnual ? selectedPackage.annual_usd_price : selectedPackage.monthly_usd_price} | 
                EUR €{selectedPackage.isAnnual ? selectedPackage.annual_eu_price : selectedPackage.monthly_eu_price}
                <br />
                <strong>Restrictions:</strong>
                <ul>
                  {selectedPackage.dashboard_access && <li>Dashboard Access</li>}
                  {selectedPackage.analytics_access && <li>Analytics Access</li>}
                  {selectedPackage.reports_access && <li>Reports Access</li>}
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="text-center">
        <Col>
          <Button variant="secondary" onClick={handleBackToPayment}>
            Back to Payment
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SignupCancel;
