import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faCartArrowDown, faChartPie, faChevronDown, faClipboard, faCommentDots, faFileAlt, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown } from '@themesberg/react-bootstrap';
import { ChoosePhotoWidget, ProfileCardWidget } from "../../../components/Widgets";
import { GeneralInfoForm } from "../../../components/Forms";
import { useUserRole } from "../../../contexts/UserRoleContext";
import Profile3 from "../../../assets/img/team/profile-picture-3.jpg";
import { ToastContainer, toast } from "react-toastify"
export default () => {
  const { userRole } = useUserRole();

  return (
    <>
    <ToastContainer hideProgressBar={true} theme="light" pauseOnHover autoClose={5000}/>
      <Row className="mt-4">
        <Col xs={12} xl={8}>
          <GeneralInfoForm />
        </Col>

        <Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              <ProfileCardWidget />
            </Col>
            <Col xs={12}>
              <ChoosePhotoWidget
                title="Select profile photo"
                photo={Profile3}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
