import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createNotification = (payload) => {
    return doPrivatePostRequest(payload, '/notifications/create')
}

export const updateNotification = (_doc_id, payload) => {
    return doPrivatePostRequest(payload, `/notifications/update/${_doc_id}`)
}

export const getNotification = (_doc_id) => {
    return doPrivatePostRequest(`/notifications/get/${_doc_id}`)
}

export const getAllNotifications = () => {
    const payload = {
        'st': 'ALL'
    }
    return doPrivatePostRequest(payload, '/notifications/get')
}

export const deleteNotification = (_doc_id) => {
    return doPrivateDelRequest(`/notifications/delete/${_doc_id}`)
}
