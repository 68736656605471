import React, { useState, useEffect } from "react";
import analytics from '../../../functions/analytics'
import { Container, Col, Row, Breadcrumb, Button } from '@themesberg/react-bootstrap';
import { useParams } from "react-router-dom";
import { ListingCard } from '../../../components/ListingCard'
import { getAllPages } from "../../../api/public/pages";
import { getAllBrands } from "../../../api/private/brands";
import { NavigationBar } from "../../../components/frontendNav/Navigation";
import { Footer } from '../../../components/frontendNav/Footer'
import { Routes } from "../../../routes";
import { makeToast } from "../../../functions/toasts";
import { getAllCompanies } from "../../../api/private/companies";

//TODO: Change website link to view company link to take to company page
//TODO: Multi quote type request

export default () => {
  const { brand_name } = useParams();
  const { trading_name } = useParams();
  const [page, setPage] = useState({});
  const [companies, setCompanies] = useState([])
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [locationFilter, setLocationFilter] = useState('');
  const [bbbEEFilter, setBbbEEFilter] = useState('');
  // const [locations, setLocations] = useState([]);
  // const [bbbeeLevels, setBbbeeLevels] = useState([]);
  const [displayedBrandsCount, setDisplayedBrandsCount] = useState(3);

  useEffect(() => {
    loadPages();
    loadBrands();
    loadCompanies()

    analytics.page()
  }, [])

  const loadMoreBrands = () => {
    setDisplayedBrandsCount(prevCount => prevCount + 3);
  };

  const loadBrands = () => {
    getAllBrands().then(response => {
      if (response.data.Result === 'OK') {
        let brands = response.data.Records;
        brands = brands.map(brand => ({
          ...brand,
          brand_name_slug: brand.brand_name.toLowerCase().replace(/ /g, '-').replace(/[^a-z0-9-]/g, '')
        }));
        setBrands(brands);
        setLoading(false);
      } else {
        console.warn(response.data);
        makeToast('error', 'Failed to load posts');
      }
    }).catch(error => {
      console.error(error);
      makeToast('error', 'Failed to load posts');
    });
};

  function loadPages() {
    getAllPages().then(response => {
      if (response.data && response.data.Result === 'OK') {
        const records = response.data.Records;
        const foundPage = records.find(p => p.slug === "brands");
        if (foundPage) {
          setPage(foundPage);
          setLoading(false);
          console.log(`Page found:`, foundPage);
        } else {
          console.warn(`Page with slug "careers" not found.`);
        }
      } else {
        console.warn('Unexpected response structure:', response);
      }
    }).catch(error => {
      console.error('Error loading pages:', error);
    }).finally(() => {
      setLoading(false);
    });
  }

  function loadCompanies() {
    getAllCompanies().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        let companies = _resp.data.Records.map(company => ({
          ...company,
          trading_name_slug: company.trading_name.toLowerCase().replace(/ /g, '-').replace(/[^a-z0-9-]/g, '')
        }));
        setCompanies(companies);
      } else {
        console.warn(_resp.data);
      }
    }).catch((_resp) => {
      console.error(_resp);
      makeToast('error', 'Failed to load listings');
    });
}


  // Handler for location filter change
  const handleLocationChange = (e) => {
    setLocationFilter(e.target.value);
  };

  // Handler for BBBEE Level filter change
  const handleBBBEEChange = (e) => {
    setBbbEEFilter(e.target.value);
  };

  // Filter brands based on selected filters
const filteredBrands = brands.filter(brand => {
  let passLocationFilter = true;
  let passBBBEEFilter = true;

  // Check location filter
  if (locationFilter !== '') {
    passLocationFilter = brand.brand_location.toLowerCase().includes(locationFilter.toLowerCase());
  }

  // Check BBBEE Level filter
  if (bbbEEFilter !== '') {
    passBBBEEFilter = brand.meta.some(metaItem => {
      if (typeof metaItem === 'string') {
        return metaItem.toLowerCase() === bbbEEFilter.toLowerCase();
      }
      return false;
    });
  }

  return passLocationFilter && passBBBEEFilter;
});


  const bbbeeLevels = ['Level 1', 'Level 2', 'Level 3', 'Level 4', 'Exempt'];

  const locations = [
    'Eastern Cape',
    'Free State',
    'Gauteng',
    'KwaZulu-Natal',
    'Limpopo',
    'Mpumalanga',
    'Northern Cape',
    'North West',
    'Western Cape'
  ];


  return (
    <>
        {/*-- Navigation Bar --*/}
        <NavigationBar />
      {loading ? (
        <Row className="justify-content-center">
          <Col>Loading...</Col>
        </Row>
      ) : (
        <>
          <section className="partner-brands text-center align-items-center justify-content-center overflow-hidden pt-5 pt-lg-6 pb-6 pb-lg-6 bg-grey text-dark">
            <Container>
              {page && (
                <h1 className="fw-bold text-dark">{page['title']}</h1>
              )}
              <div className="d-block mb-4 mb-md-0 text-left">
                <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                  <Breadcrumb.Item>You are here:</Breadcrumb.Item>
                  <Breadcrumb.Item>Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Brands</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              {page && (
                <p dangerouslySetInnerHTML={{ __html: page['page_content'] }} />
              )}
            </Container>
          </section>

          <div className="bg-primary table-settings pt-6 pb-6 px-3 px-lg-7">
            <Container>
              <Row className="justify-content-center align-items-center">
                <Col xs={12}>
                  <section className="text-center align-items-center justify-content-center overflow-hidden pt-4 pt-lg-4 pb-4 pb-lg-4 bg-primary text-white">
                    <div className="d-flex flex-column flex-md-row justify-content-center search-box">
                      <select className="form-select me-2 mb-2 mb-md-0" onChange={handleLocationChange}>
                        <option value="">Filter by nearest location</option>
                        {locations.map(location => (
                          <option key={location} value={location}>{location}</option>
                        ))}
                      </select>

                      <select className="form-select me-2 mb-2 mb-md-0" onChange={handleBBBEEChange}>
                        <option value="">Filter by BBEEE Level</option>
                        {bbbeeLevels.map(level => (
                          <option key={level} value={level}>{level}</option>
                        ))}
                      </select>
                    </div>
                  </section>
                </Col>
              </Row>
            </Container>
          </div>

          {/*-- Listing Card for Listings --*/}
          <ListingCard filteredBrands={filteredBrands.slice(0, displayedBrandsCount)} />

          {displayedBrandsCount < filteredBrands.length && (
            <Row className="justify-content-center mt-4 mb-4">
              <Col className="text-center">
                <Button onClick={loadMoreBrands} variant="primary">Load More</Button>
              </Col>
            </Row>
          )}

          {/*-- Footer --*/}
          <Footer />
        </>
      )}
    </>
  );
};