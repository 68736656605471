import React, { useState, useEffect } from 'react';
import { getAllProducts } from '../../api/private/products';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Image, Button, Container, Navbar, Nav } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { Routes } from '../../routes';
import { createQuotes } from "../../api/private/quotes"

const QuoteForm = ({ handleSubmit, productBrands, handleBrandChange, customSingleValue, selectedProduct, handleProductNameChange, filteredProducts, products, setProducts, extractDistinctiveAttributes, selectedProducts, setSelectedProducts, selectedBrand, setSelectedBrand, handleAddNewQuoteProduct }) => {
    const [currentStep, setCurrentStep] = useState(1);

    return (
        <>
            <Container className="mx-0 px-0 py-0 my-0">
                <Row className="justify-content-center overflow-hidden pb-0 mb-0">
                    <Col sm={12} md={8} className="quote-type-wrapper py-4">
                        {/* <div className="quote-table mx-6">
                            <h3 className="mt-4">Products to quote</h3>
                            <hr />
                            <div className="quote-card-wrapper">
                                {submittedData.length === 0 ? (
                                    <p className="text-center"><FontAwesomeIcon icon={faPlus} /> Select your products to quote</p>
                                ) : (
                                    submittedData.map((data, index) => (
                                        <Card key={index} className="product-added-card mb-4">
                                            <Card.Body>
                                                <div className="d-flex justify-content-between col-md-12">
                                                    <div className="product-card-title">
                                                        <h4>{data.productType}</h4>
                                                        <img src="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=" width="100px" />
                                                    </div>
                                                    <div className="product-card-info mx-4 mt-4">
                                                        <p><b>Product Brand:</b> {data.productBrand}</p>
                                                        <p><b>Product:</b> {data.product}</p>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    ))
                                )}
                            </div> */}
                        <div className="quote-card-wrapp">
                            <h3 className="text-center">Products</h3>
                            <form className="add-quote-product mx-0 px-0" onSubmit={handleSubmit}>
                                <h4>Add additional products</h4>
                                <div className="mb-3">
                                    <div className="mb-3">
                                        <label className="form-label">Product Brand:</label>
                                        <Select
                                            className="brand-select"
                                            options={productBrands}
                                            onChange={handleBrandChange}
                                            getOptionLabel={option => (
                                                <div style={{ display: 'inline-flex', alignItems: 'left' }}>
                                                    {option.image && <img src={option.image} style={{ width: 20, height: 20, marginRight: 10 }} alt="" />}{option.label}
                                                </div>
                                            )}
                                            components={{ SingleValue: customSingleValue }}
                                        />

                                    </div>
                                </div>
                                {/* Product Type */}
                                <div className="mb-3">
                                    <label className="form-label">Product:</label>
                                    <Select
                                        className="product-select"
                                        value={selectedProduct}
                                        onChange={handleProductNameChange}
                                        options={filteredProducts}
                                        getOptionLabel={(option) => option.name || "No Name"} // Adjust according to your actual data structure
                                        getOptionValue={(option) => option.id || option.name} // Adjust according to your actual data structure
                                        isClearable={true}
                                        placeholder="Select Product"
                                    />

                                </div>

                                {/* Submit button */}
                                <hr />
                                <div className="mb-3 align-items-center">
                                    <button onClick={handleAddNewQuoteProduct} className="btn mx-2 btn-primary">
                                        Add Product
                                    </button>
                                </div>
                            </form>
                        </div>
                        {/* </div> */}
                    </Col>
                </Row>
            </Container>
        </>
    );
}
export default QuoteForm