import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createPackages = (_email, _userId) => {

    const payload = {
            'linked_user': {
                'email': _email,
                'doc_id': _userId
            },
            'linked_companies': []
    }

    return doPrivatePostRequest(payload, '/website/packages/create')
}

export const updatePackages = (_doc_id, payload) => {
    return doPrivatePostRequest(payload, `/website/packages/update/${_doc_id}`)
}

export const getPackages = (_doc_id) => {
    return doPrivatePostRequest(`/website/packages/get/${_doc_id}`)
}

export const getPackagesById = (_doc_id) => {
    return doPrivatePostRequest({}, `/website/packages/get/${_doc_id}`);
  };  

export const getAllPackages = () => {
    const payload = {
        'st': 'ALL'
    }
    return doPrivatePostRequest(payload, '/website/packages/get')
}

export const deletePackages = (_doc_id) => {
    return doPrivateDelRequest(`/website/packages/delete/${_doc_id}`)
}
