import React, { useState, useEffect } from "react";
import analytics from '../../../functions/analytics'
import { BlogCards } from "../../../components/BlogCards";
import { Col, Row, Card, Image, Button, Container, ListGroup, Tooltip, OverlayTrigger, Form, Navbar, Nav, Badge } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { NavigationBar } from "../../../components/frontendNav/Navigation";
import { Footer } from '../../../components/frontendNav/Footer'
import { Routes } from "../../../routes";

export default () => {

  useEffect(() => {
    analytics.page()
  })

  return (
    <>
      {/*-- Navigation Bar --*/}
      <NavigationBar />

      
      <section className="scrollable-section justify-content-center align-items-center">
        <Container className="d-flex align-items-center text-dark justify-content-center">
          <Nav className="navbar-nav-hover px-0 mx-0 scrollable-nav">
            <Nav.Link as={Link} to={Routes.About.path}>Forklift Sales</Nav.Link>
            <Nav.Link as={Link} to={Routes.Insights.path}>Forklift Hire</Nav.Link>
            <Nav.Link as={Link} to={Routes.Insights.path}>Forklift Repairs</Nav.Link>
            <Nav.Link as={Link} to={Routes.Insights.path}>Forklift Spares</Nav.Link>
            <Nav.Link as={Link} to={Routes.Insights.path}>Forklift Services</Nav.Link>
          </Nav>
        </Container>
        <div className="scroll-indicator"></div>
      </section>

      <section className="bg-white justify-content-center align-items-start">
        <Container className="align-items-center text-dark justify-content-start pt-2 pb-2">
          <Row className="pt-4">
            <Col xs={12} sm={12}>
              <h1 className="fw-bolder lead">Forklifing.co Blog, Insights & Resources</h1>
              <p className="lead">Home to the Latest News, Insights and useful resources</p>
            </Col>
          </Row>
        </Container>
      </section>

      <BlogCards />

      {/*-- Footer --*/}
      <Footer />
    </>
  );
};