import Sec from '../functions/advancedMath';  
export const user_data_key = 'FRK-AUTH-USER';
export const api_token_key = 'FLC-JWT';

const localSessionApi = {
    setUserApiToken: (_token) => {
        console.warn(`localSessionApi -> setUserApiToken / TO BE REMOVED`);
        sessionStorage.setItem(api_token_key, _token);
    },
    getUserApiToken: () => {
        console.warn(`localSessionApi -> getUserApiToken / TO BE REMOVED`);
        const token = sessionStorage.getItem(api_token_key);
        if (token === null) {
            return null;
        } else {
            console.log("Token returned");
            return token;
        }
    },
    setWithExpiry: (key, value) => {
        console.log("Attempting to set key with expiry:", key, value);  // Added to verify input values
        try {
            const now = new Date();
            const item = {
                value: value,
                expiry: now.getTime() + (25 * 24 * 60 * 60 * 1000), // 25 days in milliseconds
            };
            localStorage.setItem(key, JSON.stringify(item));  // Temporarily bypass Sec.encryptData for debugging
            console.log("Set operation successful:", localStorage.getItem(key)); // Check what's actually stored
        } catch (error) {
            console.error("Error setting key with expiry:", key, error);
        }
    },
    getWithExpiry: (key) => {
        try {
            if (!key) {
                console.error("Key is undefined");
                return null;
            }
            const itemStr = localStorage.getItem(key);
            if (!itemStr) {
                console.log("No item found for key:", key);
                return null;
            }
            if (isLikelyEncrypted(itemStr)) {
                const decryptedStr = Sec.decryptData(itemStr);
                const item = JSON.parse(decryptedStr);
                const now = new Date();
                if (now.getTime() > item.expiry) {
                    localStorage.removeItem(key);
                    console.log("Item has expired and has been removed from local storage.");
                    return null;
                }
                return item.value;
            } else {
                console.log("Data does not appear to be encrypted:", itemStr);
                return null; // Or handle unencrypted data as you see fit
            }
        } catch (error) {
            console.error("Failed to decrypt or parse the item from local storage:", error);
            if (key === user_data_key) {
                console.log("Redirecting due to critical data retrieval failure.");
                window.location.replace('/');  
            }
            return null;
        }
    }
};

function isLikelyEncrypted(str) {
    const base64Regex = /^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$/;
    return base64Regex.test(str);
}

export default localSessionApi;
