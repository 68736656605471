import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Routes } from "../routes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCheckCircle, faCertificate, faEye } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Button } from '@themesberg/react-bootstrap';
import { makeToast } from '../functions/toasts';
import { getAllCompanies } from '../api/private/companies';
import badge1 from '../assets/img/bbbee/1.png';
import badge2 from '../assets/img/bbbee/2.png';
import badge3 from '../assets/img/bbbee/3.png';
import badge4 from '../assets/img/bbbee/4.png';
import badge5 from '../assets/img/bbbee/5.png';
import useIsMobile from '../utils/UseIsMobile';

export const CompanyCard = () => {
  const [companies, setCompanies] = useState([]);
  const isMobile = useIsMobile(); // Use the hook to detect mobile

  const bbbee_badges = [
    { level: "1", badge_asset: badge1 },
    { level: "2", badge_asset: badge2 },
    { level: "3", badge_asset: badge3 },
    { level: "4", badge_asset: badge4 },
    { level: "exempt", badge_asset: badge5 },
  ];

  useEffect(() => {
    loadCompanies();
  }, []);

  function loadCompanies() {
    getAllCompanies().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        const companies = _resp.data.Records;
        setCompanies(companies);
      } else {
        console.warn(_resp.data);
      }
    }).catch((_resp) => {
      console.log(_resp);
      makeToast('error', 'Failed to load listings');
    });
  }

  const getBbbeeBadge = (level) => {
    if (level === undefined) {
      const exemptBadge = bbbee_badges.find(badge => badge.level === "exempt");
      return exemptBadge ? exemptBadge.badge_asset : '';
    }
    const badge = bbbee_badges.find(badge => badge.level === level);
    return badge ? badge.badge_asset : '';
  };

  const truncateText = (text, maxWords) => {
    const wordsArray = text.split(' ');
    if (wordsArray.length > maxWords) {
      return `${wordsArray.slice(0, maxWords).join(' ')}...`;
    }
    return text;
  };

  return (
    <section className="company-list mb-4 pb-4 pt-4">
      {companies.length > 0 ? companies.map((company, index) => (
        isMobile ? (
          // Mobile Card Layout
          <Row key={index} className="justify-content-center align-items-center px-3 py-4">
            <Col xs={12}>
              <Card className="h-100">
                <Row className="g-0">
                  <Col xs={4} className="d-flex align-items-center justify-content-center">
                    {company.company_logo ?
                      <img src={`${company.company_logo.image_file}`} alt="Company Logo" className="img-fluid" style={{ maxHeight: '100px' }} /> :
                      <img src="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=" alt="logo" className="img-fluid" style={{ maxHeight: '100px' }} />
                    }
                  </Col>
                  <Col xs={8}>
                    <Card.Body>
                      <Card.Title>
                        {company.trading_name}
                      </Card.Title>
                      <Card.Text>
                        <small>{company.industry_type}</small>
                      </Card.Text>
                      <Card.Text>
                        <FontAwesomeIcon icon={faHome} />&nbsp; <b>Address:</b> {company.location.address}, {company.location.area_code}<br />
                        <FontAwesomeIcon icon={faCertificate} />&nbsp; <b>B-BBEE Level:</b> {company.bbbee.level}<br />
                        <FontAwesomeIcon icon={faEye} />&nbsp; <b>Company Bio:</b> {truncateText(company.company_bio, 10)}
                      </Card.Text>
                    </Card.Body>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="text-center">
                    <Button variant="brand-orange" as={Link} to={Routes.Wizard.path} className="btn btn-primary p-2" style={{ width: '100%' }}>
                      Get A Quote
                    </Button>
                    <Button variant="primary" as={Link} to={`${Routes.CompanyView.path.replace(':trading_name', company.trading_name)}`} className="btn btn-primary p-2 mt-2" style={{ width: '100%' }}>
                      View Company
                    </Button>
                    <Button variant="primary" as={Link} to={`${Routes.CompanyView.path.replace(':slug', company.slug)}`} className="btn btn-primary p-2 mt-2" style={{ width: '100%' }}>
                      View Brands
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        ) : (
          // Desktop Card Layout (same as before)
          <Row key={index} className="justify-content-center align-items-center px-6 py-4">
            <Col xs={12} md={12} lg={12} xl={12}>
              <Card className="h-100">
                <Row className="g-0">
                  <Col md={3} className="mx-0 my-0 py-0 px-0 d-flex align-items-start justify-content-start">
                    {company.company_logo ?
                      <img src={`${company.company_logo.image_file}`} alt="Company Logo" style={{ objectFit: 'contain', maxHeight: '400px' }} /> :
                      <img src="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=" alt="logo" style={{ objectFit: 'contain', maxHeight: '400px' }} />
                    }
                  </Col>
                  <Col md={6}>
                    <Card.Body>
                      <Card.Title>
                        {company.trading_name}
                      </Card.Title>
                      <Card.Text>
                        <small>{company.industry_type}</small>
                      </Card.Text>
                      <Card.Text>
                        <ul style={{ listStyle: 'none', marginLeft: '0px', paddingLeft: '0px' }} className="company_card_details">
                          <li><FontAwesomeIcon icon={faHome} />&nbsp; <b>Address:</b> {company.location.address}, {company.location.area_code}</li>                        
                          <li><FontAwesomeIcon icon={faCertificate} />&nbsp; <b>B-BBEE Level:</b> {company.bbbee.level}</li>
                          <li><FontAwesomeIcon icon={faEye} />&nbsp; <b>Company Bio:</b> {truncateText(company.company_bio, 20)}</li>
                        </ul>
                        <hr />
                        <div style={{fontSize:'14px'}} className="offering-section mt-2">
                          <Row>
                            <Col md={4}>
                              <FontAwesomeIcon style={{color: 'green'}} icon={faCheckCircle} /> Offering Placeholder<br/>
                              <FontAwesomeIcon style={{color: 'green'}} icon={faCheckCircle} /> Offering Placeholder<br/>
                              <FontAwesomeIcon style={{color: 'green'}} icon={faCheckCircle} /> Offering Placeholder
                            </Col>
                            <Col md={4}>
                              <FontAwesomeIcon style={{color: 'green'}} icon={faCheckCircle} /> Offering Placeholder<br/>
                              <FontAwesomeIcon style={{color: 'green'}} icon={faCheckCircle} /> Offering Placeholder<br/>
                              <FontAwesomeIcon style={{color: 'green'}} icon={faCheckCircle} /> Offering Placeholder
                            </Col>
                            <Col md={4}>
                              <FontAwesomeIcon style={{color: 'green'}} icon={faCheckCircle} /> Offering Placeholder<br/>
                              <FontAwesomeIcon style={{color: 'green'}} icon={faCheckCircle} /> Offering Placeholder<br/>
                              <FontAwesomeIcon style={{color: 'green'}} icon={faCheckCircle} /> Offering Placeholder
                            </Col>
                          </Row>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Col>
                  <Col>
                    <div style={{ flex: '0 0 25%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', borderLeft: '1px solid #d7d7d7', maxHeight: '400px', height: '320px' }}>
                      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: '10px 0px 10px 10px' }}>
                        <Button variant="brand-orange" as={Link} to={Routes.Wizard.path} className="btn btn-primary p-3 cardBtn" style={{ marginBottom: '10px', borderRadius: '0px 0px 0px 0px' }}>
                          Get A Quote
                        </Button>
                      </div>
                      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: '10px 0px 10px 10px' }}>
                        <Button variant="primary" as={Link} to={`${Routes.CompanyView.path.replace(':trading_name', company.trading_name)}`} className="btn btn-primary p-3 cardBtn" style={{ marginBottom: '10px', borderRadius: '0px 0px 0px 0px' }}>
                          View Company
                        </Button>
                      </div>
                      <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: '10px 0px 10px 10px' }}>
                        <Button variant="primary" as={Link} to={`${Routes.CompanyView.path.replace(':slug', company.slug)}`} className="btn btn-primary p-3 cardBtn" style={{ marginBottom: '10px', borderRadius: '0px 0px 0px 0px' }}>
                          View Brands
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        )
      )) : (
        <Row className="justify-content-center py-9">
          <Col className="text-center">
            <h3>No companies could be found.</h3>
          </Col>
        </Row>
      )}
    </section>
  );
};
