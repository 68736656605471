import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Button, Col, Breadcrumb, Card } from '@themesberg/react-bootstrap';
import { NavigationBar } from "../../../components/frontendNav/Navigation";
import { Footer } from '../../../components/frontendNav/Footer';
import { getAllCompanies } from '../../../api/private/companies';
import { makeToast } from "../../../functions/toasts";
import { getAllBrands } from "../../../api/public/brands";
import analytics from '../../../functions/analytics';
import { BlogCards } from "../../../components/BlogCards";
import { Routes } from "../../../routes";

export default () => {
    const { trading_name } = useParams();
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [brands, setBrands] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadCompanies();
        loadBrands();
        analytics.page();
    }, [trading_name]);

    const loadBrands = () => {
        getAllBrands().then(response => {
            if (response.data.Result === 'OK') {
                let brands = response.data.Records;
                // Transform brand names to URL-friendly slugs
                brands = brands.map(brand => ({
                    ...brand,
                    brand_name: brand.brand_name.toLowerCase().replace(/ /g, '-').replace(/[^a-z0-9-]/g, '')
                }));
                setBrands(brands);
                setLoading(false);
            } else {
                console.warn(response.data);
                makeToast('error', 'Failed to load posts');
            }
        }).catch(error => {
            console.error(error);
            makeToast('error', 'Failed to load posts');
        });
    };

    const loadCompanies = () => {
        getAllCompanies().then(response => {
            if (response.data.Result === 'OK') {
                const companies = response.data.Records;
                const decodedTradingName = decodeURIComponent(trading_name.replace(/-/g, ' '));
                const company = companies.find(company => company.trading_name.toLowerCase() === decodedTradingName.toLowerCase());
                setSelectedCompany(company);
                if (company) {
                    setBrands(company.brands);
                }
                setLoading(false);
            } else {
                console.warn(response.data);
                makeToast('error', 'Failed to load posts');
            }
        }).catch(error => {
            console.error(error);
            makeToast('error', 'Failed to load posts');
        });
    };

    return (
        <>
            <NavigationBar />
            <section className="partner-brands text-center align-items-center justify-content-center overflow-hidden pt-5 pt-lg-6 pb-6 pb-lg-6 bg-light text-dark">
                <div style={{ width: '90%', textAlign: 'left', paddingLeft: '10%' }}>
                    {selectedCompany && (
                        <h1 className="fw-bold text-dark">{selectedCompany.trading_name}</h1>
                    )}
                    <div className="d-block mb-4 mb-md-0">
                        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                            <Breadcrumb.Item>You are here:</Breadcrumb.Item>
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                            <Breadcrumb.Item>Companies</Breadcrumb.Item>
                            {selectedCompany ? (
                                <Breadcrumb.Item active>
                                    {selectedCompany.trading_name}
                                </Breadcrumb.Item>
                            ) : (
                                <Breadcrumb.Item active>
                                    Read
                                </Breadcrumb.Item>
                            )}
                        </Breadcrumb>
                    </div>
                </div>
            </section>

            <section className="bg-white justify-content-center align-items-start">
                <Container className="pt-6">
                    {loading ? (
                        <Row className="justify-content-center">
                            <Col>Loading...</Col>
                        </Row>
                    ) : selectedCompany ? (
                        <Row>
                            <Col md={8}>
                                <h5>About <b style={{ color: "#DA6220" }}>{`${selectedCompany.trading_name}`}</b></h5>
                                <p>{selectedCompany.company_bio ? selectedCompany.company_bio : ''}</p>
                                <span className="company-info">
                                    <div className="c-item">
                                        <b>Company Type</b>
                                        <p>{selectedCompany.company_type ? selectedCompany.company_type : ''}</p>
                                    </div>
                                    <div className="c-item">
                                        <b>Registered Address</b>
                                        <p>{selectedCompany.registered_address ? selectedCompany.registered_address : ''}</p>
                                    </div>
                                    <div className="c-item">
                                        <b>Website Address</b>
                                        <p as={Link} external to={selectedCompany.website_url}>{selectedCompany.website_url ? selectedCompany.website_url : ''}</p>
                                    </div>
                                </span>
                            </Col>
                            <Col md={4}>
                                {selectedCompany.company_logo ?
                                    <img src={selectedCompany.company_logo} alt={selectedCompany.trading_name} className="img-fluid" />
                                    :
                                    <img src="https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=" className="m-2 company-page-logo" />
                                }
                            </Col>
                            <Col className="pt-6 mt-4 company-blog text-center" md={12}>
                                <h3>Latest {`${selectedCompany.trading_name}`} Insights</h3>
                                <BlogCards />
                            </Col>
                        </Row>
                    ) : (
                        <Row className="justify-content-center">
                            <Col>No post found.</Col>
                        </Row>
                    )}
                </Container>
                <hr />
                <Container className="pt-6 pb-6">
                    <Row className="brands-view-title mb-3">
                        <Col md={12}>
                            <h3>Forklift Brands</h3>
                        </Col>
                    </Row>
                    <Row>
                        {brands.length > 0 ? (
                            brands
                                .filter(brand => brand.brand_image !== '') // Filter out brands without an image
                                .slice(0, 3) // Limit the display to 3 cards
                                .map((brand, index) => (
                                    <Col key={index} className="mx-1" md={3}>
                                        <Card className="brands-card-view" as={Link} to={`${Routes.BrandsView.path.replace(':brand_name', brand.brand_name)}`}>
                                            <Card.Header className="brand-card-header m-0 p-0">
                                                <Row>
                                                    <Col md={4} className="brand-image-wrapper">
                                                        <img src={brand.brand_image} alt={brand.brand_name} height="75px" width="75px" />
                                                    </Col>
                                                    <Col md={8} as={Link} to={`${Routes.BrandsView.path.replace(':brand_name', brand.brand_name)}`}>
                                                        <h4>{brand.brand_name}</h4>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body className="px-0">
                                                <p><strong>Founded</strong> {brand.founded}</p>
                                                <p><strong>Locations</strong> {brand.brand_location}</p>
                                                {/* {brand.cta && brand.cta.map((cta, ctaIndex) => (
                            <Button key={ctaIndex} variant="primary" href={cta.link}>{cta.button_text}</Button>
                        ))} */}
                                                <small>{brand.brand_description &&
                                                    brand.brand_description
                                                        .split(' ')
                                                        .slice(0, 20)
                                                        .join(' ')
                                                }</small>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                        ) : (
                            <div>No linked brands</div>
                        )}

                        <div className="card-divider"></div>
                    </Row>
                </Container>
            </section>

            <Footer />
        </>
    );
};
