import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { Col, Row, Form, Button, Modal, Tab, Tabs, Table } from '@themesberg/react-bootstrap';
import { useDropzone } from 'react-dropzone';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';
import { createMessage } from '../../api/private/messages';
import { getAllUsers } from '../../api/private/users';
import { hashData, unhashData } from '../../utils/crypto';
import { getAllBrands } from '../../api/private/brands';
import { getAllProducts } from '../../api/private/products';
import AlertComponent from '../../functions/alerts';

const AddQuoteModal = forwardRef(({
    showModal,
    handleCloseModal,
    createQuote,
    updateQuote,
    initialQuoteData,
    isEditMode,
    userRole,
    suppliers,
}, ref) => {
    const [quoteLines, setQuoteLines] = useState([]);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [users, setUsers] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [alert, setAlert] = useState({ type: '', message: '' });
    const [messages, setMessages] = useState([]);
    const [isContactDetailsVisible, setIsContactDetailsVisible] = useState(false);
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [brands, setBrands] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const vatRate = 0.15;

    const getDefaultData = () => ({
        user_profile: {
            name: "",
            surname: "",
            company: "",
            designation: "",
            contact_number: "",
            email_address: "",
            physical_address: ""
        },
        assistance_required: {},
        products: [],
        brands: [],
        quote_type: "",
        quote_status: "in progress",
        suppliers: [],
        supplier_notes: "",
        quote_details: {
            quote_value: ""
        }
    });

    const [quoteData, setQuoteData] = useState(getDefaultData());

    useImperativeHandle(ref, () => ({
        openQuoteModal(data) {
            setQuoteData({
                ...getDefaultData(),
                ...data,
                quote_details: {
                    ...getDefaultData().quote_details,
                    ...data.quote_details,
                    quote_status: data.quote_status || 'in progress',
                },
            });
            setQuoteLines(data.quote_details?.quote_lines || []);
            setSelectedSuppliers(data.suppliers || []);
            setUploadedDocuments(data.quote_docs || []); // Set uploaded documents from initialQuoteData
            setSelectedBrands(data.brands || []);
            setSelectedProducts(data.products || []);
            setSelectedMessage(data);
        }
    }));

    useEffect(() => {
        if (showModal) {
            loadBrands();
            loadProducts();
            loadUsers(); // Load users when modal is opened
        }
    }, [showModal]);

    const loadBrands = () => {
        getAllBrands()
            .then((response) => {
                if (response.data.Result === 'OK') {
                    const records = response.data.Records;
                    const brandOptions = records.map(record => ({
                        value: record._id,
                        label: record.brand_name,
                        thumbnail: record.brand_image,
                    }));
                    setBrands(records);
                    setSelectedBrands([]);
                } else {
                    console.warn(response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const loadProducts = () => {
        getAllProducts()
            .then((response) => {
                if (response.data.Result === 'OK') {
                    const records = response.data.Records.map(record => ({
                        _id: record._id,
                        name: record.name || "",
                        slug: record.slug || "",
                        price: record.price || "",
                        brand: record.brand || "",
                        description: record.description || "",
                        meta_data: record.meta_data || [],
                        category: record.category || "",
                        thumbnail: record.thumbnail || "",
                    }));
                    setProducts(records);
                    setSelectedProducts([]);
                } else {
                    console.warn(response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const matchProducts = () => {
        return quoteData.products.map(productId => {
            const product = products.find(p => p._id === productId);
            const meta = Array.isArray(product?.meta_data) ? product.meta_data : [];
            const type = meta.find(metaItem => metaItem.key === 'Type')?.value || 'N/A';
            
            console.log("product:", product);
            return {
                ...product,
                brandName: brands.find(b => b._id === product?.brand)?.brand_name,
                type
            };
        });
    };

    const addLine = () => {
        setQuoteLines([...quoteLines, { sku: '', name: '', description: '', rate: 0, quantity: 0, price: 0 }]);
    };

    const updateLine = (index, field, value) => {
        const newLines = [...quoteLines];
        newLines[index][field] = value;
        if (field === 'rate' || field === 'quantity') {
            newLines[index]['price'] = newLines[index]['rate'] * newLines[index]['quantity'];
        }
        setQuoteLines(newLines);
    };

    const removeLine = (index) => {
        const newLines = [...quoteLines];
        newLines.splice(index, 1);
        setQuoteLines(newLines);
    };

    const updateQuoteStatus = (status) => {
        setQuoteData((prevData) => ({
            ...prevData,
            quote_details: {
                ...prevData.quote_details,
                quote_status: status,
            },
        }));
        console.log(`Quote status updated to: ${status}`);
    };

    const downloadQuotePDF = () => {
        const doc = new jsPDF();

        doc.text('Quote Details', 10, 10);
        doc.text(`Name: ${quoteData.user_profile.name}`, 10, 20);
        doc.text(`Surname: ${quoteData.user_profile.surname}`, 10, 30);
        doc.text(
            `Company: ${userRole === 'admin' && isContactDetailsVisible
                ? unhashData(quoteData.user_profile.company)
                : 'Hidden'
            }`,
            10,
            40
        );
        doc.text(`Designation: ${quoteData.user_profile.designation}`, 10, 50);
        doc.text(
            `Contact Number: ${userRole === 'admin' && isContactDetailsVisible
                ? unhashData(quoteData.user_profile.contact_number)
                : 'Hidden'
            }`,
            10,
            60
        );
        doc.text(
            `Email: ${userRole === 'admin' && isContactDetailsVisible
                ? unhashData(quoteData.user_profile.email_address)
                : 'Hidden'
            }`,
            10,
            70
        );
        doc.text(
            `Physical Address: ${userRole === 'admin' && isContactDetailsVisible
                ? unhashData(quoteData.user_profile.physical_address)
                : 'Hidden'
            }`,
            10,
            80
        );

        const tableColumn = ['Sku', 'Name', 'Description', 'Rate', 'Quantity', 'Price'];
        const tableRows = [];

        quoteLines.forEach((line) => {
            const lineData = [
                line.sku,
                line.name,
                line.description,
                line.rate.toString(),
                line.quantity.toString(),
                line.price ? line.price.toFixed(2) : '0.00',
            ];
            tableRows.push(lineData);
        });

        autoTable(doc, {
            head: [tableColumn],
            body: tableRows,
            startY: 90,
        });

        const subtotal = quoteLines.reduce((acc, line) => acc + (line.price || 0), 0);
        const vat = subtotal * vatRate;
        const total = subtotal + vat;

        let yOffset = doc.lastAutoTable.finalY + 10;
        doc.text(`Subtotal: $${subtotal.toFixed(2)}`, 14, yOffset);
        doc.text(`VAT (${(vatRate * 100).toFixed(0)}%): $${vat.toFixed(2)}`, 14, yOffset + 10);
        doc.text(`Total: $${total.toFixed(2)}`, 14, yOffset + 20);

        const dateStr = new Date().toISOString().slice(0, 10);
        const pdfData = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfData);
        setPdfUrl(pdfUrl);

        doc.save(`quote_details_${dateStr}.pdf`);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        // Check if quoteData.user_profile is defined
        if (!quoteData.user_profile) {
            console.error('user_profile is undefined:', quoteData);
            setAlert({ type: 'error', message: 'User profile data is missing. Please fill in the required fields.' });
            return;
        }
    
        // Check if required fields are filled
        if (!quoteData.user_profile.email_address) {
            console.error('Email address is missing:', quoteData.user_profile);
            setAlert({ type: 'error', message: 'Email address is required.' });
            return;
        }
    
        const hashedData = {
            ...quoteData,
            user_profile: {
                ...quoteData.user_profile,
                company: hashData(quoteData.user_profile.company),
                contact_number: hashData(quoteData.user_profile.contact_number),
                email_address: hashData(quoteData.user_profile.email_address),
                physical_address: hashData(quoteData.user_profile.physical_address),
            },
            suppliers: selectedSuppliers,
        };
    
        if (isEditMode && initialQuoteData && initialQuoteData._id) {
            updateQuote(initialQuoteData._id, hashedData);
        } else {
            createQuote(hashedData);
            console.log(hashedData);
        }
        handleCloseModal();
    };    

    const handleChange = (section, field, value) => {
        if (section === 'supplier_notes') {
            setQuoteData((prevState) => ({
                ...prevState,
                [section]: value,
            }));
        } else {
            setQuoteData((prevState) => ({
                ...prevState,
                [section]: {
                    ...prevState[section],
                    [field]: value,
                },
            }));
        }
    };

    const handleAssistanceChange = (e) => {
        const value = e.target.value;
        handleChange('assistance_required', 'value', value);
    };

    const onDrop = useCallback((acceptedFiles) => {
        // Retrieve user details from localStorage
        const userDetails = JSON.parse(localStorage.getItem('user_details'));

        // Check if userDetails is defined and has the necessary information
        if (userDetails && userDetails.company) {
            const supplierCompany = userDetails.company; // Extract the supplier (company) information

            // Handle file upload
            const newDocuments = acceptedFiles.map((file) => ({
                _id: Date.now() + file.name, // Generate unique ID for the document
                supplier: supplierCompany,
                fileName: file.name,
                uploadDate: new Date(),
                url: URL.createObjectURL(file), // Temporary URL for display
                status: 'Pending',
            }));
            setUploadedDocuments((prevDocs) => [...prevDocs, ...newDocuments]);
        } else {
            console.error('User details not found in localStorage or missing company information.');
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await fetch('http://localhost:5001/upload', {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const data = await response.json();
                    const fileUrl = data.url;

                    // Update state with the file URL
                    setPdfUrl(fileUrl);
                    updateQuoteStatus('quoting');

                    const newDocument = {
                        _id: Date.now() + file.name, // generate a unique ID for the document
                        supplier: quoteData.user_profile.company,
                        fileName: file.name,
                        uploadDate: new Date(),
                        url: fileUrl,
                        status: 'Pending',
                    };

                    setUploadedDocuments([...uploadedDocuments, newDocument]);
                } else {
                    console.error('File upload failed');
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    const handleAcceptQuote = (docId) => {
        updateQuoteStatus('approved');
        const updatedDocuments = uploadedDocuments.map((doc) =>
            doc._id === docId ? { ...doc, status: 'Approved' } : doc
        );
        setUploadedDocuments(updatedDocuments);
    };

    const formatAssistanceRequired = (assistanceRequired) => {
        if (typeof assistanceRequired === 'object' && assistanceRequired !== null) {
            return Object.entries(assistanceRequired)
                .filter(([_, value]) => value)
                .map(([key, value]) => ({
                    key: key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()),
                    value:
                        typeof value === 'object'
                            ? Array.isArray(value)
                                ? value.join('\n')
                                : JSON.stringify(value, null, 2).replace(/[{}"]/g, '')
                            : value,
                }));
        }
        return [];
    };

    const loadUsers = () => {
        getAllUsers()
            .then((response) => {
                if (response.data.Result === 'OK') {
                    setUsers(response.data.Records);
                } else {
                    console.warn(response.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const addProduct = () => {
        const newProduct = { title: '', brand: '', type: '' };
        setQuoteData((prevData) => ({
            ...prevData,
            products: [...prevData.products, newProduct],
        }));
    };

    const updateProduct = (index, field, value) => {
        const newProducts = [...quoteData.products];
        newProducts[index][field] = value;
        setQuoteData((prevData) => ({
            ...prevData,
            products: newProducts,
        }));
    };

    const removeProduct = (index) => {
        const newProducts = [...quoteData.products];
        newProducts.splice(index, 1);
        setQuoteData((prevData) => ({
            ...prevData,
            products: newProducts,
        }));
    };

    function generateRandomId() {
        const randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        return randomString;
    }

    const handleSendToClient = () => {
        const supplierEmail = quoteData.user_profile.email_address;
        const supplierName = quoteData.user_profile.company;
        const quoteIdLastSixDigits = initialQuoteData?._id.slice(-6);
        const subject = `${supplierName} - Quote ${quoteIdLastSixDigits}`;
        const body = quoteData.supplier_notes;
        const conversationId = generateRandomId(); // Generate a new conversation ID

        // Extract user details from localStorage
        const userDetails = JSON.parse(localStorage.getItem('user_details'));
        const createdByEmail = userDetails.email_address;
        const createdByDocId = userDetails._id;

        if (supplierEmail) {
            const payload = {
                "_id": "",
                "_rev": "",
                "created_on": new Date().toISOString(),
                "created_by": {
                    "email": createdByEmail || 'Forklifting Co.',
                    "doc_id": createdByDocId // Using extracted user details
                },
                "conversation_id": conversationId, // Use the generated conversation ID
                "title": selectedMessage ? selectedMessage.title : `Quote Details - ${quoteIdLastSixDigits}`,
                "subject": subject,
                "body": body,
                "recipient": {
                    "doc_id": "", // Assuming you have the recipient's document ID
                    "email": quoteData.user_profile.email_address
                },
                "received_time": new Date().toISOString(),
                "attachments": [{
                    "filename": `quote_${quoteIdLastSixDigits}.pdf`,
                    "url": `assets/docs/quote_${quoteIdLastSixDigits}.pdf`
                }],
                "meta_data": {},
                "status": 'new',
                "quoteRef": quoteData._id
            };

            console.log('Payload to be sent:', payload);

            createMessage(payload)
                .then((response) => {
                    if (response.data.Result === 'SUCCESS') {
                        console.log('Message sent successfully:', response.data); // Log successful response
                        const updatedMessages = [...messages, response.data.newMessage];
                        setMessages(updatedMessages);
                        setNewMessage('');
                        setAlert({ type: 'success', message: 'Message sent successfully' });
                    } else {
                        console.log('Failed to send message:', response.data); // Log failure response
                        setAlert({ type: 'error', message: 'Failed to send message: ' + response.data.Message + ' Please contact Forklifting Co.' });
                    }
                })
                .catch((error) => {
                    console.error('Error sending message:', error); // Log the error
                    setAlert({ type: 'error', message: 'Failed to send message. Please contact Forklifting Co.' });
                });
        } else {
            setAlert({ type: 'error', message: 'Supplier email not found' });
        }
    };

    const toggleContactDetailsVisibility = () => {
        setIsContactDetailsVisible(!isContactDetailsVisible);
    };

    const handleBrandChange = (selectedOptions) => {
        setSelectedBrands(selectedOptions);
        setQuoteData({
            ...quoteData,
            brands: selectedOptions,
        });
    };

    const handleProductChange = (selectedOptions) => {
        setSelectedProducts(selectedOptions);
        setQuoteData({
            ...quoteData,
            products: selectedOptions,
        });
    };

    const getStatusStyle = (status) => {
        switch (status) {
            case 'undefined':
                return { backgroundColor: '#c7c7c7' };
            case 'in progress':
                return { backgroundColor: 'yellow' };
            case 'quoting':
                return { backgroundColor: 'orange' }
            case 'approved':
                return { backgroundColor: 'green' };
            case 'rejected':
                return { backgroundColor: 'red' };
            default:
                return {};
        }
    };

    return (
        <>
            <Modal size="xl" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditMode ? 'Update Quote' : 'Add Quote'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Tabs defaultActiveKey="general">
                            <Tab eventKey="general" title="General">
                                <Form>
                                    <Row className="mt-3">
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={quoteData.user_profile?.name || ''}
                                                    onChange={(e) =>
                                                        handleChange('user_profile', 'name', e.target.value)
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Surname</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={quoteData.user_profile?.surname}
                                                    onChange={(e) =>
                                                        handleChange('user_profile', 'surname', e.target.value)
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Company</Form.Label>
                                                <Form.Control
                                                    type={
                                                        userRole === 'admin' || (userRole === 'supplier' && isContactDetailsVisible)
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    value={quoteData.user_profile?.company}
                                                    onChange={(e) =>
                                                        handleChange('user_profile', 'company', e.target.value)
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Job Function</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={quoteData.user_profile?.designation}
                                                    onChange={(e) =>
                                                        handleChange('user_profile', 'designation', e.target.value)
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Contact Number</Form.Label>
                                                <Form.Control
                                                    type={
                                                        userRole === 'admin' && isContactDetailsVisible
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    value={quoteData.user_profile?.contact_number}
                                                    onChange={(e) =>
                                                        handleChange('user_profile', 'contact_number', e.target.value)
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Email Address</Form.Label>
                                                <Form.Control
                                                    type={
                                                        userRole === 'admin' && isContactDetailsVisible
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    value={quoteData.user_profile?.email_address}
                                                    onChange={(e) =>
                                                        handleChange('user_profile', 'email_address', e.target.value)
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Physical Address</Form.Label>
                                                <Form.Control
                                                    type={
                                                        userRole === 'admin' && isContactDetailsVisible
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    as={
                                                        userRole === 'admin' && isContactDetailsVisible
                                                            ? 'textarea'
                                                            : 'input'
                                                    }
                                                    value={quoteData.user_profile?.physical_address}
                                                    onChange={(e) =>
                                                        handleChange('user_profile', 'physical_address', e.target.value)
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {userRole === 'admin' && (
                                        <Button
                                            className="mt-3 mb-3"
                                            variant="secondary"
                                            onClick={toggleContactDetailsVisibility}
                                        >
                                            {isContactDetailsVisible ? 'Hide' : 'Show'} Contact Details
                                        </Button>
                                    )}
                                </Form>
                            </Tab>
                            <Tab eventKey="info" title="Quote Info">
                                <Row className="mt-3">
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Quote Type</Form.Label>
                                            <Form.Control type="text" value={quoteData.quote_type} readOnly />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Quote Status</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={quoteData.quote_details.quote_status}
                                                readOnly
                                                className="text-black"
                                                style={getStatusStyle(quoteData.quote_details.quote_status)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <h4 className="mt-3 mb-3">Quote Request Details</h4>
                                <hr />
                                <Tabs defaultActiveKey="assistance" className="mt-3">
                                    <Tab eventKey="assistance" title="Assistance Required">
                                        <Form.Group className="mt-3 mb-3">
                                            <Form.Label>Assistance Required</Form.Label>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>Form Field</th>
                                                        <th>Response Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {formatAssistanceRequired(quoteData.assistance_required).map(
                                                        ({ key, value }) => (
                                                            <tr key={key}>
                                                                <td>{key}</td>
                                                                <td style={{ whiteSpace: 'pre-wrap' }}>{value || 'N/A'}</td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Form.Group>
                                    </Tab>
                                    <Tab eventKey="products" title="Products">
                                        <Table striped bordered hover className="mb-3 mt-3">
                                            <thead>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Brand</th>
                                                    <th>Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {matchProducts().map((product, index) => (
                                                    <tr key={index}>
                                                        <td>{product?.name || 'N/A'}</td>
                                                        <td>{product?.brandName || 'N/A'}</td>
                                                        <td>{product?.type || 'N/A'}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Tab>
                                    <Tab eventKey="suppliers" title="Supplier Quote Details">
                                        <Row className="mt-3">
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Quote Value</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder="R0.00"
                                                        value={quoteData.quote_details.quote_value}
                                                        onChange={(e) =>
                                                            handleChange('quote_details', 'quote_value', e.target.value)
                                                        }
                                                    />
                                                    <div className="description">
                                                        Please enter the full quote value here.
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Upload File</Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        onChange={handleFileChange}
                                                    />
                                                    <div className="description">
                                                        Please upload your quote here.
                                                    </div>
                                                </Form.Group>
                                                {pdfUrl && (
                                                    <div className="d-flex justify-content-end align-items-end mx-2 mb-3">
                                                        <a
                                                            href={pdfUrl}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            View Quote
                                                        </a>
                                                    </div>
                                                )}
                                            </Col>
                                            <Col md={12}>
                                                <Form.Label>Upload brochures</Form.Label>
                                                <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
                                                    <input {...getInputProps()} />
                                                    {isDragActive ? (
                                                        <p>Drop the files here ...</p>
                                                    ) : (
                                                        <p>Drag & drop some files here, or click to select files</p>
                                                    )}
                                                </div>
                                                <ul>
                                                    {uploadedDocuments.map((file, index) => (
                                                        <li key={index}>{file.fileName}</li>
                                                    ))}
                                                </ul>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Supplier Notes</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={5}
                                                        value={quoteData.supplier_notes}
                                                        placeholder="Please add your notes to the client"
                                                        onChange={(e) =>
                                                            handleChange(
                                                                'supplier_notes',
                                                                null,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={2}>
                                                <Button
                                                    variant="secondary"
                                                    className="mb-3 mt-2"
                                                    onClick={handleSendToClient}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faPlaneDeparture}
                                                    />{' '}
                                                    Send To Buyer
                                                </Button>
                                            </Col>
                                            <AlertComponent className="my-3 mx-3" type={alert.type} message={alert.message} />
                                        </Row>
                                    </Tab>
                                </Tabs>
                            </Tab>
                            {(userRole === 'admin' || userRole === 'buyer') && (
                                <Tab eventKey="files" title="Quotes Received">
                                    <Table striped bordered hover className="mt-3">
                                        <thead>
                                            <tr>
                                                <th>Supplier</th>
                                                <th>File Name</th>
                                                <th>Upload Date</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {uploadedDocuments.length > 0 ? (
                                                uploadedDocuments.map((doc) => (
                                                    <tr key={doc._id}>
                                                        <td>{doc.supplier}</td>
                                                        <td>{doc.fileName}</td>
                                                        <td>{new Date(doc.uploadDate).toLocaleDateString()}</td>
                                                        <td>{doc.status}</td>
                                                        <td>
                                                            <a href={doc.url} className="ms-2" target="_blank" rel="noopener noreferrer">
                                                                View
                                                            </a>
                                                            <a href={doc.url} className="ms-2" download={doc.fileName} target="_blank" rel="noopener noreferrer">
                                                                Download
                                                            </a>
                                                            <Button variant="success" className="ms-2" onClick={() => handleAcceptQuote(doc._id)}>
                                                                Accept
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="5" className="text-center">
                                                        No quotes received
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Tab>
                            )}
                        </Tabs>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                            <Button variant="brand-orange" type="submit">
                                {isEditMode ? 'Save Quote' : 'Add Quote'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
});

export default AddQuoteModal;
