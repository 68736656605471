import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';
import { FaqsTables } from "../../../components/tables/FaqsTables";
import AddFaqsModal from "../../../components/modals/AddFaqModal";
import { getAllFaqs, createFaqs, updateFaqs } from "../../../api/private/faqs";
import { makeToast } from "../../../functions/toasts";
import AlertComponent  from '../../../functions/alerts'
import { useUserRole } from "../../../contexts/UserRoleContext";
import { ToastContainer, toast} from "react-toastify";

export default () => {
  const { userRole } = useUserRole();
  const [faqs, setFaqs] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [pageMounted, setPageMounted] = useState(false)
  const [initialFaqData, setInitialFaqData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [alert, setAlert] = useState({ type: '', message: '' });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    if (!pageMounted) {
      setPageMounted(true)
      loadFaqs();
    }
  }, [pageMounted])

  function loadFaqs() {
    getAllFaqs().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        const records = _resp.data.Records
        setFaqs(records)
        setAlert({type: 'success', message:`Loaded faqs successfully`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      } else {
        console.warn(_resp.data)
        setAlert({type: 'error', message:`Loaded faqs unsuccessfully`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      }
    }).catch((_resp) => {
      console.log(_resp)
      setAlert({type: 'error', message:`Loaded faqs unsuccessfully`})
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    })
  }

  function createFaq(_obj) {
    createFaqs(_obj).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        makeToast('success', 'Created new FAQ');
        setShowModal(false);
        setAlert({type: 'success', message:`Created faq successfully`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadFaqs();  
      } else {
        console.warn(_resp.data);
        setAlert({type: 'error', message:`Faq not created successfully`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadFaqs();
      }
    }).catch((_resp) => {
      console.log(_resp);
      makeToast('error', 'Failed to create FAQ');
      setAlert({type: 'error', message:`Faq not created successfully`})
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      loadFaqs();
    });
  }
  
  function updateFaq(faqData) {
    if (!faqData._id) {
        console.error("Missing FAQ _id for update operation");
        makeToast('error', 'Missing FAQ identifier');
        return;
    }

    updateFaqs(faqData._id, faqData).then(_resp => {
        if (_resp.data.Result === 'OK') {
            makeToast('success', 'FAQ updated successfully');
            setShowModal(false);
            setAlert({type: 'success', message:`Updated faqs successfully`})
            setTimeout(() => setAlert({ type: '', message: '' }), 5000);
            loadFaqs();
        } else {
            console.warn(_resp.data);
            setAlert({type: 'error', message:`Failed to update faqs`})
            setTimeout(() => setAlert({ type: '', message: '' }), 5000);
            loadFaqs();
        }
    }).catch(_resp => {
        console.log(_resp);
        makeToast('error', 'Failed to update FAQ');
        setAlert({type: 'error', message:`Failed to update faqs`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadFaqs();
    });
}

  function deleteFaq(faq){
    deleteFaq(faq).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        makeToast('success', 'Deleted user')
        setAlert({type: 'success', message:`Deleted faq successfully`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadFaqs();
      } else {
        console.warn(_resp.data)
        setAlert({type: 'error', message:`Could not delete faq`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadFaqs();
      }
    }).catch((_resp) => {
      console.log(_resp)
      makeToast('error', 'Failed to delete user')
      setAlert({type: 'error', message:`Could not delete faq`})
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      loadFaqs();
    })
  }

  const handleEditFaq = (faq) => {
    console.log("Editing FAQ:", faq);
    setInitialFaqData(faq);
    setIsEditMode(true);
    setShowModal(true);
};

  const handleAddFaq = () => {
    setInitialFaqData(null);
    setIsEditMode(false);
    setShowModal(true);
  };
  
    return (
      <>
      <ToastContainer hideProgressBar={true} theme="light" pauseOnHover autoClose={5000}/>
       <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>CMS</Breadcrumb.Item>
            <Breadcrumb.Item active>Faqs</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Faqs</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
            <Button className="btn btn-primary" onClick={handleAddFaq}>Add Faq</Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            {/* <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup> */}
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <AlertComponent type={alert.type} message={alert.message} />      

      <FaqsTables faqs={faqs}
      deleteFaq={deleteFaq} handleEditFaq={handleEditFaq}
      />
      <AddFaqsModal showModal={showModal} 
      handleCloseModal={handleCloseModal} 
      createFaq={createFaq} 
      updateFaq={updateFaq} 
      initialFaqData={initialFaqData}
      isEditMode={isEditMode}
      />
      </>
  );
};
