import React, { useState, useEffect } from 'react';
import { Routes } from "../../routes";
import { Col, Row, Form, Button, Table, Modal } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const CompareModal = ({ onClose, selectedProducts, offerings }) => {
    const [cookies, setCookie] = useCookies(['productRequest', 'bulkRequestQuote']);

    const handleRequestQuote = (productId, productName) => {
        setCookie('productRequest', JSON.stringify({ _id: productId, name: productName }), { path: '/' });
    };

    const handleBulkRequestQuote = () => {
        const productRequests = selectedProducts.map(product => ({
            _id: product._id,
            name: product.name
        }));
        setCookie('bulkProductRequests', JSON.stringify(productRequests), { path: '/' });
    };

    const stripHtml = (html) => {
        const temporalDivElement = document.createElement("div");
        temporalDivElement.innerHTML = html;
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    }

    const getPreviewText = (html, maxLength) => {
        const plainText = stripHtml(html);
        return plainText.length > maxLength ? `${plainText.slice(0, maxLength)}...` : plainText;
    }

    const calculateItemWidth = () => {
        const numProducts = selectedProducts.length;
        return numProducts > 0 ? `${100 / numProducts}%` : '100%';
    };

    return (
        <Modal
            show={true}
            onHide={onClose}
            dialogClassName="modal-fullscreen"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Compare Products
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="h-100">
                    <Col className="d-flex justify-space-evenly compare-cols text-center" md={12}>
                        {selectedProducts.map(offering => (
                            <div className="compare-item mx-5" key={offering._id} style={{ width: calculateItemWidth() }}>
                                <div className="compare-title-wrapper">
                                    <img
                                        src={offering.image || "https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM="}
                                        alt={offering.name}
                                        width="100%"
                                        className="product-image"
                                    />
                                    <h4 className="compare-title mt-3">{offering.name}</h4>
                                </div>
                                <div className="compare-detail">
                                    <Table className="table-striped compare-table">
                                        <tbody>
                                            <tr>
                                                <td className="fw-bold strong">Description</td>
                                                <td style={{ whiteSpace: 'normal', textAlign: "left", padding: "3px" }}>
                                                    {getPreviewText(offering.description, 100)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold strong">Load Capacity</td>
                                                <td>{offering.meta_data?.load || 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold strong">Load Centre</td>
                                                <td>{offering.meta_data?.load_centre || 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td className="fw-bold strong">Lift Reach</td>
                                                <td>{offering.meta_data?.reach || 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2">
                                                    <Button className="btn btn-primary mb-3 mx-1" onClick={() => handleRequestQuote(offering._id, offering.name)}>
                                                        Request A Quote
                                                    </Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        ))}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleBulkRequestQuote} disabled={selectedProducts.length === 0}>
                    Request Quotes for All
                </Button>
                <Button variant="secondary" onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CompareModal;
