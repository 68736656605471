import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
    app: 'forklifting-co',
    plugins: [
      googleAnalytics({
        measurementIds: ['G-H66Y0Q2FBC']
      })
    ]
  })
  
export default analytics