import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, ButtonGroup, Breadcrumb, Dropdown } from '@themesberg/react-bootstrap';
import { getAllTransactions } from "../../../api/private/transactions";
import { makeToast } from "../../../functions/toasts";
import { TransactionsTable } from "../../../components/tables/TransactionTables";
import { ToastContainer } from "react-toastify";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);

  const loadTransactions = () => {
    getAllTransactions().then((_resp) => {
      if (_resp.data.Result === 'SUCCESS') {
        setTransactions(_resp.data.Records);
      } else {
        console.warn(_resp.data);
        makeToast('error', 'Failed to load transactions');
      }
    }).catch((_resp) => {
      console.error(_resp);
      makeToast('error', 'Failed to load transactions');
    });
  };

  useEffect(() => {
    loadTransactions(); // Fetch transactions when component mounts
  }, []);

  const viewTransactionDetails = (transactionData) => {
    console.log("View transaction details:", transactionData);
  };

  const downloadStatement = () => {
    console.log("Downloading statement...");
  };

  return (
    <>
      <ToastContainer hideProgressBar={true} theme="light" pauseOnHover autoClose={5000} />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Subscriptions</Breadcrumb.Item>
            <Breadcrumb.Item active>Transactions</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Transactions</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
            <Button className="btn btn-primary" onClick={downloadStatement}>Download Statement</Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}></Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <TransactionsTable
        transactions={transactions}
        loadTransactions={loadTransactions} // Pass the function here
        viewTransactionDetails={viewTransactionDetails}
      />
    </>
  );
};

export default Transactions;
