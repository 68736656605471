import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, ButtonGroup, Breadcrumb, Dropdown } from '@themesberg/react-bootstrap';
import { getAllProducts, createProduct, updateProduct, deleteProduct } from "../../../api/private/products";
import { ProductsTables } from "../../../components/tables/ProductsTables";
import { getAllOfferings } from "../../../api/private/offerings";
import { getAllBrands } from "../../../api/private/brands";
import { makeToast } from "../../../functions/toasts";
import AddProductModal from "../../../components/modals/AddProductModal";

export default () => {
  const [products, setProducts] = useState([]);
  const [offerings, setOfferings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialProductData, setInitialProductData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [alert, setAlert] = useState({ type: '', message: '' });
  const [brands, setBrands] = useState([]);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    loadProducts();
    loadOfferings();
    loadBrands();
  }, []);

  function loadBrands() {
    getAllBrands().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        const records = _resp.data.Records;
        setBrands(records);
        setAlert({ type: 'success', message: 'Loaded brands successfully' });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      } else {
        console.warn(_resp.data);
        setAlert({ type: 'error', message: 'Failed to load brands' });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      }
    }).catch((_resp) => {
      console.log(_resp);
      makeToast('error', 'Failed to load brands');
      setAlert({ type: 'error', message: 'Failed to load brands' });
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    });
  }

  const loadProducts = () => {
    getAllProducts().then((_resp) => {
      console.log("Products API response:", _resp.data); // Log API response
      if (_resp.data.Result === 'OK') {
        const records = _resp.data.Records.map(record => ({
          _id: record._id,
          name: record.name || "", // Provide a default value if undefined
          slug: record.slug || "",
          price: record.price || "",
          brand: record.brand || "",
          category: record.category || "",
          description: record.description || "",
          meta_data: record.meta_data || {},
        }));
        console.log("Setting products:", records); // Log records being set
        setProducts(records);
        setLoading(false);
        setAlert({ type: 'success', message: 'Loaded products successfully' });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      } else {
        console.warn(_resp.data);
        setAlert({ type: 'error', message: 'Failed to load products' });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      }
    }).catch((_resp) => {
      console.log(_resp);
      setLoading(false);
      makeToast('error', 'Failed to load products');
      setAlert({ type: 'error', message: 'Failed to load products' });
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    });
  };

  const loadOfferings = () => {
    getAllOfferings().then((_resp) => {
      console.log("Offerings API response:", _resp.data); // Log API response
      if (_resp.data.Result === 'OK') {
        const records = _resp.data.Records;
        console.log("Setting offerings:", records); // Log records being set
        setOfferings(records);
        setLoading(false);
      } else {
        console.warn(_resp.data);
      }
    }).catch((_resp) => {
      console.log(_resp);
      setLoading(false);
      makeToast('error', 'Failed to load offerings');
    });
  };

  const createProducts = (productData) => {
    const payload = {
      name: productData.name,
      slug: productData.slug,
      price: productData.price,
      brand: productData.brand,
      category: productData.category,
      description: productData.description,
      meta_data: productData.meta_data
    };

    console.log("Creating product with payload:", payload); // Log payload
    createProduct(payload).then(_resp => {
      console.log("Create Product API response:", _resp.data); // Log API response
      if (_resp.data.Result === 'SUCCESS') {
        makeToast('success', 'Created new product');
        handleCloseModal();
        setAlert({ type: 'success', message: 'Added product successfully' });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadProducts();
      } else {
        console.warn(_resp.data);
        setAlert({ type: 'error', message: 'Failed to add product' });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      }
    }).catch(_resp => {
      console.log(_resp);
      makeToast('error', 'Failed to create product');
      setAlert({ type: 'error', message: 'Failed to add product' });
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    });
  };

  const updateProducts = (productData) => {
    const payload = {
      name: productData.name,
      slug: productData.slug,
      price: productData.price,
      brand: productData.brand,
      category: productData.category,
      description: productData.description,
      meta_data: productData.meta_data
    };

    console.log("Updating product with payload:", payload); // Log payload
    updateProduct(productData._id, payload).then(_resp => {
      console.log("Update Product API response:", _resp.data); // Log API response
      if (_resp.data.Result === 'SUCCESS') {
        makeToast('success', 'Product updated successfully');
        setShowModal(false);
        setAlert({ type: 'success', message: 'Updated product successfully' });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadProducts();
      } else {
        console.warn(_resp.data);
        setAlert({ type: 'error', message: 'Failed to update product' });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      }
    }).catch(_resp => {
      console.log(_resp);
      makeToast('error', 'Failed to update product');
      setAlert({ type: 'error', message: 'Failed to update product' });
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    });
  };

  const deleteProducts = (productId) => {
    deleteProduct(productId).then((_resp) => {
      console.log("Delete Product API response:", _resp.data); // Log API response
      if (_resp.data.Result === 'SUCCESS') {
        makeToast('success', 'Deleted product');
        setAlert({ type: 'success', message: 'Deleted product successfully' });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadProducts();
      } else {
        console.warn(_resp.data);
        setAlert({ type: 'error', message: 'Failed to delete product' });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      }
    }).catch((_resp) => {
      console.log(_resp);
      makeToast('error', 'Failed to delete product');
      setAlert({ type: 'error', message: 'Failed to delete product' });
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    });
  };

  const handleEditProduct = (product) => {
    setInitialProductData(product);
    setIsEditMode(true);
    setShowModal(true);
  };

  const handleAddProduct = () => {
    setInitialProductData(null);
    setIsEditMode(false);
    setShowModal(true);
  };

  console.log("Products state:", products); // Log products state

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Brands</Breadcrumb.Item>
            <Breadcrumb.Item active>Products</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Products</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
            <Button className="btn btn-primary" onClick={handleAddProduct}>Add Product</Button>
          </ButtonGroup>
        </div>
      </div>

      <ProductsTables brands={brands} products={products} deleteProducts={deleteProducts} handleEditProduct={handleEditProduct} />
      <AddProductModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        createProducts={createProducts}
        updateProducts={updateProducts}
        initialProductData={initialProductData}
        offerings={offerings}
        isEditMode={isEditMode}
      />
    </>
  );
};
