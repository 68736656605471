import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faEdit, faEllipsisH, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Table, Dropdown, Pagination, ButtonGroup, Form } from '@themesberg/react-bootstrap';

const ValueChange = ({ value }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return (
    value ? <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}
      </span>
    </span> : "--"
  );
};

const ITEMS_PER_PAGE = 10;

export const MediaTables = ({ images }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    filename: "",
    uploadedBy: "",
    description: "",
    dateCreated: "",
    dateModified: ""
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const filteredImages = images.filter(image => {
    return (
      (filters.filename === "" || (image.image_name ?? "").toLowerCase().includes(filters.filename.toLowerCase())) &&
      (filters.uploadedBy === "" || (image.author ?? "").toLowerCase().includes(filters.uploadedBy.toLowerCase())) &&
      (filters.description === "" || (image.description ?? "").toLowerCase().includes(filters.description.toLowerCase())) &&
      (filters.dateCreated === "" || (image.dateCreated ?? "").toLowerCase().includes(filters.dateCreated.toLowerCase())) &&
      (filters.dateModified === "" || (image.dateModified ?? "").toLowerCase().includes(filters.dateModified.toLowerCase()))
    );
  });

  const currentTableData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredImages.slice(startIndex, endIndex);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageCount = Math.ceil(filteredImages.length / ITEMS_PER_PAGE);
  const items = [];
  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
        {number}
      </Pagination.Item>
    );
  }

  const TableRow = (props) => {
    const { image_name, image_url, fileSize, description, fileType, dateCreated, dateModified, author } = props;

    return (
      <tr>
        <td><img src={image_url} height="50px" width="50px" alt="thumbnail" /></td>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center">
            <div className="title-col">
              <span className="h6">{image_name}</span><br />
              {fileType ? fileType : "-"}<br />
              {fileSize ? fileSize : "-"}
            </div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">
          {author ? author : "-"}
        </td>
        <td className="fw-bold border-0">
          {description ? description : "-"}
        </td>
        <td className="border-0">
          {dateCreated ? dateCreated : "-"}
        </td>
        <td className="fw-bold border-0">
          {dateModified ? dateModified : "-"}
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="tableNav">
              <Dropdown.Item>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger">
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Thumbnail</th>
              <th className="border-0">Filename</th>
              <th className="border-0">Uploaded By</th>
              <th className="border-0">Description</th>
              <th className="border-0">Date Created</th>
              <th className="border-0">Date Modified</th>
              <th className="border-bottom">Action</th>
            </tr>
            <tr>
              <th></th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Filename"
                  name="filename"
                  value={filters.filename}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Uploaded By"
                  name="uploadedBy"
                  value={filters.uploadedBy}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Description"
                  name="description"
                  value={filters.description}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Date Created"
                  name="dateCreated"
                  value={filters.dateCreated}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Date Modified"
                  name="dateModified"
                  value={filters.dateModified}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredImages.length > 0 ? (
              currentTableData().map((image) => <TableRow key={`image-${image.id}`} {...image} />)
            ) : (
              <tr>
                <td colSpan="7" className="text-center">
                  No records could be found at this time. Create your first image by clicking Add Image
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination className="justify-content-center">{items}</Pagination>
      </Card.Body>
    </Card>
  );
};
