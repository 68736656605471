import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createCategories = (payload) => {
    return doPrivatePostRequest(payload, '/website/categories/create')
}

export const updateCategories = () => {

    const payload = {}

    return doPrivatePostRequest(payload, '/website/categories/update')
}

export const getCategories = (_doc_id) => {
    return doPrivatePostRequest(`/website/categories/get/${_doc_id}`)
}

export const getAllCategories = () => {
    const payload = {
        'st': 'ALL'
    }
    return doPrivatePostRequest(payload, '/website/categories/get')
}

export const deleteCategories = (_doc_id) => {
    return doPrivateDelRequest(`/website/categories/delete/${_doc_id}`)
}
