import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { Container, FormControl, Card, Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Accordion, Tab, Nav } from '@themesberg/react-bootstrap';
import { createMessage, getAllMessages, updateMessage } from '../../../api/private/messages';
import { getAllUsers } from "../../../api/private/users";
import './Messages.css'; // Ensure to create a CSS file for custom styles
import AddQuoteModal from "../../../components/modals/AddQuoteModal";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useIsMobile from "../../../utils/UseIsMobile"; // Import your custom hook

export default () => {
  const [messages, setMessages] = useState({});
  const [filteredMessages, setFilteredMessages] = useState({});
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [activeKey, setActiveKey] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showNewMessageForm, setShowNewMessageForm] = useState(false);
  const [users, setUsers] = useState([]);
  const [recipient, setRecipient] = useState('');
  const [subject, setSubject] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalQuoteData, setModalQuoteData] = useState(null);
  const quotesRef = useRef();
  const isMobile = useIsMobile(); // Use the hook to detect mobile

  useEffect(() => {
    loadMessages();
    loadUsers();
  }, []);

  useEffect(() => {
    filterMessages();
  }, [messages, searchTerm, startDate, endDate]);

  function generateRandomId() {
    const randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    return randomString;
  }

  const loadMessages = () => {
    getAllMessages().then(response => {
      if (response.data.Result === 'OK') {
        const groupedMessages = response.data.Records.reduce((acc, message) => {
          if (!acc[message.conversation_id]) {
            acc[message.conversation_id] = [];
          }
          acc[message.conversation_id].push(message);
          return acc;
        }, {});

        Object.keys(groupedMessages).forEach(key => {
          groupedMessages[key] = groupedMessages[key].sort((a, b) => new Date(b.received_time) - new Date(a.received_time));
        });

        setMessages(groupedMessages);
        setFilteredMessages(groupedMessages);
      } else {
        console.error('Failed to load messages:', response.data.Message);
      }
    }).catch(error => {
      console.error('Error fetching messages:', error);
    });
  };

  const loadUsers = () => {
    getAllUsers().then(response => {
      if (response.data.Result === 'OK') {
        setUsers(response.data.Records);
      } else {
        console.error('Failed to load users:', response.data.Message);
      }
    }).catch(error => {
      console.error('Error fetching users:', error);
    });
  };

  const filterMessages = () => {
    const searchTermLower = searchTerm.toLowerCase();
    const filtered = Object.keys(messages).reduce((acc, conversationId) => {
      const filteredConversation = messages[conversationId].filter(message => {
        const createdByEmail = message.created_by?.email?.toLowerCase() || '';
        const subject = message.subject?.toLowerCase() || '';
        const body = message.body?.toLowerCase() || '';
        const matchesSearchTerm =
          createdByEmail.includes(searchTermLower) ||
          subject.includes(searchTermLower) ||
          body.includes(searchTermLower);
        const matchesDateRange =
          (!startDate || new Date(message.received_time) >= new Date(startDate)) &&
          (!endDate || new Date(message.received_time) <= new Date(endDate));
        return matchesSearchTerm && matchesDateRange;
      });
      if (filteredConversation.length > 0) {
        acc[conversationId] = filteredConversation;
      }
      return acc;
    }, {});
    setFilteredMessages(filtered);
  };

  const handleSelectMessage = (conversationId, messageId) => {
    if (messages[conversationId]) {
      setSelectedConversationId(conversationId);
      const selectedMessage = messages[conversationId].find(message => message._id === messageId);
      if (selectedMessage) {
        setSelectedMessage(selectedMessage);
        setActiveKey(selectedMessage._id);
        updateMessageStatus(selectedMessage._id);
      } else {
        console.error(`Message with ID ${messageId} not found in conversation ${conversationId}`);
      }
    } else {
      console.error(`Conversation with ID ${conversationId} not found`);
    }
  };

  const updateMessageStatus = (messageId) => {
    updateMessage(messageId, { status: 'read' }).then(response => {
      if (response.data.Result !== 'SUCCESS') {
        console.error('Failed to update message status:', response.data.Message);
      }
    }).catch(error => {
      console.error('Error updating message status:', error);
    });
  };

  const createNewMessage = () => {
    const userDetails = JSON.parse(localStorage.getItem('user_details'));
    const createdByEmail = userDetails.email_address;
    const createdByDocId = userDetails._id;

    const conversationId = selectedConversationId || generateRandomId();

    const payload = {
      created_by: {
        email: createdByEmail,
        doc_id: createdByDocId
      },
      conversation_id: conversationId,
      title: `${newMessage.slice(0,30)}...`,
      subject: subject,
      body: newMessage,
      recipient: recipient,
      received_time: new Date().toISOString(),
      attachments: [],
      meta_data: [],
      status: 'new',
      quoteRef: ''
    };

    createMessage(payload).then(response => {
      if (response.data.Result === 'OK' || response.data.Result === 'SUCCESS') {
        toast.success('Message sent successfully');
        setShowNewMessageForm(false);
        setSubject('');
        setNewMessage('');
        setRecipient('');

        const updatedMessages = { ...messages };
        if (!updatedMessages[conversationId]) {
          updatedMessages[conversationId] = [];
        }
        updatedMessages[conversationId].push(response.data.newMessage);
        setMessages(updatedMessages);
        setFilteredMessages(updatedMessages);
      } else {
        toast.error('Failed to send message');
      }
    }).catch(error => {
      console.error('Error sending message:', error);
      toast.error('Failed to send message');
    });
  };

  const hasNewMessage = (messageList) => {
    return messageList.some(message => message.status === 'new');
  };

  const handleNewMessageClick = () => {
    setSelectedConversationId(null);
    setShowNewMessageForm(true);
  };

  const handleAccordionClick = (messageId) => {
    setActiveKey(activeKey === messageId ? null : messageId);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const openQuoteModal = (quoteRef) => {
    setModalQuoteData(quoteRef);
    setShowModal(true);
  };

  return (
    <Container fluid>
      <ToastContainer hideProgressBar={true} theme="light" pauseOnHover autoClose={5000} />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Inbox</Breadcrumb.Item>
            <Breadcrumb.Item active>Chat</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Chat</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
            <Button variant="outline-primary" size="sm" onClick={handleNewMessageClick}>New Message</Button>
          </ButtonGroup>
        </div>
      </div>

      {isMobile ? (
        <Tab.Container defaultActiveKey="inbox">
          <Nav variant="pills" className="flex-row justify-content-center mb-3">
            <Nav.Item>
              <Nav.Link eventKey="inbox">Inbox</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="message">Message</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="inbox">
              <Card className="bg-light email-list" style={{ height: 'calc(100vh - 200px)', overflowY: 'scroll' }}>
                <Card.Body>
                  <small className="px-2">Search your mail</small>
                  <InputGroup className="mb-0 px-2 pt-2">
                    <FormControl
                      placeholder="Search by sender, subject, etc."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </InputGroup>
                  <div className="mb-3 d-flex px-2 justify-content-between">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="Start Date"
                      className="form-control"
                    />
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="End Date"
                      className="form-control ml-2"
                    />
                  </div>
                  {Object.keys(filteredMessages).length > 0 ? (
                    Object.entries(filteredMessages).map(([conversationId, messageList]) => (
                      <Card
                        className={`my-0 email-item ${selectedConversationId === conversationId ? 'active' : ''} ${hasNewMessage(messageList) ? 'new' : ''}`}
                        key={conversationId}
                        onClick={() => handleSelectMessage(conversationId, messageList[0]._id)}
                        style={{ cursor: 'pointer' }}
                      >
                        <Card.Body className="email-card-body">
                          <div className="email-info p-2">
                            <div>
                              <h5 className="email-title">{messageList[0].created_by?.email || 'Unknown'}</h5>
                              <h6 className="email-subject">{messageList[0].subject || 'No Subject'}</h6>
                            </div>
                            <p className="mx-2 email-date">{new Date(messageList[0].created_on).toLocaleDateString()}</p>
                            <small>{messageList.length} messages</small>
                          </div>
                        </Card.Body>
                      </Card>
                    ))
                  ) : (
                    <h5 className="text-center my-4">No messages found.</h5>
                  )}
                </Card.Body>
              </Card>
            </Tab.Pane>
            <Tab.Pane eventKey="message">
              {showNewMessageForm ? (
                <div className="new-message-form">
                  <h5>Create New Message</h5>
                  <Form>
                    <Form.Group>
                      <Form.Label>Recipient</Form.Label>
                      <Form.Control as="select" value={recipient} onChange={(e) => setRecipient(e.target.value)}>
                        <option value="">Select Recipient</option>
                        {users.map(user => (
                          <option key={user._id} value={user._id}>{user.email_address}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Subject</Form.Label>
                      <Form.Control type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Message</Form.Label>
                      <Form.Control as="textarea" rows={4} value={newMessage} onChange={(e) => setNewMessage(e.target.value)} />
                    </Form.Group>
                    <Button variant="primary" className="my-2 mx-1" onClick={createNewMessage}>Send</Button>
                    <Button variant="secondary" className="my-2 mx-1" onClick={() => setShowNewMessageForm(false)}>Cancel</Button>
                  </Form>
                </div>
              ) : (
                selectedConversationId && selectedMessage && filteredMessages[selectedConversationId] ? (
                  <>
                    <div className="quote-box">
                      <div className="quote-info align-items-center justify-content-start pt-2">
                        <p><strong>Subject:</strong> {selectedMessage.subject || selectedConversationId}<br />
                          <strong>Date Created:</strong> {new Date(selectedMessage.received_time).toLocaleString()}</p>
                      </div>
                      {selectedMessage.quote_ref ? (
                        <Button className="view-quote-btn" variant="primary" onClick={() => openQuoteModal(selectedMessage.quote_ref)}>View Quote</Button>
                      ) : null}
                    </div>

                    <InputGroup className="mb-3">
                      <FormControl
                        placeholder="Type your message here..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                      />
                      <Button variant="outline-secondary"><FontAwesomeIcon icon={faPaperclip} /></Button>
                      <Button variant="outline-secondary" onClick={createNewMessage}>
                        Send
                      </Button>
                    </InputGroup>
                    <Accordion defaultActiveKey={activeKey} onSelect={handleAccordionClick}>
                      {filteredMessages[selectedConversationId].map(message => (
                        <Accordion.Item eventKey={message._id} key={message._id}>
                          <Accordion.Header>
                            {message.title}
                            {message.status === 'new' && <span className="badge badge-primary ml-2">New</span>}
                          </Accordion.Header>
                          <Accordion.Body>
                            <p>{message.body}</p>
                            {message.attachments && message.attachments.length > 0 && (
                              <div>
                                <strong>Attachments:</strong>
                                <ul>
                                  {message.attachments.map((attachment, index) => (
                                    <li key={index}>
                                      <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                        {attachment.filename}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </>
                ) : (
                  <h5 className="text-center my-4">Select a message to view</h5>
                )
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      ) : (
        <Row>
          <Col xs={12} md={4} lg={4}>
            <Card className="bg-light email-list" style={{ height: 'calc(100vh - 200px)', overflowY: 'scroll' }}>
              <Card.Body>
                <small className="px-2">Search your mail</small>
                <InputGroup className="mb-0 px-2 pt-2">
                  <FormControl
                    placeholder="Search by sender, subject, etc."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </InputGroup>
                <div className="mb-3 d-flex px-2 justify-content-between">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Start Date"
                    className="form-control"
                  />
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="End Date"
                    className="form-control ml-2"
                  />
                </div>
                {Object.keys(filteredMessages).length > 0 ? (
                  Object.entries(filteredMessages).map(([conversationId, messageList]) => (
                    <Card
                      className={`my-0 email-item ${selectedConversationId === conversationId ? 'active' : ''} ${hasNewMessage(messageList) ? 'new' : ''}`}
                      key={conversationId}
                      onClick={() => handleSelectMessage(conversationId, messageList[0]._id)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Card.Body className="email-card-body">
                        <div className="email-info p-2">
                          <div>
                            <h5 className="email-title">{messageList[0].created_by?.email || 'Unknown'}</h5>
                            <h6 className="email-subject">{messageList[0].subject || 'No Subject'}</h6>
                          </div>
                          <p className="mx-2 email-date">{new Date(messageList[0].created_on).toLocaleDateString()}</p>
                          <small>{messageList.length} messages</small>
                        </div>
                      </Card.Body>
                    </Card>
                  ))
                ) : (
                  <h5 className="text-center my-4">No messages found.</h5>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={8} lg={8}>
            {showNewMessageForm ? (
              <div className="new-message-form">
                <h5>Create New Message</h5>
                <Form>
                  <Form.Group>
                    <Form.Label>Recipient</Form.Label>
                    <Form.Control as="select" value={recipient} onChange={(e) => setRecipient(e.target.value)}>
                      <option value="">Select Recipient</option>
                      {users.map(user => (
                        <option key={user._id} value={user._id}>{user.email_address}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={4} value={newMessage} onChange={(e) => setNewMessage(e.target.value)} />
                  </Form.Group>
                  <Button variant="primary" className="my-2 mx-1" onClick={createNewMessage}>Send</Button>
                  <Button variant="secondary" className="my-2 mx-1" onClick={() => setShowNewMessageForm(false)}>Cancel</Button>
                </Form>
              </div>
            ) : (
              selectedConversationId && selectedMessage && filteredMessages[selectedConversationId] ? (
                <>
                  <div className="quote-box">
                    <div className="quote-info align-items-center justify-content-start pt-2">
                      <p><strong>Subject:</strong> {selectedMessage.subject || selectedConversationId}<br />
                        <strong>Date Created:</strong> {new Date(selectedMessage.received_time).toLocaleString()}</p>
                    </div>
                    {selectedMessage.quote_ref ? (
                      <Button className="view-quote-btn" variant="primary" onClick={() => openQuoteModal(selectedMessage.quote_ref)}>View Quote</Button>
                    ) : null}
                  </div>

                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder="Type your message here..."
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                    />
                    <Button variant="outline-secondary"><FontAwesomeIcon icon={faPaperclip} /></Button>
                    <Button variant="outline-secondary" onClick={createNewMessage}>
                      Send
                    </Button>
                  </InputGroup>
                  <Accordion defaultActiveKey={activeKey} onSelect={handleAccordionClick}>
                    {filteredMessages[selectedConversationId].map(message => (
                      <Accordion.Item eventKey={message._id} key={message._id}>
                        <Accordion.Header>
                          {message.title}
                          {message.status === 'new' && <span className="badge badge-primary ml-2">New</span>}
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>{message.body}</p>
                          {message.attachments && message.attachments.length > 0 && (
                            <div>
                              <strong>Attachments:</strong>
                              <ul>
                                {message.attachments.map((attachment, index) => (
                                  <li key={index}>
                                    <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                                      {attachment.filename}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </>
              ) : (
                <h5 className="text-center my-4">Select a message to view</h5>
              )
            )}
          </Col>
        </Row>
      )}

      <AddQuoteModal
        ref={quotesRef}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        createQuote={() => {}}  // Define createQuote function here
        updateQuote={() => {}}  // Define updateQuote function here
        initialQuoteData={modalQuoteData}
        isEditMode={!!modalQuoteData}
        userRole={"admin"}  // Define userRole here
        suppliers={[]}  // Define suppliers array here
      />
    </Container>
  );
};
