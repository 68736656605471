// utils/crypto.js
import CryptoJS from 'crypto-js';

const secretKey = 'your-secret-key';

export const hashData = (data) => {
  return CryptoJS.AES.encrypt(data, secretKey).toString();
};

export const unhashData = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
