import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';
import { PostsTables } from "../../../components/tables/PostsTables";
import AddPostModal from "../../../components/modals/AddPostModal";
import { getAllPosts, createPosts, updatePosts, deletePosts } from "../../../api/private/posts";
import { makeToast } from "../../../functions/toasts";
import AlertComponent from "../../../functions/alerts";
import { useUserRole } from "../../../contexts/UserRoleContext";
import {ToastContainer, toast} from "react-toastify"

export default () => {
  const { userRole } = useUserRole();
  const [posts, setPosts] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [pageMounted, setPageMounted] = useState(false)
  const [initialPostData, setInitialPostData] = useState(null)
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [isEditMode, setIsEditMode] = useState(false)
  const [alert, setAlert] = useState({ type: '', message: '' });

  useEffect(() => {
    loadPosts();
  }, []);

  const loadPosts = () => {
    getAllPosts().then(response => {
      if (response.data.Result === 'OK') {
        setPosts(response.data.Records);
        setAlert({type: 'success', message:`Loaded posts successfully`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      } else {
        console.warn(response.data);
        makeToast('error', 'Failed to load users');
        setAlert({type: 'error', message:`Loaded posts unsuccessfully`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      }
    }).catch(error => {
      console.error(error);
      makeToast('error', 'Failed to load users');
    });
  };

  function createPost(postData) {
    createPosts(postData).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        handleCloseModal()
        setAlert({ type: 'success', message: `${_resp.data.Message}` })
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadPosts()
      } else {
        console.warn(_resp.data);
        console.log(postData)
        handleCloseModal()
        setAlert({ type: 'success', message: `${_resp.data.Message}` })
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadPosts()
      }
    }).catch((_resp) => {
      console.error(_resp);
      makeToast('error', 'Failed to create page');
      setAlert({ type: 'error', message: `${_resp.data.Message}` })
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    });
  }

  function updatePost(postData) {
    if (!postData._id) {
      console.error("Document ID is missing or undefined.");
      makeToast('error', 'Document ID is missing');
      return;
    }

    const payload = {
      title: postData.title,
      seo_title: postData.seo_title,
      seo_description: postData.seo_description,
      slug: postData.slug,
      author: postData.author,
      canonical_link: postData.canonical_link,
      excerpt: postData.excerpt,
      status: postData.status,
      date_published: postData.date_published,
      created_on: postData.created_on,
      page_content: postData.page_content,
      image_doc_id: postData.image_doc_id,
      component_content: postData.component_content,
    };

    updatePosts(postData._id, payload).then(response => {
      if (response.data.Result === 'SUCCESS') {
        makeToast('success', 'Post updated successfully');
        handleCloseModal();
        setAlert({type: 'success', message:`Updated post successfully`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadPosts();
      } else {
        console.warn(response.data);
        setAlert({type: 'error', message:`Failed to update post`})
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        makeToast('error', response.data.Message || 'Failed to update post');
      }
    }).catch(error => {
      console.error('Failed to update post:', error);
      makeToast('error', 'Failed to update post');
      setAlert({type: 'error', message:`Failed to update post`})
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    });
  }

  function deletePost(post) {
    deletePosts(post).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        makeToast('success', 'Deleted page')
        setAlert({ type: 'success', message: `${_resp.data.Message}` })
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadPosts();
      } else {
        console.warn(_resp.data)
        setAlert({ type: 'success', message: `${_resp.data.Message}` })
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadPosts()
      }
    }).catch((_resp) => {
      console.log(_resp)
      makeToast('error', 'Failed to delete page')
      setAlert({ type: 'error', message: `${_resp.data.Message}` })
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    })
  }

  const handleEditPost = (post) => {
    console.log("Selected post for editing:", post);
    if (!post._id) {
      makeToast('error', 'Selected post does not have an ID');
      return;
    }
    setInitialPostData(post);
    setIsEditMode(true);
    setShowModal(true);
  };  

  const handleAddPost = () => {
    setInitialPostData(null);
    setIsEditMode(false);
    setShowModal(true);
  };

  return (
    <>
      <ToastContainer hideProgressBar={true} theme="light" pauseOnHover autoClose={5000}/>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>CMS</Breadcrumb.Item>
            <Breadcrumb.Item active>Posts</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Posts</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
            <Button className="btn btn-primary" onClick={handleAddPost}>Add Post</Button>
          </ButtonGroup>
        </div>
      </div>

      <AlertComponent type={alert.type} message={alert.message} />

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            {/* <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup> */}
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <PostsTables posts={posts} handleShowModal={handleShowModal} deletePost={deletePost} handleEditPost={handleEditPost} />
      <AddPostModal initialPostData={initialPostData} createPost={createPost}
        updatePost={updatePost} showModal={showModal} handleCloseModal={handleCloseModal} isEditMode={isEditMode} />
    </>
  );
};
