import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createPages = (payload) => {
    return doPrivatePostRequest(payload, '/website/pages/create')
}

export const updatePages = (_doc_id, payload) => {
    return doPrivatePostRequest(payload, `/website/pages/update/${_doc_id}`)
}

export const getPages = (_doc_id) => {
    return doPrivatePostRequest(`/website/pages/get/${_doc_id}`)
}

export const getAllPages = () => {
    const payload = {
        'st': 'ALL'
    }
    return doPrivatePostRequest(payload, '/website/pages/get')
}

export const deletePages = (_doc_id) => {
    return doPrivateDelRequest(`/website/pages/delete/${_doc_id}`)
}
