import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest, doPrivateFilePostRequest } from "./requests";

export const createImage = (_payload) => {
    const payload = {
        "image_name": _payload['image_name'],
        "caption": _payload['caption'],
        "description": _payload['description'],
        "image_alt": _payload['image_alt']
    }
    return doPrivatePostRequest(payload, '/website/images/create')
}

export const updateImage = () => {
    const payload = {}
    return doPrivatePostRequest(payload, '/website/images/update')
}

export const uploadImage = (_doc_id, _file) => {
    let payload = new FormData();
    payload.append('file', _file);
    return doPrivateFilePostRequest(payload, `/website/images/upload/${_doc_id}`)
}

export const getImage = (_doc_id) => {
    return doPrivateGetRequest(`/website/images/get/${_doc_id}`)
}

export const getAllImages = () => {
    const payload = {
        'st': 'ALL'
    }
    return doPrivatePostRequest(payload, '/website/images/get')
}

export const deleteImage = (_doc_id) => {
    return doPrivateDelRequest(`/website/images/delete/${_doc_id}`)
}
