import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Card, Col, Row, Form, Button, Breadcrumb, Tabs, Tab } from '@themesberg/react-bootstrap';
import { NavigationBar } from "../../components/frontendNav/Navigation";
import { Footer } from '../../components/frontendNav/Footer';
import analytics from '../../functions/analytics'

const TabForm = ({ onSubmit }) => (
  <Form onSubmit={onSubmit}>
    <Form.Group className="mt-3">
      <Form.Label>First Name</Form.Label>
      <Form.Control placeholder="First Name" name="firstName" required />
    </Form.Group>
    <Form.Group className="mt-3">
      <Form.Label>Last Name</Form.Label>
      <Form.Control placeholder="Last Name" name="lastName" required />
    </Form.Group>
    <Form.Group className="mt-3">
      <Form.Label>Mobile Number</Form.Label>
      <Form.Control placeholder="Mobile Number" name="mobileNumber" required type="tel" />
    </Form.Group>
    <Form.Group className="mt-3">
      <Form.Label>Email Address</Form.Label>
      <Form.Control placeholder="Email Address" name="email" required type="email" />
    </Form.Group>
    <Form.Group className="mt-3">
      <Form.Label>Message</Form.Label>
      <Form.Control as="textarea" rows={3} placeholder="Message" name="message" required />
    </Form.Group>
    <Button className="mt-4" variant="primary" type="submit">Submit</Button>
  </Form>
);

const Contact = () => {
  const [activeTab, setActiveTab] = useState('Advertising');
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.tab) {
      setActiveTab(location.state.tab);
    }
    analytics.page()
  }, [location]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    console.log(data); // Handle form submission here
  };

  return (
    <>
      <NavigationBar />
      {/* TODO: Social media icons and newsletter signup on contact page*/}
      <section className="partner-brands text-center align-items-center justify-content-center overflow-hidden pt-5 pt-lg-6 pb-6 pb-lg-6 bg-grey text-dark">
        <div style={{ width: '90%', textAlign: 'left', paddingLeft: '10%' }}>
          <div className="d-block mb-4 mb-md-0">
            <h1 className="fw-bold text-dark">Contact</h1>
            <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
              <Breadcrumb.Item>You are here:</Breadcrumb.Item>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Contact</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>

      <section className="align-items-center justify-content-center overflow-hidden pt-5 pt-lg-6 pb-6 pb-lg-6 bg-white text-dark">
        <Container>
          <Row>
            <Col md={6} lg={6}>
              <Tabs activeKey={activeTab} onSelect={k => setActiveTab(k)} className="mb-3">
                <Tab className="mt-3" eventKey="Advertising" title="Advertising">
                  <p>For advertising enquiries, please fill in the form below and one of our team members will get back to you with our advertising costs.</p>
                  <TabForm onSubmit={handleSubmit} />
                </Tab>
                <Tab className="mt-3" eventKey="Log A Ticket" title="Log A Ticket">
                <p>For support enquiries, please fill in the form below to log a ticket and one of our team members will get back to you to assist you with your query.</p>
                  <TabForm onSubmit={handleSubmit} />
                </Tab>
                <Tab className="mt-3" eventKey="Enquiries" title="Enquiries">
                <p>For general enquiries, please fill in the form below and one of our team members will get back to you to assist you with your query.</p>
                  <TabForm onSubmit={handleSubmit} />
                </Tab>
              </Tabs>
            </Col>
            <Col md={6} lg={6}>
              <Card className="bg-light p-4" style={{ border:"1px solid #efefef" }}>
                <h3>Speak To Us</h3>
                <Row className="contact_wrapper mt-4">
                  <Col md={6} className="contact">
                    <h6>Email</h6>
                    <a href="mailto:info@forklifting.co.za">info@forklifting.co.za</a>
                  </Col>
                  <Col md={6} className="contact">
                    <h6>Office Line</h6>
                    <a href="tel:+1234567890">+12 345 678 90</a>
                  </Col>
                </Row>
                <Row className="contact_wrapper mt-4">
                  <Col md={6} className="contact">
                    <h6>Office Hours</h6>
                    Monday to Friday<br/>08:00 to 17:00
                  </Col>
                  <Col md={6} className="contact">
                    <h6>Emergencies</h6>
                    <a href="tel:+1234567890">+12 345 678 90</a><br/>
                    <a href="tel:+1234567890">+12 345 678 90</a> (Whatsapp)
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
