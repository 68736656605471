import React, { useState, useEffect } from "react";
import { Col, Row, Card, Button, Container, Nav, Tab } from '@themesberg/react-bootstrap';
import { SupplierForm } from "../../components/SupplierForm";
import { NavigationBar } from "../../components/frontendNav/Navigation";
import { Footer } from '../../components/frontendNav/Footer';
import analytics from "../../functions/analytics";
import useIsMobile from '../../utils/UseIsMobile'; 

export default () => {
  const isMobile = useIsMobile(); // Use the hook to detect mobile
  const [activeTab, setActiveTab] = useState('basic');

  useEffect(() => {
    analytics.page();
  }, []);

  const renderPackageContent = (packageName) => (
    <>
      <h4 className="fw-bolder text-dark mb-4">
        {packageName === 'basic' && 'Get started with a basic package'}
        {packageName === 'plus' && 'Upgrade to Plus from USD125 per month*'}
        {packageName === 'pro' && 'Upgrade to Pro from USD250 per month*'}
      </h4>
      <span className="lead mb-4 pb-4">Included:</span>
      <ul className="lead pt-4">
        <li className="lead">Unlimited traffic</li>
        <li className="lead">Company Profile</li>
        <li className="lead">Product & Service Listing</li>
        <li className="lead">Directs traffic to your website</li>
        <li className="lead">Eligibility for ranking on Leader matrix</li>
      </ul>
      <Button
        style={{ borderRadius: "0px", boxShadow: "25px 19px 40px 0px rgba(0, 0, 0, 0.24)" }}
        variant="brand-orange"
        className="btn btn-primary mt-4 px-3"
      >
        {packageName === 'basic' && 'Create A Free Profile'}
        {packageName === 'plus' && 'Upgrade To Plus'}
        {packageName === 'pro' && 'Get The Pro Account'}
      </Button>
    </>
  );

  return (
    <>
      {/*-- Navigation Bar --*/}
      <NavigationBar />

      <section className="partner-brands align-items-center justify-content-center overflow-hidden pt-5 pt-lg-4 pb-4 pb-lg-4 bg-white text-dark">
        <Container className="my-5">
          <Row className="justify-content-start align-items-start">
            <Col md={12}>
              <Row>
                {/* Left column */}
                <Col md={6} className="mb-4">
                  <div style={{ width: '100%', background: "#efefef", padding: '50px' }}>
                    <h3 className="mb-4 fw-bolder">Access to market with your FREE Forklifting.co company profile!</h3>
                    <p className="lead">Try Forklifting.co for free with a basic profile. This is a great way to get listed on Forklifting.co’s directories, gain exposure and collect leads and sales.</p>
                    <ul>
                      <li className="mb-2">Unlimited traffic</li>
                      <li className="mb-2">Company Profile</li>
                      <li className="mb-2">Product profiles</li>
                      <li className="mb-2">Service line profiles</li>
                      <li className="mb-2">Eligibility for ranking on Leader matrix</li>
                    </ul>
                    <p className="text-center mt-4 lead">Interested in other options?</p>
                  </div>
                </Col>
                <Col style={{ padding: "50px" }} md={6} className="p-4">
                  <h3 className="fw-bolder">Get started by creating your company profile</h3>
                  <p>Already have an account, login here.</p>
                  {/*-- Supplier Form --*/}
                  <SupplierForm />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="partner-brands align-items-center justify-content-center overflow-hidden pt-2 pb-4 pb-lg-4 px-4 bg-white text-dark">
        <h3 className="fw-bolder">Interested in our other options?</h3>
        {isMobile ? (
          <Tab.Container id="package-tabs" activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
            <Nav variant="pills" className="flex-row justify-content-center">
              <Nav.Item>
                <Nav.Link eventKey="basic">Basic</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="plus">Plus</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="pro">Pro</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="mt-4">
              <Tab.Pane eventKey="basic">
                {renderPackageContent('basic')}
              </Tab.Pane>
              <Tab.Pane eventKey="plus">
                {renderPackageContent('plus')}
              </Tab.Pane>
              <Tab.Pane eventKey="pro">
                {renderPackageContent('pro')}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        ) : (
          <Row>
            <Col style={{ padding: "50px", backgroundColor: "#fdfdfd" }} className="mt-4 p-4 pt-6 pb-6" xs={4}>
              {renderPackageContent('basic')}
            </Col>
            <Col style={{ padding: "50px", backgroundColor: "#efefef" }} className="mt-4 p-4 pb-6 pt-6" xs={4}>
              {renderPackageContent('plus')}
            </Col>
            <Col style={{ padding: "50px", backgroundColor: "#fdfdfd" }} className="mt-4 p-4 pt-6 pb-6" xs={4}>
              {renderPackageContent('pro')}
            </Col>
          </Row>
        )}
        <div className="partner-brands text-center align-items-center justify-content-center overflow-hidden pt-4 pt-lg-4 mt-4 pb-2 pb-lg-2 bg-white text-dark">
          <h3 className="fw-bolder">We offer customized advertising solutions to our Pro customers</h3>
          <p>Promote your latest deals and company updates to the right audience. Showcase your marketing and sales campaigns on our platform, in our <br />emails, or industry newsletters. We can even create dedicated landing pages for better engagement and tracking.</p>
        </div>
      </section>

      {/*-- Footer --*/}
      <Footer />
    </>
  );
};
