import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Tab, Card, Image, Button, ButtonGroup, Container, ListGroup, Tooltip, OverlayTrigger, Form, Dropdown, InputGroup, Navbar, Nav, Badge } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import '../../../scss/styles.css';
import { NavigationBar } from "../../../components/frontendNav/Navigation";
import { Footer } from '../../../components/frontendNav/Footer'
import { Routes } from "../../../routes";
import { getAllFaqs } from "../../../api/public/faqs";
import analytics from '../../../functions/analytics'

export default () => {
  const [supportFaqs, setSupportFaqs] = useState([])
  const [isLoading, setIsLoading] = useState(true);

  const DynamicComponent = ({ content }) => {
    return (
      <div>
        {content.heading && <h4 className={`font-class-${content.font_class || 'default'}`}>{content.heading}</h4>}
        {content.text && <p>{content.text}</p>}
        {content.link_url && <a href={content.link_url} className="btn btn-primary">{content.button || 'Click here'}</a>}
        {content.items && <ul>{content.items.map((item, idx) => <li key={idx}>{item}</li>)}</ul>}
        {/* Add more fields as necessary */}
      </div>
    );
  };


  useEffect(() => {
    loadFaqs(); // Load FAQs when the component mounts
    analytics.page()
  }, []);

  function loadFaqs() {
    getAllFaqs().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        const records = _resp.data.Records;
        // Filter FAQs by type and update state
        setSupportFaqs(records.filter(faq => faq.type === 'support'));
        setIsLoading(false);
      } else {
        console.warn(_resp.data);
        setIsLoading(false);
      }
    }).catch((_resp) => {
      console.error(_resp);
    });
  }

  return (
    <>
      {/*-- Navigation Bar --*/}
      <NavigationBar />

      <section className="overflow-hidden">
        <div className="bg-primary table-settings pb-4 pt-4">
          <Row className="justify-content-center align-items-center">
            <div className="background-overlay"></div>
            <Col xs={12} md={12} lg={12} xl={12}>
              <section className="text-center align-items-center justify-content-center overflow-hidden pt-4 pt-lg-4 pb-4 pb-lg-4 bg-primary text-white">
                <span className="fw-normal text-white">SUPPORT CENTRE</span>
                <h1 className="fw-bold text-white">How can we help you?</h1>
              </section>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center pb-4">
            <Col xs={9} md={9} lg={9} xl={9}>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control type="text" placeholder="Search" />
              </InputGroup>
            </Col>
          </Row>
        </div>
      </section>

      <section className="support-kb mb-6 pt-6 py-6 pb-9 pb-lg-9 bg-white text-dark" style={{ minHeight: '100vh' }}>
        {!isLoading && supportFaqs.length > 0 && (
          <Tab.Container
            className="mb-6"
            id="support-kb-tab"
            defaultActiveKey={`${supportFaqs[0]._id}-0`} // Ensure this matches the eventKey of the first Nav.Link
          >
            <Row className="mx-6 px-6">
              <Col sm={4}>
                <Nav variant="pills" className="support-title flex-column">
                  {supportFaqs.map((faq, faqIndex) => faq.component_content
                    .filter(content => content.component_content_type === 'heading') // Assuming 'heading' type is used for titles
                    .map((content, contentIndex) => (
                      <Nav.Item key={`${faq._id}-${contentIndex}`}>
                        <Nav.Link eventKey={`${faq._id}-${contentIndex}`}>
                          <b>{content.heading}</b>
                        </Nav.Link>
                      </Nav.Item>
                    ))
                  )}
                </Nav>
              </Col>
              <Col sm={8}>
                <Tab.Content>
                  {supportFaqs.map((faq, faqIndex) =>
                    faq.component_content.map((content, contentIndex) => (
                      <Tab.Pane key={`${faq._id}-${contentIndex}`} eventKey={`${faq._id}-${contentIndex}`}>
                        <DynamicComponent content={content} />
                      </Tab.Pane>
                    ))
                  )}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        )}

      </section>


      <section style={{ background: "#DA6220" }} className="orange-section pb-6 pb-lg-6">
        <Container>
          <Row className="justify-content-center text-center align-items-center orange-section-height">
            <div className="center-block mt-4">
              <h2 style={{ fontSize: "48px" }} className="fw-bolder text-dark">Can't Find What You Need</h2>
              <p className="lead mx-auto">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
              <Button style={{ background: "#DA6220", borderRadius: "0px", boxShadow: "25px 19px 40px 0px rgba(0, 0, 0, 0.24)" }} variant="brand-orange" className="mt-4 btn">Log A Ticket</Button>
            </div>
          </Row>
        </Container>
      </section>

      {/*-- Footer --*/}
      <Footer />
    </>
  );
};