import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';
import AddSupplierModal from "../../../components/modals/AddSupplierModal";
import { getAllSuppliers, createSuppliers, updateSuppliers, deleteSuppliers } from "../../../api/private/suppliers";
import { SuppliersTables } from "../../../components/tables/SuppliersTables";
import { makeToast } from "../../../functions/toasts";
import AlertComponent from '../../../functions/alerts'
import { getAllCompanies } from "../../../api/private/companies";
import { getAllUsers } from "../../../api/private/users";
import { useUserRole } from "../../../contexts/UserRoleContext";
import { ToastContainer, toast} from "react-toastify";

export default () => {
  const { userRole } = useUserRole();
  const [suppliers, setSuppliers] = useState([]);
  const [initialSupplierData, setInitialSupplierData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [pageMounted, setPageMounted] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [alert, setAlert] = useState({ type: '', message: '' });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    if (!pageMounted) {
      setPageMounted(true);
      loadSuppliers();
      loadUsers();
      loadCompanies();
    }
  }, [pageMounted]);

  const loadUsers = () => {
    getAllUsers().then(response => {
      if (response.data.Result === 'OK') {
        setUsers(response.data.Records);
      } else {
        console.warn(response.data);
        makeToast('error', 'Failed to load users');
      }
    }).catch(error => {
      console.error(error);
      makeToast('error', 'Failed to load users');
    });
  };

  const loadCompanies = () => {
    getAllCompanies().then(response => {
      if (response.data.Result === 'OK') {
        const companiesArray = Object.keys(response.data.Records).map(key => {
          return {
            id: key,
            ...response.data.Records[key]
          };
        });
        setCompanies(companiesArray);
      } else {
        console.error("Failed to fetch companies:", response.data.Message);
      }
    }).catch(error => {
      console.error("Error fetching companies:", error);
    });
  };

  const loadSuppliers = () => {
    getAllSuppliers().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        setSuppliers(_resp.data.Records);
        setAlert({ type: 'success', message: `Loaded suppliers successfully` });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      } else {
        console.warn(_resp.data);
        setAlert({ type: 'success', message: `Loaded suppliers successfully` });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      }
    }).catch((_resp) => {
      console.log(_resp);
      setAlert({ type: 'error', message: `Failed to load suppliers` });
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    });
  };

  const createSupplierAcc = (supplierData) => {
    createSuppliers(supplierData).then((_resp) => {
      if (_resp.data.Result === 'OK') {
        setAlert({ type: 'success', message: `${_resp.data.Message}` });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        handleCloseModal();
        loadSuppliers();
      } else {
        console.warn(_resp.data);
        setAlert({ type: 'success', message: `${_resp.data.Message}` });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        handleCloseModal();
        loadSuppliers();
      }
    }).catch((_resp) => {
      console.error(_resp);
      setAlert({ type: 'success', message: `${_resp.data.Message}` });
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    });
  };

  const updateSupplier = (supplierData) => {
    updateSuppliers(supplierData, supplierData._id).then(response => {
      console.log("Supplier data:",  supplierData);
      console.log("Supplier Id:", supplierData._id);
      if (response.data.Result === 'OK') {
        setAlert({ type: 'success', message: `${response.data.Message}` });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        setShowModal(false);
        loadSuppliers(); // Refresh the suppliers list
      } else {
        console.warn(response.data);
        makeToast('error', 'Failed to update supplier');
      }
    }).catch(error => {
      console.error(error);
      makeToast('error', 'Failed to update supplier');
    });
  };

  const deleteSupplier = (supplierId) => {
    deleteSuppliers(supplierId).then(response => {
      if (response.data.Result === 'OK') {
        setAlert({ type: 'success', message: `${response.data.Message}` });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadSuppliers();
      } else {
        console.warn(response.data);
        setAlert({ type: 'success', message: `${response.data.Message}` });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
        loadSuppliers();
      }
    }).catch(response => {
      console.error(response);
      setAlert({ type: 'error', message: `Failed to delete supplier` });
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    });
  };

  const handleEditSupplier = (supplier) => {
    setInitialSupplierData(supplier);
    setIsEditMode(true);
    setShowModal(true);
  };

  useEffect(() => {
    console.log("Initial Supplier Data:", initialSupplierData);
}, [initialSupplierData]);


  return (
    <>
    <ToastContainer hideProgressBar={true} theme="light" pauseOnHover autoClose={5000}/>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Companies</Breadcrumb.Item>
            <Breadcrumb.Item active>Company Users</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Company Users</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
            <Button className="btn btn-primary" onClick={() => { setIsEditMode(false); handleShowModal(); }}>Add Company User</Button>
          </ButtonGroup>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={6} lg={3} xl={4}>
            {/* <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control type="text" placeholder="Search" />
            </InputGroup> */}
          </Col>
          <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
                <span className="icon icon-sm icon-gray">
                  <FontAwesomeIcon icon={faCog} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
                <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
                <Dropdown.Item className="d-flex fw-bold">
                  10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">20</Dropdown.Item>
                <Dropdown.Item className="fw-bold">30</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </div>

      <AlertComponent type={alert.type} message={alert.message} />

      <SuppliersTables suppliers={suppliers} deleteSupplier={deleteSupplier} handleEditSupplier={handleEditSupplier} />
      <AddSupplierModal
        showModal={showModal}
        createSupplierAcc={createSupplierAcc}
        updateSupplier={updateSupplier}
        handleCloseModal={handleCloseModal}
        companies={companies}
        users={users}
        isEditMode={isEditMode}
        initialSupplierData={initialSupplierData}
      />
    </>
  );
};
