import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';
import { MediaTables } from "../../../components/tables/MediaTables";
import { getAllImages } from "../../../api/private/images";
import { makeToast } from "../../../functions/toasts";
import UploadMediaModal from "../../../components/modals/UploadMediaModal";
import AlertComponent from "../../../functions/alerts"
import { useUserRole } from "../../../contexts/UserRoleContext";
import { ToastContainer, toast } from "react-toastify"
export default () => {
const { userRole } = useUserRole();
const [images, setImages] = useState([])
const [showModal, setShowModal] = useState(false);
const [pageMounted, setPageMounted] = useState(false);
const [alert, setAlert] = useState({ type: '', message: '' });


useEffect(() => {
  if (!pageMounted) {
    setPageMounted(true)
    loadImages();
  }
}, [pageMounted])

function loadImages() {
  const payload = {'st': 'ALL'}
  getAllImages(payload).then((_resp) => {
    if (_resp.data.Result === 'OK') {
      const records = _resp.data.Records
      setImages(records)
      setAlert({type: 'success', message:`Loaded images successfully`})
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    } else {
      console.warn(_resp.data)
      setAlert({type: 'success', message:`Loaded images successfully`})
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    }
  }).catch((_resp) => {
    console.log(_resp)
    setAlert({type: 'error', message:`Loaded images unsuccessfully`})
    setTimeout(() => setAlert({ type: '', message: '' }), 5000);  })
}

function onNewMediaClick(){
  setShowModal(true)
}

function onUploadMediaModalResp(_cmd, _value){
  if(_cmd === 'close'){
    setShowModal(false)
  }
}

return (
<>
<ToastContainer hideProgressBar={true} theme="light" pauseOnHover autoClose={5000}/>
  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
    <div className="d-block mb-4 mb-md-0">
      <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
        <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
        <Breadcrumb.Item>CMS</Breadcrumb.Item>
        <Breadcrumb.Item active>Media</Breadcrumb.Item>
      </Breadcrumb>
      <h4>Media</h4>
    </div>
    <div className="btn-toolbar mb-2 mb-md-0">
      <ButtonGroup>
        <Button variant="outline-primary" size="sm">Share</Button>
        <Button variant="outline-primary" size="sm">Export</Button>
        <Button onClick={() => onNewMediaClick()} variant="btn btn-primary">Add Image</Button>
      </ButtonGroup>
    </div>
  </div>

  <AlertComponent type={alert.type} message={alert.message} />      

  <div className="table-settings mb-4">
    <Row className="justify-content-between align-items-center">
      <Col xs={8} md={6} lg={3} xl={4}>
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
          <Form.Control type="text" placeholder="Search" />
        </InputGroup>
      </Col>
      <Col xs={4} md={2} xl={1} className="ps-md-0 text-end">
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle split as={Button} variant="link" className="text-dark m-0 p-0">
            <span className="icon icon-sm icon-gray">
              <FontAwesomeIcon icon={faCog} />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-xs dropdown-menu-right">
            <Dropdown.Item className="fw-bold text-dark">Show</Dropdown.Item>
            <Dropdown.Item className="d-flex fw-bold">
              10 <span className="icon icon-small ms-auto"><FontAwesomeIcon icon={faCheck} /></span>
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold">20</Dropdown.Item>
            <Dropdown.Item className="fw-bold">30</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  </div>

  <MediaTables images={images} />
  {showModal === true?
    <UploadMediaModal _show={true} _onResp={onUploadMediaModalResp}/>
  : null}
</>
)};
