import { doPrivatePostRequest, doPrivateGetRequest} from "./requests";

export const updateRobots = (content) => {

    const payload = {
            'content': content
        }

    return doPrivatePostRequest(payload, '/robots.txt')
}

export const getRobots = () => {
    return doPrivateGetRequest('/robots.txt')
}