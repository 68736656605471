import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Button, Modal } from '@themesberg/react-bootstrap';
import 'react-quill/dist/quill.snow.css';

const AddCategoryModal = ({ showModal, handleCloseModal, createCategory, updateCategory, initialCategoryData, isEditMode }) => {
    const [categoryData, setCategoryData] = useState({
        seo_title: "",
        seo_meta_descr: "",
        slug: "",
        schema: "",
        author: "",
        canonical_url: "",
        index_url: "",
        featured_image_url: "",
        addon_meta: [{ category_name: "" }]
    });

    useEffect(() => {
        if (showModal && isEditMode && initialCategoryData) {
            setCategoryData({ ...initialCategoryData });
        } else {
            resetForm();
        }
    }, [showModal, isEditMode, initialCategoryData]);

    const resetForm = () => {
        setCategoryData({
            seo_title: "",
            seo_meta_descr: "",
            slug: "",
            schema: "",
            author: "",
            canonical_url: "",
            index_url: "",
            featured_image_url: "",
            addon_meta: [{ category_name: "" }]
        });
    };

    const handleCategoryDataChange = (key, value) => {
        setCategoryData(prev => ({ ...prev, [key]: value }));
    };

    const handleAddonMetaChange = (value, index) => {
        const updatedAddonMeta = [...categoryData.addon_meta];
        updatedAddonMeta[index] = { category_name: value };
        setCategoryData(prev => ({ ...prev, addon_meta: updatedAddonMeta }));
    };

    const handleSubmit = () => {
        if (isEditMode && initialCategoryData && initialCategoryData._id) {
            updateCategory(initialCategoryData._id, categoryData);
        } else {
            createCategory(categoryData);
        }
        handleCloseModal();
    };

    return (
        <Modal size="lg" show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{isEditMode ? 'Update Category' : 'Add Category'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col md={6}>
                            {categoryData.addon_meta.map((addon, index) => (
                                <Row key={index}>
                                    <Col md={12}>
                                        <Form.Group controlId={`addonMeta${index}`}>
                                            <Form.Label>Category Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter category name"
                                                value={addon.category_name}
                                                onChange={(e) => handleAddonMetaChange(e.target.value, index)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            ))}
                            <Form.Group controlId="seoTitle">
                                <Form.Label>SEO Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter SEO title"
                                    value={categoryData.seo_title}
                                    onChange={(e) => handleCategoryDataChange('seo_title', e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="slug">
                                <Form.Label>Slug</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter slug"
                                    value={categoryData.slug}
                                    onChange={(e) => handleCategoryDataChange('slug', e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group controlId="seoDescription">
                                <Form.Label>SEO Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter SEO description"
                                    value={categoryData.seo_meta_descr}
                                    onChange={(e) => handleCategoryDataChange('seo_meta_descr', e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="author">
                                <Form.Label>Author</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter author name"
                                    value={categoryData.author}
                                    onChange={(e) => handleCategoryDataChange('author', e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="canonicalUrl">
                                <Form.Label>Canonical URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter canonical URL"
                                    value={categoryData.canonical_url}
                                    onChange={(e) => handleCategoryDataChange('canonical_url', e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Group controlId="featuredImageUrl">
                                <Form.Label>Featured Image URL</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter featured image URL"
                                    value={categoryData.featured_image_url}
                                    onChange={(e) => handleCategoryDataChange('featured_image_url', e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    {isEditMode ? 'Update' : 'Add'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddCategoryModal;
