import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../routes';

const Signout = ({ onSignOut }) => {
  const history = useHistory();

  useEffect(() => {
    onSignOut();
    history.push(Routes.Signin.path);
  }, [onSignOut, history]);

  return null; // Return null as the component will navigate immediately
};

export default Signout;
