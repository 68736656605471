
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faArrowDown, faArrowUp, faEdit, faEllipsisH, faExternalLinkAlt, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Nav, Card, Image, Button, Table, Dropdown, ProgressBar, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';

const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return (
    value ? <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}{suffix}
      </span>
    </span> : "--"
  );
};

export const OfferingsTables = ({ offerings, deleteOffering, handleEditOffering }) => {
  const TableRow = (props) => {
    const { _id, offering, rental_service, maintenance_and_repairs, training_programmings, spare_parts_and_accessories, emergency_services_and_response, technology_solutions, refurbished_solutions, customized_solutions, compliance_and_certification_services, warranty_offered, notes, offeringMeta } = props;

    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center" onClick={() => handleEditOffering({ _id, offering, rental_service, maintenance_and_repairs, training_programmings, spare_parts_and_accessories, emergency_services_and_response, technology_solutions, refurbished_solutions, customized_solutions, compliance_and_certification_services, warranty_offered, notes, offeringMeta })}>
            <div><span className="h6">Offering</span></div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">{rental_service ? rental_service : "-"}</td>
        <td className="border-0">{maintenance_and_repairs ? maintenance_and_repairs : "-"}</td>
        <td className="fw-bold border-0">{training_programmings ? training_programmings : "-"}</td>
        <td className="border-0">{spare_parts_and_accessories ? spare_parts_and_accessories : "-"}</td>
        <td className="fw-bold border-0">{emergency_services_and_response ? emergency_services_and_response : "-"}</td>
        <td className="border-0">{technology_solutions ? technology_solutions : "-"}</td>
        <td className="fw-bold border-0">{refurbished_solutions ? refurbished_solutions : "-"}</td>
        <td className="border-0">{customized_solutions ? customized_solutions : "-"}</td>
        <td className="fw-bold border-0">{compliance_and_certification_services ? compliance_and_certification_services : "-"}</td>
        <td className="border-0">{warranty_offered ? warranty_offered : "-"}</td>
        <td className="fw-bold border-0">{notes ? notes : "-"}</td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="tableNav">
              <Dropdown.Item onClick={() => handleEditOffering({ _id, offering, rental_service, maintenance_and_repairs, training_programmings, spare_parts_and_accessories, emergency_services_and_response, technology_solutions, refurbished_solutions, customized_solutions, compliance_and_certification_services, warranty_offered, notes, offeringMeta })}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={() => deleteOffering(_id)}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Offering</th>
              <th className="border-0">Rental Service</th>
              <th className="border-0">Maintenance And Repairs</th>
              <th className="border-0">Training Programmings</th>
              <th className="border-0">Spare Parts And Accessories</th>
              <th className="border-0">Emergency Services And Response</th>
              <th className="border-0">Technology Solutions</th>
              <th className="border-0">Refurbished Solutions</th>
              <th className="border-0">Customized Solutions</th>
              <th className="border-0">Compliance And Certification Services</th>
              <th className="border-0">Warranty Offered</th>
              <th className="border-0">Notes</th>
              <th className="border-bottom">Action</th>
            </tr>
          </thead>
          <tbody>
            {offerings.length > 0 ? (
              offerings.map(offering => <TableRow key={`offerings-${offering.id}`} {...offering} />)
            ) : (
              <tr>
                <td colSpan="8" className="text-center">
                  No records could be found at this time. Create your first offering by clicking Add Offering
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};