import React, { useState, useEffect } from "react";
import { Col, Row } from '@themesberg/react-bootstrap';
import { NavigationBar } from "../../components/frontendNav/Navigation";
import { Footer } from '../../components/frontendNav/Footer'
import { getAllPages } from "../../api/private/pages";
import analytics from '../../functions/analytics'
import { Routes } from "../../routes";

export default () => {

  const [page, setPage] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadPages();
    analytics.page();
  }, []);

  function loadPages() {
    getAllPages().then(response => {
      if (response.data && response.data.Result === 'OK') {
        const records = response.data.Records;
        const foundPage = records.find(p => p.slug === "careers");
        if (foundPage) {
          setPage(foundPage);
          setLoading(false);
          console.log(`Page found:`, foundPage);
        } else {
          console.warn(`Page with slug "careers" not found.`);
        }
      } else {
        console.warn('Unexpected response structure:', response);
      }
    }).catch(error => {
      console.error('Error loading pages:', error);
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <>
      <NavigationBar />
      {loading ? (
        <Row className="justify-content-center">
          <Col>Loading...</Col>
        </Row>
      ) : (
        <>
          <section className="partner-brands align-items-start justify-content-start overflow-hidden pt-5 pt-lg-6 pb-6 pb-lg-6 bg-white text-dark px-3 px-md-3 px-xl-7 px-lg-7">
            <Row className="w-100 mx-0"> {/* Full-width row */}
              <Col xs={12} className="px-0"> {/* Full-width column on mobile */}
                {page && (
                  <h1 className="fw-bold text-dark">{page['title']}</h1>
                )}
                {page && (
                  <p dangerouslySetInnerHTML={{ __html: page['page_content'] }} />
                )}
              </Col>
            </Row>
          </section>

          {/*-- Footer --*/}
          <Footer />
        </>
      )}
    </>
  );
};
