import React, { useState } from 'react';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Modal } from '@themesberg/react-bootstrap';

const AddRequestModal = ({ showModal, handleCloseModal }) => {
    return (
        <>
            <Modal size="lg" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Listing</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Add your form or content for adding a listing here */}
                    {/* Example: */}
                    <Form>
                        <Row>
                            <Col md={12} className="mb-3">
                                <Form.Group controlId="title">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter title" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="mb-3">
                                <Form.Group controlId="type">
                                    <Form.Label>Listing Type</Form.Label>
                                    <Form.Select required>
                                        <option value="">Select category</option>
                                        <option value="Forklifting Hire">Forklifting Hire</option>
                                        <option value="Forklifting Sales">Forklifting Sales</option>
                                        <option value="Forklifting Spares">Forklifting Spares</option>
                                        <option value="Forklifting Repairs">Forklifting Repairs</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className="mb-3">
                                <Form.Group controlId="category">
                                    <Form.Label>Category</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter category" />
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group controlId="linkedOffering">
                                    <Form.Label>Linked Offering</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter linked offering" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="mb-3">
                                <Form.Group controlId="description">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" required placeholder="Enter description" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="mb-3">
                                <Form.Group controlId="brochure">
                                    <Form.Label>Brochure</Form.Label>
                                    <Form.Control required type="file" placeholder="Enter brochure" />
                                </Form.Group>
                            </Col>
                            <Col md={12} className="mb-3">
                                <Form.Group controlId="photos">
                                    <Form.Label>Photos</Form.Label>
                                    <Form.Control required type="file" placeholder="Enter photos" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddRequestModal;
