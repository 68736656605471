import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createProduct = (payload) => {
    return doPrivatePostRequest(payload, '/products/create')
}

export const updateProduct = (_doc_id, payload) => {
    return doPrivatePostRequest(payload, `/products/update/${_doc_id}`)
}

export const getProduct = (_doc_id) => {
    return doPrivatePostRequest(`/products/get/${_doc_id}`)
}

export const getAllProducts = () => {
    const payload = {
        'st': 'ALL'
    }
    return doPrivatePostRequest(payload, '/products/get')
}

export const deleteProduct = (_doc_id) => {
    return doPrivateDelRequest(`/products/delete/${_doc_id}`)
}
