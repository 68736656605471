import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Button, Tab, Tabs, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Modal } from '@themesberg/react-bootstrap';

const AddOfferingModal = ({ showModal, handleCloseModal, createOffering, updateOffering, initialOfferingData, isEditMode }) => {
    const [offeringData, setOfferingData] = useState({
        offering: "",
        offering_content: "",
        rental_service: false,
        maintenance_and_repairs: false,
        training_programmings: false,
        spare_parts_and_accessories: false,
        emergency_services_and_response: false,
        technology_solutions: false,
        refurbished_solutions: false,
        customized_solutions: false,
        compliance_and_certification_services: false,
        warranty_offered: false,
        notes: ""
    });

    useEffect(() => {
        if (showModal && isEditMode && initialOfferingData) {
            setOfferingData({
                ...initialOfferingData,
            });
        } else {
            setOfferingData({
                offering: "",
                offering_content: "",
                rental_service: false,
                maintenance_and_repairs: false,
                training_programmings: false,
                spare_parts_and_accessories: false,
                emergency_services_and_response: false,
                technology_solutions: false,
                refurbished_solutions: false,
                customized_solutions: false,
                compliance_and_certification_services: false,
                warranty_offered: false,
                notes: ""
            });
        }
    }, [initialOfferingData, isEditMode, showModal]);

    function addMetaField() {
        setOfferingData(prev => ({
            ...prev,
            offeringMeta: [...prev.offeringMeta, { key: "", value: "" }]
        }));
    }

    function updateMetaField(index, key, value) {
        const updatedMeta = offeringData.offeringMeta.map((item, i) => {
            if (i === index) {
                return { ...item, [key]: value };
            }
            return item;
        });

        setOfferingData(prev => ({
            ...prev,
            offeringMeta: updatedMeta
        }));
    }

    function removeMetaField(index) {
        setOfferingData(prev => ({
            ...prev,
            offeringMeta: prev.offeringMeta.filter((_, i) => i !== index)
        }));
    }

    function onKeyValueChange(key, event) {
        const isCheckbox = event.target.type === 'checkbox';
        const value = isCheckbox ? event.target.checked : event.target.value;

        setOfferingData(prevOfferingData => ({
            ...prevOfferingData,
            [key]: value,
        }));
    }

    function handleFileChange(key, files) {
        if (files.length > 0) {
            setOfferingData(prevOfferingData => ({
                ...prevOfferingData,
                [key]: files[0],
            }));
        }
    }

    return (
        <>
            <Modal size="lg" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditMode ? 'Update Offering' : 'Add Offering'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Tabs defaultActiveKey="general" id="uncontrolled-tab-example" className="mb-3">
                            <Tab eventKey="general" title="General">
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="type">
                                            <Form.Label>Offering Type</Form.Label>
                                            <Form.Select required value={offeringData.offering} onChange={(e) => onKeyValueChange('offering', e)}>
                                                <option value="">Select a type</option>
                                                <option value="service">Products</option>
                                                <option value="product">Services</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="offering_content">
                                            <Form.Label>Offering Content</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                required
                                                name="offering_content"
                                                placeholder="Add offering content"
                                                value={offeringData.offering_content}
                                                onChange={(e) => onKeyValueChange('offering_content', e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group>
                                            <Form.Label>Services Offered</Form.Label>
                                            <div>
                                                <Row>
                                                    <Col xs={12} sm={12} md={4}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Rental Service"
                                                            name="rental_service"
                                                            value={offeringData.rental_service}
                                                            onChange={(e) => onKeyValueChange('rental_service', e)}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Maintenance & Repairs"
                                                            name="maintenance_and_repairs"
                                                            value={offeringData.maintenance_and_repairs}
                                                            onChange={(e) => onKeyValueChange('maintenance_and_repairs', e)}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Training Programmings"
                                                            name="training_programmings"
                                                            value={offeringData.training_programmings}
                                                            onChange={(e) => onKeyValueChange('training_programmings', e)}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={12} md={4}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Spare Parts & Accessories"
                                                            name="spare_parts_and_accessories"
                                                            value={offeringData.spare_parts_and_accessories}
                                                            onChange={(e) => onKeyValueChange('spare_parts_and_acessories', e)}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Emergency Services & Response"
                                                            name="emergency_services_and_response"
                                                            value={offeringData.emergency_services_and_response}
                                                            onChange={(e) => onKeyValueChange('emergency_services_and_response', e)}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Technology Solutions"
                                                            name="technology_solutions"
                                                            value={offeringData.technology_solutions}
                                                            onChange={(e) => onKeyValueChange('technology_solutions', e)}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Refurbished Solutions"
                                                            name="refurbished_solutions"
                                                            value={offeringData.refurbished_solutions}
                                                            onChange={(e) => onKeyValueChange('refurbished_solutions', e)}
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={12} md={4}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Customized Solutions"
                                                            name="customized_solutions"
                                                            value={offeringData.customized_solutions}
                                                            onChange={(e) => onKeyValueChange('customized_solutions', e)}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Compliance & Certificate Services"
                                                            name="compliance_and_certification_services"
                                                            value={offeringData.compliance_and_certification_services}
                                                            onChange={(e) => onKeyValueChange('compliance_and_certification_services', e)}
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Warranty Offered"
                                                            name="warranty_offered"
                                                            value={offeringData.warranty_offered}
                                                            onChange={(e) => onKeyValueChange('warranty_offered', e)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <Form.Group controlId="notes">
                                            <Form.Label>Notes</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                required
                                                name="notes"
                                                placeholder="Enter notes"
                                                value={offeringData.notes}
                                                onChange={(e) => onKeyValueChange('notes', e)}  // Correctly passing 'notes' as the key and the event
                                            />
                                        </Form.Group>
                                    </Col>

                                </Row>
                            </Tab>
                            <Tab eventKey="options" title="Product Options">
                                {offeringData.offeringMeta.map((field, index) => (
                                    <Row key={index} className="mb-3">
                                        <Col md={5}>
                                            <Form.Control
                                                type="text"
                                                placeholder="Key"
                                                value={field.key}
                                                onChange={(e) => updateMetaField(index, 'key', e.target.value)}
                                            />
                                        </Col>
                                        <Col md={5}>
                                            <Form.Control
                                                type="text"
                                                placeholder="Value"
                                                value={field.value}
                                                onChange={(e) => updateMetaField(index, 'value', e.target.value)}
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <Button variant="danger" onClick={() => removeMetaField(index)}>Remove</Button>
                                        </Col>
                                    </Row>
                                ))}
                                <Button variant="primary" onClick={addMetaField}>Add Field</Button>
                            </Tab>
                        </Tabs>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="brand-orange" onClick={() => isEditMode ? updateOffering(offeringData) : createOffering(offeringData)}>
                        {isEditMode ? 'Update Offering' : 'Add Offering'}
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    );
};

export default AddOfferingModal;
