import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Card, Image, Button, Container } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import LogoCarousel from '../components/LogoCarousel';
import { NavigationBar } from "../components/frontendNav/Navigation";
import { Footer } from '../components/frontendNav/Footer';
import { Routes } from "../routes";
import { faPlay, faCog, faReceipt, faCertificate, faBox, faTruckLoading, faDollarSign, faScrewdriver, faPersonBooth } from '@fortawesome/free-solid-svg-icons';
import analytics from '../functions/analytics';

export default () => {

  useEffect(() => {
    analytics.page();
  }, []);

  const history = useHistory();

  return (
    <>
      {/*-- Navigation Bar --*/}
      <NavigationBar />

      <section className="section-header overflow-hidden bg-primary pb-2 pb-lg-2 pt-6 pt-lg-6 text-white">
        <Container>
          <Row id="header-banner">
            <Col xs={12} md={6} className="text-left">
              <h1 className="fw-bolder text-white">Comparative quotes, at your fingertips, from the world's leading and trusted forklift brands.</h1>
              <div className="d-flex align-items-start justify-content-start mt-4">
                <Button style={{ borderRadius: "0px", boxShadow: "25px 19px 40px 0px rgba(0, 0, 0, 0.24)" }} variant="brand-orange" as={Link} to={Routes.Signup.path} className="mt-4 py-3 px-5 text-white me-3">
                  Register Free Account
                </Button>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <img style={{ transform: "translateY(-50px)", height: "500px" }} src="https://forklifting.co.za/wp-content/uploads/2023/12/Call_to_Actions1.png" />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="partner-brands text-center align-items-center justify-content-center overflow-hidden pt-5 pt-lg-6 pb-6 pb-lg-6 bg-white text-dark">
        <p className="fw-normal lead text-dark mx-6">Unlock the world of forklift possibilities. Our platform connects you to leading forklift brands allowing you to request and compare quotations, products, and services quickly and effortlessly.</p>
        <div style={{ height: "150px", maxHeight: "300px" }} className="spacer-small"></div>
        <LogoCarousel />
      </section>

      <section className="quote-section align-items-center justify-content-center overflow-hidden pt-6 pb-6">
        <Container>
          <h2 className="fw-bolder mb-4 text-center">What do you need a quote on?</h2>
          <Row className="justify-content-center">
            <Col xs={6} md={3} className="mb-3">
              <a className="icon-card-home bg-white d-flex flex-column align-items-center justify-content-center h-100" onClick={() => history.push('wizard/wizard?selectedOption=Forklift%20Hire')}>
                <FontAwesomeIcon icon={faTruckLoading} className="custom-icon" size="5x" />
                <h4 className="mt-2 text-center">Forklift Hire</h4>
              </a>
            </Col>
            <Col xs={6} md={3} className="mb-3">
              <a className="icon-card-home bg-white d-flex flex-column align-items-center justify-content-center h-100" onClick={() => history.push('wizard/wizard?selectedOption=Forklift%20Sales')}>
                <FontAwesomeIcon icon={faDollarSign} className="custom-icon" size="5x" />
                <h4 className="mt-2 text-center">Forklift Sales</h4>
              </a>
            </Col>
            <Col xs={6} md={3} className="mb-3">
              <a className="icon-card-home bg-white d-flex flex-column align-items-center justify-content-center h-100" onClick={() => history.push('wizard/wizard?selectedOption=Forklift%20Repairs')}>
                <FontAwesomeIcon icon={faScrewdriver} className="custom-icon" size="5x" />
                <h4 className="mt-2 text-center">Forklift Repairs</h4>
              </a>
            </Col>
            <Col xs={6} md={3} className="mb-3">
              <a className="icon-card-home bg-white d-flex flex-column align-items-center justify-content-center h-100" onClick={() => history.push('wizard/wizard?selectedOption=Forklift%20Training')}>
                <FontAwesomeIcon icon={faPersonBooth} className="custom-icon" size="5x" />
                <h4 className="mt-2 text-center">Forklift Training</h4>
              </a>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={6} md={3} className="mb-3">
              <a className="icon-card-home bg-white d-flex flex-column align-items-center justify-content-center h-100" onClick={() => history.push('wizard/wizard?selectedOption=Forklift%20Maintenance')}>
                <FontAwesomeIcon icon={faCog} className="custom-icon" size="5x" />
                <h4 className="mt-2 text-center">Maintenance</h4>
              </a>
            </Col>
            <Col xs={6} md={3} className="mb-3">
              <a className="icon-card-home bg-white d-flex flex-column align-items-center justify-content-center h-100" onClick={() => history.push('wizard/wizard?selectedOption=Refurb%20Forklifts')}>
                <FontAwesomeIcon icon={faReceipt} className="custom-icon" size="5x" />
                <h4 className="mt-2 text-center">Refurb Forklifts</h4>
              </a>
            </Col>
            <Col xs={6} md={3} className="mb-3">
              <a className="icon-card-home bg-white d-flex flex-column align-items-center justify-content-center h-100" onClick={() => history.push('wizard/wizard?selectedOption=Load%20Testing')}>
                <FontAwesomeIcon icon={faBox} className="custom-icon" size="5x" />
                <h4 className="mt-2 text-center">Load Testing</h4>
              </a>
            </Col>
            <Col xs={6} md={3} className="mb-3">
              <a className="icon-card-home bg-white d-flex flex-column align-items-center justify-content-center h-100" onClick={() => history.push('wizard/wizard?selectedOption=Certification')}>
                <FontAwesomeIcon icon={faCertificate} className="custom-icon" size="5x" />
                <h4 className="mt-2 text-center">Certification</h4>
              </a>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bg-white more-info align-items-center justify-content-center overflow-hidden pt-6 pb-6">
        <Container>
          <Row>
            <Col md={5} lg={5}>
              <span className="sub-title mb-4">HOW IT WORKS</span>
              <h1 className="fw-bolder">Your one-stop shop for making business decisions quickly.</h1>
              <p style={{ fontSize: '18px', lineHeight: '30px' }} className="long-text my-4">Looking to buy or rent a forklift; searching for spare parts, forklift maintenance, services, or training? Look no further. No matter where you are in your research process, it’s time to say goodbye to endless online searching, and waiting. Our platform will source a minimum of 3 or more comparative quotes so that you can make informed business decisions quickly. Register a free account today and submit your requests for quotes.</p>
              <div className="button-group">
                <Button style={{ borderRadius: "5px" }} className="btn btn-primary px-4 mr-2">Learn More</Button>
                <Button style={{ background: "transparent", border: "1px solid #DA6220", borderRadius: "5px" }} className="btn btn-primary text-dark px-4 mx-2"><FontAwesomeIcon icon={faPlay} /> Watch Demo</Button>
              </div>
            </Col>
            <Col md={6} lg={6}>
              <img className="ml-4 mobile-margin-top" style={{ zIndex: "2", width: "800px!important", boxShadow: "0px 25px 30px -15px rgba(0, 0, 0, 0.15)" }} src="https://forklifting.co.za/wp-content/uploads/2020/11/top-3-1.png" width="800px" />
            </Col>
          </Row>
        </Container>
      </section>
      
      {/*-- Footer --*/}
      <Footer />
    </>
  );
};
