import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from '@themesberg/react-bootstrap';
import { NavigationBar } from "../../components/frontendNav/Navigation";
import { Footer } from '../../components/frontendNav/Footer';
import analytics from '../../functions/analytics';
import { getAllFaqs } from "../../api/public/faqs";

export default () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [buyerFaqs, setBuyerFaqs] = useState([]); // State for buyer FAQs
  const [supplierFaqs, setSupplierFaqs] = useState([]); // State for supplier FAQs

  useEffect(() => {
    loadFaqs(); // Load FAQs when the component mounts
    analytics.page();
  }, []);

  function loadFaqs() {
    getAllFaqs().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        const records = _resp.data.Records;
        // Filter FAQs by type and update state
        setBuyerFaqs(records.filter(faq => faq.type === 'buyer'));
        setSupplierFaqs(records.filter(faq => faq.type === 'supplier'));
      } else {
        console.warn(_resp.data);
      }
    }).catch((_resp) => {
      console.error(_resp);
    });
  }

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const DynamicComponent = ({ content }) => {
    switch (content.component_content_type) {
      case 'text':
        return <p style={{ marginTop: '10px' }}>{content.text}</p>;
      
      case 'button':
        return (
          <a href={content.link_url} className="btn btn-primary" style={{ marginTop: '10px' }}>
            {content.text}
          </a>
        );

      case 'list':
        return (
          <ul style={{ marginTop: '10px' }}>
            {content.items.map((item, idx) => <li key={idx}>{item}</li>)}
          </ul>
        );
    
      case 'heading':
        const HeadingTag = `h${content.font_class}`; // Assuming font_class is a number from 1 to 6
        return <HeadingTag style={{ marginTop: '10px' }}>{content.text}</HeadingTag>;
    
      case 'quote':
        return (
          <blockquote style={{ marginTop: '10px', borderLeft: '5px solid #eee', paddingLeft: '10px' }}>
            <p>{content.text}</p>
            {content.author && <footer>{content.author}</footer>}
          </blockquote>
        );
  
      default:
        return null; 
    }
  };

  // Render FAQ items
  const renderFaqItems = (faqItems) => {
    return faqItems.map((item, index) => (
      <div key={index} style={{ cursor: "pointer", paddingLeft: "20px", paddingBottom: "10px", paddingTop: "10px" }} className="accordion-item border">
        <div className="accordion-header" onClick={() => toggleAccordion(index)}>
          <h5 className="fw-bolder"><FontAwesomeIcon icon={faPlus} className="me-2" /> {item.title}</h5>
          <span className={`accordion-icon ${activeIndex === index ? 'active' : ''}`}></span>
        </div>
        {activeIndex === index && (
          <div className="accordion-content">
           {item.component_content.map((content, idx) => (
            <DynamicComponent key={idx} content={content} />
          ))}
          </div>
        )}
      </div>
    ));
  };

  return (
    <>
      <NavigationBar />
      
      {/*-- Buyers FAQ Section --*/}
      <section className="buyers-faqs align-items-center justify-content-center overflow-hidden pt-5 pt-lg-6 pb-6 pb-lg-6 bg-white text-dark">
        <Row className="mx-3 mx-md-6 px-4">
          <Col xs={12} md={4} className="mb-4 mb-md-0"> {/* Adjusted column classes */}
            <h3 className="fw-bolder text-dark">Buyers<br/>Frequently Asked Questions</h3>
          </Col>
          <Col xs={12} md={8}>
            <div className="accordion">
              {renderFaqItems(buyerFaqs)}
            </div>
          </Col>
        </Row>
      </section>

      {/*-- Sellers FAQ Section --*/}
      <section className="sellers-faqs align-items-center justify-content-center overflow-hidden pt-2 pt-lg-2 pb-6 pb-lg-6 bg-white text-dark">
        <Row className="mx-3 mx-md-6 px-4">
          <Col xs={12} md={4} className="mb-4 mb-md-0"> {/* Adjusted column classes */}
            <h3 className="fw-bolder text-dark">Sellers<br/>Frequently Asked Questions</h3>
          </Col>
          <Col xs={12} md={8}>
            <div className="accordion">
              {renderFaqItems(supplierFaqs)}
            </div>
          </Col>
        </Row>
      </section>

      <Footer />
    </>
  );
};
