import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Col, Row, Form, Button } from '@themesberg/react-bootstrap';
import { getAllBrands } from '../../../api/private/brands';
import { getAllProducts } from '../../../api/private/products';
import { getAllUsers } from '../../../api/private/users';
import { getAllSuppliers } from '../../../api/private/suppliers';
import { getAllQuotes } from '../../../api/private/quotes';
import './DashboardOverview.css';

const DashboardOverview = () => {
  const [usersCount, setUsersCount] = useState(0);
  const [brandsCount, setBrandsCount] = useState(0);
  const [suppliersCount, setSuppliersCount] = useState(0);
  const [quotesCount, setQuotesCount] = useState(0);
  const [productsByBrand, setProductsByBrand] = useState([]);
  const [productsByCategory, setProductsByCategory] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    loadData();
  }, []);

  const loadData = (startDate, endDate) => {
    loadUsers(startDate, endDate);
    loadBrands(startDate, endDate);
    loadSuppliers(startDate, endDate);
    loadQuotes(startDate, endDate);
    loadProductsByBrand(startDate, endDate);
    loadProductsByCategory(startDate, endDate);
  };

  const loadUsers = (startDate, endDate) => {
    getAllUsers({ startDate, endDate }).then(response => {
      if (response.data.Result === 'OK') {
        setUsersCount(response.data.Records.length);
      } else {
        console.warn(response.data);
      }
    }).catch(error => {
      console.error(error);
    });
  };

  const loadBrands = (startDate, endDate) => {
    getAllBrands({ startDate, endDate }).then(response => {
      if (response.data.Result === 'OK') {
        setBrandsCount(response.data.Records.length);
      } else {
        console.warn(response.data);
      }
    }).catch(error => {
      console.error(error);
    });
  };

  const loadSuppliers = (startDate, endDate) => {
    getAllSuppliers({ startDate, endDate }).then(response => {
      if (response.data.Result === 'OK') {
        setSuppliersCount(response.data.Records.length);
      } else {
        console.warn(response.data);
      }
    }).catch(error => {
      console.error(error);
    });
  };

  const loadQuotes = (startDate, endDate) => {
    getAllQuotes({ startDate, endDate }).then(response => {
      if (response.data.Result === 'OK') {
        setQuotesCount(response.data.Records.length);
      } else {
        console.warn(response.data);
      }
    }).catch(error => {
      console.error(error);
    });
  };

  const loadProductsByBrand = (startDate, endDate) => {
    getAllProducts({ startDate, endDate }).then(response => {
      if (response.data.Result === 'OK') {
        const products = response.data.Records;
        const brandCounts = products.reduce((acc, product) => {
          acc[product.brand] = (acc[product.brand] || 0) + 1;
          return acc;
        }, {});
        const formattedData = Object.keys(brandCounts).map(brand => ({
          brand,
          count: brandCounts[brand]
        }));
        setProductsByBrand(formattedData);
      } else {
        console.warn(response.data);
      }
    }).catch(error => {
      console.error(error);
    });
  };

  const loadProductsByCategory = (startDate, endDate) => {
    getAllProducts({ startDate, endDate }).then(response => {
      if (response.data.Result === 'OK') {
        const products = response.data.Records;
        const categoryCounts = products.reduce((acc, product) => {
          acc[product.category] = (acc[product.category] || 0) + 1;
          return acc;
        }, {});
        const formattedData = Object.keys(categoryCounts).map(category => ({
          category,
          count: categoryCounts[category]
        }));
        setProductsByCategory(formattedData);
      } else {
        console.warn(response.data);
      }
    }).catch(error => {
      console.error(error);
    });
  };

  const handleFilterClick = () => {
    loadData(startDate, endDate);
  };

  return (
    <div className="dashboard-container">
      <Form className="date-filter-form mb-4 mx-0 d-flex align-items-end flex-wrap">
        <Form.Group controlId="startDate" className="me-3 mb-3">
          <Form.Label>Start Date</Form.Label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="form-control"
          />
        </Form.Group>
        <Form.Group controlId="endDate" className="me-3 mb-3">
          <Form.Label>End Date</Form.Label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className="form-control"
          />
        </Form.Group>
        <Button variant="primary" onClick={handleFilterClick} className="mb-3">
          Filter
        </Button>
      </Form>

      <div className="grid-container">
        <div className="grid-item">
          <div className="dash-card card">
            <div className="card-title">Users Count</div>
            <div className="card-value">{usersCount}</div>
          </div>
        </div>

        <div className="grid-item">
          <div className="dash-card card">
            <div className="card-title">Brands Count</div>
            <div className="card-value">{brandsCount}</div>
          </div>
        </div>

        <div className="grid-item">
          <div className="dash-card card">
            <div className="card-title">Suppliers Count</div>
            <div className="card-value">{suppliersCount}</div>
          </div>
        </div>

        <div className="grid-item">
          <div className="dash-card card">
            <div className="card-title">Quotes Count</div>
            <div className="card-value">{quotesCount}</div>
          </div>
        </div>
      </div>

      <div className="grid-container">
        <div className="chart-container">
          <div className="dash-card card">
            <div className="card-title">Products by Brand</div>
            <ApexCharts
              options={{
                chart: { id: 'products-by-brand' },
                labels: productsByBrand.map(p => p.brand)
              }}
              series={productsByBrand.map(p => p.count)}
              type="pie"
              height={350}
            />
          </div>
        </div>

        <div className="chart-container">
          <div className="dash-card card">
            <div className="card-title">Products by Category</div>
            <ApexCharts
              options={{
                chart: { id: 'products-by-category' },
                xaxis: { categories: productsByCategory.map(p => p.category) }
              }}
              series={[
                {
                  name: 'Products',
                  data: productsByCategory.map(p => p.count)
                }
              ]}
              type="bar"
              height={350}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardOverview;
