import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown } from '@themesberg/react-bootstrap';
import { useUserRole } from "../../../contexts/UserRoleContext";

export default () => {
  const { userRole } = useUserRole();
  const [sitemapContent, setSitemapContent] = useState(''); // Sitemap content state

  const handleSitemapContentChange = (event) => {
    setSitemapContent(event.target.value);
  };

  const exportToXML = () => {
    // Implement the logic to export the sitemap content to XML
    console.log('Exporting to XML');
  };

  const exportToCSV = () => {
    // Implement the logic to export the sitemap content to CSV
    console.log('Exporting to CSV');
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>SEO</Breadcrumb.Item>
            <Breadcrumb.Item active>Sitemap</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Sitemap</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
          </ButtonGroup>
        </div>
      </div>
      <Row className="mt-3">
        <Col>
          <Form.Group controlId="sitemapContent">
            <Form.Label>Sitemap Content</Form.Label>
            <Form.Control
              as="textarea"
              rows={10}
              value={sitemapContent}
              onChange={handleSitemapContentChange}
              style={{ height: 'auto', minHeight: '80vh' }} // Adjust the textarea to full page or as needed
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};
