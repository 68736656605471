import React, { useState, useEffect } from 'react';
import ProductCard from "../../../../components/ProductCards"
import { Container, Breadcrumb, Button, Accordion, Col, Row, Card } from '@themesberg/react-bootstrap';
import { getAllOfferings } from "../../../../api/private/offerings";
import { getAllProducts } from "../../../../api/private/products";
import { NavigationBar } from "../../../../components/frontendNav/Navigation";
import { Footer } from '../../../../components/frontendNav/Footer'
import CompareModal from '../../../../components/modals/CompareModal'

export default () => {
    const [showModal, setShowModal] = useState(false);
    // const [offerings, setOfferings] = useState([]);
    const [filters, setFilters] = useState({
        brand: [],
        location: [],
        vehicleType: []
    });

    const [displayedOfferings, setDisplayedOfferings] = useState([]);
    const [showLoadMore, setShowLoadMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(6);
    const [offerings, setOfferings] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);

    useEffect(() => {
        loadOfferingProducts();
    }, []);

    useEffect(() => {
        const newDisplayedOfferings = offerings.slice(startIndex, endIndex);
        setDisplayedOfferings(prevDisplayedOfferings => [...prevDisplayedOfferings, ...newDisplayedOfferings]);
        setShowLoadMore(endIndex < offerings.length);
    }, [startIndex, endIndex]);

    function loadOfferingProducts() {
        getAllProducts().then((_resp) => {
            if (_resp.data.Result === 'OK') {
                const offerings = _resp.data.Records;
                console.log(offerings)
                setOfferings(offerings);
                setDisplayedOfferings(offerings)
                console.log(displayedOfferings)
            } else {
                console.warn(_resp.data);
            }
        }).catch((_resp) => {
            console.error(_resp);
        });
    }

    const [openFilters, setOpenFilters] = useState(false);

    const handleToggleFilter = () => {
        setOpenFilters(!openFilters);
    };

    const handleLoadMore = () => {
        setStartIndex(endIndex);
        setEndIndex(endIndex + 6);
    };

    const handleFilterChange = (filter, value) => {
        const updatedFilters = { ...filters };
        const index = updatedFilters[filter].indexOf(value);
        if (index === -1) {
            updatedFilters[filter].push(value);
        } else {
            updatedFilters[filter].splice(index, 1);
        }
        setFilters(updatedFilters);
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const toggleProductSelection = (product) => {
        setSelectedProducts(prev => {
            const exists = prev.find(p => p._id === product._id);
            if (exists) {
                return prev.filter(p => p._id !== product._id);
            } else {
                return [...prev, product];
            }
        });
    };

    const handleToggleProductSelection = (product) => {
        setSelectedProducts(prev => {
            const exists = prev.some(p => p._id === product._id);
            if (exists) {
                return prev.filter(p => p._id !== product._id);
            } else {
                return [...prev, product];
            }
        });
    };

    const handleRemoveProductFromComparison = productId => {
        setSelectedProducts(prev => prev.filter(p => p._id !== productId));
    };


    return (
        <>
            {/* Navigation Bar */}
            <NavigationBar />
            <section className="partner-brands text-center align-items-center justify-content-center overflow-hidden pt-5 pt-lg-6 pb-6 pb-lg-6 bg-grey text-dark">
                <div style={{ width: '90%', textAlign: 'left', paddingLeft: '10%' }}>
                    <h1 className="fw-bold text-dark">Products</h1>
                    <div className="d-block mb-4 mb-md-0">
                        <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                            <Breadcrumb.Item>You are here:</Breadcrumb.Item>
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                            <Breadcrumb.Item active>Products</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
            </section>
            <section className="bg-white partner-brands align-items-center justify-content-center overflow-hidden pt-5 pt-lg-6 pb-6 pb-lg-6 text-dark">
                <Container>
                    <Row>
                        <Col sm={12} md={3}>
                            <div className="product-filter">
                                <h4 onClick={handleToggleFilter} style={{ cursor: 'pointer' }}>Filters</h4>
                                <Accordion show={openFilters}>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header className="bg-white">Brand</Accordion.Header>
                                        <Accordion.Body>
                                            {/* Checkbox options for Brand */}
                                            <div className="filter-option">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="Honda"
                                                        id="brandCheckboxHonda"
                                                        checked={filters.brand.includes('Honda')}
                                                        onChange={() => handleFilterChange('brand', 'Honda')}
                                                    />
                                                    <label className="form-check-label" htmlFor="brandCheckboxHonda">
                                                        Honda
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="Toyota"
                                                        id="brandCheckboxToyota"
                                                        checked={filters.brand.includes('Toyota')}
                                                        onChange={() => handleFilterChange('brand', 'Toyota')}
                                                    />
                                                    <label className="form-check-label" htmlFor="brandCheckboxToyota">
                                                        Toyota
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="CAT"
                                                        id="brandCheckboxCAT"
                                                        checked={filters.brand.includes('CAT')}
                                                        onChange={() => handleFilterChange('brand', 'CAT')}
                                                    />
                                                    <label className="form-check-label" htmlFor="brandCheckboxCAT">
                                                        CAT
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="Heister"
                                                        id="brandCheckboxHeister"
                                                        checked={filters.brand.includes('Heister')}
                                                        onChange={() => handleFilterChange('brand', 'Heister')}
                                                    />
                                                    <label className="form-check-label" htmlFor="brandCheckboxHeister">
                                                        Heister
                                                    </label>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header className="bg-white">Location</Accordion.Header>
                                        <Accordion.Body>
                                            {/* Checkbox options for Location */}
                                            <div className="filter-option">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="Eastern Cape"
                                                        id="locationCheckboxEasternCape"
                                                        checked={filters.location.includes('Eastern Cape')}
                                                        onChange={() => handleFilterChange('location', 'Eastern Cape')}
                                                    />
                                                    <label className="form-check-label" htmlFor="locationCheckboxEasternCape">
                                                        Eastern Cape
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="Free State"
                                                        id="locationCheckboxFreeState"
                                                        checked={filters.location.includes('Free State')}
                                                        onChange={() => handleFilterChange('location', 'Free State')}
                                                    />
                                                    <label className="form-check-label" htmlFor="locationCheckboxFreeState">
                                                        Free State
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="Gauteng"
                                                        id="locationCheckboxGauteng"
                                                        checked={filters.location.includes('Gauteng')}
                                                        onChange={() => handleFilterChange('location', 'Gauteng')}
                                                    />
                                                    <label className="form-check-label" htmlFor="locationCheckboxGauteng">
                                                        Gauteng
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="KwaZulu-Natal"
                                                        id="locationCheckboxKwaZuluNatal"
                                                        checked={filters.location.includes('KwaZulu-Natal')}
                                                        onChange={() => handleFilterChange('location', 'KwaZulu-Natal')}
                                                    />
                                                    <label className="form-check-label" htmlFor="locationCheckboxKwaZuluNatal">
                                                        KwaZulu-Natal
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="Limpopo"
                                                        id="locationCheckboxLimpopo"
                                                        checked={filters.location.includes('Limpopo')}
                                                        onChange={() => handleFilterChange('location', 'Limpopo')}
                                                    />
                                                    <label className="form-check-label" htmlFor="locationCheckboxLimpopo">
                                                        Limpopo
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="Mpumalanga"
                                                        id="locationCheckboxMpumalanga"
                                                        checked={filters.location.includes('Mpumalanga')}
                                                        onChange={() => handleFilterChange('location', 'Mpumalanga')}
                                                    />
                                                    <label className="form-check-label" htmlFor="locationCheckboxMpumalanga">
                                                        Mpumalanga
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="North West"
                                                        id="locationCheckboxNorthWest"
                                                        checked={filters.location.includes('North West')}
                                                        onChange={() => handleFilterChange('location', 'North West')}
                                                    />
                                                    <label className="form-check-label" htmlFor="locationCheckboxNorthWest">
                                                        North West
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="Northern Cape"
                                                        id="locationCheckboxNorthernCape"
                                                        checked={filters.location.includes('Northern Cape')}
                                                        onChange={() => handleFilterChange('location', 'Northern Cape')}
                                                    />
                                                    <label className="form-check-label" htmlFor="locationCheckboxNorthernCape">
                                                        Northern Cape
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="Western Cape"
                                                        id="locationCheckboxWesternCape"
                                                        checked={filters.location.includes('Western Cape')}
                                                        onChange={() => handleFilterChange('location', 'Western Cape')}
                                                    />
                                                    <label className="form-check-label" htmlFor="locationCheckboxWesternCape">
                                                        Western Cape
                                                    </label>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header className="bg-white">Vehicle Type</Accordion.Header>
                                        <Accordion.Body>
                                            {/* Checkbox options for Vehicle Type */}
                                            <div className="filter-option">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="Forklift"
                                                        id="vehicleTypeCheckboxForklift"
                                                        checked={filters.vehicleType.includes('Forklift')}
                                                        onChange={() => handleFilterChange('vehicleType', 'Forklift')}
                                                    />
                                                    <label className="form-check-label" htmlFor="vehicleTypeCheckboxForklift">
                                                        Forklift
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="Truck"
                                                        id="vehicleTypeCheckboxTruck"
                                                        checked={filters.vehicleType.includes('Truck')}
                                                        onChange={() => handleFilterChange('vehicleType', 'Truck')}
                                                    />
                                                    <label className="form-check-label" htmlFor="vehicleTypeCheckboxTruck">
                                                        Truck
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value="Crane"
                                                        id="vehicleTypeCheckboxCrane"
                                                        checked={filters.vehicleType.includes('Crane')}
                                                        onChange={() => handleFilterChange('vehicleType', 'Crane')}
                                                    />
                                                    <label className="form-check-label" htmlFor="vehicleTypeCheckboxCrane">
                                                        Crane
                                                    </label>
                                                </div>
                                                {/* Add similar checkboxes for other vehicle types */}
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Col>
                        <Col xs={12} md={9}>
                            <div className="d-flex justify-content-between align-items-end">
                                <h4>Select and compare products</h4>
                                {selectedProducts.length >= 2 && (
                                    <Button onClick={() => setShowModal(true)}>Compare</Button> 
                                )}
                            </div>
                            <hr />
                            <div className="shop-page">
                                <div className="product-list row">
                                    {displayedOfferings.length > 0 ? (
                                        displayedOfferings?.map(prod => (
                                            <Col className="mb-4" key={prod._id} xs={12} md={4}>
                                                <ProductCard prod={prod}
                                                    openModal={() => setShowModal(true)}
                                                    toggleProductSelection={toggleProductSelection}
                                                    isSelected={selectedProducts.some(p => p._id === prod._id)} />
                                            </Col>
                                        ))
                                    ) : (
                                        <h3 className="text-center">No product offerings available</h3>
                                    )}
                                    {showLoadMore && (
                                        <div className="text-center mt-4">
                                            <Button variant="primary" onClick={handleLoadMore}>Load More</Button>
                                        </div>
                                    )}
                                    {showModal && <CompareModal selectedProducts={selectedProducts} onClose={() => setShowModal(false)} onRemoveProduct={handleRemoveProductFromComparison} />}                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section >
            {/* Footer */}
            < Footer />
        </>
    );
};
